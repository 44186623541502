import memoize from "lodash/memoize";
import uniq from "lodash/uniq";
import { type RubyCountry, type RubyRegion, type RubyRegionId } from "../services/backend/RubyData";
import { SUPPORTED_COUNTRY_CODES } from "./vars";

import countryData from "../data/world-countries.json";
import languageData from "../data/world-languages.json";

export interface Country {
    name?: string;
    flagEmoji?: string;
    continent?: string;
    countryCode?: string;
}

export function getCountry(countryCode: string): Country {
    const country = countryData.find((data) => data.cca2 === countryCode);
    if (!country) {
        throw new Error("Unknown country code: " + countryCode);
    }
    return {
        name: country.name.common,
        flagEmoji: country.flag,
        continent: country.region,
        countryCode,
    };
}

export function getCountries(countryCodes: string[] = []) {
    return countryCodes.map((code) => getCountry(code));
}

export function getSupportedCountyCodes(filterCodes?: string[]) {
    const countries = SUPPORTED_COUNTRY_CODES;
    if (filterCodes) {
        return countries.filter((country) => filterCodes.includes(country));
    }
    return countries;
}

export function getSupportedCountries(filterCodes?: string[]) {
    return getCountries(getSupportedCountyCodes(filterCodes));
}

export function getCountriesAsOptions() {
    return countryData
        .sort((a, b) => {
            return a.name.common.localeCompare(b.name.common);
        })
        .reduce((options, country) => {
            options[country.cca2] = country.name.common;
            return options;
        }, {});
}

export const getContinentCountries = memoize(
    (countryCodes: string[]) => {
        const countries = getCountries(countryCodes);
        const continents = uniq(countries.map((country) => country.continent)).sort();
        return continents.map((continent) => {
            return {
                name: continent,
                countries: countries
                    .filter((country) => country.continent === continent)
                    .sort((a, b) => a.name.localeCompare(b.name)),
            };
        });
    },
    (countryCodes: string[]) => countryCodes.sort().join("-")
);

export function getCountryCodeByLanguage(languageCode: string): string {
    const language = getLanguage(languageCode);

    if (!language) {
        return null;
    }

    const country = countryData.find((country) => {
        const countryLang = Object.entries(country.languages ?? {}).find(
            ([_code, langName]) => langName === language.name
        );
        return !!countryLang;
    });

    return country?.cca2 ?? null;
}

export function getCountryCodesFromRegionIds(regionIds: RubyRegionId[], regions: RubyRegion[]): RubyCountry[] {
    return uniq(
        regionIds
            .map((id) => regions?.find((r) => r.id === id))
            .filter((r) => !!r)
            .map((r) => r.country)
    );
}

export function getRegionIdsForCountryCodes(countryCodes: RubyCountry[], regions: RubyRegion[]): RubyRegionId[] {
    return regions.filter((region) => countryCodes.includes(region.country)).map((r) => r.id);
}

export function getLanguage(languageCode: string) {
    return languageData.find((lang) => lang.code === languageCode);
}
