import capitalize from "lodash/capitalize";
import { type RubyEventSummaryReport } from "../services/backend/RubyData";
import { currencyFormatter } from "./currency";
import { type KeywordMetricsReportDetails, type MetricsReport } from "./metrics";
import { numberFormatter, percentFormatter, safeDivide, sumReducer } from "./number";
import { languageString } from "./text";
import { addGranularity, getTemporalRange, type ReportGranularity, type Temporal } from "./time";

export type EventsList = string[];

type EventSummaryReportTotals = RubyEventSummaryReport["totals"];
export interface EventReport extends EventSummaryReportTotals {
    //revenue: number;
    //unique: number;
    events: number;
    revenuePerEvent: number;
    revenuePerUniqueEvent: number;
    // May be computed on the fly in some cases
    costPerEvent?: number;
    costPerUniqueEvent?: number;
    returnOnSpend?: number;
}

export type EventKey = keyof Omit<EventReport, "totals">;
export interface TemporalEventReport extends Temporal, EventReport {
    // date: number;
    // granularity: ReportGranularity;
}

export interface EventKeywordReport extends EventReport {
    keyword: string;
}

export interface EventKeywordTemporalReport extends TemporalEventReport {
    keyword: string;
}

export interface EventSummaryReport extends EventReport {
    name: string;
}

export interface EventKeywordSummaryReport extends EventKeywordReport, KeywordMetricsReportDetails {
    costPerEvent: number;
}

export function eventNameToLabel(eventName: string) {
    if (!eventName) {
        return "";
    }

    eventName = eventName.replace(/^(Adjust_|AppsFlyer_af_|AppsFlyer_|af_)(.+?)/gi, "$2");
    eventName = eventName.replace(/_/gi, " ").trim();

    return capitalize(eventName);
}

export function createEventsSpendReport(
    rubyReport: RubyEventSummaryReport | EventReport,
    metrics: MetricsReport
): Required<EventReport> {
    if (!rubyReport) {
        return null;
    }
    if (!isEventReport(rubyReport)) {
        rubyReport = createEventsReport(rubyReport);
    }
    return {
        ...rubyReport,
        costPerEvent: safeDivide(metrics?.spend, rubyReport.events),
        costPerUniqueEvent: safeDivide(metrics?.spend, rubyReport.unique),
        returnOnSpend: safeDivide(rubyReport.revenue, metrics?.spend),
    };
}

function isEventReport(report: object): report is EventReport {
    return !!report && "revenue" in report && "unique" in report && "events" in report;
}

export function createEventsReport(rubyReport: RubyEventSummaryReport): EventReport {
    return calculateAdditionalEvents({
        ...rubyReport.totals,
        events: rubyReport.count,
    });
}

export function calculateAdditionalEvents(
    report: Omit<EventReport, "revenuePerEvent" | "revenuePerUniqueEvent">
): EventReport {
    return {
        ...report,
        revenuePerEvent: safeDivide(report.revenue, report.events),
        revenuePerUniqueEvent: safeDivide(report.revenue, report.unique),
    };
}

export function aggregateEvents(events: EventReport[]): EventReport {
    const totalEvents: EventReport = {
        events: 0,
        revenue: 0,
        revenuePerEvent: 0,
        revenuePerUniqueEvent: 0,
        unique: 0,
    };
    for (const key in totalEvents) {
        totalEvents[key] = events?.reduce<number>(sumReducer(key as keyof EventReport), 0) ?? 0;
    }
    return calculateAdditionalEvents(totalEvents);
}

export function aggregateEventsAtGranularity(
    reports: TemporalEventReport[],
    granularity: ReportGranularity,
    from?: number,
    to?: number
): TemporalEventReport[] {
    //TODO this currently assumes we start with HOURLY input data

    if (!from || !to) {
        [from, to] = getTemporalRange(reports);
    }

    const reportGroups = generateEmptyTemporalReport(from, to, granularity);

    reports.forEach((report) => {
        const groupIdx = reportGroups.findIndex(
            (group) => group.date <= report.date && addGranularity(group.date, granularity) > report.date
        );
        reportGroups[groupIdx] = {
            ...reportGroups[groupIdx],
            ...aggregateEvents([reportGroups[groupIdx], report]),
        };
    });

    return reportGroups;
}

function generateEmptyTemporalReport(from: number, to: number, granularity: ReportGranularity): TemporalEventReport[] {
    const reportGroups: TemporalEventReport[] = [];
    let i = from;
    while (i <= to) {
        reportGroups.push({
            date: i,
            granularity,
            events: 0,
            unique: 0,
            revenue: 0,
            revenuePerEvent: 0,
            revenuePerUniqueEvent: 0,
        });
        i = addGranularity(i, granularity);
    }
    return reportGroups;
}

export function formatEvents(data: EventReport, currency: string): { [key in EventKey]: string } {
    return Object.entries(data).reduce((acc, [key, value]) => {
        acc[key] = eventFormatter(key as EventKey, currency)(value as number);
        return acc;
    }, {}) as { [key in EventKey]: string };
}

export const eventLabels = {
    revenue: languageString("campaign.events.stat.revenue"),
    events: languageString("campaign.events.stat.events"),
    unique: languageString("campaign.events.stat.uniqueEvents"),
    revenuePerEvent: languageString("campaign.events.stat.revenuePerEvent"),
    revenuePerUniqueEvent: languageString("campaign.events.stat.revenuePerUniqueEvent"),
    costPerEvent: languageString("campaign.events.stat.costPerEvent"),
    costPerUniqueEvent: languageString("campaign.events.stat.costPerUniqueEvent"),
    returnOnSpend: languageString("campaign.events.stat.returnOnSpend"),
};

export const eventIcon = (eventId: string, statKey: keyof EventReport) => {
    switch (statKey) {
        case "revenue":
            return "Rocket";
        case "events":
            return "Target";
        case "unique":
            return "Impressions";
        case "revenuePerEvent":
            return "Chart";
        case "revenuePerUniqueEvent":
            return "ThumbsUp";
        case "costPerEvent":
            return "Search";
        case "costPerUniqueEvent":
            return "Tap";
        case "returnOnSpend":
            return "AB";
        default:
            return "Chart";
    }
};

export const eventFormatter = (statKey: keyof EventReport, currency: string) => {
    switch (statKey) {
        case "revenue":
        case "revenuePerEvent":
        case "revenuePerUniqueEvent":
        case "costPerEvent":
        case "costPerUniqueEvent":
            return currencyFormatter(currency);
        case "returnOnSpend":
            return percentFormatter(1);
        case "events":
        case "unique":
        default:
            return numberFormatter();
    }
};

export const eventDescending = (eventId: string, statKey: keyof EventReport) => {
    switch (statKey) {
        case "costPerEvent":
        case "costPerUniqueEvent":
            return true;
        case "revenue":
        case "revenuePerEvent":
        case "revenuePerUniqueEvent":
        case "events":
        case "unique":
        default:
            return false;
    }
};

export const eventNoColor = (eventId: string, statKey: keyof EventReport) => {
    switch (statKey) {
        case "revenue":
        case "revenuePerEvent":
        case "revenuePerUniqueEvent":
        case "costPerEvent":
        case "costPerUniqueEvent":
        case "events":
        case "unique":
        default:
            return false;
    }
};

export const eventIsPercent = (eventId: string, statKey: keyof EventReport) => {
    switch (statKey) {
        case "returnOnSpend":
            return true;
        case "revenue":
        case "revenuePerEvent":
        case "revenuePerUniqueEvent":
        case "costPerEvent":
        case "costPerUniqueEvent":
        case "events":
        case "unique":
        default:
            return false;
    }
};
