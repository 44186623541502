import moment, { type Moment } from "moment";
import { formatNumber } from "./number";
import { languageString } from "./text";

export const minimumTimeFilterDate = moment.utc().subtract(24, "months").startOf("month").startOf("day");
export const MIN_POSSIBLE_DATE = 946684800000; // 1st Jan 2000

export enum TimeFilterType {
    TODAY = "TODAY",
    YESTERDAY = "YESTERDAY",
    LAST_7 = "LAST_7",
    LAST_30 = "LAST_30",
    THIS_MONTH = "THIS_MONTH",
    LAST_MONTH = "LAST_MONTH",
    TOTAL = "TOTAL",
    CUSTOM = "CUSTOM",
}

export interface TimeFilter {
    description: string;
    to: Moment;
    from: Moment;
}

export function getTimeFilter(
    type: TimeFilterType,
    from?: moment.Moment,
    to?: moment.Moment,
    campaignCreationDate?: moment.Moment
): TimeFilter {
    let filter: TimeFilter = null;
    switch (type) {
        case TimeFilterType.TODAY: {
            filter = {
                description: languageString("ui.timeFilter.today", "Today"),
                from: moment.utc().startOf("day"),
                to: moment.utc().endOf("day"),
            };
            break;
        }

        case TimeFilterType.YESTERDAY: {
            filter = {
                description: languageString("ui.timeFilter.yesterday", "Yesterday"),
                from: moment.utc().subtract(1, "day").startOf("day"),
                to: moment.utc().subtract(1, "day").endOf("day"),
            };
            break;
        }

        case TimeFilterType.LAST_7: {
            filter = {
                description: languageString("ui.timeFilter.last7", "Last 7 days"),
                from: moment.utc().subtract(7, "days").startOf("day"),
                to: moment.utc().subtract(1, "day").endOf("day"),
            };
            break;
        }

        case TimeFilterType.LAST_30: {
            filter = {
                description: languageString("ui.timeFilter.last30", "Last 30 days"),
                from: moment.utc().subtract(30, "days").startOf("day"),
                to: moment.utc().subtract(1, "day").endOf("day"),
            };
            break;
        }

        case TimeFilterType.THIS_MONTH: {
            filter = {
                description: languageString("ui.timeFilter.thisMonth", "Month to date"),
                from: moment.utc().startOf("month").startOf("day"),
                to: moment.utc().endOf("day"),
            };
            break;
        }

        case TimeFilterType.LAST_MONTH: {
            filter = {
                description: languageString("ui.timeFilter.lastMonth", "Last month"),
                from: moment.utc().subtract(1, "month").startOf("month").startOf("day"),
                to: moment.utc().subtract(1, "month").endOf("month").endOf("day"),
            };
            break;
        }

        case TimeFilterType.TOTAL: {
            filter = {
                description: languageString("ui.timeFilter.total", "All time"),
                from: moment.utc(MIN_POSSIBLE_DATE),
                to: moment.utc().endOf("day"),
            };
            break;
        }

        case TimeFilterType.CUSTOM: {
            if (!from) {
                from = moment.utc(MIN_POSSIBLE_DATE);
            }
            if (!to) {
                to = moment.utc();
            }
            filter = {
                description: languageString("ui.timeFilter.custom", "Custom"),
                from: from.startOf("day"),
                to: to.endOf("day"),
            };
            break;
        }
    }

    if (campaignCreationDate && filter.from.isBefore(campaignCreationDate)) {
        filter.from = moment(campaignCreationDate).startOf("day");
    }
    if (campaignCreationDate && filter.to.isBefore(campaignCreationDate)) {
        filter = {
            description: filter.description ?? languageString("ui.timeFilter.total", "All time"),
            from: moment.utc(campaignCreationDate).startOf("day"),
            to: moment.utc().endOf("day"),
        };
    }

    filter.from = filter.from.startOf("day");
    filter.to = filter.to.endOf("day");

    return filter;
}

export function getPreviousTimeFilter(timeFilter: TimeFilter, campaignCreationDate: number): TimeFilter {
    const from = moment.utc(timeFilter.from);
    const to = moment.utc(timeFilter.to);
    const creationDate = moment.utc(campaignCreationDate);
    let filter: TimeFilter = null;

    // This is off by 1 (hence the +1) because the start date includes itself but wont be counted by the diff.
    const lengthInDays = Math.abs(to.diff(from, "days")) + 1;
    if (moment.utc(from).subtract(lengthInDays, "days").isBefore(creationDate.startOf("day"))) {
        return null;
    }

    if (lengthInDays > 152) {
        const lengthInMonths = Math.abs(to.diff(from, "months"));
        filter = getTimeFilter(
            TimeFilterType.CUSTOM,
            from.subtract(lengthInMonths, "months"),
            to.subtract(lengthInMonths, "months"),
            creationDate
        );
        filter.description = languageString("ui.timeFilter.previous.months", "previous {1} months", [
            formatNumber(lengthInMonths),
        ]);
    } else if (lengthInDays > 31) {
        const lengthInWeeks = Math.abs(to.diff(from, "weeks"));
        filter = getTimeFilter(
            TimeFilterType.CUSTOM,
            from.subtract(lengthInWeeks, "weeks"),
            to.subtract(lengthInWeeks, "weeks"),
            creationDate
        );
        filter.description = languageString("ui.timeFilter.previous.weeks", "previous {1} weeks", [
            formatNumber(lengthInWeeks),
        ]);
    } else if (lengthInDays > 1) {
        filter = getTimeFilter(
            TimeFilterType.CUSTOM,
            from.subtract(lengthInDays, "days"),
            to.subtract(lengthInDays, "days"),
            creationDate
        );
        filter.description = languageString("ui.timeFilter.previous.days", "previous {1} days", [
            formatNumber(lengthInDays),
        ]);
    } else {
        filter = getTimeFilter(TimeFilterType.CUSTOM, from.subtract(1, "day"), to.subtract(1, "day"), creationDate);
        filter.description = languageString("ui.timeFilter.previous.day", "previous day");
    }

    return filter;
}

export function getAllTimeFilters(creationDate?: Moment) {
    return [
        getTimeFilter(TimeFilterType.TODAY, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.YESTERDAY, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.LAST_7, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.LAST_30, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.THIS_MONTH, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.LAST_MONTH, undefined, undefined, creationDate),
        getTimeFilter(TimeFilterType.TOTAL, undefined, undefined, creationDate),
    ];
}
