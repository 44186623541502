import { isExperiment, isImportedCampaign } from "@/selectors/campaignSelectors";
import type { RubyCampaignId, RubyTeamId } from "@/services/backend/RubyData";
import { useStateSelector } from "@/store/stateHooks";
import { Link, useLocation, useParams } from "react-router-dom";
import { languageString } from "../../../utilities/text";
import { getUrl } from "../../../utilities/url";
import { useCampaignDataLoader } from "../campaignDataLoader/CampaignDataLoader";
import NavigationButton from "../navigation/NavigationButton";
import "./breadcrumbs.css";

interface BreadcrumbsProps {
    pageName?: string;
}

export interface Breadcrumb {
    url?: string;
    label: string;
}

export default function Breadcrumbs({ pageName }: BreadcrumbsProps) {
    const params = useParams();
    const path = useLocation();
    const teamId = parseInt(params.teamId, 10);
    const campaignId = parseInt(params.campaignId, 10);
    const experimentId = parseInt(params.experimentId, 10);
    const keywordId = parseInt(params.keywordId, 10);
    const eventName = params.eventName;

    const isLoggedIn = useStateSelector((state) => state.user.isLoggedIn);

    const isCampaignsListing = getUrl.campaigns(teamId) === path.pathname;
    const isCampaignPage = path.pathname.startsWith(getUrl.campaign(teamId, campaignId));
    const isCampaignConfig = isCampaignPage && path.pathname.includes("/config/");
    const isExperimentsPage = path.pathname.startsWith(getUrl.experiment(teamId, experimentId));
    const isImportedCampaignPage = path.pathname.startsWith(getUrl.importedCampaign(teamId, campaignId));

    const isKeywordPage = path.pathname.startsWith(getUrl.campaignKeyword(teamId, campaignId, keywordId));
    const isEventPage = path.pathname.startsWith(getUrl.campaignEvent(teamId, campaignId, eventName));

    return (
        <div className="breadcrumbs">
            <NavigationButton />
            <nav className="breadcrumbs-nav">
                {!isCampaignsListing && (
                    <span className="breadcrumbs-crumb">
                        {isLoggedIn ? (
                            <Link to={teamId ? getUrl.campaigns(teamId) : getUrl.campaignsRoot()}>
                                {languageString("navigation.campaigns.listing", "Campaign Listing")}
                            </Link>
                        ) : (
                            <Link to={getUrl.home()}>{languageString("breadcrumbs.home", "Home")}</Link>
                        )}
                    </span>
                )}

                {isCampaignPage && (
                    <>
                        <span className="breadcrumbs-crumb">
                            <CampaignLink teamId={teamId} campaignId={campaignId} />
                        </span>
                        {isCampaignConfig ? (
                            <span className="breadcrumbs-crumb breadcrumbs-crumb_active">
                                {languageString("navigation.config.title", "Configuration")}
                            </span>
                        ) : (
                            <span className="breadcrumbs-crumb breadcrumbs-crumb_active">
                                {languageString("navigation.reporting.title", "Reporting")}
                            </span>
                        )}
                    </>
                )}

                {isExperimentsPage && (
                    <>
                        <span className="breadcrumbs-crumb">
                            <Link to={getUrl.experiments(teamId)}>
                                {languageString("navigation.campaigns.experiments", "Experiments")}
                            </Link>
                        </span>
                        <span className="breadcrumbs-crumb">
                            <CampaignLink teamId={teamId} campaignId={experimentId} />
                        </span>
                    </>
                )}

                {isImportedCampaignPage && (
                    <>
                        <span className="breadcrumbs-crumb">
                            <Link to={getUrl.importedCampaigns(teamId)}>
                                {languageString("navigation.campaigns.importedCampaigns", "Imported Campaigns")}
                            </Link>
                        </span>
                        <span className="breadcrumbs-crumb">
                            <CampaignLink teamId={teamId} campaignId={campaignId} />
                        </span>
                    </>
                )}

                {isKeywordPage && (
                    <span className="breadcrumbs-crumb">
                        <Link to={getUrl.campaignKeywords(teamId, campaignId)}>
                            {languageString("navigation.reporting.keywords")}
                        </Link>
                    </span>
                )}

                {isEventPage && (
                    <span className="breadcrumbs-crumb">
                        <Link to={getUrl.campaignEvents(teamId, campaignId)}>
                            {languageString("navigation.reporting.events")}
                        </Link>
                    </span>
                )}

                {pageName && <span className="breadcrumbs-crumb breadcrumbs-crumb_active">{pageName}</span>}
            </nav>
        </div>
    );
}

function CampaignLink({ campaignId, teamId }: { campaignId: RubyCampaignId; teamId: RubyTeamId }) {
    const loader = useCampaignDataLoader(campaignId, teamId);
    const campaign = loader.useCampaign();

    let link = getUrl.campaign(teamId, campaignId);

    if (isExperiment(campaign.data)) {
        link = getUrl.experiment(teamId, campaignId);
    } else if (isImportedCampaign(campaign.data)) {
        link = getUrl.importedCampaign(teamId, campaignId);
    }

    return <Link to={link}>{campaign.data?.name}</Link>;
}
