import isNumber from "lodash/isNumber";
import { type ReactNode } from "react";
import AnimateHeight from "react-animate-height";
import useMeasure from "../../../utilities/useMeasure";
import "./autoHeight.css";

interface AutoHeightProps {
    children?: ReactNode;
    fixedHeight?: number;
    className?: string;
    easing?: string;
    duration?: number;
}

const AutoHeight = ({ children, fixedHeight, className, easing, duration }: AutoHeightProps) => {
    const { ref, bounds } = useMeasure<HTMLDivElement>();

    return (
        <AnimateHeight
            className={className}
            height={isNumber(fixedHeight) ? fixedHeight : "auto"}
            easing={easing ?? "cubic-bezier(.28,2.01,.62,.78)"}
            duration={duration ?? 350}
        >
            <div className={`autoHeight`} style={{ height: bounds.height > 0 ? bounds.height : "auto" }}>
                <div ref={ref}>{children}</div>
            </div>
        </AnimateHeight>
    );
};

export default AutoHeight;
