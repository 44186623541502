import type React from "react";

export class PortalService {
    private zIndex: number;
    private portals: Map<string, React.Component>;

    constructor() {
        this.zIndex = 100;
        this.portals = new Map<string, React.Component>();
    }

    getZIndex(): number {
        return this.zIndex++;
    }

    registerPortal(id: string, portal: React.Component) {
        this.portals.set(id, portal);
    }

    unregisterPortal(id: string) {
        this.portals.delete(id);
    }

    getPortal(id: string): React.Component {
        return this.portals.get(id);
    }
}
