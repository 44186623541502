import { useStateSelector } from "@/store/stateHooks";
import React, { Suspense } from "react";
import Helmet from "react-helmet";
import { Navigate, useLocation } from "react-router-dom";
import { isSnapshot } from "../../../utilities/snapshot";
import { languageString } from "../../../utilities/text";
import { getPostLoginUrl, getUrl } from "../../../utilities/url";
import NavigationPage from "../../assemblies/navigation/NavigationPage";
import SpinnerOverlay from "../spinnerOverlay/SpinnerOverlay";

interface ComponentWithMetaProps {
    children?: React.ReactNode;
    canonicalPath?: string;
    title?: string;
    description?: string;
    withNavigation?: boolean;
    asidePanel?: React.ReactElement;
    hidePrerender?: boolean;
}

export function PublicRoute({
    children,
    canonicalPath,
    title,
    description,
    hidePrerender,
    withNavigation,
    asidePanel,
}: ComponentWithMetaProps) {
    return (
        <SecureRoute
            canonicalPath={canonicalPath}
            title={title}
            description={description}
            hidePrerender={hidePrerender}
            withNavigation={withNavigation}
            asidePanel={asidePanel}
            isPrivate={false}
        >
            {children}
        </SecureRoute>
    );
}

export function PrivateRoute({
    children,
    canonicalPath,
    title,
    description,
    withNavigation,
    asidePanel,
}: ComponentWithMetaProps) {
    return (
        <SecureRoute
            canonicalPath={canonicalPath}
            title={title}
            description={description}
            withNavigation={withNavigation}
            asidePanel={asidePanel}
            hidePrerender={true}
            isPrivate={true}
        >
            {children}
        </SecureRoute>
    );
}

function SecureRoute({
    children,
    canonicalPath,
    title,
    description,
    hidePrerender,
    withNavigation,
    asidePanel,
    isPrivate,
}: ComponentWithMetaProps & {
    isPrivate?: boolean;
}) {
    const location = useLocation();
    const { isLoggedIn, loginIsRestoring, emailVerified } = useStateSelector((state) => state.user);
    const isVerified = emailVerified || location.pathname === getUrl.verifyEmail();

    if (loginIsRestoring) {
        return <SpinnerOverlay isStatic />;
    }

    if (!isPrivate && isLoggedIn) {
        return <Navigate to={getPostLoginUrl()} />;
    }
    if (isPrivate && !isLoggedIn) {
        return <Navigate to={getUrl.login(encodeURIComponent(location.pathname + location.search))} />;
    }
    if (isPrivate && !isVerified) {
        return <Navigate to={getUrl.verifyEmail()} />;
    }
    return (
        <ComponentWithMeta
            canonicalPath={canonicalPath}
            title={title}
            description={description}
            withNavigation={withNavigation}
            asidePanel={asidePanel}
            hidePrerender={hidePrerender}
        >
            {children}
        </ComponentWithMeta>
    );
}

export function SimpleRoute({
    children,
    canonicalPath,
    title,
    description,
    hidePrerender,
    withNavigation,
    asidePanel,
}: ComponentWithMetaProps) {
    return (
        <ComponentWithMeta
            canonicalPath={canonicalPath}
            title={title}
            description={description}
            withNavigation={withNavigation}
            asidePanel={asidePanel}
            hidePrerender={hidePrerender}
        >
            {children}
        </ComponentWithMeta>
    );
}

function ComponentWithMeta({
    children,
    canonicalPath,
    withNavigation,
    title,
    description,
    asidePanel,
    hidePrerender,
}: ComponentWithMetaProps) {
    const location = useLocation();

    if (hidePrerender && isSnapshot) {
        return <SpinnerOverlay isStatic>{languageString("ui.suspense.loading", "Loading Platform")}</SpinnerOverlay>;
    }

    const spinner = <SpinnerOverlay className="u-mb128 u-mt128" isStatic />;
    const path = canonicalPath ?? location.pathname;

    return (
        <>
            <Helmet>
                <link rel="canonical" href={"https://astra.redboxmobile.com" + path} />
                {title && (
                    <title>
                        {title} - {languageString("ui.title.company", "Redbox Platform")}
                    </title>
                )}
                {description && <meta name="description" content={description} />}
            </Helmet>
            {withNavigation ? (
                <NavigationPage asidePanel={asidePanel ? React.cloneElement(asidePanel) : undefined}>
                    <Suspense fallback={spinner}>{children}</Suspense>
                </NavigationPage>
            ) : (
                <Suspense fallback={spinner}>{children}</Suspense>
            )}
        </>
    );
}
