import moment from "moment";
import { put, select, takeEvery } from "redux-saga/effects";
import { RequestActionState, type RequestAction, type RequestActions } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import { type AsyncData, type PaginatedAsyncData, type State } from "../reducers/domain";
import { type RubyListResponse } from "../services/backend/RubyData";
import { hasRequested } from "./requests";
import { delay } from "./time";
import { MAX_DATA_AGE_MINUTES } from "./vars";

export function maxDataAge() {
    return moment.utc().subtract(MAX_DATA_AGE_MINUTES, "minutes").valueOf();
}

export function* selectFromState<T>(selector: (state: State) => T): Generator<unknown, T> {
    const data: T = (yield select(selector)) as T;
    return data;
}

/**
 * Use this to select a value from state when you aren't sure if it has loaded yet
 */
export function* selectAsyncDataFromState<T>(selector: (state: State) => AsyncData<T>): Generator<unknown, T> {
    let data = (yield select(selector)) as AsyncData<T>;

    if (!hasRequested(data)) {
        // Give it 500ms to see if the request gets made.
        // This is a defence against any race conditions in the ordering of calls.
        let retries = 5;
        do {
            yield delay(100);
            data = (yield select(selector)) as AsyncData<T>;
            retries--;
        } while (retries > 0 && !hasRequested(data));
    }

    if (data.isRequesting) {
        do {
            yield delay(100);
            data = (yield select(selector)) as AsyncData<T>;
        } while (data.isRequesting);
    }

    if (data.errorMessage) {
        throw new Error(`AsyncData was not selectable: "${data.errorMessage}"`);
    }

    if (data.success) {
        return data.data;
    }

    throw new Error("AsyncData was not selectable: Request has not been made");
}

export function* runRequestAction<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, Res>["request"]>,
    actions: RequestActions<Name, Req, Res>,
    request: (req: Req) => Promise<Res>
) {
    yield put(actions.call(action.payload.request));
    try {
        const data = (yield request(action.payload.request)) as Res;
        yield put(actions.success(action.payload.request, data));
    } catch (err) {
        if (err instanceof Error) {
            yield put(actions.error(action.payload.request, err.name + ": " + err.message));
        } else {
            yield put(actions.error(action.payload.request, err as string));
        }
    }
}

const dataRequestLoops = {} as Record<
    string,
    {
        count: number;
        reqAt: number;
    }
>;

function abortDataRequestAction<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, Res>["request"]>,
    data: AsyncData<unknown>,
    message: "hasData" | "isRequesting"
) {
    const now = Date.now();
    const reqKey = `${action.type as string}__${JSON.stringify(action.payload.request)}`;
    let prevReq = dataRequestLoops[reqKey];
    if (!prevReq || prevReq.reqAt < now - 50) {
        prevReq = { count: 0, reqAt: now };
    }

    if (prevReq?.count < 10) {
        if (message === "hasData") {
            console.log("Action discarded as data is cached", action.type, data);
        } else if (message === "isRequesting") {
            console.log("Action discarded as duplicate request", action.type, data);
        }

        dataRequestLoops[reqKey] = {
            count: prevReq.count + 1,
            reqAt: now,
        };
    } else {
        console.error(action, data);
        throw new Error("Infinite loop detected, aborting data request");
    }
}

export function* runDataRequestAction<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, Res>["request"]>,
    actions: RequestActions<Name, Req, Res>,
    lookup: (state: State, req: Req) => AsyncData<unknown>,
    request: (req: Req) => Promise<Res>
) {
    const refresh = action.payload.refresh ?? false;
    const data = yield* selectFromState((state) => lookup(state, action.payload.request));

    if (!refresh && data.success && data.lastUpdated > maxDataAge()) {
        abortDataRequestAction(action, data, "hasData");
    } else if (data.isRequesting) {
        abortDataRequestAction(action, data, "isRequesting");
    } else {
        yield* runRequestAction(action, actions, request);
    }
}

export function* runPaginatedDataAction<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, RubyListResponse<Res>>["request"]>,
    actions: RequestActions<Name, Req, RubyListResponse<Res>>,
    lookup: (state: State, req: Req) => PaginatedAsyncData<unknown[]>,
    request: (req: Req, opts: { take: number; skip: number }) => Promise<RubyListResponse<Res>>
) {
    const refresh = action.payload.refresh ?? false;
    const data = yield* selectFromState((state) => lookup(state, action.payload.request));
    const opts = {
        take: 100,
        skip: 0,
    };
    if (data && data.recordsLoaded && !refresh) {
        opts.skip = data.recordsLoaded;
    }

    if (!refresh && data.success && data.lastUpdated > maxDataAge() && opts.skip === 0) {
        abortDataRequestAction(action, data, "hasData");
    } else if (data.isRequesting) {
        abortDataRequestAction(action, data, "isRequesting");
    } else {
        yield* runRequestAction(action, actions, (req) => request(req, opts));
    }
}

export function* runRequestSaga<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, Res>["request"]>,
    actions: RequestActions<Name, Req, Res>,
    request: (req: Req) => Generator<unknown, Res>
) {
    yield put(actions.call(action.payload.request));
    try {
        const data = yield* request(action.payload.request);
        yield put(actions.success(action.payload.request, data));
    } catch (err) {
        if (err instanceof Error) {
            yield put(actions.error(action.payload.request, err.name + ": " + err.message));
        } else {
            yield put(actions.error(action.payload.request, err as string));
        }
    }
}

export function* runDataRequestSaga<Name, Req, Res>(
    action: ReturnType<RequestActions<Name, Req, Res>["request"]>,
    actions: RequestActions<Name, Req, Res>,
    lookup: (state: State, req: Req) => AsyncData<unknown>,
    request: (req: Req) => Generator<unknown, Res>
) {
    const refresh = action.payload.refresh ?? false;
    const data = yield* selectFromState((state) => lookup(state, action.payload.request));
    if (!refresh && data.success && data.lastUpdated > maxDataAge()) {
        abortDataRequestAction(action, data, "hasData");
    } else if (data.isRequesting) {
        abortDataRequestAction(action, data, "isRequesting");
    } else {
        yield* runRequestSaga(action, actions, request);
    }
}

export function* takeRequests<Name, Req, Res>(
    actionName: keyof typeof ActionName,
    handler: (req: ReturnType<RequestActions<Name, Req, Res>["request"]>) => Generator
) {
    yield takeEvery(ActionName[actionName], function* (action: RequestAction<Name, Req, Res>) {
        if (action.payload.state === RequestActionState.REQUEST) {
            yield* handler(action as ReturnType<RequestActions<Name, Req, Res>["request"]>);
        }
    });
}

export function* takeSuccess<Name, Req, Res>(
    actionName: keyof typeof ActionName,
    handler: (req: ReturnType<RequestActions<Name, Req, Res>["success"]>) => Generator
) {
    yield takeEvery(ActionName[actionName], function* (action: RequestAction<Name, Req, Res>) {
        if (action.payload.state === RequestActionState.SUCCESS) {
            yield* handler(action as ReturnType<RequestActions<Name, Req, Res>["success"]>);
        }
    });
}
