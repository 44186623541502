import React from "react";
import Spinner from "../spinner/Spinner";
import "./spinnerOverlay.css";

interface SpinnerOverlayProps {
    children?: React.ReactNode;
    isStatic?: boolean;
    isClear?: boolean;
    className?: string;
    size?: number;
    isPending?: boolean;
}

export default class SpinnerOverlay extends React.PureComponent<SpinnerOverlayProps> {
    render() {
        return (
            <div
                className={`spinnerOverlay ${this.props.isStatic ? `spinnerOverlay_static` : ``} ${
                    this.props.isClear ? `spinnerOverlay_clear` : ``
                } ${this.props.className || ""}`}
                aria-busy="true"
                aria-live="polite"
                role="status"
            >
                <Spinner size={this.props.size} isPending={this.props.isPending} />
                {this.props.children && <span className="spinnerOverlay-text">{this.props.children}</span>}
            </div>
        );
    }
}
