import React from "react";
import Icon from "../icon/Icon";
import InfoBox, { type InfoBoxProps } from "../infoBox/InfoBox";
import "./successMessage.css";

export default class SuccessMessage extends React.PureComponent<InfoBoxProps> {
    static defaultProps: InfoBoxProps = {
        autoDismiss: 10,
        animateIn: true,
    };

    render() {
        return (
            <InfoBox dismissible {...this.props} className={`successMessage ${this.props.className || ""}`}>
                {this.props.children}
                <Icon.Check className="successMessage-icon" />
            </InfoBox>
        );
    }
}
