import { appSearchService, orderService, rubyService } from "@/services";
import { CampaignObjectiveType, type CampaignObjective } from "@/types/CampaignObjective";
import { safeParseObj } from "@/utilities/obj";
import { aggregateAsyncData } from "@/utilities/requests";
import { push } from "@lagunovsky/redux-react-router";
import { PaymentModelType } from "@redbox-ruby/data-lib";
import uniqBy from "lodash/uniqBy";
import { all, put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    campaignActions,
    campaignFundsActions,
    campaignSynchronisationActions,
    deleteExperimentAction,
    getActiveBudgetPlanAction,
    getCampaignAppleStatusAction,
    getChannelAction,
    getIOSAppAction,
    getIOSAppProductPagesAction,
    importStandaloneCampaignAction,
    listAllAllocationsAction,
    listBudgetPlansAction,
    listCampaignHistoryRecords,
    listCampaignsEligibleForImportAction,
    listEventsAction,
    listRegionsAction,
    listTeamEventsAction,
    updateImportedCampaignAction,
    type CampaignActions,
} from "../actions/campaignActions";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    isExperiment,
    selectCampaign,
    selectCampaignActiveBudget,
    selectCampaignAllocations,
    selectCampaignBudgetPlans,
    selectCampaignHistoryRecords,
    selectCampaigns,
    selectChannel,
    selectEligibleCampaigns,
    selectTeamEvents,
} from "../selectors/campaignSelectors";
import { selectTeam } from "../selectors/teamSelectors";
import {
    RubyChannelType,
    type RubyCampaignId,
    type RubyChannel,
    type RubyCountry,
    type RubyProductPageLocale,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { extendBudgetPlan, extendBudgetPlans } from "../utilities/budget";
import { getCountryCodeByLanguage } from "../utilities/country";
import {
    runDataRequestAction,
    runRequestAction,
    selectAsyncDataFromState,
    selectFromState,
    takeRequests,
} from "../utilities/saga";
import { getUrl } from "../utilities/url";

function* getCampaignsSaga(action: ExtractRequestActionRequestType<CampaignActions.ListCampaignsAction>) {
    yield* runDataRequestAction(
        action,
        campaignActions.list,
        (state, req) => state.campaigns.teamCampaigns[req] ?? initialAsyncDataState(),
        (req) =>
            rubyService.accounts.listCampaigns({ id: req }, null, { all: true }).then((res) => {
                return res.results.map((c) => ({
                    ...c,
                    teamId: req,
                    objective: safeParseObj<CampaignObjective>(c.objective),
                }));
            })
    );
}

function* getCampaignListingDataSaga(
    action: ExtractRequestActionRequestType<CampaignActions.GetCampaignsListDataAction>
) {
    yield* runDataRequestAction(
        action,
        campaignActions.listData,
        (state, teamId) => state.campaigns.listData[teamId] ?? initialAsyncDataState(),
        (teamId) => rubyService.interactions.listAllSortedCampaigns({ teamId })
    );
}

function* loadCampaignSaga(action: ExtractRequestActionRequestType<CampaignActions.GetCampaignAction>) {
    yield* runDataRequestAction(
        action,
        campaignActions.get,
        (state, req) => selectCampaign(state, req.campaignId),
        (req) =>
            rubyService.accounts.getCampaign(req).then((res) => {
                return {
                    ...res,
                    teamId: req.teamId,
                    objective: safeParseObj<CampaignObjective>(res.objective),
                };
            })
    );
}

function* getCampaignChannelSaga(action: ExtractRequestActionRequestType<CampaignActions.GetChannelAction>) {
    yield* runDataRequestAction(
        action,
        getChannelAction,
        (state, req) => state.campaigns.channels[req.campaignId] ?? initialAsyncDataState(),
        async (req) => {
            switch (req.channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS: {
                    return await rubyService.appleChannels.getAsrChannel({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.APPLE_SEARCH_TABS: {
                    return await rubyService.appleChannels.getAstChannel({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.APPLE_TODAY_TABS: {
                    return await rubyService.appleChannels.getAttChannel({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                    return await rubyService.appleChannels.getPpbChannel({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                default:
                    return Promise.reject();
            }
        }
    );
}

function* listCampaignChannelsSaga(action: CampaignActions.ListChannelsAction) {
    const campaigns = yield* selectAsyncDataFromState((state) => selectCampaigns(state, action.payload));
    yield all(
        campaigns.map((campaign) =>
            put(
                getChannelAction.request({
                    teamId: action.payload,
                    campaignId: campaign.id,
                    channelType: campaign.channelType,
                })
            )
        )
    );
}

export function* getCampaignRegionsSaga(action: ExtractRequestActionRequestType<CampaignActions.GetChannelAction>) {
    yield* runDataRequestAction(
        action,
        listRegionsAction,
        (state, req) => state.campaigns.regions[req.campaignId] ?? initialAsyncDataState(),
        (req) => {
            switch (req.channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS: {
                    return rubyService.appleChannels
                        .listAsrChannelRegions({ teamId: req.teamId, campaignId: req.campaignId }, null, { all: true })
                        .then((res) => res.results);
                }
                case RubyChannelType.APPLE_SEARCH_TABS: {
                    return rubyService.appleChannels
                        .listAstChannelRegions({ teamId: req.teamId, campaignId: req.campaignId }, null, { all: true })
                        .then((res) => res.results);
                }
                case RubyChannelType.APPLE_TODAY_TABS: {
                    return rubyService.appleChannels
                        .listAttChannelRegions({ teamId: req.teamId, campaignId: req.campaignId }, null, { all: true })
                        .then((res) => res.results);
                }
                case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                    return rubyService.appleChannels
                        .listPpbChannelRegions({ teamId: req.teamId, campaignId: req.campaignId }, null, { all: true })
                        .then((res) => res.results);
                }
                default:
                    return Promise.reject();
            }
        }
    );
}

function* getAppSaga(action: ExtractRequestActionRequestType<CampaignActions.GetIOSAppAction>) {
    yield* runDataRequestAction(
        action,
        getIOSAppAction,
        (state, req) => state.campaigns.apps[req.appId] ?? initialAsyncDataState(),
        (req) => appSearchService.lookupApp(req.teamId, req.appId, req.countryCode)
    );
}

function* listCampaignAppsSaga(action: CampaignActions.ListIOSAppsAction) {
    const campaigns = yield* selectAsyncDataFromState((state) => selectCampaigns(state, action.payload));

    if (campaigns.length < 1) {
        return;
    }

    const channels: RubyChannel[] = yield* selectAsyncDataFromState((state) =>
        aggregateAsyncData(...campaigns.map((campaign) => selectChannel(state, campaign.id)))
    );

    const uniqAppChannels = uniqBy(channels, "applicationRef");

    yield all(
        uniqAppChannels.map((channel) =>
            put(
                getIOSAppAction.request({
                    teamId: action.payload,
                    appId: channel.applicationRef,
                    countryCode: channel.storefront,
                })
            )
        )
    );
}

async function getCachedProductPageData(
    req: ExtractRequestActionRequestType<CampaignActions.GetIOSAppProductPagesAction>["payload"]["request"]
): Promise<Awaited<ReturnType<typeof rubyService.appleChannels.findAppProductPagesOptions>>["productPages"]> {
    const pages = await rubyService.appleReplica
        .listProductPages({ teamId: req.teamId }, { adamRef: req.appId }, { all: true })
        .then((res) => res.results);
    const locales = await rubyService.appleReplica
        .listProductPageLocales(
            {
                teamId: req.teamId,
            },
            null,
            { all: true }
        )
        .then((res) => res.results);
    return pages.map((page) => {
        return {
            productPage: page.body,
            locales: locales.filter((locale) => locale.productPageRef === page.productPageRef).flatMap((l) => l.body),
        };
    });
}

function* getProductPageSaga(action: ExtractRequestActionRequestType<CampaignActions.GetIOSAppProductPagesAction>) {
    yield* runDataRequestAction(
        action,
        getIOSAppProductPagesAction,
        (state, req) => state.campaigns.productPages[req.appId] ?? initialAsyncDataState(),
        async (req) => {
            const pages = req.campaignId
                ? await getCachedProductPageData(req)
                : await rubyService.appleChannels
                      .findAppProductPagesOptions({
                          teamId: req.teamId,
                          applicationRef: req.appId,
                      })
                      .then((res) => res.productPages);

            return pages
                .map((page) => {
                    const locales = page.locales
                        .map((locale: RubyProductPageLocale) => {
                            const assets = Object.keys(locale?.appPreviewDeviceWithAssets ?? {});
                            if (assets.length < 1) {
                                return null;
                            }
                            assets.forEach((deviceType) => {
                                // There can be multiple results per country name, but there is no way to tell except parsing this random ID value
                                locale.appPreviewDeviceWithAssets[deviceType].screenshots.forEach((screen) => {
                                    const idParts = screen.assetGenId.split(";");
                                    const match = idParts[1].match(/^(\w{2,}-)*([A-Z]{2})$/);
                                    if (match?.length) {
                                        locale.countryCode = match[match.length - 1] as RubyCountry;
                                    } else {
                                        locale.countryCode = getCountryCodeByLanguage(locale.language) as RubyCountry;
                                    }
                                    if (!locale.countryCode) {
                                        // This is a naff default but need to set something or the page crashes
                                        locale.countryCode = "GB";
                                    }
                                });
                            });
                            return locale;
                        })
                        .filter((a) => !!a);

                    return {
                        ...page.productPage,
                        locales,
                    };
                })
                .filter((page) => page.locales.length > 0);
        }
    );
}

function* getBudgetPlansSaga(action: ExtractRequestActionRequestType<CampaignActions.ListBudgetPlansAction>) {
    yield* runDataRequestAction(action, listBudgetPlansAction, selectCampaignBudgetPlans, async (req) => {
        const plans = await rubyService.budgets.listBudgetPlans(
            { campaignId: req },
            { includeAllocations: true },
            { all: true }
        );
        return extendBudgetPlans(plans.results);
    });
}

function* getActiveBudgetPlanSaga(action: ExtractRequestActionRequestType<CampaignActions.GetActiveBudgetPlanAction>) {
    yield* runDataRequestAction(action, getActiveBudgetPlanAction, selectCampaignActiveBudget, async (req) => {
        const plans = await rubyService.budgets.listActiveBudgetPlans({ campaignId: req }, undefined, {
            all: true,
        });
        if (plans.results?.length < 1) {
            // No budget plan is an acceptable response
            return null;
        }
        const plan = plans.results[0];
        return extendBudgetPlan(plan, plan.id);
    });
}

function* getAllAllocationsSaga(action: ExtractRequestActionRequestType<CampaignActions.ListAllAllocationsAction>) {
    yield* runDataRequestAction(
        action,
        listAllAllocationsAction,
        (state, req) => selectCampaignAllocations(state, req.campaignId),
        async (req) => {
            const allocations = await rubyService.budgets.listBudgetAllocations(req, null, { all: true });
            return allocations.results;
        }
    );
}

export function* getEventsSaga(action: ExtractRequestActionRequestType<CampaignActions.ListEventsAction>) {
    yield* runDataRequestAction(
        action,
        listEventsAction,
        (state, req) => state.campaigns.events[req.campaignId] ?? initialAsyncDataState(),
        (req) =>
            rubyService.attributions
                .countEvents(
                    { teamId: req.teamId },
                    {
                        campaignId: [req.campaignId],
                    }
                )
                .then((res) => {
                    return res.counts;
                })
    );
}

export function* getTeamEventsSaga(action: ExtractRequestActionRequestType<CampaignActions.ListTeamEventsAction>) {
    yield* runDataRequestAction(action, listTeamEventsAction, selectTeamEvents, (req) =>
        rubyService.attributions.countEvents({ teamId: req }).then((res) => {
            return res.counts;
        })
    );
}

function* getCampaignFundsSaga(action: ExtractRequestActionRequestType<CampaignActions.GetCampaignFundsAction>) {
    const team = yield* selectAsyncDataFromState((state) => selectTeam(state, action.payload.request.teamId));
    const teamPaymentType = team?.tier?.payment;
    yield* runDataRequestAction(
        action,
        campaignFundsActions.get,
        (state, req) => state.campaigns.funds[req.campaignId],
        async (req) => {
            if (teamPaymentType !== PaymentModelType.PREPAY) {
                return Infinity;
            }
            return rubyService.budgets.getCurrentFund({ campaignId: req.campaignId }, {}).then((res) => res.amount);
        }
    );
}

function* addCampaignFundsSaga(action: ExtractRequestActionRequestType<CampaignActions.AddCampaignFundsAction>) {
    yield* runRequestAction(action, campaignFundsActions.add, async (req) => {
        if (req.paymentId) {
            await rubyService.budgets.setDefaultStripePaymentMethod(
                {
                    teamId: req.teamId,
                },
                {
                    paymentMethodRef: req.paymentId,
                }
            );
        }
        await orderService.payOrder({
            teamId: req.teamId,
            campaignId: req.campaignId,
            amount: req.totalAmount,
        });
    });
}

async function deleteCampaign(
    channelType: RubyChannelType,
    request: {
        campaignId: RubyCampaignId;
        teamId: RubyTeamId;
    }
) {
    if (channelType === RubyChannelType.APPLE_SEARCH_RESULTS) {
        await rubyService.appleChannels.deleteAsrChannel(request);
        await rubyService.appleChannels.syncAsrChannel(request);
    } else if (channelType === RubyChannelType.APPLE_SEARCH_TABS) {
        await rubyService.appleChannels.deleteAstChannel(request);
        await rubyService.appleChannels.syncAstChannel(request);
    } else if (channelType === RubyChannelType.APPLE_TODAY_TABS) {
        await rubyService.appleChannels.deleteAttChannel(request);
        await rubyService.appleChannels.syncAttChannel(request);
    } else if (channelType === RubyChannelType.PRODUCT_PAGE_BROWSE) {
        await rubyService.appleChannels.deletePpbChannel(request);
        await rubyService.appleChannels.syncPpbChannel(request);
    }
    await rubyService.accounts.deleteCampaign(request);
}

function* deleteCampaignSaga(action: ExtractRequestActionRequestType<CampaignActions.DeleteCampaignAction>) {
    const campaign = yield* selectAsyncDataFromState((state) =>
        selectCampaign(state, action.payload.request.campaignId)
    );
    if (isExperiment(campaign)) {
        yield put(deleteExperimentAction.request(action.payload.request));
        return;
    }
    yield* runRequestAction(action, campaignActions.delete, async (req) => {
        await deleteCampaign(campaign.channelType, req);
    });
    yield put(push(getUrl.campaigns(action.payload.request.teamId)));
}

function* deleteExperimentSaga(action: ExtractRequestActionRequestType<CampaignActions.DeleteExperimentAction>) {
    const campaign = yield* selectAsyncDataFromState((state) =>
        selectCampaign(state, action.payload.request.campaignId)
    );
    yield* runRequestAction(action, campaignActions.delete, async (req) => {
        // Delete any linked campaigns first in case something fails we still have the master
        if (campaign.objective?.objectiveType === CampaignObjectiveType.EXPERIMENT && campaign.objective.campaignIdB) {
            await deleteCampaign(campaign.channelType, {
                teamId: req.teamId,
                campaignId: campaign.objective.campaignIdB,
            });
        }
        await deleteCampaign(campaign.channelType, req);
    });
    yield put(push(getUrl.experiments(action.payload.request.teamId)));
}

function* editCampaignSaga(action: ExtractRequestActionRequestType<CampaignActions.EditCampaignAction>) {
    const campaign = yield* selectAsyncDataFromState((state) =>
        selectCampaign(state, action.payload.request.campaignId)
    );
    yield* runRequestAction(action, campaignActions.edit, async ({ teamId, campaignId, ...payload }) => {
        await rubyService.accounts.updateCampaign(
            { teamId, campaignId },
            {
                ...payload,
                objective: JSON.stringify({
                    objectiveType: CampaignObjectiveType.AD_CAMPAIGN,
                    ...payload.objective,
                }),
            }
        );
        if (isExperiment(campaign) && !!campaign.objective.campaignIdB) {
            await rubyService.accounts.updateCampaign(
                { teamId, campaignId: campaign.objective.campaignIdB },
                {
                    ...payload,
                    objective: JSON.stringify({
                        objectiveType: CampaignObjectiveType.AD_CAMPAIGN,
                        ...payload.objective,
                    }),
                }
            );
        }
    });
}
export function* getAppleStatusSaga(
    action: ExtractRequestActionRequestType<CampaignActions.GetCampaignAppleStatusAction>
) {
    yield* runDataRequestAction(
        action,
        getCampaignAppleStatusAction,
        (state, req) => state.campaigns.appleStatus[req.campaignId] ?? initialAsyncDataState(),
        (req) => rubyService.appleChannels.getAppleStatus({ teamId: req.teamId, campaignId: req.campaignId })
    );
}

function* getSynchronisationSaga(
    action: ExtractRequestActionRequestType<CampaignActions.GetCampaignSynchronisationAction>
) {
    yield* runDataRequestAction(
        action,
        campaignSynchronisationActions.get,
        (state, req) => state.campaigns.synchronisation[req.campaignId] ?? initialAsyncDataState(),
        (req) => {
            switch (req.channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS: {
                    return rubyService.appleChannels.getAsrChannelSynchronisation({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.APPLE_SEARCH_TABS: {
                    return rubyService.appleChannels.getAstChannelSynchronisation({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.APPLE_TODAY_TABS: {
                    return rubyService.appleChannels.getAttChannelSynchronisation({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                    return rubyService.appleChannels.getPpbChannelSynchronisation({
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    });
                }
                default:
                    return Promise.reject();
            }
        }
    );
}

function* synchroniseCampaignSaga(action: ExtractRequestActionRequestType<CampaignActions.SynchroniseCampaignAction>) {
    const campaign = yield* selectFromState((state) => selectCampaign(state, action.payload.request));
    yield* runRequestAction(action, campaignSynchronisationActions.forceSync, async () => {
        const idPayload = {
            teamId: campaign.data.teamId,
            campaignId: campaign.data.id,
        };
        if (campaign.data.channelType === RubyChannelType.APPLE_SEARCH_RESULTS) {
            return rubyService.appleChannels.syncAsrChannel(idPayload).then();
        } else if (campaign.data.channelType === RubyChannelType.APPLE_SEARCH_TABS) {
            return rubyService.appleChannels.syncAstChannel(idPayload).then();
        } else if (campaign.data.channelType === RubyChannelType.APPLE_TODAY_TABS) {
            return rubyService.appleChannels.syncAttChannel(idPayload).then();
        } else if (campaign.data.channelType === RubyChannelType.PRODUCT_PAGE_BROWSE) {
            return rubyService.appleChannels.syncPpbChannel(idPayload).then();
        }
    });
}

function* loadCampaignHistoryRecordsSaga(
    action: ExtractRequestActionRequestType<CampaignActions.ListCampaignHistoryRecordsAction>
) {
    yield* runDataRequestAction(
        action,
        listCampaignHistoryRecords,
        (state, req) => selectCampaignHistoryRecords(state, req),
        ({ campaignId, from, to, subjectId, type }) =>
            rubyService.history
                .listCampaignRecords(
                    { id: campaignId },
                    {
                        from,
                        to,
                        subjectId,
                        type,
                    },
                    { all: true }
                )
                .then((data) => data.results)
    );
}

function* getCampaignsEligibleForImport(
    action: ExtractRequestActionRequestType<CampaignActions.GetCampaignsEligibleForImport>
) {
    yield* runDataRequestAction(action, listCampaignsEligibleForImportAction, selectEligibleCampaigns, (teamId) =>
        rubyService.interactions.listEligibleCampaigns({ teamId }).then((data) => data.results)
    );
}

function* importStandaloneCampaign(action: ExtractRequestActionRequestType<CampaignActions.ImportStandaloneCampaign>) {
    yield* runRequestAction(action, importStandaloneCampaignAction, ({ teamId, ...req }) =>
        rubyService.interactions.importStandaloneCampaign(
            { teamId },
            { ...req, objective: JSON.stringify({ objectiveType: CampaignObjectiveType.IMPORTED_CAMPAIGN }) }
        )
    );
}

function* updateImportedCampaign(action: ExtractRequestActionRequestType<CampaignActions.UpdateImportedCampaign>) {
    yield* runRequestAction(action, updateImportedCampaignAction, async ({ teamId, campaignId }) => {
        await rubyService.interactions.updateImportedCampaign({ teamId, campaignId });
    });
}

export default function* campaignSaga() {
    yield* takeRequests(ActionName.LIST_CAMPAIGNS, getCampaignsSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGN, loadCampaignSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGNS_LIST_DATA, getCampaignListingDataSaga);
    yield* takeRequests(ActionName.GET_CHANNEL, getCampaignChannelSaga);
    yield takeEvery(ActionName.LIST_CHANNELS, listCampaignChannelsSaga);
    yield* takeRequests(ActionName.LIST_REGIONS, getCampaignRegionsSaga);
    yield* takeRequests(ActionName.GET_IOS_APP, getAppSaga);
    yield takeEvery(ActionName.LIST_IOS_APPS, listCampaignAppsSaga);
    yield* takeRequests(ActionName.LIST_BUDGET_PLANS, getBudgetPlansSaga);
    yield* takeRequests(ActionName.GET_ACTIVE_BUDGET_PLAN, getActiveBudgetPlanSaga);
    yield* takeRequests(ActionName.LIST_ALL_ALLOCATIONS, getAllAllocationsSaga);
    yield* takeRequests(ActionName.LIST_EVENTS, getEventsSaga);
    yield* takeRequests(ActionName.LIST_TEAM_EVENTS, getTeamEventsSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGN_FUNDS, getCampaignFundsSaga);
    yield* takeRequests(ActionName.DELETE_CAMPAIGN, deleteCampaignSaga);
    yield* takeRequests(ActionName.EDIT_CAMPAIGN, editCampaignSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGN_SYNCHRONISATION, getSynchronisationSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGN_APPLE_STATUS, getAppleStatusSaga);
    yield* takeRequests(ActionName.GET_IOS_APP_PRODUCT_PAGES, getProductPageSaga);
    yield* takeRequests(ActionName.SYNCHRONISE_CAMPAIGN, synchroniseCampaignSaga);
    yield* takeRequests(ActionName.ADD_CAMPAIGN_FUNDS, addCampaignFundsSaga);
    yield* takeRequests(ActionName.LIST_CAMPAIGN_HISTORY_RECORDS, loadCampaignHistoryRecordsSaga);
    yield* takeRequests(ActionName.DELETE_EXPERIMENT, deleteExperimentSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT, getCampaignsEligibleForImport);
    yield* takeRequests(ActionName.IMPORT_STANDALONE_CAMPAIGN, importStandaloneCampaign);
    yield* takeRequests(ActionName.UPDATE_IMPORTED_CAMPAIGN, updateImportedCampaign);
}
