import { ActionName, type ActionType } from "@/actions/ActionType";
import type { State } from "@/reducers/domain";
import { RubyUserRole } from "@/services/backend/RubyData";

export type GAEvent = { event: string } & Record<string, string | number>;

export function getUserParameters(action: ActionType, state: State): Partial<GAEvent> {
    let userId: number = null;
    let userType: string = null;

    if (state.user.userId) {
        userId = state.user.userId;
        userType = state.user.role === RubyUserRole.ADMIN ? "internal" : "external";
    } else if (action.type === ActionName.LOGIN_SUCCESS) {
        userId = action.payload.user.id;
        userType = action.payload.user.role === RubyUserRole.ADMIN ? "internal" : "external";
    }

    return {
        parameter6: userId,
        parameter9: userType,
    };
}

export function getTimestampParameters(): Partial<GAEvent> {
    const now = new Date();
    return {
        timeStamp: now.getTime(),
        timeStampIso: now.toISOString(),
    };
}
