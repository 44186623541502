import { ExperimentAlignment } from "@/types/CampaignObjective";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { getUrl } from "../utilities/url";
import { CreationStep, ExperimentTimeCadenceAlignment, type CreationState, type State } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const initialCreationState: CreationState = {
    step: CreationStep.LOGIN,
    dirty: {
        [CreationStep.LOGIN]: false,
        [CreationStep.TEAM]: false,
        [CreationStep.APP]: false,
        [CreationStep.REGIONS]: false,
        [CreationStep.COST_PER_DOWNLOAD]: false,
        [CreationStep.TARGETING]: false,
        [CreationStep.PRODUCT_PAGE]: false,
        [CreationStep.KEYWORDS]: false,
        [CreationStep.BUDGET]: false,
        [CreationStep.PAYMENT]: false,
        [CreationStep.FINALISE]: false,
        [CreationStep.EXPERIMENT_INFO]: false,
        [CreationStep.EXPERIMENT]: false,
        [CreationStep.EXPERIMENT_TARGETING]: false,
        [CreationStep.EXPERIMENT_PRODUCT_PAGE]: false,
        [CreationStep.EXPERIMENT_REGION]: false,
        [CreationStep.EXPERIMENT_KEYWORDS]: false,
        [CreationStep.EXPERIMENT_BID_AMOUNT]: false,
    },
    restore: initialRequestState(),
    creation: initialRequestState(),
    creationProgress: 0,
    templateCampaign: null,
    app: null,
    channelType: null,
    team: null,
    regions: [],
    targeting: {},
    budget: null,
    seedKeywords: [],
    targetingKeywords: [],
    targetingKeywordsBroad: [],
    negativeKeywords: [],
    costPerDownload: null,
    regionalCostPerDownload: {},
    regionalStartingBids: {},
    organisationId: null,
    startingBid: null,
    createSeparateRegions: false,
    experimentType: null,
    experimentAlignment: null,
    experimentTimeRange: null,
    experimentTimeCadence: 1,
    experimentTimeCadenceAlignment: ExperimentTimeCadenceAlignment.ALTERNATING,
    experimentTargeting: {},
    experimentProductPage: null,
    experimentBidAmount: null,
    experimentKeywords: null,
    experimentKeywordsBroad: null,
};

export default function creationReducer(
    state: CreationState = initialCreationState,
    action: ActionType,
    globalState: State
) {
    switch (action.type) {
        case ActionName.SET_CREATION_STEP: {
            state.step = action.payload;
            break;
        }

        case ActionName.LOGIN_SUCCESS: {
            state.dirty[CreationStep.LOGIN] = true;
            break;
        }

        case ActionName.CREATE_TEAM: {
            if (
                action.payload.state === RequestActionState.SUCCESS &&
                globalState.router.location.pathname === getUrl.campaignCreation()
            ) {
                state.dirty[CreationStep.TEAM] = true;
                state.team = action.payload.response.team.id;
                state.templateCampaign = null;
                state.app = null;
                state.organisationId = null;
                state.regions = [];
            }
            break;
        }

        case ActionName.SET_CREATION_TEAM: {
            const noChange = action.payload.teamId === state.team;

            state.dirty[CreationStep.TEAM] = true;
            state.templateCampaign = action.payload.campaignId;
            state.team = action.payload.teamId;
            state.app = noChange ? state.app : null;
            state.organisationId = noChange ? state.organisationId : null;
            state.regions = noChange ? state.regions : [];
            break;
        }

        case ActionName.SET_CREATION_APP: {
            const noChange = action.payload.trackId === state.app?.trackId;
            state.dirty[CreationStep.APP] = true;
            state.app = action.payload;
            state.regions = noChange ? state.regions : [];
            state.targeting = noChange ? state.targeting : {};
            state.productPage = noChange ? state.productPage : null;
            state.experimentType = noChange ? state.experimentType : null;
            state.experimentAlignment = noChange ? state.experimentAlignment : null;
            break;
        }

        case ActionName.SET_CREATION_CHANNEL: {
            const noChange = action.payload === state.channelType;
            state.dirty[CreationStep.APP] = true;
            state.channelType = action.payload;
            state.targeting = noChange ? state.targeting : {};
            state.productPage = noChange ? state.productPage : null;
            state.experimentType = noChange ? state.experimentType : null;
            state.experimentAlignment = noChange ? state.experimentAlignment : null;
            break;
        }

        case ActionName.SET_CREATION_REGIONS: {
            state.dirty[CreationStep.REGIONS] = true;
            state.regions = action.payload.regions;
            state.createSeparateRegions = action.payload.createSeparateRegions && action.payload.regions.length > 1;
            break;
        }

        case ActionName.SET_CREATION_TARGETING: {
            state.dirty[CreationStep.TARGETING] = true;
            state.targeting = action.payload;
            if (state.experimentAlignment === ExperimentAlignment.REGIONAL) {
                state.experimentAlignment = null;
            }
            break;
        }

        case ActionName.SET_CREATION_KEYWORDS: {
            state.dirty[CreationStep.KEYWORDS] = !action.payload.isAutomated;
            state.seedKeywords = action.payload.seedKeywords?.map((k) => k.toLocaleLowerCase()) ?? null;
            state.targetingKeywords = action.payload.targetingKeywords.map((k) => k.toLocaleLowerCase());
            state.targetingKeywordsBroad = action.payload.targetingKeywordsBroad.map((k) => k.toLocaleLowerCase());
            state.negativeKeywords = action.payload.negativeKeywords.map((k) => k.toLocaleLowerCase());
            state.lockedBidPrice = action.payload.lockedBidPrice ?? null;
            break;
        }

        case ActionName.SET_CREATION_BIDS: {
            state.dirty[CreationStep.COST_PER_DOWNLOAD] = true;
            state.costPerDownload = action.payload.costPerDownloadTarget;
            state.startingBid = action.payload.startingBid;
            state.regionalCostPerDownload = action.payload.regionalCostPerDownload;
            state.regionalStartingBids = action.payload.regionalStartingBid;
            break;
        }

        case ActionName.SET_DEFAULT_PAYMENT_METHOD: {
            if (
                action.payload.state === RequestActionState.SUCCESS &&
                (globalState.router.location.pathname.startsWith(getUrl.campaignCreation()) ||
                    globalState.router.location.pathname.startsWith(getUrl.experimentCreation()))
            ) {
                state.dirty[CreationStep.PAYMENT] = true;
            }
            break;
        }

        case ActionName.SET_CREATION_BUDGET: {
            state.dirty[CreationStep.BUDGET] = true;
            state.budget = action.payload;
            break;
        }

        case ActionName.SET_CREATION_PRODUCT_PAGE: {
            state.dirty[CreationStep.PRODUCT_PAGE] = true;
            state.productPage = action.payload;
            break;
        }

        case ActionName.CREATE_CAMPAIGN: {
            state.creation = requestActionReducer(action);
            break;
        }

        case ActionName.UPDATE_CREATION_PROGRESS: {
            state.creationProgress = action.payload;
            break;
        }

        case ActionName.SET_CREATION_ORGANISATION: {
            state.organisationId = action.payload;
            break;
        }

        case ActionName.RESET_CREATION: {
            globalState.creation = {
                ...initialCreationState,
                dirty: {
                    ...initialCreationState.dirty,
                    [CreationStep.LOGIN]: globalState.user.isLoggedIn,
                },
            };
            break;
        }

        case ActionName.RESTORE_CREATION_STATE: {
            if (action.payload.state === RequestActionState.SUCCESS && action.payload.response?.team > 0) {
                globalState.creation = {
                    ...state,
                    ...(action.payload.response ?? {}),
                    step: state.step,
                    creation: state.creation,
                    creationProgress: state.creationProgress,
                    restore: requestActionReducer(action, () => null),
                };
            } else {
                state.restore = requestActionReducer(action, () => null);
            }
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT: {
            state.dirty[CreationStep.EXPERIMENT] = true;
            state.experimentType = action.payload.experimentType;
            state.experimentTimeRange = action.payload.experimentTimeRange;
            state.experimentTimeCadence = action.payload.experimentTimeCadence;
            state.experimentTimeCadenceAlignment = action.payload.experimentTimeCadenceAlignment;
            if (
                action.payload.experimentAlignment !== ExperimentAlignment.REGIONAL &&
                state.experimentAlignment === ExperimentAlignment.REGIONAL
            ) {
                state.experimentRegion = null;
                state.targeting = {
                    ...state.targeting,
                    locality: undefined,
                    adminArea: undefined,
                };
            }
            state.experimentAlignment = action.payload.experimentAlignment;
            state.experimentProductPage = null;
            state.experimentBidAmount = null;
            state.experimentKeywords = null;
            state.experimentKeywordsBroad = null;
            state.experimentTargeting = {};
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT_REGION: {
            state.dirty[CreationStep.EXPERIMENT_REGION] = true;
            state.experimentRegion = action.payload.experimentRegion;
            state.targeting = {
                ...state.targeting,
                locality: action.payload.locality,
                adminArea: action.payload.adminArea,
            };
            state.experimentTargeting = {
                ...(state.experimentTargeting ?? {}),
                locality: action.payload.experimentLocality,
                adminArea: action.payload.experimentAdminArea,
            };
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT_PRODUCT_PAGE: {
            state.dirty[CreationStep.EXPERIMENT_PRODUCT_PAGE] = true;
            state.experimentProductPage = action.payload;
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT_TARGETING: {
            state.dirty[CreationStep.EXPERIMENT_TARGETING] = true;
            state.experimentTargeting = action.payload;
            break;
        }

        case ActionName.READ_EXPERIMENT_INFO: {
            state.dirty[CreationStep.EXPERIMENT_INFO] = true;
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT_KEYWORDS: {
            state.dirty[CreationStep.EXPERIMENT_KEYWORDS] = true;
            state.experimentKeywords = action.payload.keywords;
            state.experimentKeywordsBroad = action.payload.keywordsBroad;
            break;
        }

        case ActionName.SET_CREATION_EXPERIMENT_BID_PRICE: {
            state.dirty[CreationStep.EXPERIMENT_BID_AMOUNT] = true;
            state.experimentBidAmount = action.payload;
            break;
        }
    }
}
