import React from "react";
import { languageString } from "../../../utilities/text";
import ButtonIcon from "../../blocks/buttons/icon/ButtonIcon";
import Scroller from "../../blocks/scroller/Scroller";
import "./asidePanel.css";

interface AsidePanelProps {
    className?: string;
    asideClassName?: string;
    content: React.ReactElement;
    children: React.ReactNode;
}

interface AsidePanelState {
    isExpanded: boolean;
}

export default class AsidePanel extends React.PureComponent<AsidePanelProps, AsidePanelState> {
    constructor(props: AsidePanelProps) {
        super(props);
        this.state = {
            isExpanded: true,
        };
    }

    toggleExpanded() {
        this.setState({
            isExpanded: !this.state.isExpanded,
        });
    }

    render() {
        return (
            <div className={`asidePanel~root ${this.props.className || ""}`}>
                <div className="asidePanel-page">{this.props.children}</div>
                <aside
                    className={`asidePanel ${this.props.asideClassName || ""} ${
                        this.state.isExpanded ? "is-expanded" : ""
                    }`}
                >
                    <div className="asidePanel-element">
                        <Scroller padding={0}>
                            <div className="asidePanel-elementWrap">
                                {React.cloneElement(this.props.content, { isExpanded: this.state.isExpanded })}
                            </div>
                        </Scroller>
                    </div>
                    <ButtonIcon
                        icon="ChevronRight"
                        label={languageString("ui.alt.expandAside")}
                        className="asidePanel-expand"
                        onClick={this.toggleExpanded.bind(this)}
                    />
                </aside>
            </div>
        );
    }
}
