import isNil from "lodash/isNil";
import memoize from "lodash/memoize";
import moment, { isMoment, type Moment, type unitOfTime } from "moment";

type Dateish = Moment | Date | number;

const getDateFormatter = memoize(
    (options?: Intl.DateTimeFormatOptions) => new Intl.DateTimeFormat(undefined, { timeZone: "UTC", ...options }),
    JSON.stringify
);

export const formatDate = memoize(
    (inputDate: Dateish, options?: Intl.DateTimeFormatOptions) => {
        if (!inputDate) {
            return "-";
        }

        if (isMoment(inputDate)) {
            inputDate = inputDate.toDate();
        }
        if (typeof inputDate === "number") {
            inputDate = new Date(inputDate);
        }

        if (!(inputDate instanceof Date)) {
            return "-";
        }

        const formatter = getDateFormatter(options);

        return formatter.format(inputDate);
    },
    (inputDate: Dateish, options?: Intl.DateTimeFormatOptions) => {
        let dateStr = "";
        if (inputDate instanceof Date) {
            dateStr = inputDate.toISOString();
        } else if (moment.isMoment(inputDate)) {
            dateStr = inputDate.format();
        } else {
            dateStr = `${inputDate}`;
        }
        return `${dateStr}-${JSON.stringify(options)}`;
    }
);

export function localToUTC(val: Date | number): Date {
    if (val === null) {
        return null;
    }
    const date = new Date(val);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
}

export function UTCToLocal(val: Date | number): Date {
    if (val === null) {
        return null;
    }
    const date = new Date(val);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
}

function fromDateish(inputDate: Dateish): Moment {
    if (isNil(inputDate)) {
        return null;
    }
    if (isMoment(inputDate)) {
        return inputDate.clone();
    }
    if (typeof inputDate === "number") {
        return moment.utc(inputDate);
    }
    if (inputDate instanceof Date) {
        return moment.utc(inputDate);
    }
    return null;
}

export function getDateMidPoint(start: Dateish, end: Dateish, accuracy: unitOfTime.StartOf = "millisecond") {
    const startTime = fromDateish(start).valueOf();
    const endTime = fromDateish(end).valueOf();
    const midPoint = moment.utc(startTime + (endTime - startTime) / 2);
    return midPoint.startOf(accuracy);
}

export const formatHour = (hour: number) => {
    const date = new Date("2000-01-01T00:00:00");
    date.setHours(hour);
    return date.toLocaleTimeString(undefined, { hour: "2-digit", minute: "2-digit" });
};

export const formatDay = (day: number) => {
    const date = new Date("2022-11-06"); // This is a Sunday (i.e. 0)
    date.setDate(date.getDate() + day);
    return date.toLocaleDateString(undefined, { weekday: "short" });
};

export const formatWeekHour = (weekHour: number) => {
    return formatDay(Math.floor(weekHour / 24)) + " " + formatHour(weekHour % 24);
};
