import {
    RubyChannelType,
    type RubyASRChannel,
    type RubyASRRegion,
    type RubyASTChannel,
    type RubyATTChannel,
    type RubyATTRegion,
    type RubyChannel,
    type RubyCountry,
    type RubyPPBChannel,
    type RubyPPBRegion,
    type RubyRegion,
} from "../services/backend/RubyData";

export function castChannelType(
    channelType: RubyChannelType.APPLE_SEARCH_RESULTS,
    channel: RubyChannel
): RubyASRChannel;
export function castChannelType(channelType: RubyChannelType.APPLE_SEARCH_TABS, channel: RubyChannel): RubyASTChannel;
export function castChannelType(channelType: RubyChannelType.APPLE_TODAY_TABS, channel: RubyChannel): RubyATTChannel;
export function castChannelType(channelType: RubyChannelType.PRODUCT_PAGE_BROWSE, channel: RubyChannel): RubyPPBChannel;
export function castChannelType(channelType: RubyChannelType, channel: RubyChannel): RubyChannel {
    return channel;
}

export function castRegionType(channelType: RubyChannelType.APPLE_SEARCH_RESULTS, region: RubyRegion): RubyASRRegion;
export function castRegionType(channelType: RubyChannelType.APPLE_SEARCH_TABS, region: RubyRegion): RubyASRRegion;
export function castRegionType(channelType: RubyChannelType.APPLE_TODAY_TABS, region: RubyRegion): RubyATTRegion;
export function castRegionType(channelType: RubyChannelType.PRODUCT_PAGE_BROWSE, region: RubyRegion): RubyPPBRegion;
export function castRegionType(channelType: RubyChannelType, region: RubyRegion): RubyRegion {
    return region;
}

export function isRegionWithPurpose(channelType: RubyChannelType, region?: RubyRegion): region is RubyASRRegion {
    return channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export function isRegionsWithPurpose(channelType: RubyChannelType, regions?: RubyRegion[]): regions is RubyASRRegion[] {
    return isRegionWithPurpose(channelType);
}

export function isRegionObjWithPurpose(region: RubyRegion): region is RubyASRRegion {
    return "purpose" in region && !!region["purpose"];
}

export function isRegionWithKeywords(channelType: RubyChannelType, region?: RubyRegion): region is RubyASRRegion {
    return channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export function isChannelWithKeywords(channelType: RubyChannelType, channel?: RubyChannel): channel is RubyASRChannel {
    return channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export function isChannelWithPurposes(channelType: RubyChannelType, channel?: RubyChannel): channel is RubyASRChannel {
    return channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export function isChannelRequiresProductPage(
    channelType: RubyChannelType,
    channel?: RubyChannel
): channel is RubyATTChannel {
    return channelType === RubyChannelType.APPLE_TODAY_TABS;
}

export function isChannelWithProductPage(
    channelType: RubyChannelType,
    channel?: RubyChannel
): channel is RubyASRChannel | RubyATTChannel {
    return channelType === RubyChannelType.APPLE_TODAY_TABS || channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export function isChannelWithScheduleTarget(
    channelType: RubyChannelType,
    channel?: RubyChannel
): channel is RubyASRChannel {
    return channelType === RubyChannelType.APPLE_SEARCH_RESULTS;
}

export type CountryBidMapping = { [K in RubyCountry]?: number | null };
