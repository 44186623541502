import { CAMPAIGN_CARD_DIAL_SIZE } from "@/components/assemblies/campaignCard/constants";
import type { RubyCampaignId } from "@/services/backend/RubyData";
import { currencyFormatter } from "../../../../../utilities/currency";
import { languageString } from "../../../../../utilities/text";
import { useCampaignDataLoader } from "../../../../assemblies/campaignDataLoader/CampaignDataLoader";
import RequestLoader from "../../../../blocks/requestLoader/RequestLoader";
import StatRadialTarget from "../../../../blocks/stats/radialTarget/StatRadialTarget";

interface BudgetPacingDialProps {
    campaignId?: RubyCampaignId;
    size?: number;
}

export default function BudgetPacingDial({ size = CAMPAIGN_CARD_DIAL_SIZE, campaignId }: BudgetPacingDialProps) {
    const loader = useCampaignDataLoader(campaignId);
    const budgetPacing = loader.useBudgetPacing();
    const currency = loader.useCurrency();

    const formatCurrency = currencyFormatter(currency);

    return (
        <div className="u-relative">
            <RequestLoader
                request={budgetPacing}
                noPending
                placeholder={
                    <StatRadialTarget
                        label={languageString("campaign.listing.budgetPacing.title")}
                        hint={languageString("campaign.listing.budgetPacing.description")}
                        value={0}
                        max={1000}
                        target={0}
                        formatter={formatCurrency}
                        size={size}
                        subLabel={languageString("campaign.listing.budgetPacing.target", "", [formatCurrency(0)])}
                    />
                }
                fallback={
                    <div className="u-fade2">
                        <StatRadialTarget
                            label={languageString("campaign.budget.noActiveBudget")}
                            hint={languageString("campaign.listing.budgetPacing.description")}
                            value={0}
                            max={1000}
                            target={0}
                            formatter={formatCurrency}
                            size={size}
                            subLabel={languageString("campaign.listing.budgetPacing.target", "", [formatCurrency(0)])}
                        />
                    </div>
                }
            >
                <StatRadialTarget
                    label={languageString("campaign.listing.budgetPacing.title")}
                    hint={languageString("campaign.listing.budgetPacing.description")}
                    value={budgetPacing?.data?.actualDailySpend}
                    max={budgetPacing?.data?.budget?.totalBudget}
                    target={budgetPacing?.data?.idealDailySpend}
                    formatter={formatCurrency}
                    size={size}
                    subLabel={languageString("campaign.listing.budgetPacing.target", "", [
                        formatCurrency(budgetPacing?.data?.idealDailySpend),
                    ])}
                />
            </RequestLoader>
        </div>
    );
}
