import {
    CampaignObjectiveType,
    type AdCampaign,
    type ExperimentCampaign,
    type ImportedCampaign,
} from "@/types/CampaignObjective";
import type { EventsList } from "@/utilities/events";
import { mapObject } from "@/utilities/obj";
import { createSelector } from "@reduxjs/toolkit";
import intersection from "lodash/intersection";
import pullAll from "lodash/pullAll";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { CampaignSortOrder, type AsyncData, type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyChannelStatus,
    RubyRegionStatus,
    type RubyASRRegion,
    type RubyBudgetPlan,
    type RubyCampaign,
    type RubyCampaignHistoryRecordQuery,
    type RubyCampaignId,
    type RubyCampaignListingData,
    type RubyChannel,
    type RubyCountry,
    type RubyIOSApp,
    type RubyRegion,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { getAllocatedBudget } from "../utilities/budget";
import { aggregateAsyncData } from "../utilities/requests";
import { isChannelWithPurposes } from "../utilities/types";
import { matchHistoryRecord } from "./matchers";

export function selectActiveCampaignId(state: State) {
    return state.ui.activeCampaign;
}

export function selectActiveTeamId(state: State) {
    return state.ui.activeTeam;
}

function selectCampaignIds(state: State, teamId?: RubyTeamId) {
    teamId ??= selectActiveTeamId(state);
    return state.campaigns.teamCampaigns[teamId];
}

function selectAllCampaignsData(state: State) {
    return state.campaigns.campaigns;
}

export const selectCampaigns = createSelector(
    [selectCampaignIds, selectAllCampaignsData],
    (campaignIds, campaignData) => {
        if (!campaignIds) {
            return initialAsyncDataState<RubyCampaign[]>([]);
        }
        if (!campaignIds.success) {
            return {
                ...campaignIds,
                data: null,
            };
        }
        const campaigns = campaignIds.data.map((id) => campaignData[id]?.data);
        return {
            ...campaignIds,
            data: campaigns,
        };
    }
);

export const selectAdCampaigns = createSelector([selectCampaigns], (campaigns): AsyncData<AdCampaign[]> => {
    if (campaigns.success) {
        const adCampaigns = campaigns.data.filter(isAdCampaign);
        return {
            ...campaigns,
            data: adCampaigns,
        };
    }
    return { ...campaigns, data: null };
});

export function selectCampaign(state: State, campaignId?: RubyCampaignId): AsyncData<RubyCampaign> {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.campaigns[campaignId] ?? initialAsyncDataState();
}

export function selectChannel(state: State, campaignId?: RubyCampaignId): AsyncData<RubyChannel> {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.channels[campaignId] ?? initialAsyncDataState();
}

export const selectChannels = createSelector(
    [(state: State) => state.campaigns.channels, selectCampaigns],
    (channelMap, campaigns): AsyncData<RubyChannel[]> => {
        if (!campaigns.success) {
            return initialAsyncDataState();
        }
        const channels = campaigns.data?.map((campaign) => channelMap[campaign.id]);
        return aggregateAsyncData(...channels);
    }
);

export const selectCampaignsChannels = createSelector(
    [(state: State) => state.campaigns.channels, (_state: State, campaignIds: RubyCampaignId[]) => campaignIds],
    (channelMap, campaignIds) => {
        const channels: Record<RubyCampaignId, AsyncData<RubyChannel>> = {};
        campaignIds?.forEach((campaignId) => {
            channels[campaignId] = channelMap[campaignId] ?? initialAsyncDataState();
        });
        return channels;
    }
);

export function selectRegions(state: State, campaignId?: RubyCampaignId): AsyncData<RubyRegion[]> {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.regions[campaignId] ?? initialAsyncDataState();
}

export const selectCampaignsRegions = createSelector(
    [(state: State) => state.campaigns.regions, (_state: State, campaignIds: RubyCampaignId[]) => campaignIds],
    (regionsMap, campaignIds) => {
        const regions: Record<RubyCampaignId, AsyncData<RubyRegion[]>> = {};
        campaignIds?.forEach((campaignId) => {
            regions[campaignId] = regionsMap[campaignId] ?? initialAsyncDataState();
        });
        return regions;
    }
);

export function selectFunds(state: State, campaignId?: RubyCampaignId): AsyncData<number> {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.funds[campaignId] ?? initialAsyncDataState();
}

export function selectCampaignSearchString(state: State) {
    return state.campaigns.search.toLocaleLowerCase();
}

export function selectRegionFilter(state: State) {
    return state.reports.regionFilter;
}

export function selectSearchAppFilter(state: State) {
    return state.campaigns.searchAppFilter;
}

export function selectCampaignSortOrder(state: State) {
    return state.campaigns.searchSortOrder;
}

export function selectCampaignVisibilityFilter(state: State) {
    return state.campaigns.searchVisibility;
}

export function selectCampaignListData(state: State, teamId?: RubyTeamId) {
    teamId ??= selectActiveTeamId(state);
    return state.campaigns.listData[teamId] ?? initialAsyncDataState();
}

export function selectApp(state: State, appRef: RubyChannel["applicationRef"]): AsyncData<RubyIOSApp> {
    return state.campaigns.apps[appRef] ?? initialAsyncDataState();
}

export function selectAppByCampaign(state: State, campaignId?: RubyCampaignId): AsyncData<RubyIOSApp> {
    const channel = selectChannel(state, campaignId);
    return selectApp(state, channel.data?.applicationRef);
}

export function selectCampaignBudgetPlans(state: State, campaignId?: RubyCampaignId): AsyncData<RubyBudgetPlan[]> {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.budgets[campaignId] ?? initialAsyncDataState();
}

export const selectCampaignsBudgetPlans = createSelector(
    [(state: State) => state.campaigns.budgets, (state: State, campaignIds: RubyCampaignId[]) => campaignIds],
    (budgets, campaignIds = []) => {
        const campaignsBudgets = campaignIds.reduce(
            (budgetMap: Record<RubyCampaignId, AsyncData<RubyBudgetPlan[]>>, campaignId) => {
                budgetMap[campaignId] = budgets[campaignId] ?? initialAsyncDataState();
                return budgetMap;
            },
            {}
        );
        const request = aggregateAsyncData(...Object.values(campaignsBudgets));
        const data = mapObject(campaignsBudgets, (_, budgetPlan) => budgetPlan.data);
        return {
            ...request,
            data,
        };
    }
);

function selectServerCampaignActiveBudget(state: State, campaignId: RubyCampaignId) {
    return state.campaigns.activeBudgets[campaignId] ?? initialAsyncDataState();
}

export function selectCampaignBudgetPlan(state: State, campaignId?: RubyCampaignId, budgetId?: RubyBudgetPlan["id"]) {
    campaignId ??= selectActiveCampaignId(state);
    budgetId ??= selectCampaignActiveBudget(state, campaignId).data?.id;
    const budgets = state.campaigns.budgets[campaignId];
    return budgets?.data?.find((b) => b.id === budgetId);
}

export const selectCampaignActiveBudget = createSelector(
    [selectServerCampaignActiveBudget, selectCampaignBudgetPlans],
    (activeBudget, budgets) => {
        if (activeBudget?.isRequesting || activeBudget?.success) {
            return activeBudget;
        }

        if (!budgets?.success) {
            return activeBudget ?? initialAsyncDataState();
        }

        const budget = budgets.data?.find((b) => b.active);
        return {
            ...budgets,
            data: budget,
        };
    }
);

export const selectCampaignsActiveBudgets = createSelector(
    [
        (state: State) => state.campaigns.activeBudgets,
        (state: State) => state.campaigns.budgets,
        (state: State, campaignIds: RubyCampaignId[]) => campaignIds,
    ],
    (activeBudgets, budgets, campaignIds = []) => {
        const campaignBudgetMap = campaignIds.reduce(
            (budgetMap: Record<RubyCampaignId, AsyncData<RubyBudgetPlan>>, campaignId) => {
                if (activeBudgets[campaignId]?.isRequesting || activeBudgets[campaignId]?.success) {
                    budgetMap[campaignId] = activeBudgets[campaignId];
                    return budgetMap;
                }

                if (!budgets[campaignId]?.success) {
                    budgetMap[campaignId] = activeBudgets[campaignId] ?? initialAsyncDataState();
                    return budgetMap;
                }

                const budget = budgets[campaignId].data?.find((b) => b.active);

                budgetMap[campaignId] = {
                    ...budgets[campaignId],
                    data: budget,
                };
                return budgetMap;
            },
            {}
        );

        const budgetData = aggregateAsyncData(...Object.values(campaignBudgetMap));
        const data = mapObject(campaignBudgetMap, (_, budgetPlan) => budgetPlan.data);
        return { ...budgetData, data };
    }
);

export const selectCampaignBudgetAllocated = createSelector([selectCampaignBudgetPlan], (budget) =>
    getAllocatedBudget(budget)
);

export function selectCampaignAllocations(state: State, campaignId?: RubyCampaignId) {
    campaignId ??= selectActiveCampaignId(state);
    return state.campaigns.allocations?.[campaignId] ?? initialAsyncDataState();
}

// A convenient global object containing all campaign info
interface CampaignDetails {
    campaign: RubyCampaign;
    channel: RubyChannel;
    regions: RubyRegion[];
    app: RubyIOSApp;
    budget: RubyBudgetPlan;
    activeCountries: RubyCountry[];
    inactiveCountries: RubyCountry[];
    allocatedBudget: number;
    unallocatedBudget: number;
    funds: number;
}

const selectTeamCampaignDetails = createSelector(
    [
        selectCampaigns,
        (state: State) => state.campaigns.channels,
        (state: State) => state.campaigns.regions,
        (state: State) => state.campaigns.activeBudgets,
        (state: State) => state.campaigns.apps,
        (state: State) => state.campaigns.funds,
    ],
    (campaigns, allChannels, allRegions, allBudgets, allApps, allFunds): AsyncData<CampaignDetails>[] => {
        return (
            campaigns.data?.map((campaign) => {
                return createCampaignDetails(
                    { ...campaigns, data: campaign },
                    allChannels[campaign?.id],
                    allRegions[campaign?.id],
                    allBudgets[campaign?.id],
                    allApps[allChannels[campaign?.id]?.data?.applicationRef],
                    allFunds[campaign?.id]
                );
            }) ?? []
        );
    }
);

function createCampaignDetails(
    campaign: AsyncData<RubyCampaign>,
    channel: AsyncData<RubyChannel>,
    regions: AsyncData<RubyRegion[]>,
    budget: AsyncData<RubyBudgetPlan>,
    app: AsyncData<RubyIOSApp>,
    funds: AsyncData<number>
): AsyncData<CampaignDetails> {
    const allocatedBudget = getAllocatedBudget(budget?.data);
    const data: CampaignDetails = {
        campaign: campaign?.data,
        channel: channel?.data,
        regions: regions?.data,
        budget: budget?.data,
        app: app?.data,
        activeCountries: [],
        inactiveCountries: [],
        allocatedBudget,
        unallocatedBudget: (budget?.data?.totalBudget ?? 0) - (allocatedBudget ?? 0),
        funds: funds?.data,
    };

    const requests = aggregateAsyncData(campaign, channel, regions, budget, funds, app);
    if (!requests.success) {
        return {
            ...requests,
            data,
        };
    }

    const significantRegions = regions.data.filter((region) => {
        if (isChannelWithPurposes(campaign.data?.channelType, channel.data)) {
            return channel.data?.purposes.includes((region as RubyASRRegion).purpose);
        }
        return true;
    });

    data.activeCountries = [
        ...new Set(significantRegions.filter((c) => c.status === RubyRegionStatus.ACTIVE).map((c) => c.country)),
    ];
    data.inactiveCountries = pullAll(
        [...new Set(regions.data.filter((c) => c.status !== RubyRegionStatus.ACTIVE).map((c) => c.country))],
        data.activeCountries
    );

    return {
        ...requests,
        data,
    };
}

export const selectActiveCountries = createSelector(
    [selectRegions, selectChannel, selectCampaign],
    (
        regions,
        channel,
        campaign
    ): AsyncData<{
        activeCountries: RubyCountry[];
        inactiveCountries: RubyCountry[];
    }> => {
        const req = aggregateAsyncData(campaign, channel, regions);

        if (!req.success) {
            return {
                ...req,
                data: null,
            };
        }

        const significantRegions = regions.data.filter((region) => {
            if (isChannelWithPurposes(campaign.data?.channelType, channel.data)) {
                return channel.data?.purposes.includes((region as RubyASRRegion).purpose);
            }
            return true;
        });

        const activeCountries = [
            ...new Set(significantRegions.filter((c) => c.status === RubyRegionStatus.ACTIVE).map((c) => c.country)),
        ];
        const inactiveCountries = pullAll(
            [...new Set(regions.data.filter((c) => c.status !== RubyRegionStatus.ACTIVE).map((c) => c.country))],
            activeCountries
        );

        return {
            ...req,
            data: {
                activeCountries,
                inactiveCountries,
            },
        };
    }
);

export function selectCampaignHistoryRecords(state: State, query: RubyCampaignHistoryRecordQuery) {
    const requests = state.campaigns.historyRecords[query.campaignId];
    const request = requests?.find(matchHistoryRecord(query));
    return (
        request ?? {
            ...initialAsyncDataState(),
            ...query,
        }
    );
}

export const selectCampaignsSearch = createSelector(
    [
        selectCampaigns,
        selectCampaignSearchString,
        selectRegionFilter,
        selectSearchAppFilter,
        selectCampaignSortOrder,
        selectCampaignVisibilityFilter,
        selectTeamCampaignDetails,
        selectCampaignListData,
        <T extends RubyCampaign>(_state: State, _teamId?: RubyTeamId, typeFilter?: (c: RubyCampaign) => c is T) =>
            typeFilter,
    ],
    (
        campaigns,
        search,
        regionsFilter,
        searchAppFilter,
        sortBy,
        visibilityFilter,
        campaignDetails,
        listData,
        typeFilter
    ) => {
        if (!campaigns.success) {
            return {
                ...campaigns,
                data: null,
            };
        }

        //Filter typed campaigns
        const typedCampaigns = campaigns.data.filter(typeFilter);

        //Filter Search
        const searched = typedCampaigns.filter(
            (campaign) =>
                !search ||
                campaign.name.toLocaleLowerCase().includes(search) ||
                campaign.description.toLocaleLowerCase().includes(search)
        );

        const filtered = searched
            // Filter visibility
            .filter((campaign) => {
                if (!visibilityFilter.STATUS) {
                    const details = campaignDetails.find((details) => details.data?.campaign?.id === campaign.id);
                    const active = listData.success
                        ? listData.data?.activeCampaignList?.includes(campaign.id)
                        : details?.data?.channel?.status === RubyChannelStatus.ACTIVE;
                    if (!active) return active;
                }
                if (!visibilityFilter.BUDGET_REMAINING) {
                    const budget = listData.data?.budgetRemainingList?.[campaign.id];
                    if (budget <= 0) {
                        return false;
                    }
                }
                return true;
            })
            // Filter regions
            .filter((campaign) => {
                if (!regionsFilter.length) {
                    return true;
                }
                const countryList = listData.data?.countryList?.[campaign.id];
                const details = campaignDetails.find((details) => details.data?.campaign?.id === campaign.id);
                const activeCountries = details.data?.activeCountries;
                return intersection(countryList ?? activeCountries, regionsFilter).length > 0;
            })
            // Filter apps
            .filter((campaign) => {
                if (!searchAppFilter.length) {
                    return true;
                }
                const appId = listData.data?.appList?.[campaign.id];
                const details = campaignDetails.find((details) => details.data?.campaign?.id === campaign.id);
                return searchAppFilter.includes(appId ?? details.data?.app?.trackId);
            });

        //Sort
        const sorted = sortCampaignSearch(filtered, sortBy, listData.data);

        return {
            ...campaigns,
            data: sorted,
        };
    }
);

export const selectCampaignsSearchIds = createSelector([selectCampaignsSearch], (search) =>
    search.data?.map((campaign) => campaign.id)
);

function sortCampaignSearch<T extends RubyCampaign>(
    campaigns: T[],
    sortBy: CampaignSortOrder,
    listData: RubyCampaignListingData
): T[] {
    return campaigns.sort((a, b) => {
        switch (sortBy) {
            case CampaignSortOrder.CREATED_AT: {
                return b?.createdAt - a?.createdAt;
            }
            case CampaignSortOrder.NAME: {
                return a?.name.localeCompare(b?.name);
            }

            case CampaignSortOrder.BUDGET_REMAINING: {
                const budgetA = listData?.budgetRemainingList?.[a?.id] ?? 0;
                const budgetB = listData?.budgetRemainingList?.[b?.id] ?? 0;
                return budgetB - budgetA;
            }
        }
    });
}

export const selectCampaignEvents = createSelector(
    [(state: State, campaignId: RubyCampaignId) => state.campaigns.events[campaignId]],
    (eventCounts): AsyncData<EventsList> => {
        if (eventCounts === undefined) {
            return initialAsyncDataState();
        }

        if (!eventCounts?.success) {
            return {
                ...eventCounts,
                data: null,
            };
        }

        const data = Object.keys(eventCounts.data ?? {});
        return {
            ...eventCounts,
            data,
        };
    }
);

export const selectTeamEvents = createSelector(
    [(state: State, teamId: RubyTeamId) => state.campaigns.teamEvents[teamId]],
    (eventCounts): AsyncData<EventsList> => {
        if (eventCounts === undefined) {
            return initialAsyncDataState();
        }

        if (!eventCounts?.success) {
            return {
                ...eventCounts,
                data: null,
            };
        }

        const data = Object.keys(eventCounts.data ?? {});
        return {
            ...eventCounts,
            data,
        };
    }
);

export const selectTeamActiveRegions = createSelector([selectCampaignListData], (listData): RubyCountry[] => {
    if (!listData.success) {
        return [];
    }
    return uniq(Object.values(listData.data.countryList).flat());
});

export const selectTeamActiveApps = createSelector(
    [selectTeamCampaignDetails],
    (campaignDetails): AsyncData<RubyIOSApp[]> => {
        const request = aggregateAsyncData(...campaignDetails);
        if (!request.success) {
            return {
                ...request,
                data: null,
            };
        }

        const apps = campaignDetails?.map((details) => {
            return {
                ...details,
                data: details.data?.app,
            };
        });

        const appReq = aggregateAsyncData(...apps);

        return {
            ...appReq,
            data: uniqBy(appReq.data, "trackId"),
        };
    }
);

export const selectTeamActiveAppIds = createSelector([selectCampaignListData], (listData) => {
    if (!listData.success) {
        return {
            ...listData,
            data: [],
        };
    }

    const appIds = uniqBy(
        Object.entries(listData.data.appList).map(([campaignId, appId]) => {
            return {
                storefront: listData.data.storefrontList?.[parseInt(campaignId, 10)],
                appId,
            };
        }),
        "appId"
    );
    return {
        ...listData,
        data: appIds,
    };
});

export const selectExperiments = createSelector([selectCampaigns], (campaigns): AsyncData<ExperimentCampaign[]> => {
    if (campaigns.success) {
        const experiments = campaigns.data.filter(isExperiment);
        return {
            ...campaigns,
            data: experiments,
        };
    }
    return { ...campaigns, data: null };
});

export function isExperiment(campaign: RubyCampaign): campaign is ExperimentCampaign {
    return (
        campaign?.objective?.objectiveType === CampaignObjectiveType.EXPERIMENT &&
        campaign?.objective?.campaignIdB !== campaign?.id
    );
}

export const selectImportedCampaigns = createSelector([selectCampaigns], (campaigns): AsyncData<ImportedCampaign[]> => {
    if (campaigns.success) {
        const importedCampaigns = campaigns.data.filter(isImportedCampaign);
        return {
            ...campaigns,
            data: importedCampaigns,
        };
    }
    return { ...campaigns, data: null };
});

export function isImportedCampaign(campaign: RubyCampaign): campaign is ImportedCampaign {
    return campaign?.objective?.objectiveType === CampaignObjectiveType.IMPORTED_CAMPAIGN;
}

export function isAdCampaign(campaign: RubyCampaign): campaign is AdCampaign {
    return (
        !campaign?.objective ||
        !campaign?.objective?.objectiveType ||
        campaign?.objective?.objectiveType === CampaignObjectiveType.AD_CAMPAIGN
    );
}

export function selectEligibleCampaigns(state: State, teamId?: RubyTeamId) {
    teamId ??= selectActiveTeamId(state);
    return state.campaigns.eligibleImports[teamId] ?? initialAsyncDataState();
}
