import { matchKeywordsSummary, matchSearchTermsSummary } from "@/selectors/matchers";
import { concatArray } from "@/utilities/array";
import isEqual from "lodash/isEqual";
import memoize from "lodash/memoize";
import { RequestActionState, type RequestAction } from "../actions/Action";
import { type RubyListResponse, type RubyMetricsReportQuery, type RubyTeamId } from "../services/backend/RubyData";
import { type AsyncData, type PaginatedAsyncData, type ReportAsyncData, type RequestState } from "./domain";

export type Processor<T, R> = (response: T) => R;
export type Predicate<T> = (toMatch: T) => boolean;
export type AsyncState<T, Q> = AsyncData<T> & Q;
export type PredicateSupplier<Q> = (query: Q) => Predicate<Q>;

export const initialRequestState = (): RequestState => ({
    isRequesting: false,
    success: false,
    errorMessage: null,
});

export const initialAsyncDataState = memoize(<T = null>(initVal?: T): AsyncData<T> => {
    return {
        ...initialRequestState(),
        lastUpdated: 0,
        data: initVal,
    };
}, JSON.stringify);

export const initialPaginatedAsyncData = memoize(<T extends unknown[]>(initVal?: T): PaginatedAsyncData<T> => {
    return {
        ...initialAsyncDataState<T>(initVal),
        recordsLoaded: 0,
        totalRecords: 0,
        hasAll: false,
    };
}, JSON.stringify);

export function resolvedRequestState(): RequestState {
    return {
        isRequesting: false,
        success: true,
        errorMessage: null,
    };
}

export function resolvedAsyncDataState<T>(data: T): AsyncData<T> {
    return {
        ...resolvedRequestState(),
        lastUpdated: Date.now(),
        data,
    };
}

export function requestActionReducer<T = unknown>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor?: undefined,
    prevState?: AsyncData<T>
): AsyncData<T>;
export function requestActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor: Processor<T, R>,
    prevState?: AsyncData<R>
): AsyncData<R>;
export function requestActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor?: Processor<T, R>,
    prevState?: AsyncData<R | T>
): AsyncData<R | T> {
    if (action.payload.state === RequestActionState.CALL) {
        return {
            errorMessage: null,
            isRequesting: true,
            success: false,
            data: prevState?.data ?? null,
            lastUpdated: prevState?.lastUpdated ?? 0,
        };
    }
    if (action.payload.state === RequestActionState.ERROR) {
        return {
            errorMessage: action.payload.errorMessage,
            isRequesting: false,
            success: false,
            data: prevState?.data ?? null,
            lastUpdated: prevState?.lastUpdated ?? 0,
        };
    }
    if (action.payload.state === RequestActionState.SUCCESS) {
        return {
            errorMessage: null,
            isRequesting: false,
            success: true,
            data: dataProcessor ? dataProcessor(action.payload.response) : action.payload.response,
            lastUpdated: Date.now(),
        };
    }
    return prevState ?? initialAsyncDataState();
}

export function paginatedRequestActionReducer<T extends RubyListResponse<unknown>>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor?: undefined,
    prevState?: PaginatedAsyncData<T["results"]>
): PaginatedAsyncData<T["results"]>;
export function paginatedRequestActionReducer<T extends RubyListResponse<unknown>, R extends unknown[]>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor: Processor<T["results"], R>,
    prevState?: PaginatedAsyncData<R>
): PaginatedAsyncData<R>;
export function paginatedRequestActionReducer<T extends RubyListResponse<unknown>, R extends unknown[]>(
    action: RequestAction<unknown, unknown, T>,
    dataProcessor?: Processor<T["results"], R>,
    prevState?: PaginatedAsyncData<R | T["results"]>
): PaginatedAsyncData<R | T["results"]> {
    const processor = (v: T) => {
        const results = v.results.filter((result: object) => {
            if (!prevState || !prevState.data) {
                return true;
            }
            return !prevState.data.find((prevResult: object) => {
                if ("id" in result && "id" in prevResult) {
                    return result.id === prevResult.id;
                }
                return isEqual(result, prevResult);
            });
        });
        return dataProcessor ? dataProcessor(results) : (results as T["results"]);
    };
    if (action.payload.state === RequestActionState.SUCCESS) {
        const response = action.payload.response;
        const processedAction = requestActionReducer(action, processor, prevState);
        let data: T["results"] | R = concatArray(prevState?.data, processedAction?.data);
        // If we have results with 0 skipped records, then we should reset our data assuming
        // the pagination has been refreshed/reset to page 1
        if (response.skip === 0) {
            data = dataProcessor ? dataProcessor(response.results) : response.results;
        }
        return {
            ...processedAction,
            data,
            recordsLoaded: data.length,
            totalRecords: response.total,
            hasAll: response.total <= data.length,
        };
    }
    return {
        ...prevState,
        ...requestActionReducer(action, processor, prevState),
    };
}

export function requestMetricsReportActionReducer<T = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<T>[],
    dataProcessor?: undefined
): ReportAsyncData<T>[];
export function requestMetricsReportActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<R>[],
    dataProcessor: Processor<T, R>
): ReportAsyncData<R>[];
export function requestMetricsReportActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<R | T>[],
    dataProcessor?: Processor<T, R>
): ReportAsyncData<R | T>[] {
    return requestReportActionReducer(action, prevState, matchKeywordsSummary, dataProcessor);
}

export function requestSearchTermReportActionReducer<T = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<T>[],
    dataProcessor?: undefined
): ReportAsyncData<T>[];
export function requestSearchTermReportActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<R>[],
    dataProcessor: Processor<T, R>
): ReportAsyncData<R>[];
export function requestSearchTermReportActionReducer<T = unknown, R = unknown>(
    action: RequestAction<unknown, RubyMetricsReportQuery, T>,
    prevState: ReportAsyncData<R | T>[],
    dataProcessor?: Processor<T, R>
): ReportAsyncData<R | T>[] {
    return requestReportActionReducer(action, prevState, matchSearchTermsSummary, dataProcessor);
}

export function requestReportActionReducer<T = unknown, Q = unknown>(
    action: RequestAction<unknown, Q, T>,
    prevState: AsyncState<T, Q>[],
    matcher: PredicateSupplier<Q>,
    dataProcessor?: undefined
): AsyncState<T, Q>[];
export function requestReportActionReducer<T = unknown, Q = unknown, R = unknown>(
    action: RequestAction<unknown, Q, T>,
    prevState: AsyncState<R, Q>[],
    matcher: PredicateSupplier<Q>,
    dataProcessor: Processor<T, R>
): AsyncState<R, Q>[];
export function requestReportActionReducer<
    T = unknown,
    Q extends { teamId: RubyTeamId } = { teamId: RubyTeamId },
    R = unknown
>(
    action: RequestAction<unknown, Q, T>,
    prevState: AsyncState<T | R, Q>[],
    matcher: PredicateSupplier<Q>,
    dataProcessor?: Processor<T, R>
): AsyncState<T | R, Q>[] {
    if (!prevState) {
        prevState = [];
    }
    const nextState = [...prevState];

    const query = action.payload.request;
    const requestIndex = nextState.findIndex(matcher(query));

    if (requestIndex < 0) {
        nextState.push({
            ...requestActionReducer(action, dataProcessor),
            ...query,
        });
        return nextState;
    }

    const previousReq = nextState[requestIndex];
    nextState[requestIndex] = {
        ...requestActionReducer(action, dataProcessor, previousReq),
        ...query,
    };

    return nextState;
}
