import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { loginRequestAction, loginWithGoogleAction } from "../../../../actions/userActions";
import { type State } from "../../../../reducers/domain";
import LoginComponent, { type LoginComponentProps, type LoginProps } from "./LoginComponent";

const mapStateToProps = (state: State, props: LoginProps): Partial<LoginComponentProps> => ({
    ...props,
    loginError: state.user.loginErrorMessage,
    isLoggingIn: state.user.isLoggingIn,
    isLoggingInWithGoogle: state.user.isLoggingInWithGoogle,
    isRestoring: state.user.loginIsRestoring,
    isLoggedIn: state.user.isLoggedIn,
    nextPageRedirect: state.user.loginRedirect,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<LoginComponentProps> => ({
    onLogin: (username: string, password: string) =>
        dispatch(
            loginRequestAction({
                username,
                password,
            })
        ),
    onLoginWithGoogle: () => dispatch(loginWithGoogleAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
