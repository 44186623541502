import { type UserBroadcastMessage } from "../services/BroadcastService";
import { type Action } from "./Action";

export enum MetaActionName {
    WINDOW_RESIZE = "WINDOW_RESIZE",
    WINDOW_SCROLL = "WINDOW_SCROLL",
    BROADCAST_MESSAGE = "BROADCAST_MESSAGE",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace MetaActions {
    export type WindowResizeAction = Action<
        MetaActionName.WINDOW_RESIZE,
        {
            width: number;
            height: number;
        }
    >;
    export type WindowScrollAction = Action<MetaActionName.WINDOW_SCROLL, number>;
    export type BroadcastMessageAction = Action<MetaActionName.BROADCAST_MESSAGE, UserBroadcastMessage>;
}

export type MetaActionType =
    | MetaActions.WindowResizeAction
    | MetaActions.WindowScrollAction
    | MetaActions.BroadcastMessageAction;

export function windowResizeAction(width: number, height: number): MetaActions.WindowResizeAction {
    return {
        type: MetaActionName.WINDOW_RESIZE,
        payload: {
            width,
            height,
        },
    };
}

export function windowScrollAction(scrollAmount: number): MetaActions.WindowScrollAction {
    return {
        type: MetaActionName.WINDOW_SCROLL,
        payload: scrollAmount,
    };
}

export function broadcastMessageAction(message: UserBroadcastMessage): MetaActions.BroadcastMessageAction {
    return {
        type: MetaActionName.BROADCAST_MESSAGE,
        payload: message,
    };
}
