import type React from "react";
import { Profiler } from "react";

window["redboxDebugRender"] = (enable = true) => {
    localStorage.setItem("debugRender", String(enable));
};

const profilerLogger: React.ProfilerOnRenderCallback = (name, phase, duration, baseDuration, startTime, commitTime) => {
    const debug = localStorage.getItem("debugRender") === "true";
    debug &&
        console.debug(`Re-rendered: ${name}`, {
            phase,
            duration,
            baseDuration,
            startTime,
            commitTime,
        });
};

export function AppProfiler({ id, children }: React.PropsWithChildren<{ id: string }>) {
    return (
        <Profiler id={id} onRender={profilerLogger}>
            {children}
        </Profiler>
    );
}
