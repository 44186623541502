import React from "react";
import { Link } from "react-router-dom";
import { languageString } from "../../../utilities/text";
import { getUrl } from "../../../utilities/url";
import "./footer.css";

interface FooterProps {
    className?: string;
}

export default class Footer extends React.PureComponent<FooterProps> {
    render() {
        return (
            <footer className={`footer ${this.props.className || ""}`}>
                <span>{languageString("footer.copy", "© Redbox Mobile PLC")}</span>
                <span>
                    <Link to={getUrl.privacy()}>{languageString("footer.privacyLink", "Privacy Policy")}</Link>
                    {" | "}
                    <Link to={getUrl.terms()}>{languageString("footer.termsLink", "Terms & Conditions")}</Link>
                </span>
            </footer>
        );
    }
}
