import { BroadcastChannel } from "broadcast-channel";
import { v4 as uuid } from "uuid";
import { broadcastMessageAction } from "../actions/metaActions";

const BROADCAST_KEY = "RB_Broadcaster";

export interface UserBroadcastMessage {
    id: string;
    type: BroadcastType;
    payload?: unknown;
}

export enum BroadcastType {
    LOGOUT = "LOGOUT",
    LOGIN = "LOGIN",
    LOGIN_RESTORE = "LOGIN_RESTORE",
    CREATED_CAMPAIGN = "CREATED_CAMPAIGN",
    SET_COOKIE_PREFS = "SET_COOKIE_PREFS",
}

export class BroadcastService {
    private broadcaster: BroadcastChannel;
    private lastMessageId: string;

    constructor() {
        this.broadcaster = new BroadcastChannel(BROADCAST_KEY);
        this.broadcaster.addEventListener("message", this.handleBroadcastMessage.bind(this));
    }

    private handleBroadcastMessage(message: UserBroadcastMessage) {
        if (this.lastMessageId !== message.id) {
            window.dispatchToStore(broadcastMessageAction(message));
        }
    }

    post(type: BroadcastType, payload?: unknown) {
        const message = {
            id: uuid(),
            type,
            payload,
        };
        this.lastMessageId = message.id;
        void this.broadcaster.postMessage(message);
    }
}
