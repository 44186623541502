import moment from "moment";
import { type CreationState } from "../reducers/domain";
import { MAX_DATA_AGE_MINUTES } from "../utilities/vars";

const CREATION_STORAGE_KEY = "RB_creationState";

interface CreationStateWrapper {
    updatedAt: number;
    state: CreationState;
}

export class CreationService {
    store(state: CreationState) {
        const data: CreationStateWrapper = {
            updatedAt: Date.now(),
            state,
        };
        localStorage.setItem(CREATION_STORAGE_KEY, JSON.stringify(data));
    }

    retrieve(): CreationState | null {
        try {
            const data = JSON.parse(localStorage.getItem(CREATION_STORAGE_KEY)) as CreationStateWrapper;
            if (data && moment.utc(data.updatedAt).isAfter(moment.utc().subtract(MAX_DATA_AGE_MINUTES, "minutes"))) {
                return data.state;
            }
            return null;
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    clear() {
        localStorage.removeItem(CREATION_STORAGE_KEY);
    }
}
