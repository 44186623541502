import clsx from "clsx";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import React from "react";
import { languageString } from "../../../../utilities/text";
import Icon from "../../icon/Icon";
import "./errorMessage.css";

interface ErrorMessageProps {
    children?: React.ReactNode | Error | object;
    className?: string;
    warning?: boolean;
}

export default function ErrorMessage({ className, children, warning }: ErrorMessageProps) {
    return (
        <div
            className={clsx("errorMessage", className, { errorMessage_warn: warning })}
            aria-live="polite"
            aria-label={languageString("ui.alt.errorMessage", "Error Message")}
            role="alert"
        >
            <Icon.Error />
            <div className="errorMessage-msg">
                <ErrorMessageString>{children}</ErrorMessageString>
            </div>
        </div>
    );
}

interface ErrorMessageStringProps {
    children?: React.ReactNode | Error | object;
}

interface ErrorMessageState {
    error?: Error;
}

export class ErrorMessageString extends React.PureComponent<ErrorMessageStringProps, ErrorMessageState> {
    constructor(props: ErrorMessageStringProps) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        console.error(error);
        return {
            error,
        };
    }

    render() {
        if (this.state.error) {
            return (
                <>
                    {languageString("ui.error.failedRender", "Failed to render error message: ")}
                    <pre className="u-block u-mt8">
                        {(() => {
                            if (isObject(this.props.children) && isString(this.props.children["message"])) {
                                return this.props.children["message"];
                            }
                            if (this.props.children.toString && !this.props.children.toString().startsWith("[object")) {
                                return this.props.children.toString();
                            }
                            return JSON.stringify(this.props.children, null, 2);
                        })()}
                    </pre>
                </>
            );
        }

        let message = this.props.children;
        if (this.props.children instanceof Error || hasMessage(message)) {
            message = (this.props.children as Error).message;
        }
        if (isArray(this.props.children)) {
            message = this.props.children.map((child: React.ReactNode | Error | object) => {
                if (child instanceof Error || hasMessage(child)) {
                    return child.message;
                }
                return child;
            });
        }

        return <>{message}</>;
    }
}

function hasMessage(obj: unknown): obj is { message: string } {
    return typeof obj === "object" && "message" in obj;
}
