import { ReduxRouter } from "@lagunovsky/redux-react-router";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { type History } from "history";
import React from "react";
import { Provider } from "react-redux";
import { type Store } from "redux";
import { AppProfiler } from "./AppProfiler";
import { Toaster } from "./blocks/toast/Toast";

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    },
});

interface AppProviderProps {
    store: Store;
    history: History;
    children?: React.ReactNode;
}

export default class AppProvider extends React.Component<AppProviderProps> {
    render() {
        return (
            <QueryClientProvider client={queryClient}>
                <Provider store={this.props.store}>
                    <ReduxRouter history={this.props.history}>
                        <Toaster>
                            <AppProfiler id="App">{this.props.children}</AppProfiler>
                        </Toaster>
                    </ReduxRouter>
                </Provider>
            </QueryClientProvider>
        );
    }
}
