import {
    createRouterReducerMapObject,
    type LocationChangeAction,
    type ReduxRouterState,
} from "@lagunovsky/redux-react-router";
import { type History } from "history";
import { produce } from "immer";
import { type ActionType } from "../actions/ActionType";
import accountReducer, { initialAccountState } from "./accountReducer";
import bulkReducer, { initialBulkState } from "./bulkReducer";
import campaignsReducer, { initialCampaignsState } from "./campaignsReducer";
import configReducer, { initialConfigState } from "./configReducer";
import creationReducer, { initialCreationState } from "./creationReducer";
import { type State } from "./domain";
import interactionReducer, { initialInteractionState } from "./interactionReducer";
import keywordsReducer, { initialKeywordsState } from "./keywordsReducer";
import metaReducer, { initialMetaState } from "./metaReducer";
import reportsReducer, { initialReportsState } from "./reportsReducer";
import rulesReducer, { initialRulesState } from "./rulesReducer";
import teamReducer, { initialTeamState } from "./teamReducer";
import uiReducer, { initialUIState } from "./uiReducer";
import userReducer, { initialUserState } from "./userReducer";

export const initialState = {
    router: undefined as ReduxRouterState,
    meta: initialMetaState,
    ui: initialUIState,
    user: initialUserState,
    account: initialAccountState,
    team: initialTeamState,
    campaigns: initialCampaignsState,
    config: initialConfigState,
    reports: initialReportsState,
    keywords: initialKeywordsState,
    creation: initialCreationState,
    interaction: initialInteractionState,
    bulk: initialBulkState,
    rules: initialRulesState,
} satisfies State;

export default function createReducer(
    history: History,
    initState: State = initialState
): (state: State, action: ActionType) => Readonly<State> {
    const routerReducer = createRouterReducerMapObject(history);
    return (state = initState, action: ActionType) =>
        produce<State>(state, (draftState) => {
            // This one isn't part of the codebase and doesn't understand it has Immer,
            // so we assign the whole object.
            draftState.router = routerReducer.router(draftState.router, action as unknown as LocationChangeAction);

            // Run the data reducers
            metaReducer(draftState.meta, action, draftState);
            uiReducer(draftState.ui, action, draftState);
            userReducer(draftState.user, action, draftState);
            accountReducer(draftState.account, action, draftState);
            teamReducer(draftState.team, action, draftState);
            campaignsReducer(draftState.campaigns, action, draftState);
            configReducer(draftState.config, action, draftState);
            reportsReducer(draftState.reports, action, draftState);
            keywordsReducer(draftState.keywords, action, draftState);
            creationReducer(draftState.creation, action, draftState);
            interactionReducer(draftState.interaction, action, draftState);
            bulkReducer(draftState.bulk, action, draftState);
            rulesReducer(draftState.rules, action, draftState);
        });
}
