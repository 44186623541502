import random from "lodash/random";
import uniq from "lodash/uniq";

const shapes = [
    "henagon",
    "digon",
    "triangle",
    "quadrilateral",
    "kite",
    "parallelogram",
    "rhombus",
    "lozenge",
    "rhomboid",
    "rectangle",
    "square",
    "trapezoid",
    "pentagon",
    "hexagon",
    "heptagon",
    "octagon",
    "nonagon",
    "decagon",
    "hendecagon",
    "dodecagon",
    "tridecagon",
    "tetradecagon",
    "pentadecagon",
    "hexadecagon",
    "heptadecagon",
    "octadecagon",
    "enneadecagon",
    "icosagon",
    "pentagram",
    "hexagram",
    "heptagram",
    "octagram",
    "decagram",
    "apeirogon",
    "annulus",
    "arbelos",
    "circle",
    "disc",
    "crescent",
    "lens",
    "lune",
    "quatrefoil",
    "salinon",
    "semicircle",
    "trefoil",
    "triquetra",
    "heart",
    "spiral",
    "astroid",
    "cardioid",
    "deltoid",
    "ellipse",
    "oval",
    "ovoid",
    "cross",
    "arrow",
    "cube",
    "cylinder",
    "star",
    "plane",
    "torus",
    "pyramid",
    "cone",
];

const complexShapes = [
    "acute triangle",
    "equilateral triangle",
    "heptagonal triangle",
    "isosceles triangle",
    "obtuse triangle",
    "rational triangle",
    "right triangle",
    "kepler triangle",
    "scalene triangle",
    "cyclic quadrilateral",
    "tangential quadrilateral",
    "isosceles trapezoid",
    "lemoine hexagon",
    "circular sector",
    "circular segment",
    "reuleaux polygon",
    "reuleaux triangle",
    "archimedean spiral",
    "cartesian oval",
    "cassini oval",
];

const colors = [
    "amber",
    "amethyst",
    "apricot",
    "aqua",
    "aquamarine",
    "auburn",
    "azure",
    "beige",
    "black",
    "blue",
    "bronze",
    "brown",
    "burgundy",
    "cerise",
    "cerulean",
    "charcoal",
    "chartreuse",
    "chestnut",
    "chocolate",
    "cobalt",
    "copper",
    "cream",
    "crimson",
    "cyan",
    "emerald",
    "fuchsia",
    "gold",
    "goldenrod",
    "green",
    "grey",
    "indigo",
    "ivory",
    "jade",
    "khaki",
    "lavender",
    "lemon",
    "lilac",
    "lime",
    "linen",
    "magenta",
    "magnolia",
    "maroon",
    "mauve",
    "mustard",
    "ochre",
    "olive",
    "orange",
    "peach",
    "periwinkle",
    "pink",
    "platinum",
    "plum",
    "puce",
    "purple",
    "red",
    "rose",
    "ruby",
    "russet",
    "saffron",
    "salmon",
    "sapphire",
    "scarlet",
    "seashell",
    "sepia",
    "silver",
    "tan",
    "taupe",
    "teal",
    "tomato",
    "turquoise",
    "ultramarine",
    "vermilion",
    "violet",
    "viridian",
    "white",
    "yellow",
];

const complexColors = [
    "blue green",
    "blue violet",
    "bondi blue",
    "burnt orange",
    "burnt sienna",
    "burnt umber",
    "camouflage green",
    "carrot orange",
    "fern green",
    "forest green",
    "han purple",
    "lawn green",
    "lime green",
    "midnight blue",
    "mint green",
    "moss green",
    "navajo white",
    "navy blue",
    "office green",
    "pine green",
    "powder blue",
    "prussian blue",
    "quartz grey",
    "raw umber",
    "royal blue",
    "royal purple",
    "sandy brown",
    "sea green",
    "shamrock green",
    "shocking pink",
    "sky blue",
    "slate grey",
    "spring green",
    "steel blue",
    "terra cotta",
    "titanium white",
];

interface RandomWordsConfig {
    min?: number;
    max?: number;
    exact?: number;
    formatter?: (w: string) => string;
}

export function randomWords(config: RandomWordsConfig = { exact: 1 }): string {
    const amount = config.exact ?? random(config.min ?? 1, config.max ?? 4);
    const formatter = config.formatter ?? ((a) => a);

    if (amount === 1) {
        return formatter(randomWord(shapes));
    }
    if (amount === 2) {
        return [randomWord(colors), randomWord(shapes)].map(formatter).join(" ");
    }
    if (amount === 3) {
        return [randomWord(colors), randomWord(complexShapes)].map(formatter).join(" ");
    }
    if (amount >= 4) {
        return [randomWord(complexColors), randomWord(complexShapes)].map(formatter).join(" ");
    }
}

function randomWord(words: string[]): string {
    const l = words.length - 1;
    const i = random(0, l);
    return words[i];
}

export function randomWordsSet(count: number, unique = false, config?: RandomWordsConfig): string[] {
    if (!unique) {
        return new Array(count).fill("").map(() => randomWords(config));
    }
    let words: string[] = [];
    let ejectCount = 0;
    while (words.length < count) {
        words.concat(randomWordsSet(count - words.length, false, config));
        words = uniq(words);
        ejectCount++;
        if(ejectCount > 250){
            break;
        }
    }
    return words;
}
