import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { languageString } from "../../../../utilities/text";
import { getUrl } from "../../../../utilities/url";
import { CONTACT_EMAIL } from "../../../../utilities/vars";
import ButtonLink from "../../buttons/link/ButtonLink";
import Button from "../../buttons/standard/Button";
import Card from "../../card/basic/Card";
import Icon from "../../icon/Icon";
import MailTo from "../../mailTo/MailTo";
import RequestLoader from "../../requestLoader/RequestLoader";
import Scroller from "../../scroller/Scroller";
import ErrorMessage from "../errorMessage/ErrorMessage";
import "./errorScreen.css";

interface ErrorScreenProps {
    message?: string;
    getTechnicalDetails?: () => Promise<string>;
    onLeave?: () => void;
}

export default function ErrorScreen({ message, onLeave, getTechnicalDetails }: ErrorScreenProps) {
    const [showTech, setShowTech] = useState(false);

    const technicalDetails = useQuery({
        queryKey: ["ErrorScreenTechDetails"],
        queryFn: getTechnicalDetails,
        enabled: !!getTechnicalDetails && showTech,
    });

    return (
        <div className="errorScreen">
            <div className="errorScreen-inner">
                <Icon.Error className="errorScreen-icon" width={64} height={64} />
                <h1>{languageString("ui.error.pageTitle", "Sorry, something has gone wrong")}</h1>

                {message && <ErrorMessage className="u-mb32">{message}</ErrorMessage>}

                <p className="u-pb24">
                    {languageString("ui.error.issueAssist.prefix", "If the issue persists then please")}{" "}
                    <MailTo email={CONTACT_EMAIL}>
                        {languageString("ui.error.issueAssist.emailLink", "contact us")}
                    </MailTo>{" "}
                    {languageString("ui.error.issueAssist.suffix", "for assistance.")}
                </p>

                <ButtonLink to={getUrl.home()} className="u-mb16" onClick={onLeave}>
                    {languageString("ui.error.returnHome", "Return Home")}
                </ButtonLink>

                {!!getTechnicalDetails && (
                    <>
                        {!showTech && (
                            <Button buttonType="clear" onClick={() => setShowTech(true)}>
                                {languageString("ui.error.showStack", "Show technical info")}
                            </Button>
                        )}
                        <Card className={`errorScreen-stack u-mt16 u-p0 ${showTech ? "" : "u-hidden"}`}>
                            {{
                                body: () => (
                                    <RequestLoader
                                        request={{
                                            errorMessage: technicalDetails.error as string,
                                            success: technicalDetails.isSuccess,
                                            isRequesting: technicalDetails.isLoading,
                                        }}
                                    >
                                        <Scroller autoHeight maxHeight={"50vh"}>
                                            <pre>{technicalDetails.data}</pre>
                                        </Scroller>
                                    </RequestLoader>
                                ),
                            }}
                        </Card>
                    </>
                )}
            </div>
        </div>
    );
}
