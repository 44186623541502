import React from "react";
import { languageString } from "../../../utilities/text";
import ButtonIcon from "../../blocks/buttons/icon/ButtonIcon";
import { OverlayAlignment, OverlayDirection } from "../../blocks/overlays/domain";
import ToolMenu from "../../blocks/overlays/toolMenu/ToolMenu";
import Responsive, { ResponsiveElement } from "../../blocks/responsive/Responsive";
import Breadcrumbs from "../breadcrumbs/Breadcrumbs";
import Notifications from "../notifications/Notifications";
import "./pageHeader.css";

interface PageHeaderProps {
    title: string;
    subTitle: React.ReactNode;
    filters?: React.ReactElement;
    config?: React.ReactElement;
    additionalIcon?: React.ReactElement;
}

export default class PageHeader extends React.PureComponent<PageHeaderProps> {
    render(): React.ReactNode {
        return (
            <header className="pageHeader">
                <div className="pageHeader-top">
                    <Breadcrumbs pageName={this.props.title} />
                    <div className="pageHeader-icons">
                        {this.props.additionalIcon}
                        {this.props.config && (
                            <ToolMenu preferredDirection={OverlayDirection.LEFT} alignment={OverlayAlignment.START}>
                                {{
                                    target: <ButtonIcon icon="Settings" label="Configure Page" />, //TODO language string
                                    content: this.props.config,
                                }}
                            </ToolMenu>
                        )}
                        <Notifications />
                    </div>
                </div>
                <div className="pageHeader-mid">
                    <div className="pageHeader-titleBlock">
                        <h1>{this.props.title}</h1>
                        <small>{this.props.subTitle}</small>
                    </div>
                    {this.props.filters && (
                        <div className="pageHeader-filters">
                            <Responsive>
                                <ResponsiveElement minWidth={800}>{this.props.filters}</ResponsiveElement>
                                <ResponsiveElement>
                                    <ToolMenu
                                        preferredDirection={OverlayDirection.DOWN}
                                        alignment={OverlayAlignment.END}
                                    >
                                        {{
                                            target: (
                                                <ButtonIcon
                                                    label={languageString("ui.alt.filters", "Filters")}
                                                    icon="Filter"
                                                    showLabel
                                                />
                                            ),
                                            content: <div className="u-p8">{this.props.filters}</div>,
                                        }}
                                    </ToolMenu>
                                </ResponsiveElement>
                            </Responsive>
                        </div>
                    )}
                </div>
            </header>
        );
    }
}
