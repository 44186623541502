import React from "react";
import { OverlayDirection } from "./domain";

interface OverlayControllerProps {
    children: (isOpen: boolean, onToggle: (isOpen: boolean) => void) => React.ReactElement;
}

interface OverlayControllerState {
    isOpen: boolean;
}

export default class Tooltip extends React.PureComponent<OverlayControllerProps, OverlayControllerState> {
    static defaultProps = {
        preferredDirection: OverlayDirection.UP,
    };

    constructor(props: OverlayControllerProps) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    handleToggle(isOpen: boolean) {
        this.setState({
            isOpen,
        });
    }

    render() {
        return this.props.children(this.state.isOpen, this.handleToggle.bind(this));
    }
}
