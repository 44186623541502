import { selectTeam } from "@/selectors/teamSelectors";
import { MINIMUM_BID_VALUE } from "@/utilities/vars";
import pullAllBy from "lodash/pullAllBy";
import uniqBy from "lodash/uniqBy";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { RubyKeywordStatus, type RubyKeyword } from "../services/backend/RubyData";
import { type KeywordsState, type State } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const initialKeywordsState: KeywordsState = {
    targeting: {},
    negative: {},
    seed: {},
    bidsInfo: {},
    addTargetingKeywordsRequest: initialRequestState(),
    addNegativeKeywordsRequest: initialRequestState(),
    removeTargetingKeywordsRequest: initialRequestState(),
    removeNegativeKeywordsRequest: initialRequestState(),
    makeNegativeTargetingKeywordsRequest: initialRequestState(),
    updateSeedKeywordsRequest: initialRequestState(),
    tagTargetingKeywordsRequest: initialRequestState(),
    tagNegativeKeywordsRequest: initialRequestState(),
    setKeywordsRegionsRequest: initialRequestState(),
    setKeywordsBidsRequest: initialRequestState(),
    setKeywordsMatchRequest: initialRequestState(),
    importTargetingKeywordsRequest: initialRequestState(),
    importNegativeKeywordsRequest: initialRequestState(),
};

export default function keywordsReducer(
    state: KeywordsState = initialKeywordsState,
    action: ActionType,
    globalState: State
): void {
    switch (action.type) {
        case ActionName.LOCATION_CHANGE: {
            state.addTargetingKeywordsRequest = initialRequestState();
            state.addNegativeKeywordsRequest = initialRequestState();
            state.removeTargetingKeywordsRequest = initialRequestState();
            state.removeNegativeKeywordsRequest = initialRequestState();
            state.updateSeedKeywordsRequest = initialRequestState();
            state.tagTargetingKeywordsRequest = initialRequestState();
            state.tagNegativeKeywordsRequest = initialRequestState();
            state.setKeywordsRegionsRequest = initialRequestState();
            state.setKeywordsBidsRequest = initialRequestState();
            state.setKeywordsMatchRequest = initialRequestState();
            state.importTargetingKeywordsRequest = initialRequestState();
            state.importNegativeKeywordsRequest = initialRequestState();
            break;
        }

        case ActionName.GET_TARGETING_KEYWORDS: {
            const campaignId = action.payload.request.campaignId;
            state.targeting[campaignId] = requestActionReducer(
                action,
                (keywords: RubyKeyword[]) => keywords.filter((kw) => kw.status !== RubyKeywordStatus.INVALID),
                state.targeting[campaignId]
            );
            break;
        }

        case ActionName.GET_TARGETING_KEYWORD_BIDS_INFO: {
            const teamId = action.payload.request.teamId;
            const campaignId = action.payload.request.campaignId;
            const team = selectTeam(globalState, teamId);
            state.bidsInfo[campaignId] = requestActionReducer(
                action,
                (bidInfo) => {
                    return bidInfo.map((info) => {
                        // Split the single suggested bid into a range
                        if (
                            info.suggestedBidRecommendation &&
                            (!info.minBidRecommendation || !info.maxBidRecommendation)
                        ) {
                            const diff = info.suggestedBidRecommendation * 0.1; // Set values 10% either way
                            info.minBidRecommendation = Math.max(
                                MINIMUM_BID_VALUE[team.data?.currency ?? "GBP"],
                                info.suggestedBidRecommendation - diff
                            );
                            info.maxBidRecommendation = info.suggestedBidRecommendation + diff;
                        }

                        // Ensure we don't show 0s
                        if (!info.minBidRecommendation || !info.maxBidRecommendation) {
                            delete info.minBidRecommendation;
                            delete info.maxBidRecommendation;
                        }

                        return info;
                    });
                },
                state.bidsInfo[campaignId]
            );
            break;
        }

        case ActionName.GET_NEGATIVE_KEYWORDS: {
            const campaignId = action.payload.request.campaignId;
            state.negative[campaignId] = requestActionReducer(
                action,
                (keywords: RubyKeyword[]) => keywords.filter((kw) => kw.status === RubyKeywordStatus.ACTIVE),
                state.negative[campaignId]
            );
            break;
        }

        case ActionName.GET_SEED_KEYWORDS: {
            const campaignId = action.payload.request.campaignId;
            state.seed[campaignId] = requestActionReducer(
                action,
                (keywords: RubyKeyword[]) => keywords.filter((kw) => kw.status === RubyKeywordStatus.ACTIVE),
                state.seed[campaignId]
            );
            break;
        }

        case ActionName.ADD_TARGETING_KEYWORDS: {
            state.addTargetingKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const updatedIds = action.payload.response.map((kw) => kw.id);
                state.targeting[campaignId].data ??= [];
                const keywords = state.targeting[campaignId].data.filter((kw) => !updatedIds.includes(kw.id));
                keywords.push(...action.payload.response);
                state.targeting[campaignId].data = keywords;
            }
            break;
        }

        case ActionName.ADD_NEGATIVE_KEYWORDS: {
            state.addNegativeKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;

                state.negative[campaignId].data ??= [];
                state.negative[campaignId].data.push(...action.payload.response.addedNegativeKeywords);

                state.targeting[campaignId].data = pullAllBy(
                    state.targeting[campaignId].data,
                    action.payload.response.removedTargetingKeywords,
                    "id"
                );
            }
            break;
        }

        case ActionName.REMOVE_TARGETING_KEYWORDS: {
            state.removeTargetingKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const removedIds = action.payload.request.keywords.map((k) => k.id);

                state.targeting[campaignId].data = state.targeting[campaignId].data.map((keyword) => {
                    if (removedIds.includes(keyword.id)) {
                        keyword.status = RubyKeywordStatus.DISABLED;
                    }
                    return keyword;
                });
            }
            break;
        }

        case ActionName.MAKE_NEGATIVE_TARGETING_KEYWORDS: {
            state.makeNegativeTargetingKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const removedIds = action.payload.request.keywords.map((k) => k.id);

                state.targeting[campaignId].data = state.targeting[campaignId].data.map((keyword) => {
                    if (removedIds.includes(keyword.id)) {
                        keyword.status = RubyKeywordStatus.DISABLED;
                    }
                    return keyword;
                });

                state.negative[campaignId].data ??= [];
                state.negative[campaignId].data.push(...action.payload.response);
            }
            break;
        }

        case ActionName.REMOVE_NEGATIVE_KEYWORDS: {
            state.removeNegativeKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.negative[campaignId].data ??= [];
                state.negative[campaignId].data = pullAllBy(
                    state.negative[campaignId].data,
                    action.payload.request.keywords,
                    "id"
                );
            }
            break;
        }

        case ActionName.UPDATE_SEED_KEYWORDS: {
            state.updateSeedKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;

                let campaignSeed = state.seed[campaignId].data;
                campaignSeed = pullAllBy(campaignSeed, action.payload.request.removedKeywords, "id");

                state.seed[campaignId].data = [...campaignSeed, ...action.payload.response];
            }
            break;
        }

        case ActionName.TAG_TARGETING_KEYWORDS: {
            state.tagTargetingKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.targeting[campaignId].data.forEach((keyword) => {
                    const isTagged = !!action.payload.request.keywords.find((kw) => kw.id === keyword.id);
                    if (isTagged) {
                        keyword.tag = action.payload.request.tag;
                    }
                });
            }
            break;
        }

        case ActionName.TAG_NEGATIVE_KEYWORDS: {
            state.tagNegativeKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.negative[campaignId].data.forEach((keyword) => {
                    const isTagged = !!action.payload.request.keywords.find((kw) => kw.id === keyword.id);
                    if (isTagged) {
                        keyword.tag = action.payload.request.tag;
                    }
                });
            }
            break;
        }

        case ActionName.SET_TARGETING_MATCH: {
            state.setKeywordsMatchRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                for (const keyword of action.payload.response) {
                    const updatedKeyword = state.targeting[campaignId].data.find((kw) => kw.id === keyword.id);
                    if (updatedKeyword) {
                        updatedKeyword.match = keyword.match;
                        updatedKeyword.assignments = keyword.assignments;
                    } else {
                        state.targeting[campaignId].data.push(keyword);
                    }
                }
                // Check for deleted keywords
                for (const oldKeyword of action.payload.request.keywords) {
                    const responseKeyword = action.payload.response.find((kw) => kw.id === oldKeyword.id);
                    if (!responseKeyword) {
                        state.targeting[campaignId].data = state.targeting[campaignId].data.filter(
                            (kw) => kw.id !== oldKeyword.id
                        );
                    }
                }
            }
            break;
        }

        case ActionName.SET_TARGETING_BIDS:
        case ActionName.SET_TARGETING_REGIONS: {
            if (action.type === ActionName.SET_TARGETING_BIDS) {
                state.setKeywordsBidsRequest = requestActionReducer(action);
            }
            if (action.type === ActionName.SET_TARGETING_REGIONS) {
                state.setKeywordsRegionsRequest = requestActionReducer(action);
            }

            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const newAssignments = action.payload.response;

                state.targeting[campaignId].data.forEach((keyword) => {
                    const isAffected = action.payload.request.keywords.find((kw) => kw.id === keyword.id);
                    if (isAffected) {
                        keyword.assignments = newAssignments.filter((ass) => ass.keywordId === keyword.id);
                    }
                });
            }
            break;
        }

        case ActionName.IMPORT_TARGETING_KEYWORDS: {
            state.importTargetingKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.targeting[campaignId].data = uniqBy(
                    [...state.targeting[campaignId].data, ...action.payload.response],
                    "id"
                );
            }
            break;
        }

        case ActionName.IMPORT_NEGATIVE_KEYWORDS: {
            state.importNegativeKeywordsRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.negative[campaignId].data = uniqBy(
                    [...state.negative[campaignId].data, ...action.payload.response],
                    "id"
                );
                //TODO remove from targeting
            }
            break;
        }
    }
}
