import { Formik, type FormikConfig, type FormikHelpers } from "formik";
import noop from "lodash/noop";
import omit from "lodash/omit";
import React from "react";
import { type RequestState } from "../../../../reducers/domain";
import { languageString } from "../../../../utilities/text";
import { seconds } from "../../../../utilities/time";
import Button, { type ButtonProps } from "../../buttons/standard/Button";
import Card from "../../card/basic/Card";
import InfoBox from "../../infoBox/InfoBox";
import Wizard from "../../modalWizard/ModalWizard";
import Toast from "../../toast/Toast";
import { FormContext, type FormContextState } from "./FormContext";
import "./form.css";

export interface FormProps<T> extends FormikConfig<T> {
    children?: React.ReactNode;
    request: RequestState;
    buttonLabel: React.ReactNode;
    buttonType?: ButtonProps["buttonType"];
    successLabel?: React.ReactNode;
    successAutoDismiss?: number;
    className?: string;
    display?: "Modal" | "Card";
    isOpen?: boolean;
    title?: React.ReactNode;
    autoClose?: boolean;
    disabled?: boolean;
    onClose?: () => void;
    id?: string;
    hasPermission?: boolean;
    onSuccess?: () => void;
    resetOnSuccess?: boolean;
    loading?: RequestState; // This currently is only used for Card display
}

interface FormState {
    resetFunc: () => void;
    isSubmitting: boolean;
    noPermission: boolean;
}

export default class Form<T = unknown> extends React.PureComponent<FormProps<T>, FormState> {
    constructor(props: FormProps<T>) {
        super(props);
        this.state = {
            isSubmitting: false,
            noPermission: false,
            resetFunc: noop,
        };
    }

    static defaultProps = {
        successAutoDismiss: 10,
    };

    componentDidUpdate(prevProps: FormProps<T>) {
        if (this.props.request?.success && !prevProps.request?.success) {
            if ((this.props.display === "Modal" && this.props.resetOnSuccess !== false) || this.props.resetOnSuccess) {
                this.state.resetFunc?.();
            }
            if (this.props.autoClose) {
                this.props.onClose();
            }
        }
        if (!this.props.request?.isRequesting && prevProps.request?.isRequesting && this.state.isSubmitting) {
            setTimeout(() => {
                if (this.props.request?.success) {
                    this.setState({
                        isSubmitting: false,
                    });
                    this.props.onSuccess?.();
                }
            }, seconds(this.props.successAutoDismiss + 1));
        }
    }

    onSubmit(values: T, helpers: FormikHelpers<T>) {
        if (this.props.hasPermission === false) {
            this.setState({
                noPermission: true,
            });
            return console.error("Did not submit form, user does not have permission");
        }
        this.setState({
            resetFunc: helpers.resetForm,
            isSubmitting: true,
        });
        void this.props.onSubmit?.(values, helpers);
    }

    render() {
        const className = `form ${this.props.className || ""}`;
        let button = (
            <Button
                type="submit"
                isPending={this.props.request.isRequesting && this.state.isSubmitting}
                buttonType={this.props.buttonType}
                disabled={this.props.disabled}
                id={this.props.id ? `form-submit-${this.props.id}` : null}
            >
                {this.props.buttonLabel}
            </Button>
        );
        if (this.props.hasPermission === false) {
            button = <InfoBox>{languageString("ui.permission.formDisallowed")}</InfoBox>;
        }
        const error = this.props.request.errorMessage && this.state.isSubmitting && (
            <Toast type="error" autoDismiss={0}>
                {this.props.request.errorMessage}
            </Toast>
        );
        const success = this.props.request.success && this.state.isSubmitting && (
            <Toast autoDismiss={seconds(this.props.successAutoDismiss)} type="success">
                {this.props.successLabel ?? languageString("ui.alt.formSuccess")}
            </Toast>
        );
        const warning = this.state.noPermission ? (
            <Toast type="error">{languageString("ui.permission.disallowed")}</Toast>
        ) : null;

        const contextValue: FormContextState = {
            hasPermission: this.props.hasPermission !== false,
            isSubmitting: this.state.isSubmitting,
            success: this.props.request.success,
            error: this.props.request.errorMessage ?? null,
        };

        if (this.props.display === "Modal") {
            return (
                <FormContext.Provider value={contextValue}>
                    <Wizard
                        title={this.props.title}
                        step={0}
                        isOpen={this.props.isOpen}
                        onClose={this.props.onClose}
                        id={this.props.id}
                    >
                        {[
                            {
                                ...omit(this.props, ["className", "display", "isOpen", "title", "onClose"]),
                                onSubmit: this.onSubmit.bind(this),
                                body: (
                                    <div className={className}>
                                        {this.props.children}
                                        {error}
                                        {success}
                                        {warning}
                                    </div>
                                ),
                                nextButton: button,
                            },
                        ]}
                    </Wizard>
                </FormContext.Provider>
            );
        }
        const form = (
            <FormContext.Provider value={contextValue}>
                <Formik {...this.props} onSubmit={this.onSubmit.bind(this)}>
                    {({ handleSubmit }) => (
                        <form className={className} onSubmit={handleSubmit} id={this.props.id}>
                            {this.props.children}
                            <div className="u-textRight">{button}</div>
                            {error}
                            {success}
                            {warning}
                        </form>
                    )}
                </Formik>
            </FormContext.Provider>
        );
        if (this.props.display === "Card") {
            return (
                <Card id={this.props.id} request={this.props.loading}>
                    {{
                        head: () => <h2>{this.props.title}</h2>,
                        body: () => form,
                    }}
                </Card>
            );
        }
        return form;
    }
}
