import { useCampaignDataLoader } from "@/components/assemblies/campaignDataLoader/CampaignDataLoader";
import type { RubyCampaignId } from "@/services/backend/RubyData";
import { isChannelWithKeywords } from "@/utilities/types";
import CostPerDownloadDial from "./CostPerDownloadDial";
import CostPerEventDial from "./CostPerEventDial";
import ReturnOnSpendDial from "./ReturnOnSpendDial";

interface TargetingDialProps {
    campaignId?: RubyCampaignId;
    size?: number;
}

export default function TargetingDial({ campaignId, size }: TargetingDialProps) {
    const loader = useCampaignDataLoader(campaignId);
    const channel = loader.useChannel();
    const campaign = loader.useCampaign();

    if (isChannelWithKeywords(campaign.data?.channelType, channel.data)) {
        if (channel.data?.targetRoas) {
            return <ReturnOnSpendDial size={size} campaignId={campaignId} />;
        }

        if (channel.data?.targetEvent) {
            return <CostPerEventDial size={size} campaignId={campaignId} />;
        }
    }

    return <CostPerDownloadDial size={size} campaignId={campaignId} />;
}
