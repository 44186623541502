import clamp from "lodash/clamp";
import type React from "react";
import Color from "../../../../utilities/Color";
import { formatPercent, safeDivide } from "../../../../utilities/number";
import StatRadial, { type StatRadialProps } from "../radial/StatRadial";
import "../radial/statRadial.css";

interface StatRadialTargetProps extends StatRadialProps {
    target: number;
    colorTolerance?: number; // 0 - 1
    barFillMultiplier?: number; // This makes the bar fill faster
    negativeIsGood?: boolean; // negative values don't change the color
    positiveIsGood?: boolean; // negative values don't change the color
    formatPercent?: boolean; // use the percentage value in the formatter
    targetOneHundred?: boolean;
}

export default class StatRadialTarget extends StatRadial<StatRadialTargetProps> {
    static defaultProps = {
        ...StatRadial.defaultProps,
        colorTolerance: 0.2,
        barFillMultiplier: 2,
    };

    getPercent(value: number = this.props.value, target: number = this.props.target): number {
        const change = value - target;
        return safeDivide(change, target);
    }

    getClampedPercent(...args: Parameters<typeof this.getPercent>) {
        return clamp(this.getPercent(...args), -1, 1);
    }

    formatPercent(val: number) {
        if (this.props.targetOneHundred) {
            return formatPercent(val + 1);
        }
        return (val > 0 ? "+" : "") + formatPercent(val);
    }

    getParentProps(): React.HTMLAttributes<HTMLDivElement> {
        const baseColor = Color.fromCSSVar("--color-success");
        const activeColor = Color.fromCSSVar("--color-danger");
        const percent = this.props.negativeIsGood
            ? clamp(this.getClampedPercent(), 0, 9999)
            : this.props.positiveIsGood
            ? Math.abs(clamp(this.getClampedPercent(), -9999, 0))
            : Math.abs(this.getClampedPercent());
        const colorAmount = super.getPercent(percent, 0, this.props.colorTolerance);
        const color = baseColor.blend(activeColor, clamp(colorAmount, 0, 1));
        return {
            style: {
                "--barColor": color.toString(),
            },
        };
    }

    formatValue(val: number) {
        if (this.props.formatPercent) {
            val = this.getPercent();
        }
        return this.props.formatter(val);
    }

    renderBar() {
        const bar = this.getBarValues();

        const fillStart = (bar.end - bar.start) * 0.5 + bar.start;
        const fillStartX = Math.cos(fillStart) * this.radius + this.radius;
        const fillStartY = Math.sin(fillStart) * this.radius + this.radius;

        const filledRadians =
            (bar.end - fillStart) * clamp(this.getClampedPercent() * this.props.barFillMultiplier, -1, 1);

        const fillEnd = filledRadians + fillStart;
        const fillEndX = Math.cos(fillEnd) * this.radius + this.radius;
        const fillEndY = Math.sin(fillEnd) * this.radius + this.radius;

        return (
            <svg {...this.getSVGProps()}>
                {this.drawBgBar(bar)}
                <path
                    d={`
                        M ${fillStartX} ${fillStartY}
                        A 
                            ${this.radius} ${this.radius}
                            0
                            ${fillEnd - fillStart < Math.PI ? "0" : "1"}
                            ${this.getPercent() > 0 ? "1" : "0"}
                            ${fillEndX} ${fillEndY}
                    `}
                    fill="none"
                    stroke="var(--barColor, white)"
                    strokeWidth={10}
                    strokeLinecap="round"
                />
                <circle cx={fillStartX} cy={fillStartY} r={3} fill="var(--color-fg)" />
            </svg>
        );
    }
}
