import { languageString } from "../../../utilities/text";
import { getUrl } from "../../../utilities/url";
import Login from "../../assemblies/login/login/Login";
import ButtonLink from "../../blocks/buttons/link/ButtonLink";
import EmbedYouTube from "../../blocks/embedYouTube/EmbedYouTube";
import Logo from "../../blocks/logo/Logo";
import Scroller from "../../blocks/scroller/Scroller";
import { ReactComponent as AppleLogo } from "./appleLogo.svg";
import "./landingPage.css";
import { ReactComponent as RedboxLogo } from "./redboxLogo.svg";

export default function LandingPageComponent() {
    return (
        <Scroller padding={0} className="landingPage~scroller">
            <div className="landingPage">
                <div className="landingPage-welcome u-textCenter">
                    <div className="landingPage-textWrap">
                        <RedboxLogo width={160} className="u-mb32" />
                        <h2 className="u-mb32 landingPage-title">{languageString("landingPage.title")}</h2>
                        <ButtonLink buttonType="mono" className="u-m4" to={getUrl.tierInformation()}>
                            {languageString("landingPage.buttons.viewPricing")}
                        </ButtonLink>
                        <ButtonLink buttonType="cta" className="u-m4" to={getUrl.contact()}>
                            {languageString("landingPage.buttons.contactForm")}
                        </ButtonLink>
                        <div>
                            <AppleLogo width={100} className="u-mt16" />
                        </div>
                    </div>
                </div>
                <div className="landingPage-login">
                    <div>
                        <div className="u-mb32 u-textCenter">
                            <Logo portrait />
                        </div>
                        <Login />
                    </div>
                </div>
                <div className="landingPage-quote u-textCenter">
                    <div>
                        <div className="landingPage-video">
                            <EmbedYouTube videoId="PCRhEo5uQ6g" title="Astra Promo" />
                        </div>
                        <div className="landingPage-textWrap">
                            <h2 className="u-mb16 landingPage-title">
                                {languageString("landingPage.subTitles.lineOne")}
                            </h2>
                            <p className="u-mb16">{languageString("landingPage.subTitles.lineTwo")}</p>
                            <small className="u-fade">{languageString("landingPage.subTitles.lineThree")}</small>
                        </div>
                    </div>
                </div>
            </div>
        </Scroller>
    );
}
