import { createContext } from "react";

export interface FormContextState {
    isSubmitting: boolean;
    hasPermission: boolean;
    success: boolean;
    error?: string;
}

export const FormContext = createContext<FormContextState>({
    isSubmitting: false,
    hasPermission: true,
    success: false,
    error: null,
});
