import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { setCookiePrefsAction } from "../../../actions/userActions";
import { type State } from "../../../reducers/domain";
import CookiePopoverComponent, {
    type CookiePopoverComponentProps,
    type CookiePopoverProps,
} from "./CookiePopoverComponent";

const mapStateToProps = (state: State, props: CookiePopoverProps): Partial<CookiePopoverComponentProps> => ({
    ...props,
    hasViewedCookies: state.user.hasViewedCookies,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<CookiePopoverComponentProps> => ({
    setCookieOptOut: (optOut: boolean) => dispatch(setCookiePrefsAction(optOut)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CookiePopoverComponent);
