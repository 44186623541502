import { ActionName, type ActionType } from "../actions/ActionType";
import { type BulkState, type State } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const initialBulkState: BulkState = {
    bulkEditRequest: initialRequestState(),
    selectedCampaigns: [],
    wizardStep: 0,
};

export default function bulkReducer(state: BulkState = initialBulkState, action: ActionType, _globalState: State) {
    switch (action.type) {
        case ActionName.TOGGLE_MODAL: {
            if (action.payload.state) {
                state.selectedCampaigns = [];
                state.wizardStep = 0;
            }
            break;
        }

        case ActionName.SET_BULK_CAMPAIGNS: {
            state.wizardStep = action.payload.step;
            state.selectedCampaigns = action.payload.campaignIds;
            break;
        }

        case ActionName.MAKE_BULK_EDIT: {
            state.bulkEditRequest = requestActionReducer(action);
            break;
        }
    }
}
