import clamp from "lodash/clamp";
import round from "lodash/round";
import { blendValues } from "./number";
import { cssVar } from "./style";

interface HSL {
    h: number;
    s: number;
    l: number;
    a?: number;
}
interface RGB {
    r: number;
    g: number;
    b: number;
    a?: number;
}
type Hex = string;

function isHSL(hsl: object | string): hsl is HSL {
    return typeof hsl["h"] === "number" && typeof hsl["s"] === "number" && typeof hsl["l"] === "number";
}

export default class Color {
    private color: HSL;

    constructor(input: string | Color | HSL) {
        if (input instanceof Color) {
            this.color = {
                h: input.hue,
                s: input.saturation,
                l: input.lightness,
                a: input.alpha,
            };
            return;
        }

        if (isHSL(input)) {
            this.color = { ...input };
            return;
        }

        input = input.trim();

        if (input.startsWith("var(")) {
            input = cssVar(input.replace(/^var\(/, "").replace(/\)$/, ""));
        }

        if (input.startsWith("hsl(")) {
            const [h, s, l] = this.parseNumberTriplet(input, "hsl");
            this.color = { h, s, l };
        } else if (input.startsWith("hsla(")) {
            const [h, s, l, a] = this.parseNumberQuad(input, "hsla");
            this.color = { h, s, l, a };
        } else if (input.startsWith("rgb(")) {
            const [r, g, b] = this.parseNumberTriplet(input, "rgb");
            this.color = Color.RGBToHSL({ r, g, b });
        } else if (input.startsWith("rgba(")) {
            const [r, g, b, a] = this.parseNumberQuad(input, "rgba");
            this.color = Color.RGBToHSL({ r, g, b, a });
        } else if (input.startsWith("#")) {
            this.color = Color.HexToHSL(input);
        } else {
            throw new Error(`Failed to parse value as a color: '${input}'`);
        }
    }

    static fromCSSVar(varName: string): Color {
        const value = cssVar(varName);
        return new Color(value);
    }

    toString(): string {
        return this.toHSLString();
    }

    toHSLString(): string {
        const { h, s, l, a } = this.color;
        if (a && a < 1) {
            return `hsla(${h}, ${s}%, ${l}%, ${a})`;
        }
        return `hsl(${h}, ${s}%, ${l}%)`;
    }

    toRGBString(): string {
        const { r, g, b, a } = Color.HSLToRGB(this.color);
        if (a && a < 1) {
            return `rgba(${r}, ${g}, ${b}, ${a})`;
        }
        return `rgb(${r}, ${g}, ${b})`;
    }

    toHexString(): string {
        return Color.HSLToHex(this.color);
    }

    private parseNumberSet(str: string, prefix: string): number[] {
        const colorParts = str
            .replace(`${prefix}(`, "")
            .replace(")", "")
            .replaceAll("%", "")
            .split(",")
            .map((s) => parseFloat(s.trim()));
        return colorParts;
    }

    private parseNumberTriplet(str: string, prefix: string): [number, number, number] {
        const colorParts = this.parseNumberSet(str, prefix);
        if (colorParts.length !== 3) {
            throw new Error(`Incorrect number of values to parse a valid color: '${str}', ${colorParts.join()}`);
        }
        return colorParts as [number, number, number];
    }
    private parseNumberQuad(str: string, prefix: string): [number, number, number, number] {
        const colorParts = this.parseNumberSet(str, prefix);
        if (colorParts.length !== 4) {
            throw new Error(`Incorrect number of values to parse a valid color: '${str}', ${colorParts.join()}`);
        }
        return colorParts as [number, number, number, number];
    }

    static HexToRGB(hex: Hex): RGB {
        let colorParts = hex.replace("#", "").split("");
        if (colorParts.length === 8) {
            colorParts = [
                colorParts.slice(0, 2).join(""),
                colorParts.slice(2, 4).join(""),
                colorParts.slice(4, 6).join(""),
                colorParts.slice(6, 8).join(""),
            ];
        } else if (colorParts.length === 6) {
            colorParts = [
                colorParts.slice(0, 2).join(""),
                colorParts.slice(2, 4).join(""),
                colorParts.slice(4, 6).join(""),
            ];
        } else if (colorParts.length === 3 || colorParts.length === 4) {
            colorParts = colorParts.map((part) => part + part);
        } else {
            throw new Error(`Failed to read valid hex value, ${hex}`);
        }
        // eslint-disable-next-line prefer-const
        let [r, g, b, a] = colorParts.map((val) => parseInt(val, 16));
        if (a) {
            a /= 255;
        }
        return { r, g, b, a };
    }

    static HSLToRGB({ h, s, l, a }: HSL): RGB {
        let r: number;
        let g: number;
        let b: number;

        h = h % 360;
        s = clamp(s, 0, 100) / 100;
        l = clamp(l, 0, 100) / 100;

        const c = (1 - Math.abs(2 * l - 1)) * s;
        const hh = h / 60;
        const x = c * (1 - Math.abs((hh % 2) - 1));
        r = g = b = 0;

        switch (Math.floor(hh)) {
            case 0:
                r = c;
                g = x;
                break;
            case 1:
                r = x;
                g = c;
                break;
            case 2:
                g = c;
                b = x;
                break;
            case 3:
                g = x;
                b = c;
                break;
            case 4:
                r = x;
                b = c;
                break;
            case 5:
                r = c;
                g = x;
                break;
        }

        const m = l - c / 2;
        r += m;
        g += m;
        b += m;

        r = Math.round(r * 255);
        g = Math.round(g * 255);
        b = Math.round(b * 255);

        return { r, g, b, a };
    }

    static RGBToHSL({ r, g, b, a }: RGB): HSL {
        let h: number;
        let s: number;
        let l: number;

        r = clamp(r, 0, 255);
        g = clamp(g, 0, 255);
        b = clamp(b, 0, 255);

        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        const delta = max - min;

        if (delta === 0) h = 0;
        else if (max === r) h = ((g - b) / delta) % 6;
        else if (max === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;
        h *= 60;
        if (h < 0) h += 360;
        l = (max + min) / 2;
        if (delta === 0) s = 0;
        else s = delta / (1 - Math.abs(2 * l - 1));
        s *= 100;
        l *= 100;

        h = round(h, 6);
        s = round(s, 6);
        l = round(l, 6);

        return { h, s, l, a };
    }

    static RGBToHex(rgb: RGB): Hex {
        const r = Math.round(rgb.r).toString(16).padStart(2, "0");
        const g = Math.round(rgb.g).toString(16).padStart(2, "0");
        const b = Math.round(rgb.b).toString(16).padStart(2, "0");
        let a = "";
        if (rgb.a && rgb.a < 1) {
            a = (rgb.a * 255).toString(16).padStart(2, "0");
        }
        return "#" + r + g + b + a;
    }

    static HSLToHex(hsl: HSL): Hex {
        const rgb = Color.HSLToRGB(hsl);
        return Color.RGBToHex(rgb);
    }

    static HexToHSL(hex: Hex): HSL {
        const rgb = Color.HexToRGB(hex);
        return Color.RGBToHSL(rgb);
    }

    get hue() {
        return this.color.h;
    }
    get saturation() {
        return this.color.s;
    }
    get lightness() {
        return this.color.l;
    }
    get alpha() {
        return this.color.a ?? 1;
    }
    get red() {
        const { r } = Color.HSLToRGB(this.color);
        return r;
    }
    get green() {
        const { g } = Color.HSLToRGB(this.color);
        return g;
    }
    get blue() {
        const { b } = Color.HSLToRGB(this.color);
        return b;
    }

    blend(color2: Color, percentage?: number): Color {
        return Color.blend(this, color2, percentage);
    }

    static blend(color1: Color, color2: Color, percentage = 0.5): Color {
        const h = blendValues(color1.hue, color2.hue, percentage);
        const s = blendValues(color1.saturation, color2.saturation, percentage);
        const l = blendValues(color1.lightness, color2.lightness, percentage);
        const a = blendValues(color1.alpha, color2.alpha, percentage);
        return new Color({ h, s, l, a });
    }

    lighten(percentage = 0.5) {
        const white = new Color({ h: 0, s: 0, l: 100 });
        const l = blendValues(this.lightness, white.lightness, percentage);
        return new Color({ h: this.hue, s: this.saturation, l, a: this.alpha });
    }

    darken(percentage = 0.5) {
        const black = new Color({ h: 0, s: 0, l: 0 });
        const l = blendValues(this.lightness, black.lightness, percentage);
        return new Color({ h: this.hue, s: this.saturation, l, a: this.alpha });
    }
}
