import React from "react";
import ErrorScreen from "../errorScreen/ErrorScreen";

interface ErrorBoundaryProps {
    children: React.ReactNode;
}

interface ErrorBoundaryState {
    error?: Error;
}

export default class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromError(error: Error) {
        if (error.name.includes("ChunkLoadError") && !window.location.search.includes("cacheBust")) {
            // Chunk loading errors can result from long running page views trying to
            // load additional scripts that have been redeployed since the initial page load.
            // Attempt to resolve this issue via a cache busted reload.
            window.location.assign(`?cacheBust=${Date.now()}`);
        }
        return {
            error,
        };
    }

    clearError() {
        this.setState({
            error: null,
        });
    }

    render() {
        if (this.state.error) {
            return (
                <ErrorScreen
                    getTechnicalDetails={async () => {
                        return `Name: ${this.state.error.name}
Message: ${this.state.error.message}
Stack:
${await formatStack(this.state.error.stack)}`;
                    }}
                    onLeave={this.clearError.bind(this)}
                />
            );
        }
        return this.props.children;
    }
}

const mapCache: Map<string, string> = new Map();
async function getMap(mapUrl: string): Promise<string> {
    if (mapCache.has(mapUrl)) {
        return mapCache.get(mapUrl);
    }

    const map = await fetch(mapUrl, {
        method: "GET",
        cache: "default",
    });

    if (!map.ok) {
        throw new Error(`Failed to get map file: ${mapUrl}`);
    }

    const mapData = await map.text();
    mapCache.set(mapUrl, mapData);
    return mapData;
}

async function formatStack(stack?: string): Promise<string> {
    if (!stack) {
        return "";
    }

    try {
        const Parser = await import("stacktrace-parser");
        const stacktrace = Parser.parse(stack);

        // This library is good at what it does, but it's config and typing is real irritating.
        const { SourceMapConsumer } = await import("source-map");
        // @ts-expect-error - This is a real file, it's just binary and I need a link to it.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const { default: mappings } = await import("source-map/lib/mappings.wasm");
        // @ts-expect-error - This works, the type is just missing from the lib
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        SourceMapConsumer.initialize({
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            "lib/mappings.wasm": mappings,
        });

        const formattedTrace: string[] = [];

        for (const trace of stacktrace) {
            try {
                const map = await getMap(trace.file + ".map");
                const mappedTrace = await SourceMapConsumer.with(map, null, (consumer) => {
                    return consumer.originalPositionFor({
                        line: trace.lineNumber,
                        column: trace.column - 1, // columns are considered 0-based on source-map
                    });
                });

                formattedTrace.push(
                    `${mappedTrace.name ?? trace.methodName} at ${mappedTrace.source}:${mappedTrace.line}:${
                        mappedTrace.column
                    }`
                );
            } catch (err) {
                console.error(err);
                formattedTrace.push(`${trace.methodName} at ${trace.file}:${trace.lineNumber}: ${trace.column - 1}`);
            }
        }

        return formattedTrace.join("\n");
    } catch (err) {
        console.error("Failed to parse stacktrace", err);
    }

    return stack;
}
