import { CAMPAIGN_CARD_DIAL_SIZE } from "@/components/assemblies/campaignCard/constants";
import type { RubyCampaignId } from "@/services/backend/RubyData";
import { sumBy } from "lodash";
import moment from "moment";
import { currencyFormatter } from "../../../../../utilities/currency";
import { formatPercent, safeDivide } from "../../../../../utilities/number";
import { aggregateAsyncData } from "../../../../../utilities/requests";
import { languageString } from "../../../../../utilities/text";
import { isChannelWithKeywords } from "../../../../../utilities/types";
import { useCampaignDataLoader } from "../../../../assemblies/campaignDataLoader/CampaignDataLoader";
import RequestLoader from "../../../../blocks/requestLoader/RequestLoader";
import StatRadialTarget from "../../../../blocks/stats/radialTarget/StatRadialTarget";

interface ReturnOnSpendDialProps {
    campaignId?: RubyCampaignId;
    size?: number;
}

export default function ReturnOnSpendDial({ size = CAMPAIGN_CARD_DIAL_SIZE, campaignId }: ReturnOnSpendDialProps) {
    const loader = useCampaignDataLoader(campaignId);
    const channel = loader.useChannel();
    const campaign = loader.useCampaign();
    const currency = loader.useCurrency();

    let targetRoasTimeRange: number = null;
    if (isChannelWithKeywords(campaign.data?.channelType, channel.data)) {
        targetRoasTimeRange = channel.data?.targetRoasTimeRange;
    }

    const to = loader.useMetricsTo();
    const metricsFrom = loader.useMetricsFrom();
    const from = targetRoasTimeRange ? moment.utc(to).subtract(targetRoasTimeRange, "days").valueOf() : metricsFrom;

    const metrics = loader.useMetricsSummary([from, to]);
    const events = loader.useEventListSummary([from, to]);

    const formatCurrency = currencyFormatter(currency);
    const eventRevenue = sumBy(events.data ?? [], "revenue");
    const roas = safeDivide(eventRevenue, metrics?.data?.spend);
    const roasTarget = isChannelWithKeywords(campaign.data?.channelType, channel.data)
        ? channel.data?.targetRoasGoal
        : 0;
    const roasRange = isChannelWithKeywords(campaign.data?.channelType, channel.data)
        ? channel.data?.targetRoasTimeRange
        : 7;

    return (
        <div className="u-relative">
            <RequestLoader
                request={aggregateAsyncData(events, campaign, channel, metrics)}
                placeholder={
                    <StatRadialTarget
                        label={languageString("campaign.listing.ROASTarget.title")}
                        value={0}
                        target={0}
                        formatter={formatCurrency}
                        positiveIsGood
                        size={size}
                    />
                }
                fallback={
                    <div className="u-fade2">
                        <StatRadialTarget
                            label={languageString("campaign.listing.ROASTarget.title")}
                            value={0}
                            target={0}
                            formatter={formatCurrency}
                            positiveIsGood
                            size={size}
                        />
                    </div>
                }
            >
                <StatRadialTarget
                    label={languageString("campaign.listing.ROASTarget.title")}
                    hint={languageString("campaign.listing.ROASTarget.description", "", [roasRange])}
                    value={roas ?? 0}
                    target={roasTarget / 100}
                    formatter={formatPercent}
                    formatPercent
                    positiveIsGood
                    size={size}
                    subLabel={languageString("campaign.listing.ROASTarget.target", "", [
                        formatPercent(roasTarget / 100),
                        roasRange,
                    ])}
                />
            </RequestLoader>
        </div>
    );
}
