import intersection from "lodash/intersection";
import uniqBy from "lodash/uniqBy";
import { languageString } from "../utilities/text";
import { type AppSearchProviderImpl } from "./AppSearchProvider";
import {
    type RubyCountry,
    type RubyIOSApp,
    type RubyIOSAppOptions,
    type RubyIOSAppSearchResult,
    type RubyTeamId,
} from "./backend/RubyData";

export class AppSearchService {
    private provider: AppSearchProviderImpl;

    constructor(provider: AppSearchProviderImpl) {
        this.provider = provider;
    }

    private iTunesCache = new Map<RubyIOSApp["trackId"], RubyIOSAppSearchResult>();
    async appITunesLookup(appId: RubyIOSApp["trackId"], country: RubyCountry = "US"): Promise<RubyIOSAppSearchResult> {
        if (this.iTunesCache.has(appId)) {
            return this.iTunesCache.get(appId);
        }
        const search = await this.provider.lookupITunes({
            country: country.toLocaleLowerCase(),
            entity: "software",
            id: appId,
        });
        if (search.results.length < 1) {
            throw new Error(languageString("ui.error.appNotFound"));
        }
        const result = search.results?.[0] as RubyIOSAppSearchResult;
        this.iTunesCache.set(appId, result);
        return result;
    }

    async appSearch(query: string, country: RubyCountry = "US"): Promise<RubyIOSAppSearchResult[]> {
        const appSearch = await this.provider.searchITunes({
            country: country.toLocaleLowerCase(),
            entity: "software",
            term: query,
        });
        return appSearch.results as RubyIOSAppSearchResult[];
    }

    private async developerAppLookup(
        developerName: string,
        country: RubyCountry = "US"
    ): Promise<RubyIOSAppSearchResult[]> {
        const developerDetails = await this.provider.searchITunes({
            country: country.toLocaleLowerCase(),
            entity: "softwareDeveloper",
            attribute: "softwareDeveloper",
            term: developerName,
        });

        if (developerDetails?.resultCount < 1) {
            throw new Error(languageString("ui.error.developerLookup"));
        }
        const devId = developerDetails.results.find((result) => result.artistName === developerName)?.artistId;
        if (!devId || devId < 1) {
            throw new Error(languageString("ui.error.developerLookup"));
        }

        const appSearch = await this.provider.lookupITunes({
            country: country.toLocaleLowerCase(),
            entity: "software",
            id: devId,
        });

        return appSearch.results.filter((result) => result.wrapperType === "software") as RubyIOSAppSearchResult[];
    }

    private async getAppOptions(teamId: RubyTeamId, appRef: RubyIOSApp["trackId"]): Promise<RubyIOSAppOptions> {
        const apps = await this.provider.listAppOptions(teamId, appRef.toString());
        const app = apps.find((app) => app.appRef === appRef);
        if (!app) {
            throw new Error(languageString("ui.error.unownedApp"));
        }
        return app;
    }

    private appCache = new Map<RubyIOSApp["trackId"], RubyIOSApp>();
    async lookupApp(
        teamId: RubyTeamId,
        appRef: RubyIOSApp["trackId"],
        country: RubyCountry = "US"
    ): Promise<RubyIOSApp> {
        if (this.appCache.has(appRef)) {
            return this.appCache.get(appRef);
        }
        const app = await this.lookupAppCall(teamId, appRef, country);
        this.appCache.set(appRef, app);
        return app;
    }
    private async lookupAppCall(
        teamId: RubyTeamId,
        appRef: RubyIOSApp["trackId"],
        country: RubyCountry = "US"
    ): Promise<RubyIOSApp> {
        const [app, options] = await Promise.all([
            this.appITunesLookup(appRef, country),
            this.getAppOptions(teamId, appRef),
        ]);
        return {
            ...app,
            trackId: options.appRef ?? app.trackId,
            trackName: options.appName ?? app.trackName,
            sellerName: options.developerName ?? app.sellerName,
            countries: options.countries,
        };
    }

    private teamAppCache = new Map<RubyTeamId, RubyIOSApp[]>();
    async loadTeamApps(teamId: RubyTeamId): Promise<RubyIOSApp[]> {
        if (this.teamAppCache.has(teamId)) {
            return this.teamAppCache.get(teamId);
        }
        const apps = await this.loadTeamAppsCall(teamId);
        this.teamAppCache.set(teamId, apps);
        apps.forEach((app) => {
            this.appCache.set(app.trackId, app);
        });
        return apps;
    }
    private async loadTeamAppsCall(teamId: RubyTeamId): Promise<RubyIOSApp[]> {
        const appOptions = await this.provider.listAppOptions(teamId);

        const developerMap = appOptions.reduce((devMap, opts) => {
            devMap[opts.developerName] = [...(devMap[opts.developerName] ?? []), opts.countries];
            return devMap;
        }, {} as Record<string, RubyCountry[][]>);

        const devApps = await Promise.all(
            Object.entries(developerMap).map(async ([devName, countrySets]) => {
                const intersections: RubyCountry[][] = [];
                countryLoop: for (const countrySet of countrySets) {
                    if (intersections.length < 1) {
                        intersections.push(countrySet);
                        continue;
                    }
                    for (const [i, val] of intersections.entries()) {
                        const inter: RubyCountry[] = intersection(val, countrySet);
                        if (inter.length > 0) {
                            intersections[i] = inter;
                            continue countryLoop;
                        }
                    }
                    intersections.push(countrySet);
                }

                const apps = await Promise.all(
                    intersections.map((countries) => {
                        return this.developerAppLookup(devName, countries?.[0]);
                    })
                );

                return apps.flat();
            })
        );
        const appData = uniqBy(devApps.flat(), "trackId");

        const apps = appOptions
            .map((opts) => {
                const data = appData.find((app) => app.trackId === opts.appRef);
                if (data) {
                    return {
                        ...data,
                        trackId: opts.appRef ?? data.trackId,
                        trackName: opts.appName ?? data.trackName,
                        sellerName: opts.developerName ?? data.sellerName,
                        countries: opts.countries,
                    };
                }
                return null;
            })
            .filter((a) => !!a);

        return apps;
    }
}
