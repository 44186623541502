import React from "react";
import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { toggleModalAction } from "../../../actions/uiActions";
import { type RequestState, type State } from "../../../reducers/domain";
import { type ModalProps } from "../../blocks/modal/Modal";

interface ModalControllerProps {
    id: string;
    children: React.ReactElement<ModalProps>;
    onClosed?: () => void;
    closeOnSuccess?: RequestState;
    /** if provided, called after close */
    onCloseOnSuccess?: () => void;
}
interface ModalControllerComponentProps extends ModalControllerProps {
    isOpen: boolean;
    onClose: (id: string) => void;
}

const mapStateToProps = (state: State, props: ModalControllerProps): Partial<ModalControllerComponentProps> => ({
    ...props,
    isOpen: state.ui.modals[props.id],
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<ModalControllerComponentProps> => ({
    onClose: (id: string) => dispatch(toggleModalAction(id, false)),
});

class ModalControllerComponent extends React.PureComponent<ModalControllerComponentProps> {
    componentDidUpdate(prevProps: ModalControllerComponentProps) {
        if (this.props.closeOnSuccess?.success && !prevProps.closeOnSuccess?.success) {
            this.props.onClose(this.props.id);
            this.props.onClosed?.();
            this.props.onCloseOnSuccess?.();
        }
    }

    render() {
        return React.cloneElement<ModalProps>(this.props.children, {
            isOpen: this.props.isOpen,
            onClose: () => {
                this.props.onClose(this.props.id);
                this.props.onClosed?.();
            },
            id: `modal-${this.props.id}`,
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalControllerComponent);
