import { appSearchService, asaService, rubyService, stripeService } from "@/services";
import { push } from "@lagunovsky/redux-react-router";
import { put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    listTeamHistoryRecords,
    teamAppActions,
    teamInvitesActions,
    teamInvoiceActions,
    teamMembersActions,
    teamOrganisationActions,
    teamPaymentMethodsActions,
    teamsActions,
    tiersActions,
    type TeamActions,
} from "../actions/teamActions";
import { type UIActions } from "../actions/uiActions";
import {
    selectPaymentDetails,
    selectTeamHistoryRecords,
    selectTeamOrganisations,
    selectTeamOwnedApps,
    selectTeamPaymentMethods,
} from "../selectors/teamSelectors";
import { runDataRequestAction, runRequestAction, takeRequests } from "../utilities/saga";

function* loadUserTeamsSaga(action: ExtractRequestActionRequestType<TeamActions.ListTeamsAction>) {
    yield* runDataRequestAction(
        action,
        teamsActions.list,
        (state) => state.team.teams,
        async () => {
            return await rubyService.accounts.listTeams(null, { all: true }).then((res) => res.results);
        }
    );
}

function* createNewTeamSaga(action: ExtractRequestActionRequestType<TeamActions.CreateTeamAction>) {
    yield* runRequestAction(action, teamsActions.create, async (req) => {
        const team = await rubyService.accounts.createTeam(req.team);
        const customer = await rubyService.budgets.setTeamStripeCustomer({ id: team.id }, req.paymentDetails);
        return {
            team,
            paymentDetails: customer,
        };
    });
}

function* editTeamSaga(action: ExtractRequestActionRequestType<TeamActions.UpdateTeamAction>) {
    yield* runRequestAction(action, teamsActions.update, async (req) => {
        return await rubyService.accounts.updateTeam({ id: req.teamId }, req.update);
    });
}

function* deleteTeamSaga(action: ExtractRequestActionRequestType<TeamActions.DeleteTeamAction>) {
    yield* runRequestAction(action, teamsActions.delete, async (req) => {
        await rubyService.accounts.deleteTeam({ id: req });
    });
}

function* loadTiersSaga(action: ExtractRequestActionRequestType<TeamActions.ListTiersAction>) {
    yield* runDataRequestAction(
        action,
        tiersActions.list,
        (state) => state.team.tiers,
        () => rubyService.accounts.listTiers(null, { all: true }).then((res) => res.results)
    );
}

function* createNewTierSaga(action: ExtractRequestActionRequestType<TeamActions.CreateTierAction>) {
    yield* runRequestAction(action, tiersActions.create, (req) => rubyService.accounts.createTier(req));
}

function* editTierSaga(action: ExtractRequestActionRequestType<TeamActions.EditTierAction>) {
    yield* runRequestAction(action, tiersActions.edit, (req) => rubyService.accounts.updateTier({ id: req.id }, req));
}

function* deleteTierSaga(action: ExtractRequestActionRequestType<TeamActions.DeleteTierAction>) {
    yield* runRequestAction(action, tiersActions.delete, async (req) => {
        await rubyService.accounts.deleteTier({ id: req });
    });
}

function* loadTeamInvitesSaga(action: ExtractRequestActionRequestType<TeamActions.ListTeamInvitesAction>) {
    yield* runDataRequestAction(
        action,
        teamInvitesActions.list,
        (state) => state.team.invites[action.payload.request],
        (req) =>
            rubyService.accounts
                .listInvitations({ id: req }, null, {
                    all: true,
                })
                .then((res) => res.results)
    );
}

function* createNewInviteSaga(action: ExtractRequestActionRequestType<TeamActions.CreateTeamInviteAction>) {
    yield* runRequestAction(action, teamInvitesActions.create, (req) =>
        rubyService.accounts.createInvitation(
            { id: req.teamId },
            {
                role: req.role,
                userId: req.userId,
                email: req.email?.toLocaleLowerCase(),
            }
        )
    );
}

function* removeInviteSaga(action: ExtractRequestActionRequestType<TeamActions.DeleteTeamInviteAction>) {
    yield* runRequestAction(action, teamInvitesActions.delete, (req) =>
        rubyService.accounts
            .deleteInvitation({
                invitationId: req.inviteId,
                teamId: req.teamId,
            })
            .then()
    );
}

function* setTeamLoadTeamMembersSaga(action: UIActions.SetActiveTeamAction) {
    yield put(teamMembersActions.list.request(action.payload));
}

function* loadTeamMembersSaga(action: ExtractRequestActionRequestType<TeamActions.ListTeamMembersAction>) {
    yield* runDataRequestAction(
        action,
        teamMembersActions.list,
        (state) => state.team.members[action.payload.request],
        (req) =>
            rubyService.accounts
                .listMembers({ id: req }, null, {
                    all: true,
                })
                .then((res) => res.results)
    );
}

function* removeTeamMemberSaga(action: ExtractRequestActionRequestType<TeamActions.DeleteTeamMemberAction>) {
    yield* runRequestAction(action, teamMembersActions.delete, (req) =>
        rubyService.accounts.deleteMember({ ...req }).then()
    );
}

function* updateTeamMemberSaga(action: ExtractRequestActionRequestType<TeamActions.UpdateTeamMemberAction>) {
    yield* runRequestAction(action, teamMembersActions.update, (req) =>
        rubyService.accounts.updateMember(
            {
                teamId: req.teamId,
                userId: req.userId,
            },
            req.update
        )
    );
}

function* sendUserToASA(action: TeamActions.AuthWithASAAction) {
    yield asaService.sendAuthRequest(action.payload.teamId, action.payload.nextPage);
}

function* listTeamOrganisationsSaga(action: ExtractRequestActionRequestType<TeamActions.ListTeamOrganisationsAction>) {
    yield* runDataRequestAction(
        action,
        teamOrganisationActions.list,
        (state) => selectTeamOrganisations(state, action.payload.request.teamId),
        ({ teamId }) => {
            return rubyService.appleAuth.listTeamOrganisations({ teamId }).then((data) => data.results);
        }
    );
}

function* reportASACredsSaga(action: ExtractRequestActionRequestType<TeamActions.ReportASAAuthCredsAction>) {
    let nextPage: string = null;
    yield* runRequestAction(action, teamOrganisationActions.reportASA, async () => {
        const creds = asaService.parseCredentials();

        const organisations = await rubyService.appleAuth
            .createTeamAuth({ teamId: creds.teamId }, { authCode: creds.authToken, redirectUri: creds.redirectURL })
            .then((data) => data.organisations);

        nextPage = creds.nextPage;

        return {
            teamId: creds.teamId,
            organisations,
        };
    });
    if (nextPage) {
        yield put(push(nextPage));
    }
}

function* listTeamAppsSaga(action: ExtractRequestActionRequestType<TeamActions.ListTeamAppsAction>) {
    yield* runDataRequestAction(action, teamAppActions.list, selectTeamOwnedApps, (req) =>
        appSearchService.loadTeamApps(req)
    );
}

function* listTeamPaymentMethodsSaga(
    action: ExtractRequestActionRequestType<TeamActions.ListTeamPaymentMethodsAction>
) {
    yield* runDataRequestAction(action, teamPaymentMethodsActions.list, selectTeamPaymentMethods, (req) =>
        rubyService.budgets.listStripePaymentMethod({ teamId: req }, null, { all: true }).then((res) => res.results)
    );
}

function* addPaymentMethodSaga(action: ExtractRequestActionRequestType<TeamActions.AddPaymentMethodAction>) {
    yield* runRequestAction(action, teamPaymentMethodsActions.add, async (req) => {
        const intent = await stripeService.confirmCardSetup(req);
        if (intent.status === "succeeded") {
            const method = intent.payment_method;
            if (typeof method === "string") {
                const payments = await rubyService.budgets.listStripePaymentMethod({ teamId: req });
                return payments.results.find((payment) => payment.id === method);
            } else {
                return method;
            }
        } else if (intent.status === "processing") {
            // Do some polling maybe?
            throw new Error(`Payment pending`);
        } else {
            throw new Error(`Failed to get valid status from Stripe service, ${intent.status}`);
        }
    });
}

function* deletePaymentMethodSaga(action: ExtractRequestActionRequestType<TeamActions.DeletePaymentMethodAction>) {
    yield* runRequestAction(action, teamPaymentMethodsActions.delete, async (req) => {
        await rubyService.budgets.deleteStripePaymentMethod(
            { teamId: req.teamId },
            { paymentMethodRef: req.paymentMethodId }
        );
    });
}

function* setDefaultPaymentMethodSaga(
    action: ExtractRequestActionRequestType<TeamActions.SetDefaultPaymentMethodAction>
) {
    yield* runRequestAction(action, teamPaymentMethodsActions.setDefault, async (req) => {
        await rubyService.budgets.setDefaultStripePaymentMethod(
            { teamId: req.teamId },
            { paymentMethodRef: req.paymentMethodId }
        );
    });
}

function* loadTeamHistoryRecordsSaga(
    action: ExtractRequestActionRequestType<TeamActions.ListTeamHistoryRecordsAction>
) {
    yield* runDataRequestAction(
        action,
        listTeamHistoryRecords,
        (state, req) => selectTeamHistoryRecords(state, req),
        ({ teamId, from, to, subjectId, type }) =>
            rubyService.history
                .listTeamRecords(
                    { id: teamId },
                    {
                        from,
                        to,
                        subjectId,
                        type,
                    },
                    { all: true }
                )
                .then((data) => data.results)
    );
}

function* listOrdersSaga(action: ExtractRequestActionRequestType<TeamActions.ListOrdersAction>) {
    yield* runDataRequestAction(
        action,
        teamInvoiceActions.listOrders,
        (state, req) => {
            return state.team.orders[req.teamId];
        },
        (req) => {
            return rubyService.budgets.listOrders({ id: req.teamId }, null, { all: true }).then((res) => res.results);
        }
    );
}

function* getTeamPaymentDetailsSaga(action: ExtractRequestActionRequestType<TeamActions.GetTeamPaymentDetailsAction>) {
    yield* runDataRequestAction(action, teamsActions.getPaymentDetails, selectPaymentDetails, async (req) => {
        return await rubyService.budgets.getTeamStripeCustomer({ id: req });
    });
}

function* updateTeamPaymentDetailsSaga(
    action: ExtractRequestActionRequestType<TeamActions.UpdateTeamPaymentDetailsAction>
) {
    yield* runRequestAction(action, teamsActions.updatePaymentDetails, async (req) => {
        return await rubyService.budgets.setTeamStripeCustomer({ id: req.teamId }, req.paymentDetails);
    });
}

export default function* accountSaga() {
    yield takeEvery(ActionName.LOGIN_SUCCESS, loadUserTeamsSaga);
    yield* takeRequests(ActionName.LIST_TEAMS, loadUserTeamsSaga);
    yield takeEvery(ActionName.SET_ACTIVE_TEAM, setTeamLoadTeamMembersSaga);

    yield* takeRequests(ActionName.CREATE_TEAM, createNewTeamSaga);
    yield* takeRequests(ActionName.UPDATE_TEAM, editTeamSaga);
    yield* takeRequests(ActionName.DELETE_TEAM, deleteTeamSaga);

    yield* takeRequests(ActionName.GET_TEAM_PAYMENT_DETAILS, getTeamPaymentDetailsSaga);
    yield* takeRequests(ActionName.UPDATE_TEAM_PAYMENT_DETAILS, updateTeamPaymentDetailsSaga);

    yield* takeRequests(ActionName.LIST_TEAM_HISTORY_RECORDS, loadTeamHistoryRecordsSaga);

    yield* takeRequests(ActionName.LIST_TIERS, loadTiersSaga);
    yield* takeRequests(ActionName.CREATE_TIER, createNewTierSaga);
    yield* takeRequests(ActionName.EDIT_TIER, editTierSaga);
    yield* takeRequests(ActionName.DELETE_TIER, deleteTierSaga);

    yield* takeRequests(ActionName.LIST_TEAM_INVITES, loadTeamInvitesSaga);
    yield* takeRequests(ActionName.CREATE_TEAM_INVITE, createNewInviteSaga);
    yield* takeRequests(ActionName.DELETE_TEAM_INVITE, removeInviteSaga);

    yield* takeRequests(ActionName.LIST_TEAM_MEMBERS, loadTeamMembersSaga);
    yield* takeRequests(ActionName.DELETE_TEAM_MEMBER, removeTeamMemberSaga);
    yield* takeRequests(ActionName.UPDATE_TEAM_MEMBER, updateTeamMemberSaga);

    yield* takeRequests(ActionName.LIST_TEAM_APPS, listTeamAppsSaga);
    yield* takeRequests(ActionName.LIST_TEAM_PAYMENT_METHODS, listTeamPaymentMethodsSaga);
    yield* takeRequests(ActionName.ADD_PAYMENT_METHOD, addPaymentMethodSaga);
    yield* takeRequests(ActionName.DELETE_PAYMENT_METHOD, deletePaymentMethodSaga);
    yield* takeRequests(ActionName.SET_DEFAULT_PAYMENT_METHOD, setDefaultPaymentMethodSaga);

    yield takeEvery(ActionName.AUTH_WITH_ASA, sendUserToASA);
    yield* takeRequests(ActionName.LIST_TEAM_ORGANISATIONS, listTeamOrganisationsSaga);
    yield* takeRequests(ActionName.REPORT_ASA_AUTH_CREDS, reportASACredsSaga);

    yield* takeRequests(ActionName.LIST_ORDERS, listOrdersSaga);
}
