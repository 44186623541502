import moment from "moment";
import { createRoot } from "react-dom/client";
import "requestidlecallback-polyfill";
import ResizeObserver from "resize-observer-polyfill";
import AppContainer from "./components/AppContainer";
import AppProvider from "./components/AppProvider";
import store, { history } from "./store";
import "./style/index.css";
import { getApplicationRoot } from "./utilities/dom";
import { isSnapshot } from "./utilities/snapshot";
import { DEMO_MODE } from "./utilities/vars";

let app: JSX.Element;
let refreshing = false;

// Polyfill
if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver;
}

// Configure moment to match up with the same week start as Apple
moment.updateLocale("en", {
    week: {
        dow: 1, // First day of week is Monday
        doy: 7, // First week of year must contain 1 January (7 + 1 - 1)
    },
});

// Main
if (!isSnapshot) {
    if (DEMO_MODE) {
        void installWorkerAndRestart().then(initReactApp);
    } else {
        // Turned this off  as prod is having problems
        // void installWorker();
        void unregisterWorker();
        initReactApp();
    }
} else {
    initReactApp();
}

function initReactApp() {
    if (!app) {
        app = (
            <AppProvider store={store} history={history}>
                <AppContainer />
            </AppProvider>
        );
        const root = createRoot(getApplicationRoot());
        root.render(app);
    }
}

// Service worker init

async function installWorkerAndRestart() {
    if (!("serviceWorker" in navigator)) {
        return;
    }
    navigator.serviceWorker.addEventListener("controllerchange", refresh);
    await installWorker();
}

async function installWorker() {
    if (!("serviceWorker" in navigator)) {
        return;
    }

    try {
        const registration = await navigator.serviceWorker.register("/worker.sw.js");

        if (registration.installing) {
            await new Promise((resolve) => {
                registration.installing.addEventListener("statechange", resolve);
            });
        }
    } catch (err) {
        console.error(err);
    }
}

function refresh() {
    if (refreshing) {
        return;
    }
    refreshing = true;
    location.reload();
}

async function unregisterWorker() {
    if ("serviceWorker" in navigator) {
        const registration = await navigator.serviceWorker.ready;
        await registration.unregister();
    }
}
