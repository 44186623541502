import isString from "lodash/isString";
import React from "react";
import { languageString } from "../../../utilities/text";
import HiddenSection from "../hiddenSection/HiddenSection";
import "./expandableArea.css";

interface ExpandableAreaProps {
    prefix?: React.ReactNode;
    title?: React.ReactNode;
    children?: React.ReactNode;
    startOpen?: boolean;
    className?: string;
    styleBlock?: string;
}

interface ExpandableAreaState {
    isOpen: boolean;
}

export default class ExpandableArea extends React.PureComponent<ExpandableAreaProps, ExpandableAreaState> {
    constructor(props: ExpandableAreaProps) {
        super(props);
        this.state = {
            isOpen: props.startOpen,
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        });
    }

    render() {
        const styleBlock = this.props.styleBlock ?? "expandableArea";

        return (
            <div className={`${styleBlock} ${this.state.isOpen ? "is-open" : ""} ${this.props.className || ""}`}>
                <button
                    className={`${styleBlock}-title`}
                    onClick={this.toggle.bind(this)}
                    aria-label={
                        isString(this.props.title)
                            ? languageString("ui.alt.toggleAreaTitle", "Toggle visibility of {1}", [this.props.title])
                            : languageString("ui.alt.toggleArea", "Toggle visibility")
                    }
                    type="button"
                >
                    {this.props.prefix}
                    {this.props.title || "\u00a0"}
                </button>
                <HiddenSection isHidden={!this.state.isOpen}>
                    <div className={`${styleBlock}-body`} aria-hidden={!this.state.isOpen}>
                        {this.props.children}
                    </div>
                </HiddenSection>
            </div>
        );
    }
}
