import { roundCorners } from "../../../../utilities/paths";
import { cssVar } from "../../../../utilities/style";
import { Overlay } from "../Overlay";
import OverlayController from "../OverlayController";
import { type OverlayProps } from "../domain";
import "./tooltip.css";

export default function Tooltip(props: OverlayProps) {
    return (
        <OverlayController>
            {(isOpen, onToggle) => <ControlledTooltip {...props} isOpen={isOpen} onToggle={onToggle} />}
        </OverlayController>
    );
}

export class ControlledTooltip extends Overlay {
    static defaultProps = {
        ...Overlay.defaultProps,
        baseClass: "tooltip",
    };

    drawBackground() {
        if (this.props.alignment && !this.props.alignment.isCenter) {
            console.warn(`Tooltip does not correctly support ${JSON.stringify(this.props.alignment)} alignment!`);
        }

        const s = this.state;
        const P_SIZE = 12;
        const P_WIDTH = 12;

        let width = s.width;
        let height = s.height;

        let d = `
            M 0 0
            H ${s.width}
            V ${s.height}
            H 0
            Z
        `;

        if (s.direction.isUp) {
            const c = s.width / 2 + s.centerOffset;
            height = s.height + P_SIZE;
            d = `
                M 0 0
                H ${s.width}
                V ${s.height}
                H ${c + P_WIDTH}
                L ${c} ${height}
                L ${c - P_WIDTH} ${s.height}
                H 0
                Z
            `;
        }

        if (s.direction.isDown) {
            const c = s.width / 2 + s.centerOffset;
            height = s.height + P_SIZE;
            d = `
                M 0 ${P_SIZE}
                H ${c - P_WIDTH}
                L ${c} 0
                L ${c + P_WIDTH} ${P_SIZE}
                H ${s.width}
                V ${height}
                H 0
                Z
            `;
        }

        if (s.direction.isRight) {
            const c = s.height / 2 + s.centerOffset;
            width = s.width + P_SIZE;
            d = `
                M ${P_SIZE} 0
                H ${width}
                V ${s.height}
                H ${P_SIZE}
                V ${c + P_WIDTH}
                L 0 ${c}
                L ${P_SIZE} ${c - P_WIDTH}
                Z
            `;
        }

        if (s.direction.isLeft) {
            const c = s.height / 2 + s.centerOffset;
            width = s.width + P_SIZE;
            d = `
                M 0 0
                H ${s.width}
                V ${c - P_WIDTH}
                L ${width} ${c}
                L ${s.width} ${c + P_WIDTH}
                V ${s.height}
                H 0
                Z
            `;
        }

        const radius = parseInt(cssVar("--interactableRadius"));

        d = roundCorners(d, radius);

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={`0 0 ${width} ${height}`}
                style={{
                    width: width,
                    height: height,
                }}
                aria-hidden
            >
                <defs>
                    <linearGradient id={`${this.backgroundId}`} x1={0} x2={1} y1={0} y2={0}>
                        <stop offset="0%" stopColor="white" stopOpacity={0.1} />
                        <stop offset="100%" stopColor="white" stopOpacity={0} />
                    </linearGradient>
                </defs>
                <path d={d} fill="var(--backgroundColor)" />
                <path d={d} stroke="var(--borderColor)" fill={`url(#${this.backgroundId})`} />
            </svg>
        );
    }
}
