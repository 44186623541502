import {
    type RubyCampaignId,
    type RubyChannelUpdateBody,
    type RubyKeywordMatchType,
} from "../services/backend/RubyData";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum BulkActionName {
    SET_BULK_CAMPAIGNS = "SET_BULK_CAMPAIGNS",
    MAKE_BULK_EDIT = "MAKE_BULK_EDIT",

    BULK_ADD_TARGETING_KEYWORDS = "BULK_ADD_TARGETING_KEYWORDS",
    BULK_REMOVE_TARGETING_KEYWORDS = "BULK_REMOVE_TARGETING_KEYWORDS",
    BULK_ADD_NEGATIVE_KEYWORDS = "BULK_ADD_NEGATIVE_KEYWORDS",
    BULK_REMOVE_NEGATIVE_KEYWORDS = "BULK_REMOVE_NEGATIVE_KEYWORDS",
    BULK_SET_COST_PER_DOWNLOAD = "BULK_SET_COST_PER_DOWNLOAD",
    BULK_ADD_BUDGET = "BULK_ADD_BUDGET",
    BULK_SET_DISCOVERY_WEIGHTING = "BULK_SET_DISCOVERY_WEIGHTING",
    BULK_PAUSE = "BULK_PAUSE",
    BULK_UNPAUSE = "BULK_UNPAUSE",
    BULK_SET_ORDER_NUMBER = "BULK_SET_ORDER_NUMBER",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace BulkActions {
    export type SetBulkCampaignsAction = Action<
        BulkActionName.SET_BULK_CAMPAIGNS,
        {
            campaignIds: RubyCampaignId[];
            step: number;
        }
    >;
    export type BulkEditRequestAction = RequestAction<BulkActionName.MAKE_BULK_EDIT, void, void>;
    export type BulkAddTargetingKeywordsAction = Action<
        BulkActionName.BULK_ADD_TARGETING_KEYWORDS,
        {
            keywords: string[];
            label: string;
            match: RubyKeywordMatchType;
        }
    >;
    export type BulkAddNegativeKeywordsAction = Action<
        BulkActionName.BULK_ADD_NEGATIVE_KEYWORDS,
        {
            keywords: string[];
            label: string;
        }
    >;
    export type BulkRemoveNegativeKeywordsAction = Action<
        BulkActionName.BULK_REMOVE_NEGATIVE_KEYWORDS,
        { keywords: string[] }
    >;
    export type BulkRemoveTargetingKeywordsAction = Action<
        BulkActionName.BULK_REMOVE_TARGETING_KEYWORDS,
        { keywords: string[] }
    >;
    export type BulkSetCostPerDownloadAction = Action<
        BulkActionName.BULK_SET_COST_PER_DOWNLOAD,
        Pick<RubyChannelUpdateBody, "cpdGoal" | "defaultBidAmount">
    >;
    export type BulkAddBudgetAction = Action<
        BulkActionName.BULK_ADD_BUDGET,
        {
            amount: number;
        }
    >;
    export type BulkSetDiscoveryWeightingAction = Action<
        BulkActionName.BULK_SET_DISCOVERY_WEIGHTING,
        {
            discoveryWeighting: number;
        }
    >;
    export type BulkPauseAction = Action<BulkActionName.BULK_PAUSE, void>;
    export type BulkUnpauseAction = Action<BulkActionName.BULK_UNPAUSE, void>;
    export type BulkSetOrderNumber = Action<
        BulkActionName.BULK_SET_ORDER_NUMBER,
        {
            orderNumber: string;
        }
    >;
}

export type BulkActionType =
    | BulkActions.SetBulkCampaignsAction
    | BulkActions.BulkEditRequestAction
    | BulkActions.BulkAddTargetingKeywordsAction
    | BulkActions.BulkRemoveTargetingKeywordsAction
    | BulkActions.BulkAddNegativeKeywordsAction
    | BulkActions.BulkRemoveNegativeKeywordsAction
    | BulkActions.BulkSetCostPerDownloadAction
    | BulkActions.BulkAddBudgetAction
    | BulkActions.BulkSetDiscoveryWeightingAction
    | BulkActions.BulkPauseAction
    | BulkActions.BulkUnpauseAction
    | BulkActions.BulkSetOrderNumber;

export function setBulkCampaignsAction(campaignIds: RubyCampaignId[], step: number) {
    return {
        type: BulkActionName.SET_BULK_CAMPAIGNS,
        payload: {
            campaignIds,
            step,
        },
    };
}

export const makeBulkEditAction = requestActions<BulkActions.BulkEditRequestAction>(BulkActionName.MAKE_BULK_EDIT);

export function bulkAddTargetingKeywordsAction(
    keywords: string[],
    label: string,
    match: RubyKeywordMatchType
): BulkActions.BulkAddTargetingKeywordsAction {
    return {
        type: BulkActionName.BULK_ADD_TARGETING_KEYWORDS,
        payload: {
            keywords,
            label,
            match,
        },
    };
}

export function bulkAddNegativeKeywordsAction(
    keywords: string[],
    label: string
): BulkActions.BulkAddNegativeKeywordsAction {
    return {
        type: BulkActionName.BULK_ADD_NEGATIVE_KEYWORDS,
        payload: {
            keywords,
            label,
        },
    };
}

export function bulkRemoveNegativeKeywordsAction(keywords: string[]): BulkActions.BulkRemoveNegativeKeywordsAction {
    return {
        type: BulkActionName.BULK_REMOVE_NEGATIVE_KEYWORDS,
        payload: {
            keywords,
        },
    };
}

export function bulkRemoveTargetingKeywordsAction(keywords: string[]): BulkActions.BulkRemoveTargetingKeywordsAction {
    return {
        type: BulkActionName.BULK_REMOVE_TARGETING_KEYWORDS,
        payload: {
            keywords,
        },
    };
}

export function bulkSetCostPerDownloadAction(
    payload: Pick<RubyChannelUpdateBody, "cpdGoal" | "defaultBidAmount">
): BulkActions.BulkSetCostPerDownloadAction {
    return {
        type: BulkActionName.BULK_SET_COST_PER_DOWNLOAD,
        payload,
    };
}

export function bulkAddBudgetAction(amount: number): BulkActions.BulkAddBudgetAction {
    return {
        type: BulkActionName.BULK_ADD_BUDGET,
        payload: {
            amount,
        },
    };
}

export function bulkSetDiscoveryWeightingAction(
    discoveryWeighting: number
): BulkActions.BulkSetDiscoveryWeightingAction {
    return {
        type: BulkActionName.BULK_SET_DISCOVERY_WEIGHTING,
        payload: {
            discoveryWeighting,
        },
    };
}

export function bulkPauseAction(): BulkActions.BulkPauseAction {
    return {
        type: BulkActionName.BULK_PAUSE,
        payload: null,
    };
}

export function bulkUnpauseAction(): BulkActions.BulkUnpauseAction {
    return {
        type: BulkActionName.BULK_UNPAUSE,
        payload: null,
    };
}

export function bulkSetOrderNumber(orderNumber: string): BulkActions.BulkSetOrderNumber {
    return {
        type: BulkActionName.BULK_SET_ORDER_NUMBER,
        payload: {
            orderNumber,
        },
    };
}
