import type { APIServiceImpl } from "@/types/APIService";
import { createAppIconDataUrl } from "@/utilities/appIcons";
import { randomWords } from "@/utilities/randomWords";
import type { RubyApiClient } from "@redbox-ruby/client-lib";
import capitalize from "lodash/capitalize";
import random from "lodash/random";
import uniqBy from "lodash/uniqBy";
import { getSupportedCountyCodes } from "../utilities/country";
import { isSnapshot } from "../utilities/snapshot";
import { languageString } from "../utilities/text";
import { delay, seconds } from "../utilities/time";
import {
    type RubyCountry,
    type RubyIOSAppOptions,
    type RubyIOSAppSearchResult,
    type RubyTeamId,
} from "./backend/RubyData";

export interface ITunesResponse<T> {
    resultCount: number;
    results: T[];
}

export interface ITunesDeveloperDetails {
    wrapperType: string;
    artistType: string;
    artistName: string;
    artistId: number;
    artistLinkUrl: string;
}

export interface ITunesLookupRequest {
    country: string;
    entity: string;
    id: number;
}

export interface ITunesSearchRequest {
    country: string;
    entity: string;
    attribute?: string;
    term: string;
}

type ITunesResponseItem = RubyIOSAppSearchResult | ITunesDeveloperDetails;

function responseIsApps(
    response: ITunesResponse<ITunesResponseItem>
): response is ITunesResponse<RubyIOSAppSearchResult> {
    return response.results.length > 0 && response.results.every((r) => r.wrapperType === "software");
}

const DOMAIN = "https://itunes.apple.com";
const CALLBACK = "callback";
const LIMIT = 200;

export abstract class AppSearchProviderImpl {
    abstract lookupITunes(payload: ITunesLookupRequest): Promise<ITunesResponse<ITunesResponseItem>>;
    abstract searchITunes(payload: ITunesSearchRequest): Promise<ITunesResponse<ITunesResponseItem>>;
    abstract listAppOptions(teamId: RubyTeamId, query?: string): Promise<RubyIOSAppOptions[]>;
}

export class AppSearchProvider extends AppSearchProviderImpl {
    private apiService: APIServiceImpl;
    private rubyService: RubyApiClient;

    constructor(apiService: APIServiceImpl, rubyService: RubyApiClient) {
        super();
        this.apiService = apiService;
        this.rubyService = rubyService;
    }

    async lookupITunes(payload: ITunesLookupRequest): Promise<ITunesResponse<ITunesResponseItem>> {
        try {
            const response = await this.requestWithRetry(
                () =>
                    this.apiService.jsonp<ITunesResponse<ITunesResponseItem>>({
                        domain: DOMAIN,
                        uri: "/lookup",
                        data: {
                            limit: LIMIT,
                            ...payload,
                        },
                        callbackParam: CALLBACK,
                    }),
                3
            );
            if (responseIsApps(response)) {
                void this.cacheAppData(payload.id, payload.country.toLocaleUpperCase() as RubyCountry, response);
            }
            return response;
        } catch (err) {
            console.error(err);
            const fallbackApp = await this.rubyService.appleProxy.lookupApplicationFromAppleiTunes(
                {
                    id: payload.id,
                },
                {
                    country: payload.country.toLocaleUpperCase() as RubyCountry,
                }
            );
            return {
                resultCount: 1,
                results: [fallbackApp],
            };
        }
    }

    searchITunes(payload: ITunesSearchRequest): Promise<ITunesResponse<ITunesResponseItem>> {
        return this.requestWithRetry(() =>
            this.apiService.jsonp<ITunesResponse<ITunesResponseItem>>({
                domain: DOMAIN,
                uri: "/search",
                data: {
                    limit: LIMIT,
                    ...payload,
                },
                callbackParam: CALLBACK,
            })
        );
    }

    async listAppOptions(teamId: RubyTeamId, query?: string): Promise<RubyIOSAppOptions[]> {
        const options = await this.rubyService.appleChannels.findApplicationOptions(
            { teamId },
            { take: 1000, skip: 0, query: query ?? null }
        );
        return uniqBy(options.results, "appRef");
    }

    private async requestWithRetry<T = unknown>(fn: () => Promise<T>, attempts?: number): Promise<T> {
        try {
            return this.retriedRequest(fn, 0, attempts);
        } catch (err) {
            console.error(err);
            throw new Error(languageString("ui.error.iTunesFailed"), { cause: err });
        }
    }

    private async retriedRequest<T>(fn: () => Promise<T>, attempt: number, attempts = 5): Promise<T> {
        let response: T;
        try {
            response = await fn();
        } catch (err) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            console.warn({ attempt, attempts, err });
            if (attempt < attempts) {
                await delay(200 * Math.pow(attempt + 1, 2));
                return this.retriedRequest(fn, attempt + 1, attempts);
            } else {
                throw err;
            }
        }
        return response;
    }

    private cacheAppData(appId: number, country: RubyCountry = "US", appData: ITunesResponse<RubyIOSAppSearchResult>) {
        return this.rubyService.appleProxy.createApplicationFromAppleiTunes(
            {
                id: appId,
            },
            {
                country,
                appData,
            }
        );
    }
}

const MOCK_DEV_ID = 1234;
const MOCK_DEV_NAME = "Astra demo developer";

export class MockedAppSearchProvider extends AppSearchProviderImpl {
    private mockedApps: RubyIOSAppSearchResult[] = [];
    private mockedDev: ITunesDeveloperDetails = null;

    constructor() {
        super();
        this.mockedDev = {
            wrapperType: "softwareDeveloper",
            artistType: "Software Artist",
            artistName: MOCK_DEV_NAME,
            artistLinkUrl: "",
            artistId: MOCK_DEV_ID,
        };
        // TODO Not sure why but generating fake app icons in react-snap crashes
        if (!isSnapshot) {
            this.mockedApps = new Array(10).fill("").map(() => this.generateApp());
        }
    }

    async lookupITunes(payload: ITunesLookupRequest): Promise<ITunesResponse<ITunesResponseItem>> {
        await delay(seconds(0.5));
        if (payload.id === MOCK_DEV_ID && payload.entity === "software") {
            return {
                resultCount: this.mockedApps.length,
                results: this.mockedApps,
            };
        }
        return {
            resultCount: 1,
            results: [this.mockedResponseItem(payload.entity, payload.id)],
        };
    }

    async searchITunes(payload: ITunesSearchRequest): Promise<ITunesResponse<ITunesResponseItem>> {
        await delay(seconds(0.5));
        if (payload.term === MOCK_DEV_NAME && payload.entity === "softwareDeveloper") {
            return {
                resultCount: 1,
                results: [this.mockedDev],
            };
        }
        return {
            resultCount: this.mockedApps.length,
            results: this.mockedApps,
        };
    }

    async listAppOptions(teamId: RubyTeamId, query?: string): Promise<RubyIOSAppOptions[]> {
        await delay(seconds(0.5));
        if (query) {
            const appId = parseFloat(query);
            const app = this.mockedApps.find((app) => app.trackId === appId);
            if (!app) {
                this.mockedApps.push(this.generateApp(appId));
            }
        }
        return [
            ...this.mockedApps.map((app) => ({
                appName: app.trackName,
                appRef: app.trackId,
                countries: getSupportedCountyCodes() as RubyCountry[],
                developerName: app.artistName,
            })),
        ];
    }

    private mockedResponseItem(entity: string, id?: number): ITunesResponseItem {
        if (entity === "software") {
            if (id) {
                const app = this.mockedApps.find((app) => app.trackId === id);
                if (app) {
                    return app;
                }
            }
            return this.generateApp(id);
        } else if (entity === "softwareDeveloper") {
            return this.mockedDev;
        } else {
            throw Error(`Invalid iTunes entity type: ${entity}`);
        }
    }

    private generateApp(id?: number): RubyIOSAppSearchResult {
        return {
            advisories: [],
            appletvScreenshotUrls: [],
            artistId: MOCK_DEV_ID,
            artistName: MOCK_DEV_NAME,
            artistViewUrl: "",
            artworkUrl60: "",
            artworkUrl100: "",
            artworkUrl512: createAppIconDataUrl(512, 512),
            averageUserRating: 5,
            averageUserRatingForCurrentVersion: 5,
            bundleId: "app.package",
            contentAdvisoryRating: "E",
            currency: "GBP",
            currentVersionReleaseDate: "2022-01-01",
            description: "A mocked application",
            features: [],
            fileSizeBytes: "",
            formattedPrice: "Free",
            genreIds: [],
            genres: [],
            ipadScreenshotUrls: [
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
                createAppIconDataUrl(2048, 1535),
            ],
            isGameCenterEnabled: false,
            isVppDeviceBasedLicensingEnabled: false,
            kind: "software",
            languageCodesISO2A: [],
            minimumOsVersion: "1",
            price: 0,
            primaryGenreId: 1,
            primaryGenreName: "",
            releaseDate: "2022-01-01",
            screenshotUrls: [
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
                createAppIconDataUrl(574, 1242),
            ],
            sellerName: MOCK_DEV_NAME,
            supportedDevices: [],
            trackCensoredName: "",
            trackContentRating: "E",
            trackId: id ?? random(10000000, 99999999),
            trackName: generateAppName(),
            trackViewUrl: "",
            userRatingCount: 1,
            userRatingCountForCurrentVersion: 1,
            version: "1.0.0",
            wrapperType: "software",
            releaseNotes: "",
            sellerUrl: "",
        };
    }
}

export function generateAppName() {
    return `${randomWords({
        min: 1,
        max: 2,
        formatter: capitalize,
    })} - Demo App`;
}
