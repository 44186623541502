import clsx from "clsx";
import React from "react";
import "./input.css";

type Inputs = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;

export interface InputProps<T = Inputs> extends React.InputHTMLAttributes<T> {
    isInvalid?: boolean;
    inputRef?: React.RefObject<HTMLInputElement>;
    readOnly?: boolean;
}

export default class Input extends React.PureComponent<InputProps> {
    render() {
        const { isInvalid, className, inputRef, readOnly, disabled, ...props } = this.props;
        return (
            <input
                className={clsx("input", `input_${this.props.type || "text"}`, className, {
                    "is-invalid": isInvalid,
                    "is-readOnly": readOnly,
                })}
                aria-invalid={isInvalid}
                ref={inputRef}
                disabled={disabled || readOnly}
                {...props}
            />
        );
    }
}
