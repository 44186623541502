import { useParams } from "react-router-dom";
import { useUserPermission } from "../../../../../utilities/permissions";
import { languageString } from "../../../../../utilities/text";
import { getUrl } from "../../../../../utilities/url";
import { useCampaignDataLoader } from "../../../../assemblies/campaignDataLoader/CampaignDataLoader";
import AppIcon from "../../../../blocks/appIcon/AppIcon";
import ButtonLink from "../../../../blocks/buttons/link/ButtonLink";
import RequestLoader from "../../../../blocks/requestLoader/RequestLoader";
import Responsive, { ResponsiveElement, responsiveValue } from "../../../../blocks/responsive/Responsive";
import BudgetPacingDial from "../dials/BudgetPacingDial";
import BudgetRemainingDial from "../dials/BudgetRemainingDial";
import TargetingDial from "../dials/TargetingDial";
import "./campaignAsidePanel.css";

export interface CampaignAsidePanelProps {
    isExpanded?: boolean;
}

export type CampaignAsidePanelComponentProps = CampaignAsidePanelProps;

export default function CampaignAsidePanel({ isExpanded }: CampaignAsidePanelComponentProps) {
    const params = useParams();
    const campaignId = parseInt(params.campaignId, 10);
    const teamId = parseInt(params.teamId, 10);

    const loader = useCampaignDataLoader(campaignId, teamId);
    const permission = useUserPermission();
    const radialSize = responsiveValue(120, { 1200: 140 });

    const campaign = loader.useCampaign();
    const app = loader.useApp();

    return (
        <div className={`campaignAsidePanel ${isExpanded ? "is-expanded" : "is-collapsed"}`}>
            <RequestLoader request={campaign}>
                <div className="campaignAsidePanel-name">
                    <AppIcon
                        className="campaignAsidePanel-icon"
                        request={app}
                        appName={app.data?.trackName}
                        iconUrl={app.data?.artworkUrl512}
                        size={isExpanded ? "large" : "medium"}
                    />
                    <h2 className="campaignAsidePanel-title">{campaign.data?.name}</h2>
                    <small className="campaignAsidePanel-subTitle">{campaign.data?.description}</small>
                </div>
                <div className="campaignAsidePanel-dials">
                    <Responsive>
                        <ResponsiveElement minWidth={1200}>
                            <TargetingDial size={radialSize} campaignId={campaignId} />
                            <BudgetPacingDial size={radialSize} campaignId={campaignId} />
                        </ResponsiveElement>
                    </Responsive>

                    <BudgetRemainingDial size={radialSize} campaignId={campaignId} />
                </div>
                <div className="campaignAsidePanel-cta">
                    <ButtonLink buttonType="cta" to={getUrl.campaignBudgetConfig(teamId, campaignId)}>
                        {languageString(
                            permission.canWrite ? "campaign.budget.addBudget" : "campaign.budget.viewBudget"
                        )}
                    </ButtonLink>
                    <ButtonLink to={getUrl.comparison(teamId)}>
                        {languageString("campaign.comparison.asideLink")}
                    </ButtonLink>
                </div>
            </RequestLoader>
        </div>
    );
}
