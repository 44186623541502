import { type TargetingKeyword } from "../selectors/keywordsSelectors";
import {
    type RubyCampaignId,
    type RubyCountry,
    type RubyKeyword,
    type RubyKeywordAssignment,
    type RubyKeywordMatchType,
    type RubyTargetingKeywordBidsInfo,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { type ImportedKeyword } from "../utilities/keywordImport";
import { requestActions, type RequestAction } from "./Action";

export interface AddKeywordPayload {
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
    keywords: string[];
}
export interface AddNegativeKeywordPayload extends AddKeywordPayload {
    tag?: string;
}
export interface AddTargetingKeywordPayload extends AddNegativeKeywordPayload {
    regions: RubyCountry[];
    match: RubyKeywordMatchType;
}

export interface EditKeywordPayload<T extends RubyKeyword = RubyKeyword> {
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
    keywords: T[];
}
export interface EditKeywordTagPayload<T extends RubyKeyword = RubyKeyword> extends EditKeywordPayload<T> {
    tag: string;
}
export interface EditKeywordRegionsPayload extends EditKeywordPayload<TargetingKeyword> {
    regions: RubyCountry[];
}
export interface EditKeywordBidsPayload extends EditKeywordPayload<TargetingKeyword> {
    bids: { [K in RubyCountry]?: number | null };
}
export interface EditKeywordMatchPayload<T extends RubyKeyword = RubyKeyword> extends EditKeywordPayload<T> {
    match: RubyKeywordMatchType;
}

export interface UpdateSeedKeywordPayload {
    enabled: boolean;
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
    removedKeywords?: RubyKeyword[];
    newKeywords?: string[];
}

export interface ImportKeywordPayload {
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
    keywords: ImportedKeyword[];
}

export enum KeywordsActionName {
    GET_TARGETING_KEYWORDS = "GET_TARGETING_KEYWORDS",
    GET_NEGATIVE_KEYWORDS = "GET_NEGATIVE_KEYWORDS",
    GET_SEED_KEYWORDS = "GET_SEED_KEYWORDS",
    GET_TARGETING_KEYWORD_BIDS_INFO = "GET_TARGETING_KEYWORD_BIDS_INFO",

    ADD_TARGETING_KEYWORDS = "ADD_TARGETING_KEYWORDS",
    ADD_NEGATIVE_KEYWORDS = "ADD_NEGATIVE_KEYWORDS",

    IMPORT_TARGETING_KEYWORDS = "IMPORT_TARGETING_KEYWORDS",
    IMPORT_NEGATIVE_KEYWORDS = "IMPORT_NEGATIVE_KEYWORDS",

    REMOVE_TARGETING_KEYWORDS = "REMOVE_TARGETING_KEYWORDS",
    REMOVE_NEGATIVE_KEYWORDS = "REMOVE_NEGATIVE_KEYWORDS",
    MAKE_NEGATIVE_TARGETING_KEYWORDS = "MAKE_NEGATIVE_TARGETING_KEYWORDS",

    UPDATE_SEED_KEYWORDS = "UPDATE_SEED_KEYWORDS",

    TAG_TARGETING_KEYWORDS = "TAG_TARGETING_KEYWORDS",
    TAG_NEGATIVE_KEYWORDS = "TAG_NEGATIVE_KEYWORDS",

    SET_TARGETING_REGIONS = "SET_TARGETING_REGIONS",
    SET_TARGETING_BIDS = "SET_TARGETING_BIDS",
    SET_TARGETING_MATCH = "SET_TARGETING_MATCH",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace KeywordsActions {
    export type GetTargetingKeywordsAction = RequestAction<
        KeywordsActionName.GET_TARGETING_KEYWORDS,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        RubyKeyword[]
    >;
    export type GetNegativeKeywordsAction = RequestAction<
        KeywordsActionName.GET_NEGATIVE_KEYWORDS,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        RubyKeyword[]
    >;
    export type GetSeedKeywordsAction = RequestAction<
        KeywordsActionName.GET_SEED_KEYWORDS,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        RubyKeyword[]
    >;

    export type GetTargetingKeywordBidsInfoAction = RequestAction<
        KeywordsActionName.GET_TARGETING_KEYWORD_BIDS_INFO,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        RubyTargetingKeywordBidsInfo[]
    >;

    export type AddTargetingKeywordsAction = RequestAction<
        KeywordsActionName.ADD_TARGETING_KEYWORDS,
        AddTargetingKeywordPayload,
        RubyKeyword[]
    >;
    export type AddNegativeKeywordsAction = RequestAction<
        KeywordsActionName.ADD_NEGATIVE_KEYWORDS,
        AddNegativeKeywordPayload,
        {
            addedNegativeKeywords: RubyKeyword[];
            removedTargetingKeywords: RubyKeyword[];
        }
    >;

    export type RemoveTargetingKeywordsAction = RequestAction<
        KeywordsActionName.REMOVE_TARGETING_KEYWORDS,
        EditKeywordPayload,
        void
    >;
    export type RemoveNegativeKeywordsAction = RequestAction<
        KeywordsActionName.REMOVE_NEGATIVE_KEYWORDS,
        EditKeywordPayload,
        void
    >;
    export type MakeNegativeTargetingKeywordsAction = RequestAction<
        KeywordsActionName.MAKE_NEGATIVE_TARGETING_KEYWORDS,
        EditKeywordPayload,
        RubyKeyword[]
    >;

    export type UpdateSeedKeywordsAction = RequestAction<
        KeywordsActionName.UPDATE_SEED_KEYWORDS,
        UpdateSeedKeywordPayload,
        RubyKeyword[]
    >;

    export type TagTargetingKeywordsAction = RequestAction<
        KeywordsActionName.TAG_TARGETING_KEYWORDS,
        EditKeywordTagPayload,
        RubyKeyword[]
    >;
    export type TagNegativeKeywordsAction = RequestAction<
        KeywordsActionName.TAG_NEGATIVE_KEYWORDS,
        EditKeywordTagPayload,
        RubyKeyword[]
    >;

    export type SetTargetingRegionsAction = RequestAction<
        KeywordsActionName.SET_TARGETING_REGIONS,
        EditKeywordRegionsPayload,
        RubyKeywordAssignment[]
    >;
    export type SetTargetingBidsAction = RequestAction<
        KeywordsActionName.SET_TARGETING_BIDS,
        EditKeywordBidsPayload,
        RubyKeywordAssignment[]
    >;
    export type SetTargetingMatchAction = RequestAction<
        KeywordsActionName.SET_TARGETING_MATCH,
        EditKeywordMatchPayload,
        RubyKeyword[]
    >;

    export type ImportTargetingKeywordsAction = RequestAction<
        KeywordsActionName.IMPORT_TARGETING_KEYWORDS,
        ImportKeywordPayload,
        RubyKeyword[]
    >;
    export type ImportNegativeKeywordsAction = RequestAction<
        KeywordsActionName.IMPORT_NEGATIVE_KEYWORDS,
        ImportKeywordPayload,
        RubyKeyword[]
    >;
}

export type KeywordsActionType =
    | KeywordsActions.GetTargetingKeywordsAction
    | KeywordsActions.GetNegativeKeywordsAction
    | KeywordsActions.GetSeedKeywordsAction
    | KeywordsActions.GetTargetingKeywordBidsInfoAction
    | KeywordsActions.AddTargetingKeywordsAction
    | KeywordsActions.AddNegativeKeywordsAction
    | KeywordsActions.RemoveTargetingKeywordsAction
    | KeywordsActions.RemoveNegativeKeywordsAction
    | KeywordsActions.MakeNegativeTargetingKeywordsAction
    | KeywordsActions.UpdateSeedKeywordsAction
    | KeywordsActions.TagTargetingKeywordsAction
    | KeywordsActions.TagNegativeKeywordsAction
    | KeywordsActions.SetTargetingRegionsAction
    | KeywordsActions.SetTargetingBidsAction
    | KeywordsActions.SetTargetingMatchAction
    | KeywordsActions.ImportTargetingKeywordsAction
    | KeywordsActions.ImportNegativeKeywordsAction;

export const targetingKeywordsActions = {
    list: requestActions<KeywordsActions.GetTargetingKeywordsAction>(KeywordsActionName.GET_TARGETING_KEYWORDS),
    add: requestActions<KeywordsActions.AddTargetingKeywordsAction>(KeywordsActionName.ADD_TARGETING_KEYWORDS),
    remove: requestActions<KeywordsActions.RemoveTargetingKeywordsAction>(KeywordsActionName.REMOVE_TARGETING_KEYWORDS),
    makeNegative: requestActions<KeywordsActions.MakeNegativeTargetingKeywordsAction>(
        KeywordsActionName.MAKE_NEGATIVE_TARGETING_KEYWORDS
    ),
    tag: requestActions<KeywordsActions.TagTargetingKeywordsAction>(KeywordsActionName.TAG_TARGETING_KEYWORDS),
    setRegions: requestActions<KeywordsActions.SetTargetingRegionsAction>(KeywordsActionName.SET_TARGETING_REGIONS),
    setBids: requestActions<KeywordsActions.SetTargetingBidsAction>(KeywordsActionName.SET_TARGETING_BIDS),
    setMatch: requestActions<KeywordsActions.SetTargetingMatchAction>(KeywordsActionName.SET_TARGETING_MATCH),
    import: requestActions<KeywordsActions.ImportTargetingKeywordsAction>(KeywordsActionName.IMPORT_TARGETING_KEYWORDS),
    listBidsInfo: requestActions<KeywordsActions.GetTargetingKeywordBidsInfoAction>(
        KeywordsActionName.GET_TARGETING_KEYWORD_BIDS_INFO
    ),
};

export const negativeKeywordsActions = {
    list: requestActions<KeywordsActions.GetNegativeKeywordsAction>(KeywordsActionName.GET_NEGATIVE_KEYWORDS),
    add: requestActions<KeywordsActions.AddNegativeKeywordsAction>(KeywordsActionName.ADD_NEGATIVE_KEYWORDS),
    remove: requestActions<KeywordsActions.RemoveNegativeKeywordsAction>(KeywordsActionName.REMOVE_NEGATIVE_KEYWORDS),
    tag: requestActions<KeywordsActions.TagNegativeKeywordsAction>(KeywordsActionName.TAG_NEGATIVE_KEYWORDS),
    import: requestActions<KeywordsActions.ImportNegativeKeywordsAction>(KeywordsActionName.IMPORT_NEGATIVE_KEYWORDS),
};

export const seedKeywordsActions = {
    list: requestActions<KeywordsActions.GetSeedKeywordsAction>(KeywordsActionName.GET_SEED_KEYWORDS),
    update: requestActions<KeywordsActions.UpdateSeedKeywordsAction>(KeywordsActionName.UPDATE_SEED_KEYWORDS),
};
