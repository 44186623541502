import { formatPercent } from "@/utilities/number";
import {
    NotificationType,
    type AppleAuthTokenErrorNotification,
    type BudgetExhaustedNotification,
    type CompareDailySpendNotification,
    type CreatedAsrChannelNotification,
    type CreatedAsrRegionNotification,
    type DailyBudgetRemainingNotification,
    type DeletedAsrChannelNotification,
    type DeletedAsrRegionNotification,
    type EndOfBudgetPlanNotification,
    type ErrorNotification,
    type FundExhaustedNotification,
    type NoPaymentMethodsNotification,
    type PaymentMethodsExpiredNotification,
    type StartOfBudgetPlanNotification,
    type UpdatedAsrChannelNotification,
    type UpdatedAsrRegionNotification,
    type UserInvitedNotification,
    type UserJoinedTeamNotification,
    type UserLeftTeamNotification,
} from "@redbox-ruby/data-lib";
import { Link } from "react-router-dom";
import { type UserState } from "../../../reducers/domain";
import { type RubyNotification } from "../../../services/backend/RubyData";
import { languageString } from "../../../utilities/text";
import { getUrl } from "../../../utilities/url";

/**
 * We don't need all the user state, so we pick a few easily re-creatable users
 */
export type MinimalUserState = Pick<UserState, "userId" | "userName">;

export type NotificationTypeProps<T = RubyNotification> = { user: MinimalUserState; note: T };

export function NotificationFactory(props: NotificationTypeProps) {
    switch (props.note.type) {
        case NotificationType.ACCOUNTS_USER_INVITED_TO_TEAM:
            return <UserInvitedToTeam {...props} />;

        case NotificationType.ACCOUNTS_USER_JOINED_TEAM:
            return <UserJoinedTeam {...props} />;

        case NotificationType.ACCOUNTS_USER_LEFT_TEAM:
            return <UserLeftTeam {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_CHANNEL_CREATED:
        case NotificationType.APPLE_CHANNELS_AST_CHANNEL_CREATED:
        case NotificationType.APPLE_CHANNELS_ATT_CHANNEL_CREATED:
        case NotificationType.APPLE_CHANNELS_PPB_CHANNEL_CREATED:
            return <ChannelCreated {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_CHANNEL_DELETED:
        case NotificationType.APPLE_CHANNELS_AST_CHANNEL_DELETED:
        case NotificationType.APPLE_CHANNELS_ATT_CHANNEL_DELETED:
        case NotificationType.APPLE_CHANNELS_PPB_CHANNEL_DELETED:
            return <ChannelDeleted {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_CHANNEL_UPDATED:
        case NotificationType.APPLE_CHANNELS_AST_CHANNEL_UPDATED:
        case NotificationType.APPLE_CHANNELS_ATT_CHANNEL_UPDATED:
        case NotificationType.APPLE_CHANNELS_PPB_CHANNEL_UPDATED:
            return <ChannelUpdated {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_REGION_CREATED:
        case NotificationType.APPLE_CHANNELS_AST_REGION_CREATED:
        case NotificationType.APPLE_CHANNELS_ATT_REGION_CREATED:
        case NotificationType.APPLE_CHANNELS_PPB_REGION_CREATED:
            return <RegionCreated {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_REGION_UPDATED:
        case NotificationType.APPLE_CHANNELS_AST_REGION_UPDATED:
        case NotificationType.APPLE_CHANNELS_ATT_REGION_UPDATED:
        case NotificationType.APPLE_CHANNELS_PPB_REGION_UPDATED:
            return <RegionUpdated {...props} />;

        case NotificationType.APPLE_CHANNELS_ASR_REGION_DELETED:
        case NotificationType.APPLE_CHANNELS_AST_REGION_DELETED:
        case NotificationType.APPLE_CHANNELS_ATT_REGION_DELETED:
        case NotificationType.APPLE_CHANNELS_PPB_REGION_DELETED:
            return <RegionDeleted {...props} />;

        case NotificationType.BUDGETS_BUDGET_PLAN_ENDED:
            return <BudgetPlanEnded {...props} />;

        case NotificationType.BUDGETS_BUDGET_PLAN_EXHAUSTED:
            return <BudgetPlanExhausted {...props} />;

        case NotificationType.BUDGETS_BUDGET_PLAN_STARTED:
            return <BudgetPlanStarted {...props} />;

        case NotificationType.BUDGETS_FUND_EXHAUSTED:
            return <BudgetFundExhausted {...props} />;

        case NotificationType.GENERAL_ERROR:
            return <GeneralError {...props} />;

        case NotificationType.NO_PAYMENT_METHODS:
            return <NoPaymentMethods {...props} />;

        case NotificationType.PAYMENT_METHODS_EXPIRED:
            return <PaymentMethodExpired {...props} />;

        case NotificationType.APPLE_AUTH_TOKEN_ERROR:
            return <AuthTokenError {...props} />;

        case NotificationType.DAILY_BUDGET_REMAINING:
            return <DailyBudgetRemaining {...props} />;

        case NotificationType.DAILY_SPEND_COMPARE:
            return <DailySpendCompare {...props} />;

        default:
            console.warn("Unsupported notification type ", props.note.type);
            return null;
    }
}

function UserInvitedToTeam({ user, note }: NotificationTypeProps) {
    const payload = note.payload as UserInvitedNotification["payload"];
    // TODO This is a placeholder element to make invites easier to accept

    if (payload.invitedUserId === user.userId) {
        return (
            <Link to={getUrl.invite(payload.invitationId)} className="u-flatLink">
                {languageString("notifications.messages.youAreInvitedToTeam", undefined, [payload.teamName])}
            </Link>
        );
    } else {
        return (
            <Link key={note.id} to={getUrl.teamDetails(payload.teamId)} className="u-flatLink">
                {languageString("notifications.messages.anotherUserInvitedToTeam", undefined, [payload.teamName])}
            </Link>
        );
    }
}

function UserJoinedTeam({ user, note }: NotificationTypeProps) {
    const payload = note.payload as UserJoinedTeamNotification["payload"];

    if (payload.joinedUserId === user.userId) {
        return (
            <Link to={getUrl.teamDetails(payload.teamId)} className="u-flatLink">
                {languageString("notifications.messages.youJoinedTeam", undefined, [payload.teamName])}
            </Link>
        );
    } else {
        return (
            <Link to={getUrl.teamDetails(payload.teamId)} className="u-flatLink">
                {languageString("notifications.messages.anotherUserJoinedTeam", undefined, [
                    payload.joinedUserName,
                    payload.teamName,
                ])}
            </Link>
        );
    }
}

function UserLeftTeam({ user, note }: NotificationTypeProps) {
    const payload = note.payload as UserLeftTeamNotification["payload"];

    if (payload.leftUserId === user.userId) {
        return (
            <Link to={getUrl.teamDetails(payload.teamId)} className="u-flatLink">
                {languageString("notifications.messages.youLeftTeam", undefined, [payload.teamName])}
            </Link>
        );
    } else {
        return (
            <Link to={getUrl.teamDetails(payload.teamId)} className="u-flatLink">
                {languageString("notifications.messages.anotherUserLeftTeam", undefined, [
                    payload.leftUserName,
                    payload.teamName,
                ])}
            </Link>
        );
    }
}

function ChannelCreated({ note }: NotificationTypeProps) {
    const payload = note.payload as CreatedAsrChannelNotification["payload"];
    return (
        <Link to={getUrl.campaignMetrics(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.channelCreated", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function ChannelUpdated({ note }: NotificationTypeProps) {
    const payload = note.payload as UpdatedAsrChannelNotification["payload"];
    return (
        <Link to={getUrl.campaignMetrics(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.channelUpdated", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function ChannelDeleted({ note }: NotificationTypeProps) {
    const payload = note.payload as DeletedAsrChannelNotification["payload"];
    return (
        <Link to={getUrl.campaignMetrics(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.channelDeleted", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function RegionCreated({ note }: NotificationTypeProps) {
    const payload = note.payload as CreatedAsrRegionNotification["payload"];
    return (
        <Link to={getUrl.campaignRegionConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.regionCreated", undefined, [
                payload.regionCountry,
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function RegionUpdated({ note }: NotificationTypeProps) {
    const payload = note.payload as UpdatedAsrRegionNotification["payload"];
    return (
        <Link to={getUrl.campaignRegionConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.regionUpdated", undefined, [
                payload.regionCountry,
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function RegionDeleted({ note }: NotificationTypeProps) {
    const payload = note.payload as DeletedAsrRegionNotification["payload"];
    return (
        <Link to={getUrl.campaignRegionConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.regionDeleted", undefined, [
                payload.regionCountry,
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function BudgetFundExhausted({ note }: NotificationTypeProps) {
    const payload = note.payload as FundExhaustedNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.budgetFundExhausted", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function BudgetPlanEnded({ note }: NotificationTypeProps) {
    const payload = note.payload as EndOfBudgetPlanNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.budgetPlanEnded", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function BudgetPlanStarted({ note }: NotificationTypeProps) {
    const payload = note.payload as StartOfBudgetPlanNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.budgetPlanStarted", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function BudgetPlanExhausted({ note }: NotificationTypeProps) {
    const payload = note.payload as BudgetExhaustedNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.budgetPlanExhausted", undefined, [
                payload.campaignName,
                payload.teamName,
            ])}
        </Link>
    );
}

function GeneralError({ note }: NotificationTypeProps) {
    const payload = note.payload as ErrorNotification["payload"];
    return (
        <Link to={getUrl.campaignsRoot()} className="u-flatLink">
            {languageString("notifications.messages.generalError", undefined, [payload.error ?? "Unknown"])}
        </Link>
    );
}

function NoPaymentMethods({ note }: NotificationTypeProps) {
    const payload = note.payload as NoPaymentMethodsNotification["payload"];
    return (
        <Link to={getUrl.teamBilling(payload.teamId)} className="u-flatLink">
            {languageString("notifications.messages.noPaymentMethods", undefined, [payload.teamName])}
        </Link>
    );
}

function PaymentMethodExpired({ note }: NotificationTypeProps) {
    const payload = note.payload as PaymentMethodsExpiredNotification["payload"];
    return (
        <Link to={getUrl.teamBilling(payload.teamId)} className="u-flatLink">
            {languageString("notifications.messages.paymentMethodExpired", undefined, [payload.teamName])}
        </Link>
    );
}

function AuthTokenError({ note }: NotificationTypeProps) {
    const payload = note.payload as AppleAuthTokenErrorNotification["payload"];
    return (
        <Link to={getUrl.teamDetails(payload.teamId)}>
            {languageString("notifications.messages.authTokenError", "", [payload.authError, payload.parentOrgRef])}
        </Link>
    );
}

function DailyBudgetRemaining({ note }: NotificationTypeProps) {
    const payload = note.payload as DailyBudgetRemainingNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.budgetPlanRemaining", "", [
                payload.campaignName,
                formatPercent(payload.threshold / 100),
            ])}
        </Link>
    );
}

function DailySpendCompare({ note }: NotificationTypeProps) {
    const payload = note.payload as CompareDailySpendNotification["payload"];
    return (
        <Link to={getUrl.campaignBudgetConfig(payload.teamId, payload.campaignId)} className="u-flatLink">
            {languageString("notifications.messages.dailySpendCompare", "", [
                payload.campaignName,
                formatPercent(payload.percentage),
            ])}
        </Link>
    );
}
