// https://github.com/olmokramer/scrollbar-width.js/blob/master/scrollbar-width.js
// Shamelessly stolen, however the original implementation has too aggressive caching

const CACHE_TIME = 10 * 1000;

let scrollbarSize: number | null = null;
let lastCalculated = 0;

export function getScrollbarSize(): number {
    if (Date.now() < lastCalculated + CACHE_TIME && scrollbarSize != null) {
        return scrollbarSize;
    }

    if (document.readyState === "loading") {
        return null;
    }

    const div1 = document.createElement("div");
    const div2 = document.createElement("div");
    div1.style.width = div2.style.width = div1.style.height = div2.style.height = "100px";
    div1.style.overflow = "scroll";
    div2.style.overflow = "hidden";
    document.body.appendChild(div1);
    document.body.appendChild(div2);
    scrollbarSize = Math.abs(div1.scrollHeight - div2.scrollHeight);
    document.body.removeChild(div1);
    document.body.removeChild(div2);

    lastCalculated = Date.now();
    return scrollbarSize;
}
