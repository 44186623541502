import { enumLanguageString, languageString } from "./text";

export async function formatResponseError(response: Response): Promise<string> {
    const status = response.status;
    let message: string;

    if (status >= 500 && status < 600) {
        message = languageString("ui.error.request.5xx");
    } else if ([400, 401, 403, 404, 405, 408].includes(status)) {
        message = enumLanguageString(`ui.error.request`, `${status}`);
    } else if (status >= 400 && status < 500) {
        message = languageString("ui.error.request.4xx");
    }

    let serverMessage = "";
    const body = await response.text().catch();
    if (body) {
        response.text = () => Promise.resolve(body);
        try {
            const errorObject = JSON.parse(body) as object;
            response.json = () => Promise.resolve(errorObject);
            if ("message" in errorObject && typeof errorObject.message === "string") {
                serverMessage = errorObject.message;
            } else if ("error" in errorObject && typeof errorObject.error === "string") {
                serverMessage = errorObject.error;
            } else if ("errors" in errorObject && typeof errorObject.errors === "object") {
                serverMessage = JSON.stringify(errorObject.errors);
            }
        } catch (err) {
            if (body.toLowerCase().startsWith("<!doctype html")) {
                const title = body.match(/<title>([^<]+)<\/title>/)[1];
                if (title) {
                    serverMessage = title;
                } else {
                    serverMessage = languageString("ui.error.request.htmlPage");
                }
            } else {
                serverMessage = body;
            }
        }
    }

    return languageString("ui.error.request.report", "", [message, serverMessage, status, response.url]);
}
