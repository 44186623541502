import { upperFirst } from "lodash";
import moment from "moment";
import { formatCurrency } from "./currency";
import { formatDate } from "./date";
import { formatNumber } from "./number";
import { writeList } from "./text";

/**
 * Converts a general value to a user friendly string.
 */
export function valueToString(value: unknown): string {
    if (value == null) {
        return "null";
    }
    if (typeof value === "string") {
        return value;
    }
    if (typeof value === "number") {
        const isFloat = value % 1 !== 0;
        return formatNumber(value, true, isFloat ? 2 : 0);
    }
    if (typeof value === "boolean") {
        return upperFirst(value.toString());
    }
    if (moment.isDate(value) || moment.isMoment(value)) {
        return formatDate(value, {
            dateStyle: "short",
            timeStyle: "long",
        });
    }
    if (Array.isArray(value)) {
        if (value.length === 0) {
            return "Empty";
        }

        return writeList(value.map(valueToString));
    }
    if (value instanceof Object) {
        if (Object.keys(value).length === 0) {
            return "Empty";
        }

        // Is Apple Money?
        if (value["currency"] && value["amount"]) {
            return formatCurrency(value["amount"] as number, value["currency"] as string);
        }

        return JSON.stringify(value, undefined, 2);
    }
    return "Unknown";
}
