import clsx from "clsx";
import React from "react";
import { languageString } from "../../../utilities/text";
import "./spinner.css";

interface SpinnerProps {
    size?: number;
    color?: string;
    className?: string;
    isPending?: boolean;
}

const RADIUS = 4;
const circum = (percent: number) => ((Math.PI * 2 * RADIUS) / 100) * percent;
const styleVars = {
    "--pathLength10": circum(10),
    "--pathLength20": circum(20),
    "--pathLength30": circum(30),
    "--pathLength40": circum(40),
    "--pathLength50": circum(50),
    "--pathLength60": circum(60),
    "--pathLength70": circum(70),
    "--pathLength80": circum(80),
    "--pathLength90": circum(90),
    "--pathLength100": circum(100),
} as React.CSSProperties;

export default class Spinner extends React.PureComponent<SpinnerProps> {
    static defaultProps = {
        size: 32,
        color: "var(--color-neutral-400)",
    };

    render() {
        return (
            <svg
                className={clsx("spinner", this.props.className, {
                    "is-pending": this.props.isPending,
                })}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 10 10"
                style={styleVars}
                width={this.props.size}
                height={this.props.size}
            >
                <title>{languageString("ui.alt.loadingSpinner", "Loading spinner")}</title>
                <circle
                    r={RADIUS}
                    cx="5"
                    cy="5"
                    stroke={this.props.color}
                    strokeWidth={this.props.size < 32 ? "1" : ".6"}
                    fill="none"
                    pathLength={circum(100)}
                />
            </svg>
        );
    }
}
