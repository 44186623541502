export enum APIMethod {
    GET = "GET",
    PUT = "PUT",
    POST = "POST",
    PATCH = "PATCH",
    DELETE = "DELETE",
    HEAD = "HEAD",
}

export interface APIRequest {
    uri: string;
    domain?: string;
    sendAuth?: boolean;
    data?: object | string | number | boolean;
    useQueryString?: boolean;
    submitAsFormData?: boolean;
    handleErrors?: boolean;
    wrappedResponse?: boolean;
    headers?: {
        [key: string]: string;
    };
}

export interface APIRequestFull extends APIRequest {
    method: APIMethod;
}

export interface WrappedResponse<T> {
    error: boolean;
    errors: string[];
    response: T;
}

export abstract class APIServiceImpl {
    abstract get<T>(request: APIRequest): Promise<T>;
    abstract put<T>(request: APIRequest): Promise<T>;
    abstract post<T>(request: APIRequest): Promise<T>;
    abstract patch<T>(request: APIRequest): Promise<T>;
    abstract delete<T>(request: APIRequest): Promise<T>;
    abstract head<T>(request: APIRequest): Promise<T>;
    abstract jsonp<T>(request: APIRequest & { callbackParam?: string }): Promise<T>;
    abstract reportError(...errorMessages: unknown[]): Promise<void>;
}
