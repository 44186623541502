import { jwtVerify, SignJWT } from "jose";
import PRIVATE_KEY from "./jwt.key";

const ISSUER = "RB_Mobile";
const KEY = new TextEncoder().encode(PRIVATE_KEY);

export async function generateToken(username: string, expiresInDays: number) {
    const jwt = await new SignJWT({
        username,
    })
        .setProtectedHeader({ alg: "HS256" })
        .setIssuedAt()
        .setIssuer(ISSUER)
        .setExpirationTime(`${expiresInDays}d`)
        .sign(KEY);

    return jwt;
}

export async function verifyToken(username: string, token: string): Promise<boolean> {
    const { payload } = await jwtVerify(token, KEY, {
        issuer: ISSUER,
        algorithms: ["HS256"],
    });

    return payload.username === username;
}
