import { formatResponseError } from "@/utilities/response";
import auth0, { type Auth0UserProfile } from "auth0-js";
import { v4 as uuid } from "uuid";
import { loginExpiredAction } from "../actions/userActions";
import { type AuthServiceImpl, type NonceToken, type UserData } from "../types/AuthService";
import { languageString } from "../utilities/text";
import { minutes } from "../utilities/time";
import { getPostLoginUrl, getUrl } from "../utilities/url";
import { AUTH0, BACKEND_URL, TERMS_CONDITIONS_VERSION } from "../utilities/vars";
import { type RubyUserMe } from "./backend/RubyData";

const NONCE_KEY = "RB_Nonce_Auth";
const SESSION_KEY = "RB_Session_Auth";

const MAX_TOKEN_AGE = minutes(30);

type SessionResponse = {
    idTokenPayload: Auth0UserProfile;
    accessToken: string;
};

export class AuthService implements AuthServiceImpl {
    private client: auth0.WebAuth;
    private redirect: string = null;
    private user: UserData;

    private sessionCheck: Promise<RubyUserMe>;
    private userInfoCall: Promise<RubyUserMe>;

    constructor() {
        this.client = new auth0.WebAuth({
            clientID: AUTH0.CLIENT_ID,
            domain: AUTH0.DOMAIN,
            audience: AUTH0.AUDIENCE,
            responseType: "token id_token",
            scope: "openid profile email",
            redirectUri: window.location.origin + getUrl.authenticate(),
        });
    }

    createAccount(username: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.signup(
                {
                    connection: "Username-Password-Authentication",
                    email: username,
                    password,
                    userMetadata: {
                        termsAndConditionVersionAccepted: TERMS_CONDITIONS_VERSION,
                    },
                },
                (err, _result) => {
                    if (err) {
                        reject(err.description);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    login(username: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.login(
                {
                    username,
                    password,
                    realm: "Username-Password-Authentication",
                    state: this.createNonce(),
                },
                (err) => {
                    if (err) {
                        reject(err.description);
                    } else {
                        resolve();
                    }
                }
            );
        });
    }

    loginWithGoogle() {
        this.client.authorize({
            connection: "google-oauth2",
            state: this.createNonce(),
        });
    }

    logout() {
        sessionStorage.removeItem(NONCE_KEY);
        localStorage.removeItem(SESSION_KEY);
        this.client.logout({
            returnTo: window.location.origin,
        });
    }

    restoreSession(): Promise<RubyUserMe> {
        const cachedData = this.getCachedUserData();
        if (cachedData) {
            return Promise.resolve(cachedData.userData);
        } else {
            return this.checkSession();
        }
    }

    checkSession(): Promise<RubyUserMe> {
        if (this.sessionCheck != null) {
            return this.sessionCheck;
        }
        this.sessionCheck = new Promise((resolve, reject) => {
            this.client.checkSession({}, (err, result: SessionResponse) => {
                if (!result || err) {
                    return reject(err.description);
                }

                this.getUserInfo(result.accessToken, result.idTokenPayload)
                    .then((userData) => {
                        this.setUserData(result.accessToken, userData);
                        resolve(userData);
                    })
                    .catch(reject);
            });
        });
        void this.sessionCheck.finally(() => {
            this.sessionCheck = null;
        });
        return this.sessionCheck;
    }

    parseHash(): Promise<RubyUserMe> {
        return new Promise((resolve, reject) => {
            const hash = window.location.hash;

            if (/access_token|error/.test(hash)) {
                const nonce = this.getNonceData();
                if (!nonce) {
                    return reject(languageString("login.error.noState"));
                }

                this.client.parseHash(
                    {
                        hash,
                        state: nonce.code,
                    },
                    (err, result: SessionResponse) => {
                        if (!result || err) {
                            return reject(languageString("login.error.failed", "", [err.errorDescription]));
                        }
                        this.redirect = nonce.redirect;

                        this.getUserInfo(result.accessToken, result.idTokenPayload)
                            .then((userData) => {
                                this.setUserData(result.accessToken, userData);
                                resolve(userData);
                            })
                            .catch(reject);
                    }
                );
            } else {
                return reject(languageString("login.error.noToken"));
            }
        });
    }

    private getUserInfo(accessToken: string, idPayload: Auth0UserProfile): Promise<RubyUserMe> {
        if (this.userInfoCall != null) {
            return this.userInfoCall;
        }
        this.userInfoCall = new Promise((resolve, reject) => {
            fetch(`${BACKEND_URL}/api/v2/accounts/me`, {
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json() as Promise<RubyUserMe>;
                    }
                    throw formatResponseError(response);
                })
                .then((user) => {
                    user.emailVerified = user.emailVerified ?? idPayload.email_verified;
                    user.name = user.name !== "User" ? user.name : idPayload.name;
                    user.email = user.email ?? idPayload.email;
                    user.authId = idPayload.sub;
                    resolve(user);
                })
                .catch(reject);
        });
        void this.userInfoCall.finally(() => {
            this.userInfoCall = null;
        });
        return this.userInfoCall;
    }

    // Warning: This does not update anything in the redux store
    async patchMetadata(data: object): Promise<void> {
        data = {
            ...this.user.userData.metadata,
            ...data,
        };
        this.user.userData.metadata = {
            ...data,
        };
        localStorage.setItem(SESSION_KEY, JSON.stringify(this.user));
        // We can't use a library to do this as the library requires the AuthService
        await fetch(`${BACKEND_URL}/api/v2/accounts/users/${encodeURIComponent(this.user.userData.id)}/metadata`, {
            method: "PUT",
            body: JSON.stringify(data),
            headers: {
                Authorization: `Bearer ${await this.getToken()}`,
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });
    }

    private setUserData(token: string, user: RubyUserMe) {
        this.user = {
            userData: user,
            token,
            age: Date.now(),
        };
        localStorage.setItem(SESSION_KEY, JSON.stringify(this.user));
    }

    private getCachedUserData(): UserData {
        const cachedUserString = localStorage.getItem(SESSION_KEY);
        if (cachedUserString) {
            const cachedUserData = JSON.parse(cachedUserString) as UserData;
            if (cachedUserData.age > Date.now() - MAX_TOKEN_AGE && cachedUserData.userData.emailVerified) {
                this.user = cachedUserData;
                return cachedUserData;
            }
        }
        return null;
    }

    resetPassword(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.client.changePassword(
                {
                    connection: "Username-Password-Authentication",
                    email,
                },
                (err) => {
                    if (err) {
                        return reject(err.errorDescription);
                    }
                    return resolve();
                }
            );
        });
    }

    setRedirect(redirect: string) {
        if (redirect !== getPostLoginUrl() && redirect !== getUrl.verifyEmail()) {
            this.redirect = redirect;
        }
    }

    getRedirect() {
        if (!this.user.userData.emailVerified) {
            return getUrl.verifyEmail();
        }
        return this.redirect;
    }

    async getToken(): Promise<string> {
        if (!this.user || !this.user.token) {
            return Promise.reject(languageString("login.error.notLoggedIn"));
        }
        if (this.user.age > Date.now() - MAX_TOKEN_AGE) {
            return this.user.token;
        }
        try {
            await this.restoreSession();
        } catch (err) {
            window.dispatchToStore(loginExpiredAction());
            return Promise.reject(err);
        }
        return this.user.token;
    }

    private getNonceData(): NonceToken | undefined {
        const nonceStr = localStorage.getItem(NONCE_KEY);
        if (nonceStr) {
            localStorage.removeItem(NONCE_KEY);
            return JSON.parse(nonceStr) as NonceToken;
        }
    }

    private createNonce(): string {
        const code = uuid();

        const redirect: string = this.redirect ?? null;

        let nonce = {
            code,
            redirect,
        };

        const previousNonce = this.getNonceData();
        if (previousNonce) {
            console.warn("Auth Service: Second nonce value generated, previous code will be kept", previousNonce.code);
            nonce = {
                code: previousNonce.code,
                redirect,
            };
        }

        localStorage.setItem(NONCE_KEY, JSON.stringify(nonce));
        return nonce.code;
    }

    crossOriginVerification() {
        this.client.crossOriginVerification();
    }
}
