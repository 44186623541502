interface Point {
    x: number;
    y: number;
}

interface Command extends Point {
    cmd: string;
    operation: string;
}

export function roundCorners(path: string, radius: number = 8): string {
    const finalCommands: Command[] = [];
    const inputCommands = parsePath(path);

    let closePoint: Command = null;
    if (inputCommands[inputCommands.length - 1].operation === "Z" && inputCommands[0].operation === "M") {
        closePoint = {
            ...inputCommands[0],
            cmd: inputCommands[0].cmd.replace("M", "L"),
            operation: "L",
        };
        // Replace the Z command
        inputCommands[inputCommands.length - 1] = closePoint;
    }

    // Add the first command, but will likely need to fix it later
    finalCommands.push(inputCommands[0]);

    for (let i = 1; i < inputCommands.length; i++) {
        const prevCmd = finalCommands[finalCommands.length - 1];
        const currCmd = inputCommands[i];
        const nextCmd = i === inputCommands.length - 1 ? inputCommands[1] : inputCommands[i + 1];

        if (
            isValidPoint(prevCmd) &&
            isValidPoint(currCmd) &&
            isValidPoint(nextCmd) &&
            nextCmd.operation === "L" &&
            currCmd.operation === "L"
        ) {
            const curveStart = moveTowards(currCmd, prevCmd, radius);
            const curveEnd = moveTowards(currCmd, nextCmd, radius);

            //Add the start position
            finalCommands.push({
                ...curveStart,
                operation: "L",
                cmd: `L ${curveStart.x} ${curveStart.y}`,
            });

            const startControl = moveTowardsFractional(curveStart, currCmd, 0.5);
            const endControl = moveTowardsFractional(currCmd, curveEnd, 0.5);

            const curveCmd = [
                "C",
                startControl.x,
                startControl.y,
                endControl.x,
                endControl.y,
                curveEnd.x,
                curveEnd.y,
            ].join(" ");

            finalCommands.push({
                x: curveEnd.x,
                y: curveEnd.y,
                operation: "C",
                cmd: curveCmd,
            });
        } else {
            finalCommands.push(currCmd);
        }
    }

    if (closePoint) {
        // Sort out the closing
        const firstCmd = finalCommands[0];
        const lastCmd = finalCommands[finalCommands.length - 1];
        firstCmd.x = lastCmd.x;
        firstCmd.y = lastCmd.y;
        firstCmd.operation = "M";
        firstCmd.cmd = `M ${firstCmd.x} ${firstCmd.y}`;
        finalCommands.push({
            cmd: "Z",
            operation: "Z",
            x: NaN,
            y: NaN,
        });
    }

    return finalCommands.map((c) => c.cmd).join(" ");
}

function isValidPoint(p: Point): boolean {
    return p && !isNaN(p.x) && !isNaN(p.y);
}

function moveTowards(movingPoint: Point, targetPoint: Point, amount: number): Point {
    const width = targetPoint.x - movingPoint.x;
    const height = targetPoint.y - movingPoint.y;

    const distance = Math.sqrt(width * width + height * height);

    return moveTowardsFractional(movingPoint, targetPoint, Math.min(1, amount / distance));
}

/**
 * @param fraction Must be between 0-1
 */
function moveTowardsFractional(movingPoint: Point, targetPoint: Point, fraction: number): Point {
    return {
        x: movingPoint.x + (targetPoint.x - movingPoint.x) * fraction,
        y: movingPoint.y + (targetPoint.y - movingPoint.y) * fraction,
    };
}

function parsePath(pathString: string): Command[] {
    return (
        pathString
            // Cleanup
            .replace(/\s{2,}/g, " ")
            // Get every value
            .split(/[,\s]/)
            .reduce((parts, part) => {
                const match = part.match("([a-zA-Z])(.+)");
                if (match) {
                    parts.push(match[1]);
                    parts.push(match[2]);
                } else {
                    parts.push(part);
                }
                return parts;
            }, [] as string[])
            // Combine the values in groups for commands
            .reduce((commands, part) => {
                if (!isNaN(parseFloat(part)) && commands.length) {
                    commands[commands.length - 1].push(part);
                } else if (part.trim() !== "") {
                    commands.push([part]);
                }
                return commands;
            }, [] as string[][])
            // Convert groups to Command[]
            .reduce((commands, commandArr) => {
                const command: Command = {
                    cmd: commandArr.join(" "),
                    operation: commandArr[0],
                    x: NaN,
                    y: NaN,
                };

                if (commandArr.length >= 3) {
                    command.x = parseFloat(commandArr[commandArr.length - 2]);
                    command.y = parseFloat(commandArr[commandArr.length - 1]);
                }
                if (command.operation === "H" && commandArr.length === 2) {
                    command.x = parseFloat(commandArr[1]);
                    command.y = commands[commands.length - 1]?.y || 0;
                    command.operation = "L";
                    command.cmd = `L ${command.x} ${command.y}`;
                }
                if (command.operation === "V" && commandArr.length === 2) {
                    command.y = parseFloat(commandArr[1]);
                    command.x = commands[commands.length - 1]?.x || 0;
                    command.operation = "L";
                    command.cmd = `L ${command.x} ${command.y}`;
                }

                commands.push(command);

                return commands;
            }, [] as Command[])
    );
}
