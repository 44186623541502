import { getChannelAction, listBudgetPlansAction, listRegionsAction } from "@/actions/campaignActions";
import { aggregateAsyncData } from "@/utilities/requests";
import { all, put, takeLatest } from "redux-saga/effects";
import { ActionName } from "../actions/ActionType";
import { makeBulkEditAction, type BulkActions } from "../actions/bulkActions";
import { budgetConfigActions, channelConfigActions, regionConfigActions } from "../actions/configActions";
import { negativeKeywordsActions, targetingKeywordsActions } from "../actions/keywordsActions";
import {
    selectActiveCountries,
    selectCampaign,
    selectCampaignActiveBudget,
    selectChannel,
} from "../selectors/campaignSelectors";
import { selectNegativeKeywords, selectTargetingKeywords } from "../selectors/keywordsSelectors";
import { selectPaymentDetails, selectTeam, selectTeamOrganisations } from "../selectors/teamSelectors";
import { RubyChannelStatus } from "../services/backend/RubyData";
import { runRequestSaga, selectAsyncDataFromState, selectFromState } from "../utilities/saga";
import { languageString } from "../utilities/text";
import { delay } from "../utilities/time";
import { isChannelWithKeywords, isChannelWithPurposes } from "../utilities/types";
import { updateBudgetPlanSaga, updateConfigSaga, updateDiscoveryWeightingSaga } from "./configSaga";
import {
    addNegativeKeywordsSaga,
    addTargetingKeywordsSaga,
    removeNegativeKeywordsSaga,
    removeTargetingKeywordsSaga,
} from "./keywordsSaga";

export function* runBulkRequestSaga(actions: Generator | Generator[]) {
    yield* runRequestSaga(makeBulkEditAction.request(), makeBulkEditAction, function* () {
        if (Array.isArray(actions)) {
            yield all(actions);
        } else {
            yield* actions;
        }
    });
}

function* bulkAddTargetingKeywordsSaga(action: BulkActions.BulkAddTargetingKeywordsAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);
    const keywords = action.payload.keywords;
    const tag = action.payload.label;
    const match = action.payload.match;

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );
    const validCampaigns = campaigns.filter((campaign) => isChannelWithKeywords(campaign.data?.channelType));

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ data: { id: campaignId, channelType } }) {
            yield put(negativeKeywordsActions.list.request({ campaignId, teamId }));
            const negativeKeywords = yield* selectAsyncDataFromState((state) =>
                selectNegativeKeywords(state, campaignId)
            );

            const negativeWordsToRemove = negativeKeywords.filter((kw) =>
                keywords.map((s) => s.toLocaleLowerCase()).includes(kw.text.toLocaleLowerCase())
            );

            yield removeNegativeKeywordsSaga(
                negativeKeywordsActions.remove.request({
                    teamId,
                    campaignId,
                    keywords: negativeWordsToRemove,
                })
            );

            yield put(
                listRegionsAction.request({
                    campaignId,
                    teamId,
                    channelType,
                })
            );
            const activeCountries = yield* selectAsyncDataFromState((state) =>
                selectActiveCountries(state, campaignId)
            );

            yield addTargetingKeywordsSaga(
                targetingKeywordsActions.add.request({
                    campaignId,
                    keywords,
                    tag,
                    teamId,
                    regions: activeCountries.activeCountries,
                    match,
                })
            );
        })
    );
}

function* bulkAddNegativeKeywordsSaga(action: BulkActions.BulkAddNegativeKeywordsAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);
    const keywords = action.payload.keywords;
    const tag = action.payload.label;

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );
    const validCampaigns = campaigns.filter((campaign) => isChannelWithKeywords(campaign.data?.channelType));

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ data: { id: campaignId } }) {
            yield put(targetingKeywordsActions.list.request({ campaignId, teamId }));
            const targetingKeywords = yield* selectAsyncDataFromState((state) =>
                selectTargetingKeywords(state, campaignId)
            );

            const targetingWordsToRemove = targetingKeywords.filter((kw) =>
                keywords.map((s) => s.toLocaleLowerCase()).includes(kw.text.toLocaleLowerCase())
            );

            yield removeTargetingKeywordsSaga(
                targetingKeywordsActions.remove.request({
                    campaignId,
                    teamId,
                    keywords: targetingWordsToRemove,
                })
            );

            const removedKeywords = targetingWordsToRemove.map((t) => t.text.toLocaleLowerCase());
            const newNegativesToAdd = keywords.filter((kw) => !removedKeywords.includes(kw.toLocaleLowerCase()));

            yield addNegativeKeywordsSaga(
                negativeKeywordsActions.add.request({
                    campaignId,
                    teamId,
                    keywords: newNegativesToAdd,
                    tag,
                })
            );
        })
    );
}

function* bulkRemoveTargetingKeywordsSaga(action: BulkActions.BulkRemoveTargetingKeywordsAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);
    const keywords = action.payload.keywords;

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );
    const validCampaigns = campaigns.filter((campaign) => isChannelWithKeywords(campaign.data?.channelType));

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ data: { id: campaignId } }) {
            yield put(targetingKeywordsActions.list.request({ campaignId, teamId }));
            const targetingKeywords = yield* selectAsyncDataFromState((state) =>
                selectTargetingKeywords(state, campaignId)
            );

            const targetingWordsToRemove = targetingKeywords.filter((kw) =>
                keywords.map((s) => s.toLocaleLowerCase()).includes(kw.text.toLocaleLowerCase())
            );

            yield removeTargetingKeywordsSaga(
                targetingKeywordsActions.remove.request({
                    campaignId,
                    teamId,
                    keywords: targetingWordsToRemove,
                })
            );
        })
    );
}

function* bulkRemoveNegativeKeywordsSaga(action: BulkActions.BulkRemoveNegativeKeywordsAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);
    const keywords = action.payload.keywords;

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );
    const validCampaigns = campaigns.filter((campaign) => isChannelWithKeywords(campaign.data?.channelType));

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ data: { id: campaignId } }) {
            yield put(negativeKeywordsActions.list.request({ campaignId, teamId }));
            const negativeKeywords = yield* selectAsyncDataFromState((state) =>
                selectNegativeKeywords(state, campaignId)
            );

            const keywordsToRemove = negativeKeywords.filter((kw) =>
                keywords.map((s) => s.toLocaleLowerCase()).includes(kw.text.toLocaleLowerCase())
            );

            yield removeNegativeKeywordsSaga(
                negativeKeywordsActions.remove.request({
                    teamId,
                    campaignId,
                    keywords: keywordsToRemove,
                })
            );
        })
    );
}

function* bulkSetCostPerDownloadSaga(action: BulkActions.BulkSetCostPerDownloadAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );

    yield* runBulkRequestSaga(
        campaigns.map(function* ({ data: { id: campaignId, channelType } }) {
            yield updateConfigSaga(
                channelConfigActions.update.request({
                    teamId,
                    campaignId,
                    channelType,
                    update: action.payload,
                })
            );
        })
    );
}

function* bulkAddBudgetSaga(action: BulkActions.BulkAddBudgetAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);

    yield* runBulkRequestSaga(
        campaignIds.map(function* (campaignId) {
            yield put(listBudgetPlansAction.request(campaignId));
            const budget = yield* selectAsyncDataFromState((state) => selectCampaignActiveBudget(state, campaignId));
            if (budget) {
                yield updateBudgetPlanSaga(
                    budgetConfigActions.update.request({
                        campaignId,
                        id: budget.id,
                        totalAmount: budget.totalBudget + action.payload.amount,
                        policy: budget.policy,
                        timeRange: [budget.start, budget.end],
                        weeklyOverspend: budget.details.weekdayOverspend,
                        method: budget.method,
                    })
                );
            }
        })
    );
}

function* bulkSetDiscoveryWeightingSaga(action: BulkActions.BulkSetDiscoveryWeightingAction) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => selectCampaign(state, campaignId))
    );
    const validCampaigns = campaigns.filter((campaign) => isChannelWithPurposes(campaign.data?.channelType));

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ data: { id: campaignId, teamId, channelType } }) {
            yield updateDiscoveryWeightingSaga(
                regionConfigActions.updateDiscoveryWeighting({
                    campaignId,
                    teamId,
                    channelType,
                    discoveryWeighting: action.payload.discoveryWeighting,
                })
            );
        })
    );
}

function* bulkPauseSaga() {
    yield* bulkTogglePauseSaga(RubyChannelStatus.ACTIVE, RubyChannelStatus.DISABLED);
}

function* bulkUnpauseSaga() {
    yield* bulkTogglePauseSaga(RubyChannelStatus.DISABLED, RubyChannelStatus.ACTIVE);
}

function* bulkTogglePauseSaga(startStatus: RubyChannelStatus, endStatus: RubyChannelStatus) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => ({
            campaign: selectCampaign(state, campaignId),
            channel: selectChannel(state, campaignId),
        }))
    );
    const validCampaigns = campaigns.filter((campaign) => campaign.channel.data?.status === startStatus);

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({
            campaign: {
                data: { id: campaignId, teamId, channelType },
            },
        }) {
            yield updateConfigSaga(
                channelConfigActions.update.request({
                    teamId,
                    campaignId,
                    channelType,
                    update: {
                        status: endStatus,
                    },
                })
            );
        })
    );
}

function* bulkSetOrderNumberSaga(action: BulkActions.BulkSetOrderNumber) {
    const campaignIds = yield* selectFromState((state) => state.bulk.selectedCampaigns);
    const teamId = yield* selectFromState((state) => state.ui.activeTeam);

    const team = yield* selectFromState((state) => selectTeam(state, teamId));
    const paymentDetails = yield* selectFromState((state) => selectPaymentDetails(state, teamId));

    if (team.data?.tier.hosted) {
        yield put(makeBulkEditAction.error(undefined, languageString("bulk.hostedTeamOrderNumber")));
        return;
    }

    for (const campaignId of campaignIds) {
        const campaign = yield* selectFromState((state) => selectCampaign(state, campaignId));
        yield put(
            getChannelAction.request({
                campaignId,
                teamId,
                channelType: campaign.data?.channelType,
            })
        );
    }
    yield delay(100);
    yield* selectAsyncDataFromState((state) =>
        aggregateAsyncData(...campaignIds.map((campaignId) => selectChannel(state, campaignId)))
    );

    const campaigns = yield* selectFromState((state) =>
        campaignIds.map((campaignId) => ({
            campaign: selectCampaign(state, campaignId),
            channel: selectChannel(state, campaignId),
        }))
    );
    const orgs = yield* selectFromState((state) => selectTeamOrganisations(state, teamId));

    const validCampaigns = campaigns.filter((campaign) => {
        if (!campaign.channel.data.orgRef) {
            return false;
        }
        const org = orgs.data.find((org) => org.orgRef === campaign.channel.data.orgRef);
        return org.applePaymentModel === "LOC";
    });

    yield* runBulkRequestSaga(
        validCampaigns.map(function* ({ campaign, channel }) {
            yield updateConfigSaga(
                channelConfigActions.update.request({
                    teamId,
                    campaignId: campaign.data.id,
                    channelType: campaign.data.channelType,
                    update: {
                        invoiceDetails: {
                            billingEmail: paymentDetails?.data?.email,
                            buyerEmail: paymentDetails?.data?.email,
                            buyerName: paymentDetails?.data?.name,
                            ...channel.data.invoiceDetails,
                            orderNumber: action.payload.orderNumber,
                        },
                    },
                })
            );
        })
    );
}

export default function* bulkSaga() {
    yield takeLatest(ActionName.BULK_ADD_TARGETING_KEYWORDS, bulkAddTargetingKeywordsSaga);
    yield takeLatest(ActionName.BULK_ADD_NEGATIVE_KEYWORDS, bulkAddNegativeKeywordsSaga);
    yield takeLatest(ActionName.BULK_REMOVE_NEGATIVE_KEYWORDS, bulkRemoveNegativeKeywordsSaga);
    yield takeLatest(ActionName.BULK_REMOVE_TARGETING_KEYWORDS, bulkRemoveTargetingKeywordsSaga);
    yield takeLatest(ActionName.BULK_SET_COST_PER_DOWNLOAD, bulkSetCostPerDownloadSaga);
    yield takeLatest(ActionName.BULK_ADD_BUDGET, bulkAddBudgetSaga);
    yield takeLatest(ActionName.BULK_SET_DISCOVERY_WEIGHTING, bulkSetDiscoveryWeightingSaga);
    yield takeLatest(ActionName.BULK_PAUSE, bulkPauseSaga);
    yield takeLatest(ActionName.BULK_UNPAUSE, bulkUnpauseSaga);
    yield takeLatest(ActionName.BULK_SET_ORDER_NUMBER, bulkSetOrderNumberSaga);
}
