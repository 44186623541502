import type { Action, RequestActionPayloadRequest } from "@/actions/Action";
import type { AsyncData, State } from "@/reducers/domain";
import { getAllObjectValues } from "@/utilities/obj";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export function useStateSelector<T = unknown>(lookupFn: (state: State) => T) {
    return useSelector(lookupFn);
}

export function useActionDispatcher<T extends unknown[]>(
    action: (...params: T) => Action<unknown, unknown>
): (...props: T) => void {
    const dispatch = useDispatch();
    return (...props: T) => dispatch(action(...props));
}

export function useAsyncData<TData, TRequest>(
    selector: (state: State) => AsyncData<TData>,
    action: (req: TRequest) => Action<unknown, RequestActionPayloadRequest<TRequest>>,
    request: TRequest
): AsyncData<TData> {
    const reqData = useStateSelector(selector);
    const sendRequest = useActionDispatcher(action);
    useEffect(() => {
        if (!reqData.success && !reqData.isRequesting && !reqData.errorMessage && !!request) {
            sendRequest(request);
        }
    }, [reqData.success, reqData.isRequesting, reqData.errorMessage, ...getAllObjectValues(request)]);
    return reqData;
}
