import React, { type CSSProperties } from "react";
import "./layoutGrid.css";

interface LayoutGridPoints {
    l?: number;
    m?: number;
    s?: number;
}

interface LayoutGridProps {
    cols?: LayoutGridPoints;
    breakpoints?: LayoutGridPoints;
    children: {
        id: string;
        cols?: LayoutGridPoints;
        rows?: LayoutGridPoints;
        x?: LayoutGridPoints;
        y?: LayoutGridPoints;
        element: React.ReactElement;
        hidden?: boolean;
    }[];
}

interface LayoutGridState {
    size: keyof LayoutGridPoints;
}

export default class LayoutGrid extends React.PureComponent<LayoutGridProps, LayoutGridState> {
    static defaultProps: Partial<LayoutGridProps> = {
        cols: {
            l: 8,
            m: 4,
            s: 2,
        },
        breakpoints: {
            l: 1600,
            m: 900,
            s: 0,
        },
    };

    mediaQueries: {
        s: MediaQueryList;
        m: MediaQueryList;
        l: MediaQueryList;
    };

    constructor(props: LayoutGridProps) {
        super(props);
        this.state = {
            size: "s",
        };
        this.mediaQueries = {
            l: window.matchMedia(`(min-width: ${this.props.breakpoints.l ?? LayoutGrid.defaultProps.breakpoints.l}px)`),
            m: window.matchMedia(`(min-width: ${this.props.breakpoints.m ?? LayoutGrid.defaultProps.breakpoints.m}px)`),
            s: window.matchMedia(`(min-width: ${this.props.breakpoints.s ?? LayoutGrid.defaultProps.breakpoints.s}px)`),
        };
    }

    componentDidMount() {
        this.handleResize();
        this.mediaQueries.l.addEventListener("change", this.handleResize.bind(this));
        this.mediaQueries.m.addEventListener("change", this.handleResize.bind(this));
        this.mediaQueries.s.addEventListener("change", this.handleResize.bind(this));
    }

    componentWillUnmount() {
        this.mediaQueries.l.removeEventListener("change", this.handleResize.bind(this));
        this.mediaQueries.m.removeEventListener("change", this.handleResize.bind(this));
        this.mediaQueries.s.removeEventListener("change", this.handleResize.bind(this));
    }

    handleResize() {
        const l = this.mediaQueries.l.matches;
        const m = this.mediaQueries.m.matches;
        const s = this.mediaQueries.s.matches;
        this.setState({
            size: l ? "l" : m ? "m" : s ? "s" : "s",
        });
    }

    render(): React.ReactNode {
        return (
            <div
                className="layoutGrid"
                style={{
                    gridTemplateColumns: `repeat(${this.props.cols[this.state.size]}, 1fr)`,
                }}
            >
                {this.props.children.map((child) => {
                    if (!child || child.hidden) {
                        return null;
                    }
                    const cols = { ...this.props.cols, ...(child.cols ?? {}) };
                    const rows = { s: 1, m: 1, l: 1, ...(child.rows ?? {}) };
                    const style: CSSProperties = {
                        gridColumn: child.x?.[this.state.size]
                            ? `${child.x[this.state.size]} / span ${cols[this.state.size]}`
                            : `span ${cols[this.state.size]}`,
                        gridRow: child.y?.[this.state.size]
                            ? `${child.y[this.state.size]} / span ${rows[this.state.size]}`
                            : `span ${rows[this.state.size]}`,
                    };
                    return (
                        <div key={child.id} id={`layoutGrid-${child.id}`} className="layoutGrid-item" style={style}>
                            {child.element}
                        </div>
                    );
                })}
            </div>
        );
    }
}
