import type React from "react";

interface MailToProps extends React.HTMLProps<HTMLAnchorElement> {
    email: string;
}

export default function MailTo({ email, children, ...props }: MailToProps) {
    const handleClick = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();

        const emailWindow = window.open(e.currentTarget.href, "mail");

        // if content loads in the opened window, it must be WebMail
        let isWebMail = false;
        ["DomContentLoaded", "load", "beforeunload", "unload"].forEach((loadEvent) =>
            emailWindow.addEventListener(loadEvent, () => (isWebMail = true))
        );

        await new Promise((resolve) => {
            setTimeout(resolve, 2000);
        });

        if (!isWebMail) {
            emailWindow.close();
        }
    };

    return (
        <a
            {...props}
            href={`mailto:${email}`}
            onClick={(e) => {
                void handleClick(e);
            }}
        >
            {children || email}
        </a>
    );
}
