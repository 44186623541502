import { ReactComponent as Disabled } from "@material-design-icons/svg/outlined/do_not_disturb_on.svg";
import { ReactComponent as NoAlert } from "@material-design-icons/svg/outlined/notifications.svg";

import { ReactComponent as Bank } from "@material-design-icons/svg/filled/account_balance.svg";
import { ReactComponent as Add } from "@material-design-icons/svg/filled/add.svg";
import { ReactComponent as Target } from "@material-design-icons/svg/filled/adjust.svg";
import { ReactComponent as Click } from "@material-design-icons/svg/filled/ads_click.svg";
import { ReactComponent as Apps } from "@material-design-icons/svg/filled/apps.svg";
import { ReactComponent as ArrowDown } from "@material-design-icons/svg/filled/arrow_downward.svg";
import { ReactComponent as ArrowUp } from "@material-design-icons/svg/filled/arrow_upward.svg";
import { ReactComponent as Article } from "@material-design-icons/svg/filled/article.svg";
import { ReactComponent as Assignment } from "@material-design-icons/svg/filled/assignment.svg";
import { ReactComponent as Blur } from "@material-design-icons/svg/filled/blur_on.svg";
import { ReactComponent as Bookmark } from "@material-design-icons/svg/filled/bookmark.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/filled/chevron_right.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/filled/close.svg";
import { ReactComponent as Comparison } from "@material-design-icons/svg/filled/compare.svg";
import { ReactComponent as CompareArrows } from "@material-design-icons/svg/filled/compare_arrows.svg";
import { ReactComponent as Support } from "@material-design-icons/svg/filled/contact_support.svg";
import { ReactComponent as DataObject } from "@material-design-icons/svg/filled/data_object.svg";
import { ReactComponent as DateRange } from "@material-design-icons/svg/filled/date_range.svg";
import { ReactComponent as Delete } from "@material-design-icons/svg/filled/delete.svg";
import { ReactComponent as Devices } from "@material-design-icons/svg/filled/devices.svg";
import { ReactComponent as Download } from "@material-design-icons/svg/filled/download.svg";
import { ReactComponent as Edit } from "@material-design-icons/svg/filled/edit.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/filled/error.svg";
import { ReactComponent as ChevronDown } from "@material-design-icons/svg/filled/expand_more.svg";
import { ReactComponent as StatusCheck } from "@material-design-icons/svg/filled/fact_check.svg";
import { ReactComponent as Filter } from "@material-design-icons/svg/filled/filter_alt.svg";
import { ReactComponent as FilterOff } from "@material-design-icons/svg/filled/filter_alt_off.svg";
import { ReactComponent as Flare } from "@material-design-icons/svg/filled/flare.svg";
import { ReactComponent as Folder } from "@material-design-icons/svg/filled/folder.svg";
import { ReactComponent as Group } from "@material-design-icons/svg/filled/group.svg";
import { ReactComponent as GroupAdd } from "@material-design-icons/svg/filled/group_add.svg";
import { ReactComponent as Help } from "@material-design-icons/svg/filled/help.svg";
import { ReactComponent as History } from "@material-design-icons/svg/filled/history.svg";
import { ReactComponent as Home } from "@material-design-icons/svg/filled/home.svg";
import { ReactComponent as Metrics } from "@material-design-icons/svg/filled/insights.svg";
import { ReactComponent as Label } from "@material-design-icons/svg/filled/label.svg";
import { ReactComponent as BarChart } from "@material-design-icons/svg/filled/leaderboard.svg";
import { ReactComponent as List } from "@material-design-icons/svg/filled/list.svg";
import { ReactComponent as Lock } from "@material-design-icons/svg/filled/lock.svg";
import { ReactComponent as SearchList } from "@material-design-icons/svg/filled/manage_search.svg";
import { ReactComponent as Menu } from "@material-design-icons/svg/filled/menu.svg";
import { ReactComponent as MoveDown } from "@material-design-icons/svg/filled/move_down.svg";
import { ReactComponent as Alert } from "@material-design-icons/svg/filled/notifications_active.svg";
import { ReactComponent as Percent } from "@material-design-icons/svg/filled/percent.svg";
import { ReactComponent as Person } from "@material-design-icons/svg/filled/person.svg";
import { ReactComponent as Phone } from "@material-design-icons/svg/filled/phone.svg";
import { ReactComponent as Camera } from "@material-design-icons/svg/filled/photo_camera.svg";
import { ReactComponent as Preview } from "@material-design-icons/svg/filled/preview.svg";
import { ReactComponent as PriceChange } from "@material-design-icons/svg/filled/price_change.svg";
import { ReactComponent as PriceCheck } from "@material-design-icons/svg/filled/price_check.svg";
import { ReactComponent as Globe } from "@material-design-icons/svg/filled/public.svg";
import { ReactComponent as Quote } from "@material-design-icons/svg/filled/request_quote.svg";
import { ReactComponent as Rule } from "@material-design-icons/svg/filled/rule.svg";
import { ReactComponent as Save } from "@material-design-icons/svg/filled/save.svg";
import { ReactComponent as Schedule } from "@material-design-icons/svg/filled/schedule.svg";
import { ReactComponent as Science } from "@material-design-icons/svg/filled/science.svg";
import { ReactComponent as Search } from "@material-design-icons/svg/filled/search.svg";
import { ReactComponent as SearchOff } from "@material-design-icons/svg/filled/search_off.svg";
import { ReactComponent as Settings } from "@material-design-icons/svg/filled/settings.svg";
import { ReactComponent as Ratio } from "@material-design-icons/svg/filled/short_text.svg";
import { ReactComponent as Sort } from "@material-design-icons/svg/filled/sort.svg";
import { ReactComponent as Sync } from "@material-design-icons/svg/filled/sync.svg";
import { ReactComponent as Check } from "@material-design-icons/svg/filled/task_alt.svg";
import { ReactComponent as Text } from "@material-design-icons/svg/filled/text_fields.svg";
import { ReactComponent as Bulb } from "@material-design-icons/svg/filled/tips_and_updates.svg";
import { ReactComponent as TrendDown } from "@material-design-icons/svg/filled/trending_down.svg";
import { ReactComponent as TrendFlat } from "@material-design-icons/svg/filled/trending_flat.svg";
import { ReactComponent as TrendUp } from "@material-design-icons/svg/filled/trending_up.svg";
import { ReactComponent as Upload } from "@material-design-icons/svg/filled/upload.svg";
import { ReactComponent as Video } from "@material-design-icons/svg/filled/videocam.svg";
import { ReactComponent as Visibility } from "@material-design-icons/svg/filled/visibility.svg";

// Find new icons here:
// https://fonts.google.com/icons?selected=Material+Icons

export type SvgComponent =
    | React.ComponentClass<React.SVGProps<SVGElement>, unknown>
    | React.FC<React.SVGProps<SVGSVGElement>>;

function wrapIcon(iconClass: SvgComponent): SvgComponent {
    iconClass.defaultProps = {
        fill: "currentColor",
        width: 18,
        height: 18,
    };
    return iconClass;
}

const Icon = {
    Error: wrapIcon(Error),
    Settings: wrapIcon(Settings),
    Help: wrapIcon(Help),
    Close: wrapIcon(Close),
    Search: wrapIcon(Search),
    ChevronDown: wrapIcon(ChevronDown),
    ChevronRight: wrapIcon(ChevronRight),
    Folder: wrapIcon(Folder),
    DateRange: wrapIcon(DateRange),
    Add: wrapIcon(Add),
    Globe: wrapIcon(Globe),
    Check: wrapIcon(Check),
    TrendUp: wrapIcon(TrendUp),
    TrendDown: wrapIcon(TrendDown),
    TrendFlat: wrapIcon(TrendFlat),
    List: wrapIcon(List),
    Person: wrapIcon(Person),
    Group: wrapIcon(Group),
    Support: wrapIcon(Support),
    Phone: wrapIcon(Phone),
    Menu: wrapIcon(Menu),
    NoAlert: wrapIcon(NoAlert),
    Alert: wrapIcon(Alert),
    Filter: wrapIcon(Filter),
    Sort: wrapIcon(Sort),
    Edit: wrapIcon(Edit),
    Delete: wrapIcon(Delete),
    Assignment: wrapIcon(Assignment),
    SearchOff: wrapIcon(SearchOff),
    BarChart: wrapIcon(BarChart),
    Text: wrapIcon(Text),
    Click: wrapIcon(Click),
    Bank: wrapIcon(Bank),
    Target: wrapIcon(Target),
    Camera: wrapIcon(Camera),
    Save: wrapIcon(Save),
    Label: wrapIcon(Label),
    Lock: wrapIcon(Lock),
    PriceChange: wrapIcon(PriceChange),
    PriceCheck: wrapIcon(PriceCheck),
    FilterOff: wrapIcon(FilterOff),
    Metrics: wrapIcon(Metrics),
    Upload: wrapIcon(Upload),
    Download: wrapIcon(Download),
    Percent: wrapIcon(Percent),
    Ratio: wrapIcon(Ratio),
    StatusCheck: wrapIcon(StatusCheck),
    Devices: wrapIcon(Devices),
    DataObject: wrapIcon(DataObject),
    Sync: wrapIcon(Sync),
    Home: wrapIcon(Home),
    Article: wrapIcon(Article),
    Quote: wrapIcon(Quote),
    Comparison: wrapIcon(Comparison),
    Apps: wrapIcon(Apps),
    GroupAdd: wrapIcon(GroupAdd),
    History: wrapIcon(History),
    Rule: wrapIcon(Rule),
    ArrowUp: wrapIcon(ArrowUp),
    ArrowDown: wrapIcon(ArrowDown),
    Bulb: wrapIcon(Bulb),
    SearchList: wrapIcon(SearchList),
    Schedule: wrapIcon(Schedule),
    Preview: wrapIcon(Preview),
    Science: wrapIcon(Science),
    Bookmark: wrapIcon(Bookmark),
    CompareArrows: wrapIcon(CompareArrows),
    Video: wrapIcon(Video),
    MoveDown: wrapIcon(MoveDown),
    Blur: wrapIcon(Blur),
    Flare: wrapIcon(Flare),
    Disabled: wrapIcon(Disabled),
    Visibility: wrapIcon(Visibility),
};

export default Icon;
