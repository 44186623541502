import { type State } from "@/reducers/domain";
import { rubyService } from "@/services";
import cloneDeep from "lodash/cloneDeep";
import moment from "moment";
import { type Dispatch, type MiddlewareAPI } from "redux";
import { type ActionType } from "../actions/ActionType";

window["redboxDebug"] = (enable = true) => {
    localStorage.setItem("debug", String(enable));
};

export const loggerMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: ActionType) => {
    const debug = localStorage.getItem("debug") === "true";
    debug && console.group(action.type);
    debug && console.log("dispatching", cloneDeep(action));
    const result = next(action);
    debug && console.log("next state", store.getState());
    debug && console.groupEnd();
    return result;
};

export const exceptionMiddleware = (_store: MiddlewareAPI) => (next: Dispatch) => (action: ActionType) => {
    try {
        return next(action);
    } catch (err) {
        console.error("Caught an exception!", err);
        throw err;
    }
};

export const cypressMiddleware = (store: MiddlewareAPI) => (next: Dispatch) => (action: ActionType) => {
    const result = next(action);
    if (window["Cypress"]) {
        window["cyAppState"] = store.getState() as State;
    }
    return result;
};

export const consoleAccessMiddleware = (_store: MiddlewareAPI) => (next: Dispatch) => (action: ActionType) => {
    const debug = localStorage.getItem("debug") === "true";
    let exposed = false;
    if (debug && !exposed) {
        window["rubyService"] = rubyService;
        window["moment"] = moment;
        exposed = true;
    }
    return next(action);
};
