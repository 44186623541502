import {
    type RubyCreateTeamBody,
    type RubyCreateTeamInvite,
    type RubyCreateTierBody,
    type RubyIOSApp,
    type RubyOrder,
    type RubyPaymentCard,
    type RubyStripeCustomer,
    type RubyTeam,
    type RubyTeamHistoryRecordQuery,
    type RubyTeamId,
    type RubyTeamInvite,
    type RubyTeamMember,
    type RubyTeamOrganisation,
    type RubyTeamRecord,
    type RubyTier,
    type RubyUpdateTeamBody,
    type RubyUpdateTeamMemberBody,
    type RubyUser,
} from "../services/backend/RubyData";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum TeamActionName {
    LIST_TEAMS = "LIST_TEAMS",
    CREATE_TEAM = "CREATE_TEAM",
    UPDATE_TEAM = "UPDATE_TEAM",
    DELETE_TEAM = "DELETE_TEAM",

    GET_TEAM_PAYMENT_DETAILS = "GET_TEAM_PAYMENT_DETAILS",
    UPDATE_TEAM_PAYMENT_DETAILS = "UPDATE_TEAM_PAYMENT_DETAILS",

    LIST_TIERS = "LIST_TIERS",
    CREATE_TIER = "CREATE_TIER",
    EDIT_TIER = "EDIT_TIER",
    DELETE_TIER = "DELETE_TIER",

    LIST_TEAM_INVITES = "LIST_TEAM_INVITES",
    CREATE_TEAM_INVITE = "CREATE_TEAM_INVITE",
    DELETE_TEAM_INVITE = "DELETE_TEAM_INVITE",

    LIST_TEAM_MEMBERS = "LIST_TEAM_MEMBERS",
    UPDATE_TEAM_MEMBER = "UPDATE_TEAM_MEMBER",
    DELETE_TEAM_MEMBER = "DELETE_TEAM_MEMBER",

    LIST_TEAM_APPS = "LIST_TEAM_APPS",

    AUTH_WITH_ASA = "AUTH_WITH_ASA",
    LIST_TEAM_ORGANISATIONS = "LIST_TEAM_ORGANISATIONS",
    REPORT_ASA_AUTH_CREDS = "REPORT_ASA_AUTH_CREDS",

    LIST_TEAM_PAYMENT_METHODS = "LIST_TEAM_PAYMENT_METHODS",
    ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD",
    DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD",
    SET_DEFAULT_PAYMENT_METHOD = "SET_DEFAULT_PAYMENT_METHOD",

    LIST_TEAM_HISTORY_RECORDS = "LIST_TEAM_HISTORY_RECORDS",

    LIST_ORDERS = "LIST_ORDERS",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace TeamActions {
    export type ListTeamsAction = RequestAction<TeamActionName.LIST_TEAMS, void, RubyTeam[]>;
    export type CreateTeamAction = RequestAction<
        TeamActionName.CREATE_TEAM,
        RubyCreateTeamBody,
        {
            team: RubyTeam;
            paymentDetails: RubyStripeCustomer;
        }
    >;
    export type UpdateTeamAction = RequestAction<
        TeamActionName.UPDATE_TEAM,
        { teamId: RubyTeamId; update: RubyUpdateTeamBody },
        RubyTeam
    >;
    export type DeleteTeamAction = RequestAction<TeamActionName.DELETE_TEAM, RubyTeamId, void>;

    export type GetTeamPaymentDetailsAction = RequestAction<
        TeamActionName.GET_TEAM_PAYMENT_DETAILS,
        RubyTeamId,
        RubyStripeCustomer
    >;
    export type UpdateTeamPaymentDetailsAction = RequestAction<
        TeamActionName.UPDATE_TEAM_PAYMENT_DETAILS,
        {
            teamId: RubyTeamId;
            paymentDetails: RubyStripeCustomer;
        },
        RubyStripeCustomer
    >;

    export type ListTiersAction = RequestAction<TeamActionName.LIST_TIERS, void, RubyTier[]>;
    export type CreateTierAction = RequestAction<TeamActionName.CREATE_TIER, RubyCreateTierBody, RubyTier>;
    export type EditTierAction = RequestAction<TeamActionName.EDIT_TIER, RubyTier, RubyTier>;
    export type DeleteTierAction = RequestAction<TeamActionName.DELETE_TIER, RubyTier["id"], void>;

    export type ListTeamInvitesAction = RequestAction<TeamActionName.LIST_TEAM_INVITES, RubyTeamId, RubyTeamInvite[]>;
    export type CreateTeamInviteAction = RequestAction<
        TeamActionName.CREATE_TEAM_INVITE,
        RubyCreateTeamInvite,
        RubyTeamInvite
    >;
    export type DeleteTeamInviteAction = RequestAction<
        TeamActionName.DELETE_TEAM_INVITE,
        { inviteId: RubyTeamInvite["id"]; teamId: RubyTeamId },
        void
    >;

    export type ListTeamMembersAction = RequestAction<TeamActionName.LIST_TEAM_MEMBERS, RubyTeamId, RubyTeamMember[]>;
    export type DeleteTeamMemberAction = RequestAction<
        TeamActionName.DELETE_TEAM_MEMBER,
        { userId: RubyUser["id"]; teamId: RubyTeamId },
        void
    >;
    export type UpdateTeamMemberAction = RequestAction<
        TeamActionName.UPDATE_TEAM_MEMBER,
        {
            userId: RubyUser["id"];
            teamId: RubyTeamId;
            update: RubyUpdateTeamMemberBody;
        },
        RubyTeamMember
    >;

    export type AuthWithASAAction = Action<TeamActionName.AUTH_WITH_ASA, { teamId: RubyTeamId; nextPage?: string }>;
    export type ListTeamOrganisationsAction = RequestAction<
        TeamActionName.LIST_TEAM_ORGANISATIONS,
        { teamId: RubyTeamId },
        RubyTeamOrganisation[]
    >;

    export type ReportASAAuthCredsAction = RequestAction<
        TeamActionName.REPORT_ASA_AUTH_CREDS,
        void,
        {
            teamId: RubyTeamId;
            organisations: RubyTeamOrganisation[];
        }
    >;
    export type ListTeamAppsAction = RequestAction<TeamActionName.LIST_TEAM_APPS, RubyTeamId, RubyIOSApp[]>;
    export type ListTeamPaymentMethodsAction = RequestAction<
        TeamActionName.LIST_TEAM_PAYMENT_METHODS,
        RubyTeamId,
        RubyPaymentCard[]
    >;
    export type AddPaymentMethodAction = RequestAction<TeamActionName.ADD_PAYMENT_METHOD, RubyTeamId, RubyPaymentCard>;
    export type DeletePaymentMethodAction = RequestAction<
        TeamActionName.DELETE_PAYMENT_METHOD,
        {
            teamId: RubyTeamId;
            paymentMethodId: RubyPaymentCard["id"];
        },
        void
    >;
    export type SetDefaultPaymentMethodAction = RequestAction<
        TeamActionName.SET_DEFAULT_PAYMENT_METHOD,
        {
            teamId: RubyTeamId;
            paymentMethodId: RubyPaymentCard["id"];
        },
        void
    >;

    export type ListTeamHistoryRecordsAction = RequestAction<
        TeamActionName.LIST_TEAM_HISTORY_RECORDS,
        RubyTeamHistoryRecordQuery,
        RubyTeamRecord[]
    >;

    export type ListOrdersAction = RequestAction<TeamActionName.LIST_ORDERS, { teamId: RubyTeamId }, RubyOrder[]>;
}

export type TeamActionType =
    | TeamActions.ListTeamsAction
    | TeamActions.CreateTeamAction
    | TeamActions.UpdateTeamAction
    | TeamActions.DeleteTeamAction
    | TeamActions.GetTeamPaymentDetailsAction
    | TeamActions.UpdateTeamPaymentDetailsAction
    | TeamActions.ListTiersAction
    | TeamActions.CreateTierAction
    | TeamActions.EditTierAction
    | TeamActions.DeleteTierAction
    | TeamActions.ListTeamMembersAction
    | TeamActions.ListTeamInvitesAction
    | TeamActions.CreateTeamInviteAction
    | TeamActions.DeleteTeamMemberAction
    | TeamActions.DeleteTeamInviteAction
    | TeamActions.UpdateTeamMemberAction
    | TeamActions.AuthWithASAAction
    | TeamActions.ListTeamOrganisationsAction
    | TeamActions.ReportASAAuthCredsAction
    | TeamActions.ListTeamAppsAction
    | TeamActions.ListTeamPaymentMethodsAction
    | TeamActions.AddPaymentMethodAction
    | TeamActions.DeletePaymentMethodAction
    | TeamActions.SetDefaultPaymentMethodAction
    | TeamActions.ListTeamHistoryRecordsAction
    | TeamActions.ListOrdersAction;

export const teamsActions = {
    list: requestActions<TeamActions.ListTeamsAction>(TeamActionName.LIST_TEAMS),
    create: requestActions<TeamActions.CreateTeamAction>(TeamActionName.CREATE_TEAM),
    update: requestActions<TeamActions.UpdateTeamAction>(TeamActionName.UPDATE_TEAM),
    getPaymentDetails: requestActions<TeamActions.GetTeamPaymentDetailsAction>(TeamActionName.GET_TEAM_PAYMENT_DETAILS),
    updatePaymentDetails: requestActions<TeamActions.UpdateTeamPaymentDetailsAction>(
        TeamActionName.UPDATE_TEAM_PAYMENT_DETAILS
    ),
    delete: requestActions<TeamActions.DeleteTeamAction>(TeamActionName.DELETE_TEAM),
};

export const listTeamHistoryRecords = requestActions<TeamActions.ListTeamHistoryRecordsAction>(
    TeamActionName.LIST_TEAM_HISTORY_RECORDS
);

export const tiersActions = {
    list: requestActions<TeamActions.ListTiersAction>(TeamActionName.LIST_TIERS),
    create: requestActions<TeamActions.CreateTierAction>(TeamActionName.CREATE_TIER),
    edit: requestActions<TeamActions.EditTierAction>(TeamActionName.EDIT_TIER),
    delete: requestActions<TeamActions.DeleteTierAction>(TeamActionName.DELETE_TIER),
};

export const teamInvitesActions = {
    list: requestActions<TeamActions.ListTeamInvitesAction>(TeamActionName.LIST_TEAM_INVITES),
    create: requestActions<TeamActions.CreateTeamInviteAction>(TeamActionName.CREATE_TEAM_INVITE),
    delete: requestActions<TeamActions.DeleteTeamInviteAction>(TeamActionName.DELETE_TEAM_INVITE),
};

export const teamMembersActions = {
    list: requestActions<TeamActions.ListTeamMembersAction>(TeamActionName.LIST_TEAM_MEMBERS),
    update: requestActions<TeamActions.UpdateTeamMemberAction>(TeamActionName.UPDATE_TEAM_MEMBER),
    delete: requestActions<TeamActions.DeleteTeamMemberAction>(TeamActionName.DELETE_TEAM_MEMBER),
};

export const teamOrganisationActions = {
    list: requestActions<TeamActions.ListTeamOrganisationsAction>(TeamActionName.LIST_TEAM_ORGANISATIONS),
    authWithASA: (teamId: RubyTeamId, nextPage?: string): TeamActions.AuthWithASAAction => ({
        type: TeamActionName.AUTH_WITH_ASA,
        payload: {
            teamId,
            nextPage,
        },
    }),
    reportASA: requestActions<TeamActions.ReportASAAuthCredsAction>(TeamActionName.REPORT_ASA_AUTH_CREDS),
};

export const teamAppActions = {
    list: requestActions<TeamActions.ListTeamAppsAction>(TeamActionName.LIST_TEAM_APPS),
};

export const teamPaymentMethodsActions = {
    list: requestActions<TeamActions.ListTeamPaymentMethodsAction>(TeamActionName.LIST_TEAM_PAYMENT_METHODS),
    add: requestActions<TeamActions.AddPaymentMethodAction>(TeamActionName.ADD_PAYMENT_METHOD),
    delete: requestActions<TeamActions.DeletePaymentMethodAction>(TeamActionName.DELETE_PAYMENT_METHOD),
    setDefault: requestActions<TeamActions.SetDefaultPaymentMethodAction>(TeamActionName.SET_DEFAULT_PAYMENT_METHOD),
};

export const teamInvoiceActions = {
    listOrders: requestActions<TeamActions.ListOrdersAction>(TeamActionName.LIST_ORDERS),
};
