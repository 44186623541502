import { createSelector } from "@reduxjs/toolkit";
import { type AsyncData, type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyTeamRole,
    type RubyIOSApp,
    type RubyPaymentCard,
    type RubyStripeCustomer,
    type RubyTeam,
    type RubyTeamHistoryRecordQuery,
    type RubyTeamId,
    type RubyTeamInvite,
    type RubyTeamMember,
    type RubyTeamOrganisation,
    type RubyTeamRecord,
    type RubyUser,
} from "../services/backend/RubyData";
import { aggregateAsyncData } from "../utilities/requests";
import { matchHistoryRecord } from "./matchers";

export function selectTeams(state: State): AsyncData<RubyTeam[]> {
    return state.team.teams ?? initialAsyncDataState<RubyTeam[]>(null);
}

export const selectTeam = createSelector([selectTeams, (_state, teamId: RubyTeamId) => teamId], (teams, teamId) => {
    if (!teams.success) {
        return { ...teams, data: null };
    }
    const team = teams.data.find((t) => t.id === teamId);
    if (!team) {
        return initialAsyncDataState<RubyTeam>(null);
    }
    return {
        ...teams,
        data: team,
    };
});

export function selectTeamMembers(state: State, teamId: RubyTeamId): AsyncData<RubyTeamMember[]> {
    return state.team.members[teamId] ?? initialAsyncDataState<RubyTeamMember[]>(null);
}

export function selectTeamOrganisations(state: State, teamId: RubyTeamId): AsyncData<RubyTeamOrganisation[]> {
    return state.team.organisations[teamId] ?? initialAsyncDataState<RubyTeamOrganisation[]>(null);
}

export const selectTeamMember = createSelector(
    [selectTeamMembers, (_state, _teamId, userId: RubyUser["id"]) => userId],
    (members, userId) => {
        if (!members.success) {
            return { ...members, data: null };
        }
        const member = members.data?.find((member) => member.user.id === userId);
        if (!member) {
            return initialAsyncDataState();
        }
        return {
            ...members,
            data: member,
        };
    }
);

export function selectTeamInvites(state: State, teamId: RubyTeamId): AsyncData<RubyTeamInvite[]> {
    return state.team.invites[teamId] ?? initialAsyncDataState<RubyTeamInvite[]>(null);
}

export const selectUsersTeamRole = createSelector(
    [selectTeamMembers, (state: State) => state.user.userId],
    (members, userId) => {
        if (!members.success) {
            return {
                ...members,
                data: null,
            };
        }
        const userMember = members.data.find((member) => member.user.id === userId);

        // Default to a Reader if the user is not in the team as they clearly have read permission to have called the API.
        // This likely indicates an admin but this is a safer default.
        const role = userMember?.role ?? RubyTeamRole.READER;

        return {
            ...members,
            data: role,
        };
    }
);

export function selectTeamHistoryRecords(state: State, query: RubyTeamHistoryRecordQuery): AsyncData<RubyTeamRecord[]> {
    const requests = state.team.historyRecords[query.teamId];
    const request = requests?.find(matchHistoryRecord(query));
    return (
        request ?? {
            ...initialAsyncDataState(),
            ...query,
        }
    );
}

export function selectUserIsOwner(state: State, teamId: RubyTeamId): boolean {
    const role = selectUsersTeamRole(state, teamId);
    if (!role.success) {
        return false;
    }
    return role.data === RubyTeamRole.OWNER;
}

export function selectUserIsWriter(state: State, teamId: RubyTeamId): boolean {
    const role = selectUsersTeamRole(state, teamId);
    if (!role.success) {
        return false;
    }
    return role.data === RubyTeamRole.WRITER;
}

export function selectUserIsReader(state: State, teamId: RubyTeamId): boolean {
    const role = selectUsersTeamRole(state, teamId);
    if (!role.success) {
        return false;
    }
    return role.data === RubyTeamRole.READER;
}

export const selectTeamOwnedApps = createSelector(
    [(state: State, teamId: RubyTeamId) => state.team.ownedApps[teamId], (state: State) => state.campaigns.apps],
    (teamAppsReq, appData): AsyncData<RubyIOSApp[]> => {
        if (!teamAppsReq?.success) {
            return {
                ...teamAppsReq,
                data: null,
            };
        }
        if (teamAppsReq.data.length < 1) {
            return {
                ...teamAppsReq,
                data: [],
            };
        }
        const apps = teamAppsReq.data.map((appId) => appData[appId]);
        return aggregateAsyncData(...apps);
    }
);

export function selectTeamPaymentMethods(state: State, teamId: RubyTeamId): AsyncData<RubyPaymentCard[]> {
    return state.team.paymentMethods[teamId] ?? initialAsyncDataState();
}

export function selectPaymentDetails(state: State, teamId: RubyTeamId): AsyncData<RubyStripeCustomer> {
    return state.team.paymentDetails[teamId] ?? initialAsyncDataState();
}
