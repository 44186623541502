import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { windowResizeAction, windowScrollAction } from "../actions/metaActions";
import { type State } from "../reducers/domain";
import App, { type AppProps } from "./App";

const mapStateToProps = (state: State): Partial<AppProps> => ({
    flipperKey: state.meta.flipperKey,
    loginRestoring: state.user.loginIsRestoring,
    error: state.ui.error,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<AppProps> => ({
    onResize: (width: number, height: number) => dispatch(windowResizeAction(width, height)),
    onScroll: (scrollAmount: number) => dispatch(windowScrollAction(scrollAmount)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
