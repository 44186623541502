import { createSelector } from "@reduxjs/toolkit";
import isEqual from "lodash/isEqual";
import {
    type AsyncData,
    type EventAsyncData,
    type KeywordReport,
    type KeywordReportAsyncData,
    type ReportAsyncData,
    type State,
} from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyPurpose,
    type RubyCampaignId,
    type RubyEventQuery,
    type RubyEventSeriesQuery,
    type RubyIOSApp,
    type RubyKeywordRankQuery,
    type RubyKeywordReportQuery,
    type RubyMetricsReportGrouping,
    type RubyMetricsReportQuery,
    type RubyRegionsReportQuery,
    type RubySearchTermReportQuery,
    type RubyTeamId,
} from "../services/backend/RubyData";
import {
    type EventKeywordReport,
    type EventKeywordSummaryReport,
    type EventKeywordTemporalReport,
    type EventReport,
    type EventSummaryReport,
    type TemporalEventReport,
} from "../utilities/events";
import {
    aggregateMetrics,
    getKeywordSummaryFromRequest,
    type AppMetricsReport,
    type CampaignMetricsReport,
    type KeywordMetricsReportDetails,
    type MetricsReport,
    type PurposeMetricsReport,
    type RegionsReport,
    type TemporalKeywordMetricsReport,
    type TemporalMetricsReport,
} from "../utilities/metrics";
import { safeDivide } from "../utilities/number";
import { aggregateAsyncData } from "../utilities/requests";
import { isChannelWithPurposes } from "../utilities/types";
import { PRODUCTION } from "../utilities/vars";
import { selectCampaign, selectCampaignEvents, selectChannel, selectTeamEvents } from "./campaignSelectors";
import { selectTargetingKeywords } from "./keywordsSelectors";
import {
    matchEventQuery,
    matchKeywordRanking,
    matchKeywordSummary,
    matchKeywordsSummary,
    matchSearchTermsSummary,
} from "./matchers";

export const selectCampaignSummary = createSelector(
    [
        (state: State, query: RubyMetricsReportQuery) => state.reports.summary[query.teamId],
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (requests, query): ReportAsyncData<MetricsReport> => {
        const request = requests?.find(
            (req) =>
                req.campaignId === query.campaignId &&
                req.teamId === query.teamId &&
                isEqual(req.campaignRef, query.campaignRef) &&
                isEqual(req.country, query.country) &&
                req.from === query.from &&
                req.to === query.to
        );
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectCampaignSummaries = createSelector(
    [
        (state: State, query: RubyMetricsReportQuery) => state.reports.teamSummaries[query.teamId],
        (state: State, query: RubyMetricsReportQuery) => state.campaigns.teamCampaigns[query.teamId],
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (requests, campaignIds, query): ReportAsyncData<CampaignMetricsReport[]> => {
        const request = requests?.find(
            (req) =>
                // Specifically not checking campaignId (it should be null)
                req.teamId === query.teamId &&
                isEqual(req.campaignRef, query.campaignRef) &&
                isEqual(req.country, query.country) &&
                req.from === query.from &&
                req.to === query.to
        );
        if (campaignIds?.success && request?.success) {
            const reports: CampaignMetricsReport[] = campaignIds.data.map((campaignId) => {
                return {
                    ...aggregateMetrics([]),
                    ...request.data.find((r) => r.campaignId === campaignId),
                    campaignId,
                };
            });
            return {
                ...request,
                data: reports,
            };
        }
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

const selectTeamCampaignsApps = createSelector(
    [
        (state: State, teamId: RubyTeamId) => state.campaigns.teamCampaigns[teamId],
        (state: State) => state.campaigns.channels,
    ],
    (campaignIds, allChannels): AsyncData<Record<RubyCampaignId, RubyIOSApp["trackId"]>> => {
        const channels = campaignIds?.data?.map((campaignId) => allChannels[campaignId]) ?? [];

        if (channels.length < 1) {
            return {
                success: true,
                errorMessage: null,
                isRequesting: false,
                lastUpdated: Date.now(),
                data: {},
            };
        }

        const channelReq = aggregateAsyncData(...channels);
        if (!channelReq.success) {
            return {
                ...channelReq,
                data: null,
            };
        }

        const appMap: Record<RubyCampaignId, RubyIOSApp["trackId"]> =
            channels?.reduce((map: Record<RubyCampaignId, RubyIOSApp["trackId"]>, channel) => {
                map[channel.data?.campaignId] = channel.data?.applicationRef;
                return map;
            }, {}) ?? {};

        return {
            ...channelReq,
            data: appMap,
        };
    }
);

export const selectAppSummaries = createSelector(
    [
        selectCampaignSummaries,
        (state: State, query: RubyMetricsReportQuery) =>
            state.campaigns.teamCampaigns[query.teamId] ?? initialAsyncDataState(),
        (state: State, query: RubyMetricsReportQuery) => selectTeamCampaignsApps(state, query.teamId),
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (summaries, campaignIds, teamApps, query): ReportAsyncData<AppMetricsReport[]> => {
        if (!teamApps.success) {
            return {
                ...teamApps,
                ...query,
                data: null,
            };
        }

        const appReports = Object.entries(teamApps.data).reduce((idMap, [campaignId, appRef]) => {
            const report =
                summaries?.data?.find((r) => r.campaignId === parseInt(campaignId, 10)) ?? aggregateMetrics([]);
            if (Array.isArray(idMap[appRef])) {
                idMap[appRef].push(report);
            } else {
                idMap[appRef] = [report];
            }
            return idMap;
        }, {} as Record<RubyIOSApp["trackId"], MetricsReport[]>);

        if (campaignIds.success && campaignIds.data.length < 1) {
            return {
                ...campaignIds,
                ...query,
                data: [],
            };
        }

        // WARNING: this request object should not be used to check if a request should be made
        return {
            ...aggregateAsyncData(summaries, campaignIds, teamApps),
            ...query,
            data: Object.entries(appReports).map(([appId, metrics]) => ({
                appId: parseInt(appId, 10),
                ...aggregateMetrics(metrics),
            })),
        };
    }
);

export const selectCampaignSeries = createSelector(
    [
        (state: State, query: RubyMetricsReportQuery) => state.reports.series[query.teamId],
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (requests, query): ReportAsyncData<TemporalMetricsReport[]> => {
        const request = requests?.find(
            (req) =>
                req.campaignId === query.campaignId &&
                req.teamId === query.teamId &&
                isEqual(req.campaignRef, query.campaignRef) &&
                isEqual(req.country, query.country) &&
                req.from === query.from &&
                req.to === query.to
        );
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectKeywordsSummary = createSelector(
    [
        (state: State, query: RubyMetricsReportQuery) => state.reports.keywordsSummary[query.teamId],
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (requests, query): ReportAsyncData<RubyMetricsReportGrouping[]> => {
        const request = requests?.find(matchKeywordsSummary(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectKeywordsSummaryDetails = createSelector(
    [
        selectKeywordsSummary,
        (state: State, query: RubyMetricsReportQuery) =>
            selectTargetingKeywords(state, query.campaignId, undefined, true),
        (_state: State, query: RubyMetricsReportQuery, blacklist?: RubyPurpose[]) => [query, blacklist] as const,
    ],
    (keywordsSummary, targetingKeywords, [query, blacklist]): ReportAsyncData<KeywordMetricsReportDetails[]> => {
        const filteredKeywords = getKeywordSummaryFromRequest(keywordsSummary, targetingKeywords, blacklist);
        if (!query.country?.length) {
            return filteredKeywords;
        }

        const filteredByCountry = filteredKeywords?.data?.filter((kw) =>
            kw.targeting.countries.some((country) => query.country.includes(country))
        );
        return { ...filteredKeywords, data: filteredByCountry };
    }
);

export const selectKeywordReport = createSelector(
    [
        (state: State, query: RubyKeywordReportQuery) => state.reports.keywordSummary[query.teamId],
        (_s: State, query: RubyKeywordReportQuery) => query,
    ],
    (requests, query): KeywordReportAsyncData<KeywordReport> => {
        const request = requests?.find(matchKeywordSummary(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectKeywordSeries = createSelector(
    [
        (state: State, query: RubyKeywordReportQuery) => state.reports.keywordSeries[query.teamId],
        (_s: State, query: RubyKeywordReportQuery) => query,
    ],
    (requests, query): KeywordReportAsyncData<TemporalKeywordMetricsReport[]> => {
        const request = requests?.find(matchKeywordSummary(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectKeywordRankSeries = createSelector(
    [
        (state: State, query: RubyKeywordRankQuery) => state.reports.keywordRankSeries[query.teamId],
        (_s: State, query: RubyKeywordRankQuery) => query,
    ],
    (requests, query) => {
        const request = requests?.find(matchKeywordRanking(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectCampaignRegionsSummary = createSelector(
    [
        (state: State, query: RubyRegionsReportQuery) => state.reports.regions[query.teamId],
        (_s: State, query: RubyRegionsReportQuery) => query,
    ],
    (requests, query): ReportAsyncData<RegionsReport> => {
        const request = requests?.find(
            (req) =>
                req.campaignId === query.campaignId &&
                req.teamId === query.teamId &&
                isEqual(req.campaignRef, query.campaignRef) &&
                req.from === query.from &&
                req.to === query.to
        );
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectTeamEventsFilter = createSelector(
    [selectTeamEvents, (state: State) => state.ui.activeEvent],
    (events, currentFilter): string | undefined => {
        if (!events.data) {
            return;
        }
        if (events.data.includes(currentFilter)) {
            return currentFilter;
        } else if (events.data.length > 0) {
            return events.data[0];
        }
    }
);

export const selectEventSummary = createSelector(
    [
        (state: State, query: RubyEventQuery) => state.reports.eventsSummary[query.teamId],
        (_s: State, query: RubyEventQuery) => query,
    ],
    (requests, query): EventAsyncData<EventReport> => {
        const request = requests?.find(matchEventQuery(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectEventListSummary = createSelector(
    [
        (state: State, query: RubyEventQuery) => state.reports.eventListSummary[query.teamId],
        (_s: State, query: RubyEventQuery) => query,
    ],
    (requests, query): EventAsyncData<EventSummaryReport[]> => {
        const request = requests?.find(matchEventQuery(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectEventKeywords = createSelector(
    [
        (state: State, query: RubyEventQuery) => state.reports.eventsKeywords[query.teamId],
        (_s: State, query: RubyEventQuery) => query,
    ],
    (requests, query): EventAsyncData<EventKeywordReport[]> => {
        const request = requests?.find(matchEventQuery(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectEventSeriesKeywords = createSelector(
    [
        (state: State, query: RubyEventSeriesQuery) => state.reports.eventsKeywordsSeries[query.teamId],
        (_s: State, query: RubyEventSeriesQuery) => query,
    ],
    (requests, query): EventAsyncData<EventKeywordTemporalReport[]> => {
        const request = requests?.find(matchEventQuery(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectEventSeries = createSelector(
    [
        (state: State, query: RubyEventQuery) => state.reports.eventsSeries[query.teamId],
        (_s: State, query: RubyEventQuery) => query,
    ],
    (requests, query): EventAsyncData<TemporalEventReport[]> => {
        const request = requests?.find(matchEventQuery(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export type TemporalNamedEventReport = TemporalEventReport & { name: string };
export const selectEventsSeries = createSelector(
    [
        (state: State, query: Omit<RubyEventQuery, "name">) => state.reports.eventsSeries[query.teamId],
        (_s: State, query: Omit<RubyEventQuery, "name">) => query,
        (state: State, query: Omit<RubyEventQuery, "name">) => selectCampaignEvents(state, query.campaignId),
    ],
    (seriesStore, query, eventList): AsyncData<TemporalNamedEventReport[][]> => {
        if (!eventList.success) {
            return {
                ...eventList,
                data: null,
            };
        }

        const eventRequests = eventList.data.map((eventName) => {
            const matchedReport = seriesStore?.find(matchEventQuery({ ...query, name: eventName }));

            if (!matchedReport) {
                return {
                    ...initialAsyncDataState(),
                    ...query,
                    name: eventName,
                };
            }

            return {
                ...matchedReport,
                data:
                    matchedReport.data?.map((report: TemporalNamedEventReport) => ({
                        ...report,
                        name: eventName,
                    })) ?? [],
            };
        });

        return {
            ...aggregateAsyncData(...eventRequests),
        };
    }
);

export const selectEventKeywordSummary = createSelector(
    [
        selectEventKeywords,
        (state: State, { name: _, ...query }: RubyEventQuery) => selectKeywordsSummaryDetails(state, query),
        (_s: State, query: RubyEventQuery) => query,
    ],
    (eventKeywordsRequest, keywordsSummaryRequest, query): EventAsyncData<EventKeywordSummaryReport[]> => {
        const { data, ...request } = aggregateAsyncData(eventKeywordsRequest, keywordsSummaryRequest);

        if (request.success) {
            const [eventKeywords, keywordsSummary] = data;
            const keywordsSummaryLookup = keywordsSummary.reduce<Record<string, KeywordMetricsReportDetails>>(
                (acc, k) => {
                    acc[k.text] = k;
                    return acc;
                },
                {}
            );

            const aggregatedData = eventKeywords.map((eventKeyword) => {
                const keywordSummary = keywordsSummaryLookup[eventKeyword.keyword];
                if (PRODUCTION && keywordSummary === undefined) {
                    console.warn(
                        `Event (${query.name}) Keyword (${eventKeyword.keyword}) not found in summary data (${query.teamId}-${query.campaignId})`
                    );
                }
                return {
                    ...eventKeyword,
                    ...keywordSummary,
                    costPerEvent: safeDivide(keywordSummary?.spend, eventKeyword?.events),
                };
            });

            return {
                ...request,
                ...query,
                data: aggregatedData,
            };
        }

        return {
            ...initialAsyncDataState(),
            ...request,
            ...query,
        };
    }
);

export const selectPurposeSummaries = createSelector(
    [
        (state: State, query: RubyMetricsReportQuery) => state.reports.purposeSummaries[query.teamId],
        (state: State, query: RubyMetricsReportQuery) => selectCampaign(state, query.campaignId),
        (state: State, query: RubyMetricsReportQuery) => selectChannel(state, query.campaignId),
        (_s: State, query: RubyMetricsReportQuery) => query,
    ],
    (requests, campaign, channel, query): ReportAsyncData<PurposeMetricsReport[]> => {
        const request = requests?.find(
            (req) =>
                req.campaignId === query.campaignId &&
                req.teamId === query.teamId &&
                isEqual(req.campaignRef, query.campaignRef) &&
                isEqual(req.country, query.country) &&
                req.from === query.from &&
                req.to === query.to
        );
        if (request && request.success) {
            const purposes =
                isChannelWithPurposes(campaign.data?.channelType, channel.data) && channel?.data?.purposes
                    ? channel?.data?.purposes
                    : Object.values(RubyPurpose);

            const report: PurposeMetricsReport[] = purposes.map((purpose) => ({
                purpose,
                ...aggregateMetrics(request.data?.filter((r) => r.purpose === purpose) ?? []),
            }));

            return {
                ...request,
                data: report,
            };
        }
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);

export const selectSearchTermsSummary = createSelector(
    [
        (state: State, query: RubySearchTermReportQuery) => state.reports.searchTermsSummaries[query.teamId],
        (_s: State, query: RubySearchTermReportQuery) => query,
    ],
    (requests, query): ReportAsyncData<RubyMetricsReportGrouping[]> => {
        const request = requests?.find(matchSearchTermsSummary(query));
        return (
            request ?? {
                ...initialAsyncDataState(),
                ...query,
            }
        );
    }
);
