import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { teamsActions, tiersActions } from "../../../../../actions/teamActions";
import { type State } from "../../../../../reducers/domain";
import { type RubyCreateTeamBody } from "../../../../../services/backend/RubyData";
import CreateTeamComponent, { type CreateTeamComponentProps, type CreateTeamProps } from "./CreateTeamComponent";

const mapStateToProps = (state: State, props: CreateTeamProps): Partial<CreateTeamComponentProps> => ({
    ...props,
    createTeamRequest: state.team.createTeam,
    tiers: state.team.tiers,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<CreateTeamComponentProps> => ({
    createTeam: (details: RubyCreateTeamBody) => dispatch(teamsActions.create.request(details)),
    getTiers: () => dispatch(tiersActions.list.request()),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTeamComponent);
