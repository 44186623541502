import memoize from "lodash/memoize";
import { SUPPORTED_CURRENCIES, SUPPORTED_HOSTED_CURRENCIES } from "./vars";

import currencies from "../data/world-currencies.json";
import { roundToPlaces } from "./number";

export function getCurrencyData(currencyCode: string) {
    return currencies[currencyCode];
}

const getFormat = memoize(
    ({ currencyCode, includeFloat }: { currencyCode: string; includeFloat: boolean }) =>
        new Intl.NumberFormat(undefined, {
            style: "currency",
            currency: currencyCode,
            minimumFractionDigits: includeFloat ? 2 : 0,
            maximumFractionDigits: includeFloat ? 2 : 0,
        }),
    ({ currencyCode, includeFloat }: { currencyCode: string; includeFloat: boolean }) =>
        `${currencyCode}-${includeFloat ? "f" : "n"}`
);

export function currencyFormatter(currencyCode = "GBP", includeFloat = true) {
    includeFloat = isCurrencyFractional(currencyCode) && includeFloat;
    const formatter = getFormat({ currencyCode, includeFloat });
    return (value = 0) =>
        formatter
            .format(isNaN(value) ? 0 : value)
            .replace(/[a-z]+/gi, "")
            .replace(/-/, "\u2212");
}

export function formatCurrency(value = 0, currencyCode = "GBP", includeFloat = true): string {
    return currencyFormatter(currencyCode, includeFloat)(value);
}

export function getCurrencySymbol(currencyCode = "GBP"): string {
    return getCurrencyData(currencyCode).units.major.symbol;
}

export function isCurrencyFractional(currencyCode: string) {
    // Don't have a nice way to know this from a library, so just hard coded
    return currencyCode !== "JPY";
}

export function formatCurrencyValue(value = 0, currencyCode = "GBP", includeFloat = true): string {
    const symbol = getCurrencySymbol(currencyCode);
    const formattedValue = currencyFormatter(currencyCode, includeFloat)(value);
    return formattedValue.replace(symbol, "");
}

export function getCurrencyName(currencyCode = "GBP"): string {
    return `${getCurrencyData(currencyCode).name} (${getCurrencySymbol(currencyCode)})`;
}

export function parseCurrency(value: string | number, includeFloat = true): number {
    if (value === null || value === undefined) {
        return null as number;
    }
    if (typeof value !== "number") {
        value = value
            .replace(/[a-z]/gi, "")
            .replace(
                new RegExp(
                    `${Object.keys(currencies)
                        .map((code) => getCurrencySymbol(code))
                        .join("|")}`,
                    "gi"
                ),
                ""
            )
            .replace(getFormatCharacter("group"), "")
            .replace(getFormatCharacter("decimal"), ".");
        value = includeFloat ? parseFloat(parseFloat(value).toFixed(2)) : Math.round(parseFloat(value));
    } else if (!includeFloat) {
        value = Math.round(value);
    } else {
        value = parseFloat(value.toFixed(2));
    }
    if (isNaN(value)) {
        value = null as number;
    }
    return value;
}

function getFormatCharacter(type: Intl.NumberFormatPartTypes, opts?: Intl.NumberFormatOptions) {
    const formatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...opts,
    });
    const groups = formatter.formatToParts(1000000.12);
    const group = groups.find((part) => part.type === type);
    return (group && group.value) || "";
}

export function getSupportedCurrencies(hosted: boolean): string[] {
    if (hosted) {
        return SUPPORTED_HOSTED_CURRENCIES;
    }
    return SUPPORTED_CURRENCIES;
}

export function getSupportedCurrenciesAsOptions(hosted: boolean): { [key: string]: string } {
    return getSupportedCurrencies(hosted).reduce((obj, code) => {
        obj[code] = getCurrencyName(code);
        return obj;
    }, {});
}

function trimCurrency(value: number): number {
    const valString = toFixed(value);
    const decimalIndex = valString.indexOf(".");
    if (decimalIndex > 0) {
        return parseFloat(valString.substring(0, decimalIndex + 6));
    }
    return value;
}

export function roundCurrency(value: number): number {
    const newValue = trimCurrency(value);
    return Math.round(newValue * 100) / 100;
}

export function floorCurrency(value: number): number {
    const newValue = trimCurrency(value) + 0.00001; // Add a tiny amount to resolve any floating point errors
    return Math.floor(newValue * 100) / 100;
}

// Support casting numbers to string without scientific notation
// https://stackoverflow.com/questions/1685680/how-to-avoid-scientific-notation-for-large-numbers-in-javascript
function toFixed(x: number) {
    if (Math.abs(x) < 1.0) {
        const e = parseInt(x.toString().split("e-")[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            return "0." + new Array(e).join("0") + x.toString().substring(2);
        }
    } else {
        let e = parseInt(x.toString().split("+")[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            return `${x}` + new Array(e + 1).join("0");
        }
    }
    return "0";
}

export function formatCurrencyRange(values: number[], currencyCode?: string, includeFloat = true) {
    includeFloat = isCurrencyFractional(currencyCode) && includeFloat;
    values = values.filter((val) => !isNaN(val));

    if (values.length < 1) {
        return formatCurrency(0, currencyCode, includeFloat);
    }

    let min = Math.min(...values);
    let max = Math.max(...values);

    if (!includeFloat) {
        min = Math.floor(min);
        max = Math.floor(min);
    }

    if (roundToPlaces(min, 2) === roundToPlaces(max, 2)) {
        return formatCurrency(min, currencyCode, includeFloat);
    }

    return `${formatCurrency(min, currencyCode, includeFloat)} - ${formatCurrency(max, currencyCode, includeFloat)}`;
}
