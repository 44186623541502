import memoize from "lodash/memoize";
import { languageString } from "./text";

const getFormat = memoize(
    ({ useGrouping, decimalPlaces }: { useGrouping: boolean; decimalPlaces: number }) =>
        new Intl.NumberFormat(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: decimalPlaces,
            useGrouping,
        }),
    ({ useGrouping, decimalPlaces }: { useGrouping: boolean; decimalPlaces: number }) =>
        `${useGrouping ? "g" : "n"}-${decimalPlaces}`
);

export function numberFormatter(useGrouping = true, decimalPlaces = 0) {
    const formatter = getFormat({ useGrouping, decimalPlaces });
    return (value = 0) => formatter.format(value).replace(/-/, "\u2212");
}

export function formatNumber(value = 0, useGrouping = true, decimalPlaces = 0) {
    return numberFormatter(useGrouping, decimalPlaces)(value);
}

export function percentFormatter(decimalPlaces = 2) {
    const formatter = getFormat({ useGrouping: false, decimalPlaces });
    return (value = 0) => formatter.format(value * 100) + "%";
}

export function formatPercent(value = 0, decimalPlaces = 2) {
    return percentFormatter(decimalPlaces)(value);
}

export function safeNumber(num: unknown): number {
    if (typeof num !== "number" || isNaN(num)) {
        return 0;
    }
    return num;
}

export function safeDivide(a: number, b: number): number {
    a = safeNumber(a);
    b = safeNumber(b);
    if (a === 0 && b === 0) {
        return 0;
    }
    return a / b;
}

export function safeSum(a: number, b: number): number {
    a = safeNumber(a);
    b = safeNumber(b);
    return a + b;
}

export function safeSub(a: number, b: number): number {
    a = safeNumber(a);
    b = safeNumber(b);
    return a - b;
}

export const sumReducer =
    <KEYS extends string>(key: KEYS) =>
    (a: number, b: Record<KEYS, number>) => {
        if (b && key in b) {
            return safeSum(a, b[key]);
        }
        return a;
    };

/**
 * @param fraction Must be between 0-1
 */
export function blendValues(val1: number, val2: number, fraction: number): number {
    return val1 + (val2 - val1) * fraction;
}

export function roundToPlaces(val: number, places = 2) {
    const factor = 10 ** places;
    return Math.round(val * factor) / factor;
}

export function rangeCorrelation(rangeX: number[], rangeY: number[]): number {
    if (!rangeX || !rangeY || rangeX.length < 1 || rangeY.length < 1) {
        return 0;
    }

    const length = Math.max(rangeX.length, rangeY.length);
    if (rangeX.length < length) {
        const diff = length - rangeX.length;
        rangeX.splice(rangeX.length, 0, ...Array<number>(diff).fill(0));
    }
    if (rangeY.length < length) {
        const diff = length - rangeY.length;
        rangeY.splice(rangeY.length, 0, ...Array<number>(diff).fill(0));
    }

    let sumX = 0;
    let sumY = 0;
    let sumXY = 0;
    let sumXX = 0;
    let sumYY = 0;

    for (const i of Object.keys(rangeX)) {
        const x = rangeX[parseInt(i, 10)];
        const y = rangeY[parseInt(i, 10)];
        if (x !== Infinity && y !== Infinity) {
            sumX += x;
            sumY += y;
            sumXY += x * y;
            sumXX += x * x;
            sumYY += y * y;
        }
    }

    return safeDivide(
        length * sumXY - sumX * sumY,
        Math.sqrt((length * sumXX - sumX * sumX) * (length * sumYY - sumY * sumY))
    );
}

export function rangeCorrelationDescription(correlation: number): string {
    correlation = safeNumber(correlation);

    const negativeOrPositive = languageString(
        correlation > 0 ? "ui.math.correlation.positive" : "ui.math.correlation.negative"
    );

    const corr = Math.abs(correlation);

    if (corr === 0) {
        return languageString("ui.math.correlation.none", "", [negativeOrPositive]);
    }
    if (corr === 1) {
        return languageString("ui.math.correlation.perfect", "", [negativeOrPositive]);
    }

    if (corr < 0.25) {
        return languageString("ui.math.correlation.weak", "", [negativeOrPositive]);
    }
    if (corr < 0.5) {
        return languageString("ui.math.correlation.moderate", "", [negativeOrPositive]);
    }
    if (corr < 0.75) {
        return languageString("ui.math.correlation.strong", "", [negativeOrPositive]);
    }

    return languageString("ui.math.correlation.veryStrong", "", [negativeOrPositive]);
}

export function mapSum<T>(values: T[], map: (value: T) => number): number {
    if (values?.length < 1) {
        return 0;
    }
    return values.reduce((prev, val) => safeSum(prev, map(val)), 0);
}

export function average(values: number[]): number {
    if (values?.length < 1) {
        return 0;
    }
    const sum = values.reduce(safeSum, 0);
    return sum / values.length;
}

export function mapAverage<T>(values: T[], map: (value: T) => number): number {
    const sum = mapSum(values, map);
    return sum / values.length;
}

export function mapMin<T>(values: T[], map: (value: T) => number): number {
    if (values?.length < 1) {
        return 0;
    }
    return values.reduce((prev, val) => Math.min(prev, safeNumber(map(val))), 0);
}

export function mapMax<T>(values: T[], map: (value: T) => number): number {
    if (values?.length < 1) {
        return 0;
    }
    return values.reduce((prev, val) => Math.max(prev, safeNumber(map(val))), 0);
}

export function calculatePercentageChange(
    value: number,
    previousValue: number,
    valuesArePercent?: boolean,
    decimalPlaces = 2
) {
    const dp = decimalPlaces ?? 2;

    const diff = value - previousValue;
    let percent = diff / Math.abs(previousValue);

    if (valuesArePercent) {
        percent = diff;
    }

    // Mask small changes
    percent = roundToPlaces(percent, 2 + dp);

    if (previousValue === 0 && value === 0) {
        percent = 0;
    }

    return percent;
}
