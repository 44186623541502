import { v4 as uuid } from "uuid";
import { getUrl } from "../utilities/url";
import { ASA_AUTH_CLIENT_ID } from "../utilities/vars";
import type { RubyTeamId } from "./backend/RubyData";

const ASA_STATE_KEY = "RB_ASA_AUTH";

interface ASACredentials {
    authToken: string;
    redirectURL: string;
    teamId: RubyTeamId;
    nextPage?: string;
}

interface ASAAuthState {
    nonce: string;
    teamId: RubyTeamId;
    nextPage?: string;
}

export class ASAService {
    private setState(teamId: RubyTeamId, nextPage?: string): ASAAuthState {
        const state: ASAAuthState = {
            nonce: uuid(),
            teamId,
            nextPage,
        };
        localStorage.setItem(ASA_STATE_KEY, JSON.stringify(state));
        return state;
    }

    private getState(): ASAAuthState {
        return JSON.parse(localStorage.getItem(ASA_STATE_KEY)) as ASAAuthState;
    }

    getClientId(): string {
        return ASA_AUTH_CLIENT_ID;
    }

    sendAuthRequest(teamId: RubyTeamId, nextPage?: string) {
        const appleUrl = new URL("https://appleid.apple.com/auth/oauth2/v2/authorize");
        appleUrl.searchParams.set("response_type", "code");
        appleUrl.searchParams.set("client_id", ASA_AUTH_CLIENT_ID);
        appleUrl.searchParams.set("redirect_uri", window.location.origin + getUrl.appleAuthCallback());
        appleUrl.searchParams.set("scope", "searchads");
        appleUrl.searchParams.set("state", this.setState(teamId, nextPage).nonce);
        window.location.href = appleUrl.href;
    }

    parseCredentials(): ASACredentials {
        const location = new URL(window.location.href);
        const code = location.searchParams.get("code");
        const nonce = location.searchParams.get("state");

        const url = location.origin + location.pathname;

        const state = this.getState();

        if (!state || state.nonce !== nonce) {
            throw new Error("Authorisation failed: mismatched state value");
        }

        if (!code || code.length < 4) {
            throw new Error("Authorisation failed: failed to find authorisation code");
        }

        return {
            authToken: code,
            redirectURL: url,
            teamId: state.teamId,
            nextPage: state.nextPage,
        };
    }
}
