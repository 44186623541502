import { authService, broadcastService, rubyService } from "@/services";
import { BroadcastType } from "@/services/BroadcastService";
import { push, type LocationChangeAction } from "@lagunovsky/redux-react-router";
import { fork, put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import { type MetaActions } from "../actions/metaActions";
import {
    createAccountAction,
    loginFailedAction,
    loginSuccessAction,
    resetPasswordAction,
    restoreLoginAction,
    setCookiePrefsAction,
    setNewNameAction,
    setTermsAcceptUrlAction,
    type UserActions,
} from "../actions/userActions";
import { COOKIE_OPT_OUT_KEY } from "../reducers/userReducer";
import { type RubyUserMe } from "../services/backend/RubyData";
import { getCookies, removeCookie } from "../utilities/cookies";
import { runRequestAction, selectFromState, takeRequests } from "../utilities/saga";
import { isSnapshot } from "../utilities/snapshot";
import { getUrl } from "../utilities/url";
import { AUTH0, TERMS_CONDITIONS_VERSION } from "../utilities/vars";

function* loginSaga(action: UserActions.LoginRequestAction) {
    try {
        yield authService.login(action.payload.username, action.payload.password);
    } catch (err) {
        console.error("userSaga.login", err);
        yield put(loginFailedAction(err as string));
    }
}

function* loginWithGoogleSaga(_action: UserActions.LoginWithGoogleAction) {
    try {
        yield authService.loginWithGoogle();
    } catch (err) {
        console.error("userSaga.loginWithGoogle", err);
        yield put(loginFailedAction(err as string));
    }
}

function* checkAuthInitialSaga() {
    const isLoggedIn = yield* selectFromState((state) => state.user.isLoggedIn);
    if (window.location.pathname === getUrl.authenticateXOrigin()) {
        authService.crossOriginVerification();
    } else if (window.location.pathname === getUrl.authenticate()) {
        // Logging In
        try {
            const user = (yield authService.parseHash()) as RubyUserMe;
            const redirect = authService.getRedirect();
            broadcastService.post(BroadcastType.LOGIN);
            yield put(loginSuccessAction(user, redirect));
        } catch (err) {
            console.error("userSaga.checkAuth", err);
            yield put(loginFailedAction((err as string) || "Failed to login"));
        }
    } else if (window.location.pathname === getUrl.acceptTerms()) {
        const urlParams = new URLSearchParams(window.location.search);
        const formReturnAction = urlParams.get("action");
        const formReturnState = urlParams.get("state");
        if (formReturnAction) {
            const actionUrl = formReturnAction.startsWith("http")
                ? formReturnAction
                : "https://" + AUTH0.DOMAIN + formReturnAction;
            const formReturnUrl = `${actionUrl}?state=${formReturnState}&termsAndConditionVersionAccepted=${TERMS_CONDITIONS_VERSION}`;
            yield put(setTermsAcceptUrlAction(formReturnUrl));
        } else {
            console.warn("Redirecting away from /accept-terms as no callback URL was provided");
            yield put(push(getUrl.login()));
        }
    } else if (!isLoggedIn) {
        // Reload session
        if (!isSnapshot) {
            try {
                yield put(restoreLoginAction());
                const user = (yield authService.restoreSession()) as RubyUserMe;
                const redirect = authService.getRedirect();
                broadcastService.post(BroadcastType.LOGIN_RESTORE);
                yield put(loginSuccessAction(user, redirect));
            } catch (err) {
                // Failed to reload
                yield put(loginFailedAction(null));
            }
        }
    }
}

// eslint-disable-next-line require-yield
function* checkAuthSaga(action: LocationChangeAction) {
    if (action.payload.location.pathname === getUrl.login()) {
        const urlParams = new URLSearchParams(action.payload.location.search);
        const redirect = urlParams.get("redirect");
        if (redirect) {
            authService.setRedirect(redirect);
        }
    }
}

function* logoutSaga() {
    broadcastService.post(BroadcastType.LOGOUT);
    yield authService.logout();
}

function* resetPwdSaga(action: ExtractRequestActionRequestType<UserActions.ResetPasswordRequestAction>) {
    yield* runRequestAction(action, resetPasswordAction, () => authService.resetPassword(action.payload.request));
}

function* loginExpiredSaga() {
    yield put(push(getUrl.login(window.location.pathname + window.location.search)));
    yield put(loginFailedAction("Session expired, you have been logged out."));
}

function* makeNewAccountSaga(action: ExtractRequestActionRequestType<UserActions.CreateAccountAction>) {
    yield* runRequestAction(action, createAccountAction, async function (req) {
        await authService.createAccount(req.username, req.password);
        await authService.login(req.username, req.password);
    });
}

function* checkLoginStateSaga(action: MetaActions.BroadcastMessageAction) {
    const isVerified = yield* selectFromState((state) => state.user.emailVerified);
    if (action.payload.type === BroadcastType.LOGOUT) {
        yield authService.logout();
    } else if (
        action.payload.type === BroadcastType.LOGIN ||
        (action.payload.type === BroadcastType.LOGIN_RESTORE && !isVerified)
    ) {
        const user = (yield authService.restoreSession()) as RubyUserMe;
        const redirect = authService.getRedirect();
        yield put(loginSuccessAction(user, redirect));
    }
}

function* updateCookiePrefsSaga(action: UserActions.SetCookiePrefsAction) {
    if (!action.payload) {
        /*
            Making an assumption that any cookies starting '_g' are from analytics and removing them
        */
        const trackingCookies = Object.keys(getCookies()).filter((name) => name.startsWith("_g"));
        trackingCookies.forEach(removeCookie);
    }
    localStorage.setItem(COOKIE_OPT_OUT_KEY, action.payload.toString());
    broadcastService.post(BroadcastType.SET_COOKIE_PREFS, action.payload);
    yield;
}

function* updateCookiePrefsRemoteSaga(action: MetaActions.BroadcastMessageAction) {
    if (action.payload.type === BroadcastType.SET_COOKIE_PREFS) {
        let pref = action.payload.payload === "true" ? true : action.payload.payload === "false" ? false : null;
        if (typeof action.payload.payload === "boolean") {
            pref = action.payload.payload;
        }
        yield put(setCookiePrefsAction(pref));
    }
}

function* updateMetadataSaga(action: UserActions.SetMetadataAction) {
    yield authService.patchMetadata(action.payload);
}

function* setNewNameSaga(action: ExtractRequestActionRequestType<UserActions.SetNewNameAction>) {
    yield* runRequestAction(action, setNewNameAction, (req) =>
        rubyService.accounts.updateUser({ id: req.userId }, { name: req.userName }).then((res) => res.name)
    );
}

export default function* userSaga() {
    yield takeEvery(ActionName.LOGIN_REQUEST, loginSaga);
    yield takeEvery(ActionName.LOGIN_WITH_GOOGLE, loginWithGoogleSaga);
    yield takeEvery(ActionName.LOCATION_CHANGE, checkAuthSaga);
    yield takeEvery(ActionName.LOGOUT, logoutSaga);
    yield takeEvery(ActionName.LOGIN_EXPIRED, loginExpiredSaga);
    yield* takeRequests(ActionName.RESET_PASSWORD, resetPwdSaga);
    yield* takeRequests(ActionName.CREATE_ACCOUNT, makeNewAccountSaga);
    yield takeEvery(ActionName.BROADCAST_MESSAGE, checkLoginStateSaga);
    yield takeEvery(ActionName.SET_COOKIE_PREFS, updateCookiePrefsSaga);
    yield takeEvery(ActionName.BROADCAST_MESSAGE, updateCookiePrefsRemoteSaga);
    yield takeEvery(ActionName.SET_METADATA, updateMetadataSaga);
    yield* takeRequests(ActionName.SET_NEW_USER_NAME, setNewNameSaga);
    yield fork(checkAuthInitialSaga);
}
