import clsx from "clsx";
import omit from "lodash/omit";
import React from "react";
import { Link, type LinkProps } from "react-router-dom";
import Button, { getButtonClass, type ButtonType } from "../standard/Button";
import "../standard/button.css";

export interface ButtonLinkProps extends LinkProps {
    buttonType: ButtonType;
    isBlock?: boolean;
    isLarge?: boolean;
    disabled?: boolean;
}

export default class ButtonLink extends React.PureComponent<ButtonLinkProps> {
    static defaultProps = {
        ...Button.defaultProps,
    };

    render() {
        const { className, children, ...props } = omit(this.props, ["buttonType", "isLarge", "isBlock"]);
        return (
            <Link className={clsx(className, getButtonClass(this.props))} {...props}>
                {children}
            </Link>
        );
    }
}
