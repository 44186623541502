import { type KeywordReport } from "../reducers/domain";
import {
    type RubyCountry,
    type RubyEventGroupingReport,
    type RubyEventQuery,
    type RubyEventSeriesQuery,
    type RubyEventSummaryReport,
    type RubyEventTimestamp,
    type RubyKeywordRankQuery,
    type RubyKeywordRankReport,
    type RubyKeywordReportQuery,
    type RubyMetricsReport,
    type RubyMetricsReportGrouping,
    type RubyMetricsReportQuery,
    type RubyRegionsReport,
    type RubyRegionsReportQuery,
    type RubySearchTermReportQuery,
} from "../services/backend/RubyData";
import { type EventKey } from "../utilities/events";
import { type MetricKey } from "../utilities/metrics";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum ReportingActionName {
    GET_METRICS_SUMMARY = "GET_METRICS_SUMMARY",
    GET_METRICS_SERIES = "GET_METRICS_SERIES",
    GET_REGIONS_REPORT = "GET_REGIONS_REPORT",
    SET_GRAPHED_METRICS = "SET_GRAPHED_METRICS",
    SET_REGION_FILTER = "SET_REGION_FILTER",
    GET_KEYWORDS_SUMMARY = "GET_KEYWORDS_SUMMARY",
    GET_KEYWORD_SUMMARY = "GET_KEYWORD_SUMMARY",
    GET_KEYWORD_SERIES = "GET_KEYWORD_SERIES",
    GET_KEYWORD_RANKS_SERIES = "GET_KEYWORD_RANKS_SERIES",
    SET_EVENT_TIME_FILTER = "SET_EVENT_TIME_FILTER",
    GET_EVENT_LIST_SUMMERY = "GET_EVENT_LIST_SUMMERY",
    GET_EVENTS_SUMMARY = "GET_EVENTS_SUMMARY",
    GET_EVENTS_KEYWORDS = "GET_EVENTS_KEYWORDS",
    GET_EVENTS_SERIES = "GET_EVENTS_SERIES",
    SET_GRAPHED_EVENT_STATS = "SET_GRAPHED_EVENT_STATS",
    GET_CAMPAIGN_SUMMARIES = "GET_CAMPAIGN_SUMMARIES",
    GET_PURPOSE_SUMMARIES = "GET_PURPOSE_SUMMARIES",
    GET_SEARCH_TERMS_SUMMARY = "GET_SEARCH_TERMS_SUMMARY",
    GET_EVENTS_SERIES_KEYWORDS = "GET_EVENTS_SERIES_KEYWORDS",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace ReportingActions {
    export type GetMetricsSummaryAction = RequestAction<
        ReportingActionName.GET_METRICS_SUMMARY,
        RubyMetricsReportQuery,
        RubyMetricsReport
    >;
    export type GetMetricsSeriesAction = RequestAction<
        ReportingActionName.GET_METRICS_SERIES,
        RubyMetricsReportQuery,
        RubyMetricsReportGrouping[]
    >;
    export type SetGraphedMetricsAction = Action<ReportingActionName.SET_GRAPHED_METRICS, MetricKey[]>;
    export type SetRegionFilterAction = Action<ReportingActionName.SET_REGION_FILTER, RubyCountry[]>;
    export type GetKeywordsSummaryAction = RequestAction<
        ReportingActionName.GET_KEYWORDS_SUMMARY,
        RubyMetricsReportQuery,
        RubyMetricsReportGrouping[]
    >;

    export type GetKeywordSummaryAction = RequestAction<
        ReportingActionName.GET_KEYWORD_SUMMARY,
        RubyKeywordReportQuery,
        KeywordReport
    >;

    export type GetKeywordSeriesAction = RequestAction<
        ReportingActionName.GET_KEYWORD_SERIES,
        RubyKeywordReportQuery,
        RubyMetricsReportGrouping[]
    >;
    export type GetKeywordRanksSeriesAction = RequestAction<
        ReportingActionName.GET_KEYWORD_RANKS_SERIES,
        RubyKeywordRankQuery,
        RubyKeywordRankReport[]
    >;
    export type GetRegionsReportAction = RequestAction<
        ReportingActionName.GET_REGIONS_REPORT,
        RubyRegionsReportQuery,
        RubyRegionsReport
    >;

    export type GetEventListSummaryAction = RequestAction<
        ReportingActionName.GET_EVENT_LIST_SUMMERY,
        RubyEventQuery,
        RubyEventGroupingReport[]
    >;
    export type GetEventsSummaryAction = RequestAction<
        ReportingActionName.GET_EVENTS_SUMMARY,
        RubyEventQuery,
        RubyEventSummaryReport
    >;
    export type GetEventsKeywordsAction = RequestAction<
        ReportingActionName.GET_EVENTS_KEYWORDS,
        RubyEventQuery,
        RubyEventGroupingReport[]
    >;
    export type GetEventsSeriesKeywordsAction = RequestAction<
        ReportingActionName.GET_EVENTS_SERIES_KEYWORDS,
        RubyEventSeriesQuery,
        (RubyEventGroupingReport & { date: number })[]
    >;
    export type GetEventsSeriesAction = RequestAction<
        ReportingActionName.GET_EVENTS_SERIES,
        RubyEventQuery,
        RubyEventGroupingReport[]
    >;
    export type SetGraphedEventStatsAction = Action<ReportingActionName.SET_GRAPHED_EVENT_STATS, EventKey[]>;
    export type GetCampaignSummariesAction = RequestAction<
        ReportingActionName.GET_CAMPAIGN_SUMMARIES,
        RubyMetricsReportQuery,
        RubyMetricsReportGrouping[]
    >;
    export type GetPurposeSummariesAction = RequestAction<
        ReportingActionName.GET_PURPOSE_SUMMARIES,
        RubyMetricsReportQuery,
        RubyMetricsReportGrouping[]
    >;
    export type SetEventTimeFilterAction = Action<ReportingActionName.SET_EVENT_TIME_FILTER, RubyEventTimestamp>;
    export type GetSearchTermsSummaryAction = RequestAction<
        ReportingActionName.GET_SEARCH_TERMS_SUMMARY,
        RubySearchTermReportQuery,
        RubyMetricsReportGrouping[]
    >;
}

export type ReportingActionType =
    | ReportingActions.GetMetricsSummaryAction
    | ReportingActions.GetMetricsSeriesAction
    | ReportingActions.SetGraphedMetricsAction
    | ReportingActions.SetRegionFilterAction
    | ReportingActions.GetKeywordsSummaryAction
    | ReportingActions.GetKeywordSummaryAction
    | ReportingActions.GetKeywordSeriesAction
    | ReportingActions.GetKeywordRanksSeriesAction
    | ReportingActions.GetRegionsReportAction
    | ReportingActions.SetEventTimeFilterAction
    | ReportingActions.GetEventListSummaryAction
    | ReportingActions.GetEventsKeywordsAction
    | ReportingActions.GetEventsSummaryAction
    | ReportingActions.GetEventsSeriesAction
    | ReportingActions.SetGraphedEventStatsAction
    | ReportingActions.GetCampaignSummariesAction
    | ReportingActions.GetPurposeSummariesAction
    | ReportingActions.GetSearchTermsSummaryAction
    | ReportingActions.GetEventsSeriesKeywordsAction;

export const getMetricsSummaryAction = requestActions<ReportingActions.GetMetricsSummaryAction>(
    ReportingActionName.GET_METRICS_SUMMARY
);

export const getMetricsSeriesAction = requestActions<ReportingActions.GetMetricsSeriesAction>(
    ReportingActionName.GET_METRICS_SERIES
);

export function setGraphedMetricsAction(metrics: MetricKey[]): ReportingActions.SetGraphedMetricsAction {
    return {
        type: ReportingActionName.SET_GRAPHED_METRICS,
        payload: metrics,
    };
}

export function setRegionFilterAction(regions: RubyCountry[]): ReportingActions.SetRegionFilterAction {
    return {
        type: ReportingActionName.SET_REGION_FILTER,
        payload: regions,
    };
}

export const getKeywordsSummaryAction = requestActions<ReportingActions.GetKeywordsSummaryAction>(
    ReportingActionName.GET_KEYWORDS_SUMMARY
);

export const getKeywordSummaryAction = requestActions<ReportingActions.GetKeywordSummaryAction>(
    ReportingActionName.GET_KEYWORD_SUMMARY
);

export const getKeywordSeriesAction = requestActions<ReportingActions.GetKeywordSeriesAction>(
    ReportingActionName.GET_KEYWORD_SERIES
);

export const getRegionsReportAction = requestActions<ReportingActions.GetRegionsReportAction>(
    ReportingActionName.GET_REGIONS_REPORT
);

export const getKeywordRanksSeriesAction = requestActions<ReportingActions.GetKeywordRanksSeriesAction>(
    ReportingActionName.GET_KEYWORD_RANKS_SERIES
);

export const getEventListSummaryAction = requestActions<ReportingActions.GetEventListSummaryAction>(
    ReportingActionName.GET_EVENT_LIST_SUMMERY
);

export const getEventsSummaryAction = requestActions<ReportingActions.GetEventsSummaryAction>(
    ReportingActionName.GET_EVENTS_SUMMARY
);

export const getEventsKeywordsAction = requestActions<ReportingActions.GetEventsKeywordsAction>(
    ReportingActionName.GET_EVENTS_KEYWORDS
);

export const getEventsSeriesKeywordsAction = requestActions<ReportingActions.GetEventsSeriesKeywordsAction>(
    ReportingActionName.GET_EVENTS_SERIES_KEYWORDS
);

export const getEventsSeriesAction = requestActions<ReportingActions.GetEventsSeriesAction>(
    ReportingActionName.GET_EVENTS_SERIES
);

export function setGraphedEventStatsAction(stats: EventKey[]): ReportingActions.SetGraphedEventStatsAction {
    return {
        type: ReportingActionName.SET_GRAPHED_EVENT_STATS,
        payload: stats,
    };
}

export const getCampaignSummariesAction = requestActions<ReportingActions.GetCampaignSummariesAction>(
    ReportingActionName.GET_CAMPAIGN_SUMMARIES
);

export const getPurposeSummariesAction = requestActions<ReportingActions.GetPurposeSummariesAction>(
    ReportingActionName.GET_PURPOSE_SUMMARIES
);

export function setEventTimeFilterAction(
    eventTimestamp: RubyEventTimestamp
): ReportingActions.SetEventTimeFilterAction {
    return {
        type: ReportingActionName.SET_EVENT_TIME_FILTER,
        payload: eventTimestamp,
    };
}

export const getSearchTermsSummaryAction = requestActions<ReportingActions.GetSearchTermsSummaryAction>(
    ReportingActionName.GET_SEARCH_TERMS_SUMMARY
);
