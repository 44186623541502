import React from "react";
import "./aspectRatioIframe.css";

interface AspectRatioIframeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
    aspectRatio: number;
}

export default class AspectRatioIframe extends React.PureComponent<AspectRatioIframeProps> {
    render() {
        const { aspectRatio, className, title, ...props } = this.props;
        return (
            <div className={`aspectRatioIframe ${className || ""}`} style={{ padding: `${100 / aspectRatio}% 0 0` }}>
                <iframe className="aspectRatioIframe-iframe" title={title} {...props} />
            </div>
        );
    }
}
