import { type RubyUser, type RubyUserMe } from "../services/backend/RubyData";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum UserActionName {
    LOGIN_REQUEST = "LOGIN_REQUEST",
    LOGIN_SUCCESS = "LOGIN_SUCCESS",
    LOGIN_ERROR = "LOGIN_ERROR",
    LOGIN_WITH_GOOGLE = "LOGIN_WITH_GOOGLE",
    CREATE_ACCOUNT = "CREATE_ACCOUNT",
    LOGOUT = "LOGOUT",
    RESTORE_LOGIN = "RESTORE_LOGIN",
    RESET_PASSWORD = "RESET_PASSWORD",
    LOGIN_EXPIRED = "LOGIN_EXPIRED",
    SET_TERMS_ACCEPT_URL = "SET_TERMS_ACCEPT_URL",
    SET_COOKIE_PREFS = "SET_COOKIE_PREFS",
    SET_METADATA = "SET_METADATA",
    SET_NEW_USER_NAME = "SET_NEW_USER_NAME",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace UserActions {
    export type LoginRequestAction = Action<UserActionName.LOGIN_REQUEST, LoginRequestPayload>;
    export type LoginSuccessAction = Action<
        UserActionName.LOGIN_SUCCESS,
        {
            user: RubyUserMe;
            nextPage: string;
        }
    >;
    export type LoginErrorAction = Action<UserActionName.LOGIN_ERROR, string>;
    export type LoginWithGoogleAction = Action<UserActionName.LOGIN_WITH_GOOGLE, void>;
    export type LogoutAction = Action<UserActionName.LOGOUT, void>;
    export type RestoreLoginAction = Action<UserActionName.RESTORE_LOGIN, void>;
    export type ResetPasswordRequestAction = RequestAction<UserActionName.RESET_PASSWORD, string>;
    export type LoginExpiredAction = Action<UserActionName.LOGIN_EXPIRED, void>;
    export type SetTermsAcceptUrlAction = Action<UserActionName.SET_TERMS_ACCEPT_URL, string>;
    export type CreateAccountAction = RequestAction<UserActionName.CREATE_ACCOUNT, LoginRequestPayload>;
    export type SetCookiePrefsAction = Action<UserActionName.SET_COOKIE_PREFS, boolean>;
    export type SetMetadataAction = Action<UserActionName.SET_METADATA, Record<string, unknown>>;
    export type SetNewNameAction = RequestAction<
        UserActionName.SET_NEW_USER_NAME,
        { userName: string; userId: RubyUser["id"] },
        string
    >;
}

export type UserActionType =
    | UserActions.LoginRequestAction
    | UserActions.LoginSuccessAction
    | UserActions.LoginErrorAction
    | UserActions.LoginWithGoogleAction
    | UserActions.LogoutAction
    | UserActions.RestoreLoginAction
    | UserActions.ResetPasswordRequestAction
    | UserActions.LoginExpiredAction
    | UserActions.SetTermsAcceptUrlAction
    | UserActions.CreateAccountAction
    | UserActions.SetCookiePrefsAction
    | UserActions.SetMetadataAction
    | UserActions.SetNewNameAction;

export interface LoginRequestPayload {
    username: string;
    password: string;
}

export function loginRequestAction(payload: LoginRequestPayload): UserActions.LoginRequestAction {
    return {
        type: UserActionName.LOGIN_REQUEST,
        payload,
    };
}

export function loginSuccessAction(user: RubyUserMe, nextPage: string): UserActions.LoginSuccessAction {
    return {
        type: UserActionName.LOGIN_SUCCESS,
        payload: {
            user,
            nextPage,
        },
    };
}

export function loginFailedAction(errorMessage: string): UserActions.LoginErrorAction {
    return {
        type: UserActionName.LOGIN_ERROR,
        payload: errorMessage,
    };
}

export function loginWithGoogleAction(): UserActions.LoginWithGoogleAction {
    return {
        type: UserActionName.LOGIN_WITH_GOOGLE,
        payload: null,
    };
}

export function logoutAction(): UserActions.LogoutAction {
    return {
        type: UserActionName.LOGOUT,
        payload: null,
    };
}

export function restoreLoginAction(): UserActions.RestoreLoginAction {
    return {
        type: UserActionName.RESTORE_LOGIN,
        payload: null,
    };
}

export const resetPasswordAction = requestActions<UserActions.ResetPasswordRequestAction>(
    UserActionName.RESET_PASSWORD
);

export function loginExpiredAction(): UserActions.LoginExpiredAction {
    return {
        type: UserActionName.LOGIN_EXPIRED,
        payload: null,
    };
}

export function setTermsAcceptUrlAction(acceptUrl: string): UserActions.SetTermsAcceptUrlAction {
    return {
        type: UserActionName.SET_TERMS_ACCEPT_URL,
        payload: acceptUrl,
    };
}

export const createAccountAction = requestActions<UserActions.CreateAccountAction>(UserActionName.CREATE_ACCOUNT);

export function setCookiePrefsAction(accepted: boolean): UserActions.SetCookiePrefsAction {
    return {
        type: UserActionName.SET_COOKIE_PREFS,
        payload: accepted,
    };
}

export function setMetadataAction(metaData: Record<string, unknown>): UserActions.SetMetadataAction {
    return {
        type: UserActionName.SET_METADATA,
        payload: metaData,
    };
}

export const setNewNameAction = requestActions<UserActions.SetNewNameAction>(UserActionName.SET_NEW_USER_NAME);
