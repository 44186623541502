import clsx from "clsx";
import isEqual from "lodash/isEqual";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { languageString } from "../../../utilities/text";
import { seconds } from "../../../utilities/time";
import ButtonIcon from "../buttons/icon/ButtonIcon";
import { ErrorMessageString } from "../errors/errorMessage/ErrorMessage";
import HiddenSection from "../hiddenSection/HiddenSection";
import Icon from "../icon/Icon";
import "./toast.css";

type ToastAddFunc = (id: string, props: ToastyProps) => void;
type ToastClearFunc = (id: string) => void;
const ToastContext = React.createContext<{
    addToast: ToastAddFunc;
    clearToast: ToastClearFunc;
}>({
    addToast: () => {
        // void
    },
    clearToast: () => {
        // void
    },
});

interface ToastProps {
    animateIn?: boolean;
    title?: string;
    children?: React.ReactNode;
    onDismiss?: () => void;
    autoDismiss?: number; // Set 0 to require manual dismiss
    type?: "normal" | "success" | "error";
}

interface ToastyProps extends ToastProps {
    createdAt: number;
}

interface ToastConsumerProps extends ToastProps {
    addToast: ToastAddFunc;
    clearToast: ToastClearFunc;
}

export default function Toast(props: ToastProps) {
    return (
        <ToastContext.Consumer>
            {({ addToast, clearToast }) => <ToastConsumer addToast={addToast} clearToast={clearToast} {...props} />}
        </ToastContext.Consumer>
    );
}

function ToastConsumer({ addToast, clearToast, onDismiss, ...props }: ToastConsumerProps) {
    const id = useRef(uuid());
    const createdAt = useRef(Date.now());
    useEffect(() => {
        addToast(id.current, {
            ...props,
            createdAt: createdAt.current,
            onDismiss: () => {
                clearToast(id.current);
                onDismiss?.();
            },
        });
    }, []);
    return null;
}

interface ToasterProps {
    children: React.ReactNode;
}

type ToastQueue = Map<string, ToastyProps>;

export function Toaster({ children }: ToasterProps) {
    const [toastQueue, setToastQueue] = useState<ToastQueue>(new Map());
    const addToast: ToastAddFunc = (id, props) => {
        setToastQueue((oldQueue) => {
            const nextQueue = new Map(oldQueue);
            if (!nextQueue.has(id) || (nextQueue.has(id) && !isEqual(nextQueue.get(id), props))) {
                nextQueue.set(id, props);
            }
            return nextQueue;
        });
    };
    const clearToast: ToastClearFunc = (id) => {
        setTimeout(() => {
            setToastQueue((oldQueue) => {
                const nextQueue = new Map(oldQueue);
                nextQueue.delete(id);
                return nextQueue;
            });
        }, 1000); // Delayed just to ensure any animations have resolved
    };
    const toastFuncs = useMemo<ToastConsumerProps>(
        () => ({
            addToast,
            clearToast,
        }),

        [toastQueue]
    );

    return (
        <ToastContext.Provider value={toastFuncs}>
            {children}
            <div
                className={clsx("toast~root", {
                    "is-empty": toastQueue.size < 1,
                })}
            >
                {[...toastQueue.entries()].map(([id, props]) => (
                    <Toasty key={id} {...props} />
                ))}
            </div>
        </ToastContext.Provider>
    );
}

function Toasty({
    animateIn = true,
    title,
    children,
    onDismiss,
    autoDismiss = seconds(10),
    type = "normal",
}: ToastyProps) {
    const [dismissed, setDismissed] = useState(animateIn);

    const handleDismiss = () => {
        setDismissed(true);
        onDismiss?.();
    };

    useEffect(() => {
        if (animateIn) {
            setDismissed(false);
        }
        if (autoDismiss > 0) {
            const timeout = setTimeout(handleDismiss, autoDismiss);
            return () => clearTimeout(timeout);
        }
    }, []);

    return (
        <HiddenSection isHidden={dismissed} easingClose="ease-in" durationClose={200}>
            <div className="u-pt8">
                <div
                    className={clsx("toast", { "is-dismissed": dismissed }, `toast_${type}`)}
                    style={{
                        "--autoDismiss": autoDismiss,
                    }}
                >
                    {type === "success" && <Icon.Check className="toast-icon" />}
                    {type === "error" && <Icon.Error className="toast-icon" />}
                    {title && <h2 className="toast-title">{title}</h2>}
                    {type === "error" ? <ErrorMessageString>{children}</ErrorMessageString> : children}
                    <ButtonIcon
                        icon="Close"
                        label={languageString("ui.alt.closeInfo", "Close")}
                        onClick={handleDismiss}
                        className="toast-dismiss"
                        borderless
                    />
                </div>
            </div>
        </HiddenSection>
    );
}
