import type React from "react";
import Card from "../../blocks/card/basic/Card";
import "./actionPage.css";

interface ActionPageProps {
    title: string;
    children: React.ReactNode;
}

export default function ActionPage({ title, children }: ActionPageProps) {
    return (
        <div className="actionPage">
            <h1 className="actionPage-title">{title}</h1>
            <Card>
                {{
                    body: () => <div className="actionPage-card">{children}</div>,
                }}
            </Card>
        </div>
    );
}
