import * as Yup from "yup";
import { getCountriesAsOptions } from "../../../../utilities/country";
import { taxIdOptions } from "../../../../utilities/tax";
import { languageString } from "../../../../utilities/text";
import { emailValidation } from "../../../../utilities/validation";
import ControlledInput from "../../../blocks/inputs/controlledInput/ControlledInput";
import InputGrid from "../../../blocks/inputs/grid/InputGrid";
import HiddenInputSection from "../../../blocks/inputs/hiddenInputSection/HiddenInputSection";
import "./customerInputs.css";

export const customerInitialValues = {
    contact: "",
    email: "",
    phone: "",
    streetAddress: "",
    city: "",
    country: "GB",
    postcode: "",
    taxApplies: false,
    taxId: "",
    taxCode: "",
};

export const customerValidationSchema = {
    contact: Yup.string().required(languageString("payment.validation.nameRequired")),
    streetAddress: Yup.string().required(languageString("payment.validation.addressRequired")),
    city: Yup.string().required(languageString("payment.validation.cityRequired")),
    country: Yup.string().required(languageString("payment.validation.countryRequired")),
    postcode: Yup.string().required(languageString("payment.validation.postcodeRequired")),
    taxId: Yup.string().when("taxApplies", {
        is: (value) => !!value,
        then: (s) => s.required(languageString("payment.validation.taxIdRequired")),
    }),
    taxCode: Yup.string().when("taxApplies", {
        is: (value) => !!value,
        then: (s) => s.required(languageString("payment.validation.taxCodeRequired")),
    }),
    email: emailValidation().required(languageString("payment.validation.emailRequired")),
};

export default function CustomerInputs() {
    return (
        <div className="customerInputs">
            <InputGrid colWidth={3}>
                <ControlledInput name="contact" label={languageString("payment.label.contactName")} />
                <ControlledInput name="email" type="email" label={languageString("payment.label.contactEmail")} />
                <ControlledInput
                    name="phone"
                    type="phone"
                    label={languageString("payment.label.contactNumber")}
                    required={false}
                />
            </InputGrid>
            <ControlledInput
                name="streetAddress"
                label={languageString("payment.label.address", "Address")}
                autoComplete="street-address"
            />
            <InputGrid colWidth={3}>
                <ControlledInput
                    name="city"
                    label={languageString("payment.label.city", "City")}
                    autoComplete="address-level2"
                />
                <ControlledInput
                    name="country"
                    label={languageString("payment.label.country", "Country")}
                    autoComplete="country-name"
                    type="select"
                    options={getCountriesAsOptions()}
                />
                <ControlledInput
                    name="postcode"
                    label={languageString("payment.label.postcode", "Postcode / Zip")}
                    autoComplete="postal-code"
                />
            </InputGrid>
            <ControlledInput name="taxApplies" type="checkbox" label={languageString("payment.label.applyTax")} />
            <HiddenInputSection hiddenBy="taxApplies" inverted>
                <InputGrid colWidth={2}>
                    <ControlledInput
                        type="select"
                        options={taxIdOptions}
                        name="taxId"
                        label={languageString("payment.label.taxId")}
                    />
                    <ControlledInput name="taxCode" label={languageString("payment.label.taxCode")} />
                </InputGrid>
            </HiddenInputSection>
        </div>
    );
}
