import type { HTMLProps } from "react";
import "./blockMessage.css";

export default function BlockMessage({ className, children, ...props }: HTMLProps<HTMLDivElement>) {
    return (
        <div className={`blockMessage ${className || ""}`} {...props}>
            {children}
        </div>
    );
}
