import clsx from "clsx";
import xor from "lodash/xor";
import React from "react";
import { v4 as uuid } from "uuid";
import {
    getContinentCountries,
    getSupportedCountries,
    getSupportedCountyCodes,
    type Country,
} from "../../../../utilities/country";
import { languageString } from "../../../../utilities/text";
import Button from "../../buttons/standard/Button";
import ExpandableArea from "../../expandableArea/ExpandableArea";
import Flag from "../../flag/Flag";
import Checkbox from "../checkbox/Checkbox";
import LabeledInput from "../labeledInput/LabeledInput";
import Radio from "../radio/Radio";
import "./countryInput.css";

interface CountryInputProps {
    name: string;
    countryCodes?: string[];
    value: string[];
    onChange?: (value: string[]) => void;
    className?: string;
    id?: string;
    selectSingle?: boolean;
}

export default function CountryInput({
    name,
    className,
    value,
    countryCodes,
    id,
    selectSingle,
    onChange,
}: CountryInputProps) {
    id ??= uuid();
    const codes = getSupportedCountyCodes(countryCodes);

    const selectAll = (
        <Button type="button" buttonType="clear" onClick={() => onChange?.(codes)}>
            {languageString("ui.input.countries.selectAll")}
        </Button>
    );
    const selectNone = (
        <Button type="button" buttonType="clear" onClick={() => onChange?.([])}>
            {languageString("ui.input.countries.selectNone")}
        </Button>
    );
    const selectors = !selectSingle ? (
        <div className="countryInput-selectors">
            {selectAll}
            {selectNone}
        </div>
    ) : (
        <></>
    );

    const checkboxBuilder = (country: Country) => {
        const inputId = `${id}-${country.countryCode}`;
        return (
            <React.Fragment key={country.countryCode}>
                <LabeledInput
                    label={
                        <div className="countryInput-country">
                            <Flag countryCode={country.countryCode} size={"small"} />
                            <span>{country.name}</span>
                        </div>
                    }
                    inputId={inputId}
                    isCheckbox
                >
                    {selectSingle ? (
                        <Radio
                            name={name}
                            id={inputId}
                            checked={!!value && value.includes(country.countryCode)}
                            value={country.countryCode}
                            onChange={(e) => {
                                onChange?.([e.target.value]);
                            }}
                        />
                    ) : (
                        <Checkbox
                            name={name}
                            id={inputId}
                            checked={!!value && value.includes(country.countryCode)}
                            value={country.countryCode}
                            onChange={(e) => {
                                onChange?.(xor([e.target.value], value));
                            }}
                        />
                    )}
                </LabeledInput>
            </React.Fragment>
        );
    };

    if (codes.length > 8) {
        const continents = getContinentCountries(codes);
        return (
            <fieldset className={clsx("countryInput", className)}>
                {continents.map((continent) => {
                    const selected = continent.countries.filter((c) => value?.includes(c.countryCode));
                    const hasSelected = selected.length > 0;
                    return (
                        <div key={continent.name} className="u-relative">
                            {!selectSingle && (
                                <Checkbox
                                    checked={hasSelected}
                                    onChange={() => {
                                        if (hasSelected) {
                                            onChange?.(
                                                value.filter(
                                                    (code) => !continent.countries.find((c) => c.countryCode === code)
                                                )
                                            );
                                        } else {
                                            onChange?.([...value, ...continent.countries.map((c) => c.countryCode)]);
                                        }
                                    }}
                                    aria-label={`Toggle all countries in ${continent.name}`}
                                    className="countryInput-setCheckbox"
                                />
                            )}
                            <ExpandableArea
                                title={
                                    <span className="countryInput-heading">
                                        {`${continent.name} ${
                                            !selectSingle ? `(${selected.length}/${continent.countries.length})` : ""
                                        }`}
                                    </span>
                                }
                                startOpen
                            >
                                <div className="countryInput-set countryInput-set_inset">
                                    {continent.countries.map(checkboxBuilder)}
                                </div>
                            </ExpandableArea>
                        </div>
                    );
                })}
                {selectors}
            </fieldset>
        );
    } else {
        const countries = getSupportedCountries(codes);
        return (
            <fieldset className={clsx("countryInput", className)}>
                <div className="countryInput-set">{countries.map(checkboxBuilder)}</div>
                {selectors}
            </fieldset>
        );
    }
}
