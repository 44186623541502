import { type RubyUserMe } from "../services/backend/RubyData";

export interface NonceToken {
    code: string;
    redirect: string;
}

export interface UserData {
    userData: RubyUserMe;
    token: string;
    age: number;
}

export abstract class AuthServiceImpl {
    abstract createAccount(username: string, password: string): Promise<void>;
    abstract login(username: string, password: string): Promise<void>;
    abstract loginWithGoogle(): void;
    abstract logout(): void;
    abstract restoreSession(): Promise<RubyUserMe>;
    abstract checkSession(): Promise<RubyUserMe>;
    abstract parseHash(): Promise<RubyUserMe>;
    abstract patchMetadata(data: object): Promise<void>;
    abstract resetPassword(email: string): Promise<void>;
    abstract setRedirect(redirect: string): void;
    abstract getRedirect(): void;
    abstract getToken(): Promise<string>;
    abstract crossOriginVerification(): void;
}
