import type { BudgetPlanTemplate } from "@/reducers/domain";
import { BudgetPlanPolicy } from "@redbox-ruby/data-lib";
import moment from "moment";
import {
    RubyBudgetPlanMethod,
    RubyBudgetPlanPolicy,
    RubyBudgetPlanStatus,
    type RubyBasicBudgetPlan,
    type RubyBudgetPlan,
} from "../services/backend/RubyData";

export function getActiveBudgetPlan(plans: RubyBasicBudgetPlan[]): RubyBasicBudgetPlan {
    if (plans.length < 1) {
        return null;
    }

    const activePlans = plans.filter((plan) => plan.status === RubyBudgetPlanStatus.ACTIVE);

    const activeByDate = activePlans.find(isActiveBudgetPlan);

    if (activeByDate) {
        return activeByDate;
    }

    const sortedPlans = activePlans.filter((plan) => plan.start < Date.now()).sort((a, b) => a.start - b.start);

    if (sortedPlans.length < 1) {
        return null;
    }

    const lastActive = sortedPlans[sortedPlans.length - 1];

    if ([BudgetPlanPolicy.ROLLOVER_AT_END, BudgetPlanPolicy.REPEATING].includes(lastActive.policy)) {
        return lastActive;
    }

    return null;
}

/**
 * This is only checking if a single plan is in date, not if it has rolled over, as that requires the full list of plans
 */
export function isActiveBudgetPlan(plan: RubyBasicBudgetPlan): boolean {
    return moment.utc().isBetween(moment.utc(plan.start).startOf("day"), moment.utc(plan.end).endOf("day"));
}

export function extendBudgetPlans(plans: RubyBasicBudgetPlan[]): RubyBudgetPlan[] {
    const activePlan = getActiveBudgetPlan(plans);
    return plans.map((plan) => {
        return extendBudgetPlan(plan, activePlan?.id);
    });
}

export function extendBudgetPlan(plan: RubyBasicBudgetPlan, activePlanId: RubyBudgetPlan["id"]): RubyBudgetPlan {
    return {
        ...plan,
        active: plan.id === activePlanId,
        lengthInDays: moment.utc(plan.end).diff(plan.start, "days") + 1,
        daysRemaining: moment.utc(plan.end).diff(moment.utc().startOf("day"), "days"),
        start: moment.utc(plan.start).startOf("day").valueOf(),
        end: moment.utc(plan.end).endOf("day").valueOf(),
    };
}

export function getAllocatedBudget(plan: RubyBudgetPlan): number {
    return (
        plan?.allocations?.reduce((sum, al) => {
            return (
                sum +
                al.details.regions.reduce((sum, region) => {
                    return sum + region.amount;
                }, 0)
            );
        }, 0) ?? null
    );
}

export function getTodaysAllocation(plan: RubyBudgetPlan) {
    const today = moment.utc();
    const todaysAllocation = plan?.allocations?.reduce((latestAllocation, allocation) => {
        if (allocation.createdAt > (latestAllocation?.createdAt ?? 0) && today.isSame(allocation.createdAt, "day")) {
            return allocation;
        }
        return latestAllocation;
    }, null);
    return todaysAllocation;
}

export const DEFAULT_WEEKLY_OVERSPEND = {
    monday: 100,
    tuesday: 100,
    wednesday: 100,
    thursday: 100,
    friday: 100,
    saturday: 100,
    sunday: 100,
};

// Overspend values are %s starting at 100%. The idea being that the default
// is to spend 100% of their allocation, but some days you can allow overspend

export const budgetPlanInitialValues = {
    timeRange: [moment.utc().startOf("day").toDate(), moment.utc().add(30, "days").endOf("day").toDate()] as [
        Date,
        Date
    ],
    totalAmount: null as number,
    weeklyOverspend: {
        monday: 1,
        tuesday: 1,
        wednesday: 1,
        thursday: 1,
        friday: 1,
        saturday: 1,
        sunday: 1,
    },
    policy: RubyBudgetPlanPolicy.STOP_AT_END,
    method: RubyBudgetPlanMethod.ADJUST_DAILY,
    setOverspend: false,
};

export function convertBudgetInputsToPlan(
    values: typeof budgetPlanInitialValues = budgetPlanInitialValues
): BudgetPlanTemplate {
    return {
        policy: values.policy,
        method: values.method,
        timeRange: [
            moment.utc(values.timeRange[0]).startOf("day").valueOf(),
            moment.utc(values.timeRange[1]).endOf("day").valueOf(),
        ],
        totalAmount: values.totalAmount,
        weeklyOverspend: values.setOverspend
            ? (Object.fromEntries(
                  Object.entries(values.weeklyOverspend)
                      .map(([day, value]) => {
                          if (value && value > 1) {
                              return [day, Math.round(value * 100)];
                          }
                          return null;
                      })
                      .filter((d) => !!d)
              ) as BudgetPlanTemplate["weeklyOverspend"])
            : null,
    };
}

export function convertBudgetTemplateToValues(plan: BudgetPlanTemplate): typeof budgetPlanInitialValues {
    if (!plan) {
        return budgetPlanInitialValues;
    }
    const overspends = Object.entries(plan.weeklyOverspend ?? {});
    const hasOverspend = overspends.some(([_day, value]) => value > 100);
    return {
        policy: plan.policy,
        method: plan.method,
        timeRange: [new Date(plan.timeRange[0]), new Date(plan.timeRange[1])],
        setOverspend: hasOverspend,
        totalAmount: plan.totalAmount,
        weeklyOverspend: {
            ...budgetPlanInitialValues.weeklyOverspend,
            ...(Object.fromEntries(
                overspends
                    .map(([day, value]) => {
                        if (value > 100) {
                            return [day, Math.round(value / 10) / 10];
                        }
                        return [day, 1];
                    })
                    .filter((d) => !!d)
            ) as BudgetPlanTemplate["weeklyOverspend"]),
        },
    };
}

export function convertBudgetPlanToTemplate(plan: RubyBudgetPlan): BudgetPlanTemplate {
    if (!plan) {
        return convertBudgetInputsToPlan();
    }
    return {
        policy: plan.policy,
        method: plan.method,
        timeRange: [plan.start, plan.end],
        totalAmount: plan.totalBudget,
        weeklyOverspend: {
            ...DEFAULT_WEEKLY_OVERSPEND,
            ...(plan.details?.weekdayOverspend ?? {}),
        },
    };
}

export function convertBudgetPlanToValues(plan: RubyBudgetPlan): typeof budgetPlanInitialValues {
    const template = convertBudgetPlanToTemplate(plan);
    return convertBudgetTemplateToValues(template);
}
