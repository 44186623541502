import React from "react";
import Spinner from "../../spinner/Spinner";
import "./button.css";

export type ButtonType = "primary" | "cta" | "danger" | "link" | "clear" | "mono";
type InputType = "button" | "submit" | "reset";

export interface ButtonProps extends React.ButtonHTMLAttributes<unknown> {
    children?: React.ReactNode;
    onClick?: (e: React.MouseEvent) => void | Promise<void>;
    buttonType: ButtonType;
    isBlock?: boolean;
    isLarge?: boolean;
    disabled?: boolean;
    type?: InputType;
    isPending?: boolean;
    pendingText?: string;
    className?: string;
    buttonRef?: React.RefObject<HTMLButtonElement>;
}

interface ButtonState {
    clickX: number;
    clickY: number;
}

export function getButtonClass(props: {
    buttonType: ButtonType;
    isBlock?: boolean;
    isLarge?: boolean;
    isPending?: boolean;
}) {
    return (
        `button ` +
        `button_${props.buttonType} ` +
        `${props.isBlock ? "button_block" : ""} ` +
        `${props.isLarge ? "button_large" : ""} ` +
        `${props.isPending ? "button_pending" : ""} `
    );
}

export default class Button extends React.PureComponent<ButtonProps, ButtonState> {
    static defaultProps = {
        buttonType: "primary",
    };

    constructor(props: ButtonProps) {
        super(props);
        this.setClickPosition = this.setClickPosition.bind(this);
        this.state = {
            clickX: 0,
            clickY: 0,
        };
    }

    setClickPosition(e: React.PointerEvent<HTMLButtonElement>) {
        const buttonRect = (e.target as HTMLButtonElement).closest(".button").getBoundingClientRect();
        this.setState({
            clickX: e.pageX - buttonRect.left,
            clickY: e.pageY - buttonRect.top,
        });
    }

    render() {
        const {
            className,
            disabled,
            isPending,
            isLarge,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            isBlock,
            pendingText,
            children,
            buttonType,
            buttonRef,
            ...props
        } = this.props;
        return (
            <button
                className={`${getButtonClass(this.props)} ${className || ""}`}
                disabled={disabled || isPending}
                aria-label={isPending ? pendingText : undefined}
                aria-live={isPending ? "polite" : "off"}
                aria-busy={isPending}
                ref={buttonRef}
                onPointerUp={this.setClickPosition.bind(this)}
                style={{
                    "--click-x": this.state.clickX,
                    "--click-y": this.state.clickY,
                }}
                {...props}
            >
                {buttonType !== "link" && <span className="button-splash" aria-hidden></span>}
                {isPending && <Spinner color="currentColor" size={isLarge ? 32 : 16} />}
                {isPending && pendingText ? pendingText : children}
            </button>
        );
    }
}
