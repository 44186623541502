import React from "react";
import { type RequestState } from "../../../reducers/domain";
import ErrorMessage from "../errors/errorMessage/ErrorMessage";
import Spinner from "../spinner/Spinner";
import SpinnerOverlay from "../spinnerOverlay/SpinnerOverlay";

interface RequestLoaderProps {
    request: RequestState;
    label?: string;
    placeholder?: React.ReactNode;
    noSpinner?: boolean; // Only show the placeholder without the spinner overlaid. Used when the placeholder has it's own 'loading' state.
    fallback?: React.ReactNode;
    noPending?: boolean;
    children?: React.ReactNode;
    size?: number;
    inline?: boolean; // Place the spinner as an inline element, and not a block.
}

export default class RequestLoader extends React.PureComponent<RequestLoaderProps> {
    renderSpinner(pending?: boolean) {
        if (this.props.placeholder) {
            return (
                <>
                    <div className="u-fade2" aria-hidden="true">
                        {this.props.placeholder}
                    </div>
                    {!this.props.noSpinner && (
                        <SpinnerOverlay isClear isPending={pending}>
                            {this.props.label}
                        </SpinnerOverlay>
                    )}
                </>
            );
        }

        if (this.props.inline) {
            return <Spinner size={this.props.size} isPending={pending} />;
        }

        return (
            <SpinnerOverlay size={this.props.size} isStatic isPending={pending}>
                {this.props.label}
            </SpinnerOverlay>
        );
    }

    render() {
        const { isRequesting, success, errorMessage } = this.props.request;

        if (!isRequesting && !success && !errorMessage && !this.props.noPending) {
            return this.renderSpinner(true);
        }

        if (isRequesting) {
            return this.renderSpinner();
        }

        if (errorMessage) {
            return this.props.fallback ?? <ErrorMessage>{errorMessage}</ErrorMessage>;
        }

        if (!success) {
            return this.props.fallback ?? null;
        }

        return this.props.children;
    }
}
