import type { StripeServiceImpl } from "@/types/StripeService";
import type { RubyApiClient } from "@redbox-ruby/client-lib";
import { loadStripe, type SetupIntent, type Stripe, type StripeElements } from "@stripe/stripe-js";
import { languageString } from "../utilities/text";
import { STRIPE } from "../utilities/vars";
import type { RubyTeamId } from "./backend/RubyData";

// https://stripe.com/docs/payments/save-and-reuse?platform=web&html-or-react=react

export class StripeService implements StripeServiceImpl {
    private stripeInstance: Stripe;
    private stripeRef: Stripe;
    private elements: StripeElements;
    private clientSecret: Record<RubyTeamId, string> = {};
    private rubyService: RubyApiClient;

    constructor(rubyService: RubyApiClient) {
        this.rubyService = rubyService;
    }

    async getStripeInstance(): Promise<Stripe> {
        if (!this.stripeInstance) {
            const stripe = await loadStripe(STRIPE.PUBLIC_KEY);
            if (!this.stripeInstance) {
                this.stripeInstance = stripe;
            }
        }
        return this.stripeInstance;
    }

    async confirmCardSetup(teamId: RubyTeamId): Promise<SetupIntent> {
        if (!this.stripeRef || !this.elements) {
            throw new Error(languageString("payment.error.stripeMissingInstance"));
        }

        const stripe = await this.getStripeInstance();

        const { error, setupIntent } = await stripe.confirmCardSetup(this.clientSecret[teamId], {
            payment_method: {
                card: this.elements.getElement("cardNumber"),
            },
        });

        this.clientSecret[teamId] = null;

        if (error) {
            console.error(error);
            void this.getClientSecret(teamId); // Found this, Unsure if this should await
            throw new Error(error.message);
        }

        return setupIntent;
    }

    async getClientSecret(teamId: RubyTeamId): Promise<string> {
        if (!this.clientSecret[teamId]) {
            const newSecret = await this.rubyService.budgets.createStripePaymentMethod({ teamId }, {});
            this.clientSecret[teamId] = newSecret.setupClientSecret;
        }
        return this.clientSecret[teamId];
    }

    setRef(stripeRef: Stripe, elements: StripeElements) {
        this.stripeRef = stripeRef;
        this.elements = elements;
    }

    async handlePaymentAction(clientSecret: string) {
        const stripe = await this.getStripeInstance();
        const result = await stripe.confirmCardPayment(clientSecret);
        if (result.error) {
            console.error(result.error);
            throw new Error(result.error.message);
        }
    }
}
