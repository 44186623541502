import { isNotNil } from "@/utilities/array";
import { languageString } from "@/utilities/text";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import type React from "react";
import { useCallback, useMemo } from "react";
import GridLayout, { WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import ButtonIcon from "../../buttons/icon/ButtonIcon";
import "./sortInput.css";

interface SortOption<T> {
    value: T;
    label: string;
}

export interface SortInputProps<T> {
    value?: T[];
    onChange?: (value: T[]) => void;
    allowAddRemove?: boolean;
    options?: SortOption<T>[];
    disabled?: boolean;
}

const ResponsiveGridLayout = WidthProvider(GridLayout);

export default function SortInput<T>({
    value = [],
    onChange = () => {
        //noop
    },
    allowAddRemove = false,
    options = [],
    disabled = false,
}: SortInputProps<T>) {
    const labels = useMemo(() => {
        return options.reduce((map, option) => {
            map.set(option.value, option.label);
            return map;
        }, new Map<T, string>());
    }, [options]);

    const unusedOptions = useMemo(() => {
        return options.filter((opt) => !value.includes(opt.value));
    }, [options, value]);

    const addValue = useCallback(
        (newValue?: T) => {
            if (newValue) {
                onChange([...value, newValue]);
            } else if (unusedOptions.length > 0) {
                onChange([...value, unusedOptions[0].value]);
            }
        },
        [onChange, value, unusedOptions]
    );

    const removeValues = useCallback(
        (removedValues: T[]) => {
            const newValues = value.filter((val) => !removedValues.some((rVal) => rVal === val));
            onChange(newValues);
        },
        [value, onChange]
    );

    const gridItems = useMemo(() => {
        const gridItems: { node: React.ReactNode; key: string }[] = [];

        value.forEach((val, i) => {
            gridItems.push({
                key: getKey(val),
                node: (
                    <div
                        className="sortInput-row"
                        key={getKey(val)}
                        data-grid={{
                            x: 0,
                            y: i,
                            w: 1,
                            h: 1,
                            i: getKey(val),
                        }}
                        data-key={getKey(val)}
                        data-index={i}
                    >
                        <span className="sortInput-label">{labels.get(val) ?? getKey(val)}</span>
                        {allowAddRemove && (
                            <ButtonIcon
                                icon="Delete"
                                onClick={() => removeValues([val])}
                                disabled={disabled}
                                label={languageString("ui.input.sort.remove")}
                                borderless
                            />
                        )}
                    </div>
                ),
            });
        });

        if (allowAddRemove) {
            if (unusedOptions.length > 0 && value.length > 0) {
                gridItems.push({
                    key: "_spacer_",
                    node: (
                        <div
                            className="sortInput-spacer"
                            key="_spacer_"
                            data-grid={{ x: 0, y: value.length, w: 1, h: 1, isDraggable: false, i: "_spacer_" }}
                            data-index={value.length}
                        ></div>
                    ),
                });
            }
            unusedOptions.forEach((item, i) => {
                gridItems.push({
                    key: getKey(item.value),
                    node: (
                        <div
                            className="sortInput-row sortInput-row_add"
                            key={getKey(item.value)}
                            data-key={getKey(item.value)}
                            data-index={value.length + i + 1}
                            data-grid={{
                                x: 0,
                                y: value.length + i + 1,
                                w: 1,
                                h: 1,
                                isDraggable: false,
                                i: getKey(item.value),
                            }}
                        >
                            <span className="sortInput-label">{labels.get(item.value) ?? getKey(item.value)}</span>
                            <ButtonIcon
                                icon="Add"
                                onClick={() => addValue(item.value)}
                                disabled={disabled}
                                label={languageString("ui.input.sort.add")}
                                borderless
                            />
                        </div>
                    ),
                });
            });
        }

        return gridItems.sort((a, b) => a.key.localeCompare(b.key)).map((a) => a.node);
    }, [value, unusedOptions, allowAddRemove, labels]);

    return (
        <div className={clsx("sortInput", { "is-disabled": disabled })}>
            <ResponsiveGridLayout
                cols={1}
                rowHeight={32}
                autoSize
                onLayoutChange={(layout) => {
                    const items = layout
                        .sort((a, b) => a.y - b.y)
                        .map((item) => {
                            return value.find((v) => getKey(v) === item.i);
                        })
                        .filter(isNotNil);
                    if (!isEqual(value, items)) {
                        onChange?.(items);
                    }
                }}
                containerPadding={[0, 0]}
                margin={[4, 4]}
                isResizable={false}
                isDraggable={!disabled}
                draggableCancel="button"
            >
                {gridItems}
            </ResponsiveGridLayout>
        </div>
    );
}

function getKey<T>(val: T) {
    return typeof val === "string" ? val : JSON.stringify(val);
}
