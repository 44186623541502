/* eslint-disable @typescript-eslint/unbound-method */
import isFunction from "lodash/isFunction";
import isNumber from "lodash/isNumber";
import React from "react";
import { languageString } from "../../../utilities/text";
import { seconds } from "../../../utilities/time";
import ButtonIcon from "../buttons/icon/ButtonIcon";
import HiddenSection from "../hiddenSection/HiddenSection";
import "./infoBox.css";

export interface InfoBoxProps {
    title?: string;
    children?: React.ReactNode;
    dismissible?: boolean | string; // Strings used as cache key for stored value
    storeDismissal?: boolean;
    onDismiss?: () => void;
    className?: string;
    autoDismiss?: number;
    animateIn?: boolean;
}

interface InfoBoxState {
    dismissed: boolean;
}

const dismissKey = "RB_infoBoxDismiss_";
const dismissalCache = {};

export default class InfoBox extends React.PureComponent<InfoBoxProps, InfoBoxState> {
    constructor(props: InfoBoxProps) {
        super(props);
        this.handleDismiss = this.handleDismiss.bind(this);
        this.state = {
            dismissed: this.props.animateIn || this.getDismissedState(),
        };
    }

    componentDidMount() {
        this.setState({
            dismissed: this.getDismissedState(),
        });
        if (isNumber(this.props.autoDismiss)) {
            setTimeout(this.handleDismiss, seconds(this.props.autoDismiss));
        }
    }

    getDismissedState() {
        if (typeof this.props.dismissible === "string") {
            if (this.props.storeDismissal) {
                return !!sessionStorage.getItem(dismissKey + this.props.dismissible);
            } else {
                return !!dismissalCache[this.props.dismissible];
            }
        }
        return false;
    }

    handleDismiss() {
        if (isFunction(this.props.onDismiss)) {
            this.props.onDismiss();
        } else {
            if (typeof this.props.dismissible === "string") {
                if (this.props.storeDismissal) {
                    sessionStorage.setItem(dismissKey + this.props.dismissible, "true");
                } else {
                    dismissalCache[this.props.dismissible] = true;
                }
            }
            this.setState({
                dismissed: true,
            });
        }
    }

    render() {
        return (
            <HiddenSection isHidden={this.state.dismissed} easing="ease-in-out" duration={200}>
                <div
                    className={`infoBox ${this.props.className || ""}`}
                    aria-hidden={this.state.dismissed}
                    aria-live="polite"
                    role="alert"
                >
                    {this.props.title && <h2 className="infoBox-title">{this.props.title}</h2>}

                    {this.props.children}

                    {this.props.dismissible && (
                        <ButtonIcon
                            icon="Close"
                            label={languageString("ui.alt.closeInfo", "Close")}
                            onClick={this.handleDismiss}
                            className="infoBox-dismiss"
                            borderless
                        />
                    )}
                </div>
            </HiddenSection>
        );
    }
}
