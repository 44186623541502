import { getTimestampParameters, getUserParameters, type GAEvent } from "@/utilities/analytics";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { type Dispatch, type MiddlewareAPI } from "redux";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { type State } from "../reducers/domain";
import { selectCampaign } from "../selectors/campaignSelectors";
import { orderService } from "../services";
import { RubyUserRole, type RubyCampaignId } from "../services/backend/RubyData";
import { ANALYTICS } from "../utilities/vars";

let hasInstalledGTM = false;

declare global {
    interface Window {
        dataLayer: object[];
    }
}

export function analyticsMiddleware(store: MiddlewareAPI) {
    return (next: Dispatch) => {
        return (action: ActionType) => {
            //Install GTM if permitted
            const cookieOptIn =
                (store.getState() as State).user.cookieOptOut === false ||
                (action.type === ActionName.SET_COOKIE_PREFS && action.payload === false);

            if (
                ANALYTICS.GTM_ID &&
                ANALYTICS.GTM_ID.startsWith("GTM-") &&
                cookieOptIn &&
                ANALYTICS.ENABLED &&
                !hasInstalledGTM
            ) {
                hasInstalledGTM = true;

                // @ts-expect-error - minified code
                // prettier-ignore
                // eslint-disable-next-line
                (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src= 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f); })(window,document,'script','dataLayer',ANALYTICS.GTM_ID);
            }

            // If GA is running
            if (window["dataLayer"] && !ANALYTICS.EVENT_BLACKLIST.includes(action.type)) {
                sendGA4Event(action, store);
                sendFormData(action, store);
            }

            return next(action);
        };
    };
}

function sendFormData(action: ActionType, _store: MiddlewareAPI) {
    switch (action.type) {
        case ActionName.SEND_SUPPORT_FORM:
        case ActionName.SEND_CONTACT_FORM: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const formData = action.payload.request;
                window["dataLayer"].push({
                    event: "formSubmission",
                    eventCategory: "formSubmission",
                    eventAction: "",
                    eventLabel: "",
                    eventValue: JSON.stringify(formData),
                });
            }
        }
    }
}

function sendGA4Event(action: ActionType, store: MiddlewareAPI) {
    const state = store.getState() as State;
    let gaEvent: GAEvent = null;
    switch (action.type) {
        case ActionName.LOCATION_CHANGE: {
            const payload = action.payload;
            gaEvent = {
                event: "GA4Pageview",
                location: payload.location.pathname,
            };
            break;
        }
        default: {
            gaEvent = formatGenericEvent(action, state);
            break;
        }
    }
    window["dataLayer"].push({
        ...gaEvent,
        ...getUserParameters(action, state),
        ...getTimestampParameters(),
    });
}

function formatGenericEvent(action: ActionType, state: State): GAEvent {
    let eventName: string = null;
    let eventStatus: string = null;
    let applicationName: string = null;
    let campaignId: string | number = null;
    let descriptionField: string = null;
    let eventValue: string | number = null;
    let conversionEventValue: number = null;

    const stateSuffix = /^(.*)_(REQUEST|ERROR|SUCCESS)$/;
    if (stateSuffix.test(action.type)) {
        eventName = action.type.replace(stateSuffix, "$1");
        eventStatus = action.type.replace(stateSuffix, "$2");
    } else {
        eventName = action.type;
    }

    if (state.router.location.pathname.startsWith("/campaigns/") && state.ui.activeCampaign) {
        campaignId = state.ui.activeCampaign;
    } else if (action.payload?.["campaignId"]) {
        campaignId = action.payload["campaignId"] as RubyCampaignId;
    } else if (
        isString(action.payload) &&
        /^[\w\d]{8}-[\w\d]{4}-[\w\d]{4}-[\w\d]{4}-[\w\d]{12}$/.test(action.payload)
    ) {
        campaignId = action.payload;
    } else if (action.payload?.["id"]) {
        campaignId = action.payload["id"] as RubyCampaignId;
    }

    if (campaignId) {
        const campaign = selectCampaign(state, parseInt(campaignId as string, 10));

        applicationName = campaign.data?.name ?? null;
        descriptionField = campaign.data?.description ?? null;
    }

    eventValue = getEventValue(action, state);
    conversionEventValue = getPaymentEventValue(action, state);

    return {
        event: "GA4Event",
        parameter1: eventName,
        parameter2: eventStatus,
        parameter3: applicationName,
        parameter4: campaignId,
        parameter5: descriptionField,
        parameter6: null, //userId
        parameter7: eventValue,
        parameter8: conversionEventValue,
        parameter9: null, //userType
    };
}

function getEventValue(action: ActionType, _state: State): string | number {
    switch (action.type) {
        case ActionName.LOGIN_SUCCESS:
            return action.payload.user.role === RubyUserRole.ADMIN ? "Admin" : null;
    }

    if (action.payload?.["error"]) {
        return action.payload["error"] as string;
    }
    if (action.payload?.["errorMessage"]) {
        return action.payload["errorMessage"] as string;
    }

    if (isString(action.payload) || isNumber(action.payload)) {
        return action.payload;
    }
    if (isBoolean(action.payload)) {
        return JSON.stringify(action.payload);
    }

    return null;
}

function getPaymentEventValue(action: ActionType, _state: State): number {
    switch (action.type) {
        case ActionName.ADD_CAMPAIGN_FUNDS:
        case ActionName.CREATE_CAMPAIGN: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const order = orderService.getLastPaidOrder();
                return order?.totalAmount ?? 0;
            }
            return null;
        }
        default:
            return null;
    }
}
