import clsx from "clsx";
import clamp from "lodash/clamp";
import { formatPercent } from "../../../../utilities/number";
import { type InputProps } from "../input/Input";
import "../input/input.css";
import "./range.css";

interface RangeProps extends InputProps {
    labelBelow?: boolean;
}

export default function Range({
    className,
    isInvalid,
    readOnly,
    inputRef,
    disabled,
    min = 0,
    max = 100,
    step = 1,
    value,
    labelBelow,
    ...props
}: RangeProps) {
    if (typeof min === "string") {
        min = parseFloat(min);
    }
    if (typeof max === "string") {
        max = parseFloat(max);
    }

    value = parseFloat(value as string);
    if (isNaN(value)) {
        value = 0;
    }

    let fillPercent = ((value - min) / (max - min)) * 100;
    if (isNaN(fillPercent)) {
        fillPercent = 0;
    }
    fillPercent = clamp(fillPercent, 0, 100);

    return (
        <div
            className={clsx("range", className, {
                "is-invalid": isInvalid,
                "is-readOnly": readOnly,
                range_labelBelow: labelBelow,
            })}
            style={{
                "--fill-percent": fillPercent,
            }}
        >
            <input
                className="input-facade range-input"
                {...props}
                aria-invalid={isInvalid}
                ref={inputRef}
                disabled={disabled || readOnly}
                min={min}
                max={max}
                step={step}
                type="range"
                value={value}
            />
            <div className="range-bar" />
            <div className="range-head" data-percent={formatPercent(fillPercent / 100, 0)} />
        </div>
    );
}
