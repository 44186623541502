import type { TimeFilter } from "@/utilities/timeFilter";
import { Field, type FieldProps } from "formik";
import isArray from "lodash/isArray";
import isBoolean from "lodash/isBoolean";
import React from "react";
import { v4 as uuid } from "uuid";
import ArrayInput from "../arrayInput/ArrayInput";
import Checkbox from "../checkbox/Checkbox";
import CountryInput from "../countryInput/CountryInput";
import CurrencyInput from "../currencyInput/CurrencyInput";
import DateInput from "../dateInput/DateInput";
import FileInput from "../fileInput/FileInput";
import { FormContext } from "../form/FormContext";
import Input from "../input/Input";
import LabeledInput from "../labeledInput/LabeledInput";
import Radio from "../radio/Radio";
import Range from "../range/Range";
import Select, { type SelectOption } from "../select/Select";
import type { SortInputProps } from "../sortInput/SortInput";
import SortInput from "../sortInput/SortInput";
import TextArea from "../textArea/TextArea";
import WeekHourInput, { type WeekHourValue } from "../weekHour/WeekHourInput";

type Inputs = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
interface BaseControlledInputProps extends React.InputHTMLAttributes<Inputs> {
    type: string;
    validate?: (value: unknown) => string | undefined;
    required?: boolean;
}

type ControlledInputProps = BaseControlledInputProps &
    (
        | {
              type:
                  | "text"
                  | "checkbox"
                  | "radio"
                  | "file"
                  | "textarea"
                  | "email"
                  | "password"
                  | "number"
                  | "range"
                  | "phone"
                  | "time";
          }
        | {
              type: "select";
              options: Record<string, string | SelectOption>;
          }
        | {
              type: "currency";
              currencyCode: string;
          }
        | {
              type: "array";
              emptyText?: string;
              displayFilter?: string;
              removeOnly?: boolean;
              formatter?: (value: string) => string;
          }
        | {
              type: "date";
              minimumDate?: number;
              maximumDate?: number;
              disableWeekends?: boolean;
              disabledDates?: number[];
              isDateRange?: boolean;
              showPresetRanges?: boolean;
              presetTimeRanges?: TimeFilter[];
          }
        | {
              type: "country";
              countryCodes?: string[];
              selectSingle?: boolean;
          }
        | {
              type: "weekHour";
              scaleWidth?: boolean;
          }
        | {
              type: "sort";
              allowAddRemove?: boolean;
              options?: SortInputProps<unknown>["options"];
          }
    );

type LabeledControlledInputProps = ControlledInputProps & {
    label: React.ReactNode;
    hint?: React.ReactNode;
    hideErrorText?: boolean;
    className?: string;
};

export default class ControlledInput extends React.PureComponent<LabeledControlledInputProps> {
    static defaultProps = {
        type: "text",
        required: true,
    };

    static Input(props: Readonly<ControlledInputProps>) {
        const safeId = props.id || uuid();

        return (
            <FormContext.Consumer>
                {(formState) => (
                    <Field name={props.name} validate={props.validate}>
                        {({ field, meta, form }: FieldProps<unknown>) => {
                            const touched = meta.touched || form.submitCount;
                            const disabled = props.disabled || !formState.hasPermission;
                            switch (props.type) {
                                case "checkbox": {
                                    return (
                                        <Checkbox
                                            {...props}
                                            type="checkbox"
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const value = e.target.value;
                                                if (value === "null" && Array.isArray(field.value)) {
                                                    let arr = [...field.value];
                                                    if (arr.includes(null)) {
                                                        arr = arr.filter((v) => v !== null);
                                                    } else {
                                                        arr.push(null);
                                                    }
                                                    void form.setFieldValue(field.name, arr);
                                                    return;
                                                }
                                                field.onChange(e);
                                            }}
                                            value={props.value}
                                            checked={
                                                isBoolean(field.value)
                                                    ? field.value
                                                    : isArray(field.value)
                                                    ? !!field.value && field.value.includes(props.value)
                                                    : !!field.value
                                            }
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "radio": {
                                    return (
                                        <Radio
                                            {...props}
                                            type="radio"
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                let value = e.target.value;
                                                // React for some dumb reason casts nulls to ""
                                                if (value === "null" || value === "") {
                                                    value = null;
                                                }
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            value={props.value}
                                            // intentionally double equals to allow value coercion
                                            // eslint-disable-next-line
                                            checked={field.value == props.value}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "select": {
                                    return (
                                        <Select
                                            {...props}
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                let value = e.target.value;
                                                if (value === "null") {
                                                    value = null;
                                                }
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            value={field.value as string}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        >
                                            {props.options}
                                        </Select>
                                    );
                                }
                                case "currency": {
                                    return (
                                        <CurrencyInput
                                            {...props}
                                            type={props.type}
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            value={field.value as number}
                                            isInvalid={touched && !!meta.error}
                                            currencyCode={props.currencyCode}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "array": {
                                    return (
                                        <ArrayInput
                                            {...props}
                                            value={(field.value as []) || []}
                                            id={safeId}
                                            onChange={(value) => {
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            isInvalid={touched && !!meta.error}
                                            emptyText={props.emptyText}
                                            displayFilter={props.displayFilter}
                                            removeOnly={props.removeOnly}
                                            formatter={props.formatter}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "file": {
                                    return (
                                        <FileInput
                                            {...props}
                                            type={props.type}
                                            id={safeId}
                                            name={field.name}
                                            value={(field.value as File) || undefined}
                                            onChange={(value) => {
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "textarea": {
                                    return (
                                        <TextArea
                                            {...props}
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            value={(field.value as string) ?? ""}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "date": {
                                    return (
                                        <DateInput
                                            {...props}
                                            defaultValue={null}
                                            value={field.value as Date | [Date, Date]}
                                            minDate={props.minimumDate ? new Date(props.minimumDate) : undefined}
                                            maxDate={props.maximumDate ? new Date(props.maximumDate) : undefined}
                                            selectRange={props.isDateRange}
                                            onChange={(value: Date | [Date, Date]) => {
                                                form.setFieldTouched(field.name);
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "country": {
                                    return (
                                        <CountryInput
                                            name={field.name}
                                            id={safeId}
                                            value={(field.value as string[]) ?? []}
                                            onChange={(value: string[]) => {
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            countryCodes={props.countryCodes}
                                            selectSingle={props.selectSingle}
                                        />
                                    );
                                }
                                case "range": {
                                    return (
                                        <Range
                                            {...props}
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            value={(field.value as number) ?? 0}
                                            isInvalid={touched && !!meta.error}
                                            labelBelow
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "weekHour": {
                                    return (
                                        <WeekHourInput
                                            {...props}
                                            value={(field.value as WeekHourValue) || {}}
                                            id={safeId}
                                            onChange={(value) => {
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            isInvalid={touched && !!meta.error}
                                            scaleWidth={props.scaleWidth}
                                            disabled={disabled}
                                        />
                                    );
                                }
                                case "sort": {
                                    return (
                                        <SortInput
                                            disabled={disabled}
                                            options={props.options}
                                            allowAddRemove={props.allowAddRemove}
                                            onChange={(value) => {
                                                void form.setFieldValue(field.name, value);
                                            }}
                                            value={field.value as unknown[]}
                                        />
                                    );
                                }
                                default: {
                                    return (
                                        <Input
                                            {...props}
                                            type={props.type}
                                            id={safeId}
                                            name={field.name}
                                            onBlur={field.onBlur}
                                            onChange={field.onChange}
                                            value={(field.value as string) ?? ""}
                                            isInvalid={touched && !!meta.error}
                                            disabled={disabled}
                                        />
                                    );
                                }
                            }
                        }}
                    </Field>
                )}
            </FormContext.Consumer>
        );
    }

    render() {
        const { name, className, label, type, hint, id, hideErrorText, required } = this.props;

        const isCheckbox = type === "checkbox" || type === "radio";
        const safeId = id || uuid();

        return (
            <Field name={name}>
                {({ meta, form }: FieldProps<unknown>) => {
                    const touched = meta.touched || form.submitCount;
                    return (
                        <LabeledInput
                            className={className}
                            label={label}
                            inputId={safeId}
                            errorMessage={!hideErrorText && touched && meta.error}
                            hint={hint}
                            isCheckbox={isCheckbox}
                            placeHintAbove={type === "array" || type === "country"}
                            required={required}
                        >
                            <ControlledInput.Input {...this.props} id={safeId} className="" required={null} />
                        </LabeledInput>
                    );
                }}
            </Field>
        );
    }
}
