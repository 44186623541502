import { languageString } from "../../../utilities/text";
import ActionPage from "../../assemblies/actionPage/ActionPage";
import Authenticate from "../../assemblies/login/authenticate/Authenticate";

export default function AuthenticatePage() {
    return (
        <ActionPage title={languageString("login.title", "Login")}>
            <Authenticate />
        </ActionPage>
    );
}
