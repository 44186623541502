import { type DemoToken } from "../reducers/domain";
import {
    type RubyListResponse,
    type RubyNotification,
    type RubyNotificationSettings,
    type RubyTeamId,
    type RubyTeamInvite,
    type RubyUserHistoryRecordQuery,
    type RubyUserRecord,
} from "../services/backend/RubyData";
import { requestActions, type RequestAction } from "./Action";

export enum AccountActionName {
    LIST_NOTIFICATIONS = "LIST_NOTIFICATIONS",
    LOAD_NOTIFICATIONS_PREFERENCES = "LOAD_NOTIFICATIONS_PREFERENCES",
    SAVE_NOTIFICATIONS_PREFERENCES = "SAVE_NOTIFICATIONS_PREFERENCES",
    DELETE_ACCOUNT = "DELETE_ACCOUNT",
    ANSWER_TEAM_INVITE = "ANSWER_TEAM_INVITE",
    LIST_USERS_TEAM_INVITES = "LIST_USERS_TEAM_INVITES",
    LIST_USER_HISTORY_RECORDS = "LIST_USER_HISTORY_RECORDS",
    DELETE_NOTIFICATION = "DELETE_NOTIFICATION",
    DELETE_ALL_NOTIFICATIONS = "DELETE_ALL_NOTIFICATIONS",
    CREATE_DEMO_TOKEN = "CREATE_DEMO_TOKEN",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace AccountActions {
    export type DeleteAccountAction = RequestAction<AccountActionName.DELETE_ACCOUNT, void, void>;
    export type ListNotificationsAction = RequestAction<
        AccountActionName.LIST_NOTIFICATIONS,
        void,
        RubyListResponse<RubyNotification>
    >;
    export type LoadNotificationsPrefsAction = RequestAction<
        AccountActionName.LOAD_NOTIFICATIONS_PREFERENCES,
        void,
        RubyNotificationSettings
    >;

    export type SaveNotificationsPrefsAction = RequestAction<
        AccountActionName.SAVE_NOTIFICATIONS_PREFERENCES,
        RubyNotificationSettings,
        RubyNotificationSettings
    >;

    export type DeleteNotificationAction = RequestAction<
        AccountActionName.DELETE_NOTIFICATION,
        RubyNotification["id"],
        void
    >;

    export type DeleteNotificationsAction = RequestAction<AccountActionName.DELETE_ALL_NOTIFICATIONS, void, void>;

    export type AnswerInviteAction = RequestAction<
        AccountActionName.ANSWER_TEAM_INVITE,
        { inviteId: RubyTeamInvite["id"]; answer: boolean; teamId: RubyTeamId },
        void
    >;

    export type ListUsersTeamInvitesAction = RequestAction<
        AccountActionName.LIST_USERS_TEAM_INVITES,
        void,
        RubyTeamInvite[]
    >;

    export type ListUserHistoryRecordsAction = RequestAction<
        AccountActionName.LIST_USER_HISTORY_RECORDS,
        RubyUserHistoryRecordQuery,
        RubyUserRecord[]
    >;
    export type CreateDemoTokenAction = RequestAction<
        AccountActionName.CREATE_DEMO_TOKEN,
        {
            email: string;
            duration: number;
        },
        DemoToken
    >;
}

export type AccountActionType =
    | AccountActions.ListNotificationsAction
    | AccountActions.LoadNotificationsPrefsAction
    | AccountActions.SaveNotificationsPrefsAction
    | AccountActions.DeleteNotificationAction
    | AccountActions.DeleteNotificationsAction
    | AccountActions.DeleteAccountAction
    | AccountActions.AnswerInviteAction
    | AccountActions.ListUsersTeamInvitesAction
    | AccountActions.ListUserHistoryRecordsAction
    | AccountActions.CreateDemoTokenAction;

export const notificationPrefsAction = {
    load: requestActions<AccountActions.LoadNotificationsPrefsAction>(AccountActionName.LOAD_NOTIFICATIONS_PREFERENCES),
    save: requestActions<AccountActions.SaveNotificationsPrefsAction>(AccountActionName.SAVE_NOTIFICATIONS_PREFERENCES),
};

export const notificationsAction = {
    list: requestActions<AccountActions.ListNotificationsAction>(AccountActionName.LIST_NOTIFICATIONS),
    delete: requestActions<AccountActions.DeleteNotificationAction>(AccountActionName.DELETE_NOTIFICATION),
    deleteAll: requestActions<AccountActions.DeleteNotificationsAction>(AccountActionName.DELETE_ALL_NOTIFICATIONS),
};

export const usersTeamInviteAction = {
    list: requestActions<AccountActions.ListUsersTeamInvitesAction>(AccountActionName.LIST_USERS_TEAM_INVITES),
    answer: requestActions<AccountActions.AnswerInviteAction>(AccountActionName.ANSWER_TEAM_INVITE),
};

export const deleteAccountAction = requestActions<AccountActions.DeleteAccountAction>(AccountActionName.DELETE_ACCOUNT);

export const listUserHistoryRecords = requestActions<AccountActions.ListUserHistoryRecordsAction>(
    AccountActionName.LIST_USER_HISTORY_RECORDS
);

export const createDemoTokenAction = requestActions<AccountActions.CreateDemoTokenAction>(
    AccountActionName.CREATE_DEMO_TOKEN
);
