import { languageString } from "../../../utilities/text";
import { CONTACT_EMAIL } from "../../../utilities/vars";
import Card from "../../blocks/card/basic/Card";
import Logo from "../../blocks/logo/Logo";
import MailTo from "../../blocks/mailTo/MailTo";
import "./downtime.css";

export default function Downtime() {
    return (
        <div className="downtime">
            <Card className="downtime-card">
                <Logo />
                <h1 className="downtime-title u-pt32">{languageString("downtime.planned.title")}</h1>
                <p className="u-pt16">{languageString("downtime.planned.message")}</p>
                <p className="u-pt16">
                    {languageString("downtime.planned.contact")} <MailTo email={CONTACT_EMAIL} />
                </p>
            </Card>
        </div>
    );
}
