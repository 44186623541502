import { OverlayAlignment, OverlayDirection, type PositionValidationProps, type ValidPositionProps } from "./domain";

export function getValidPosition(
    rects: PositionValidationProps,
    preferredDirection: OverlayDirection,
    alignment: OverlayAlignment = OverlayAlignment.CENTER
): ValidPositionProps {
    // Sort the directions in preference order
    const dirs = Object.values(OverlayDirection).sort((a, b) => {
        if (a === preferredDirection) {
            return -1;
        }
        if (b === preferredDirection) {
            return 1;
        }
        return 0;
    });

    // Find a valid position and return it
    for (const dir of dirs) {
        const position = isPositionValid({
            direction: dir,
            alignment,
            ...rects,
        });
        if (position) {
            return position;
        }
    }

    // Fallback to returning the preferred direction
    return buildPosition({
        direction: preferredDirection,
        alignment,
        ...rects,
    });
}

interface PositionValidationDirectionProps extends PositionValidationProps {
    direction: OverlayDirection;
    alignment: OverlayAlignment;
}

const MENU_WINDOW_BORDER = 8;

function buildPosition(props: PositionValidationDirectionProps): ValidPositionProps {
    const position = {
        x: props.windowRect.x + props.targetRect.x,
        y: props.windowRect.y + props.targetRect.y,
        direction: props.direction,
        centerOffset: 0,
    };

    if (props.direction === OverlayDirection.UP) {
        position.y -= props.menuRect.height;
    } else if (props.direction === OverlayDirection.DOWN) {
        position.y = position.y + props.targetRect.height;
    } else if (props.direction === OverlayDirection.LEFT) {
        position.x = position.x - props.menuRect.width;
    } else if (props.direction === OverlayDirection.RIGHT) {
        position.x = position.x + props.targetRect.width;
    }

    if (props.direction === OverlayDirection.UP || props.direction === OverlayDirection.DOWN) {
        switch (props.alignment) {
            case OverlayAlignment.CENTER: {
                position.x += props.targetRect.width / 2 - props.menuRect.width / 2;
                break;
            }
            case OverlayAlignment.START: {
                position.centerOffset = 0 - props.menuRect.width / 2 + props.targetRect.width / 2;
                break;
            }
            case OverlayAlignment.END: {
                position.x = position.x - props.menuRect.width + props.targetRect.width;
                position.centerOffset = props.menuRect.width / 2 - props.targetRect.width / 2;
                break;
            }
        }
    } else if (props.direction === OverlayDirection.LEFT || props.direction === OverlayDirection.RIGHT) {
        switch (props.alignment) {
            case OverlayAlignment.CENTER: {
                position.y += props.targetRect.height / 2 - props.menuRect.height / 2;
                break;
            }
            case OverlayAlignment.START: {
                position.centerOffset = 0 - props.menuRect.height / 2 + props.targetRect.height / 2;
                break;
            }
            case OverlayAlignment.END: {
                position.y = position.y - props.menuRect.height + props.targetRect.height;
                position.centerOffset = props.menuRect.height / 2 - props.targetRect.height / 2;
                break;
            }
        }
    }

    if (props.alignment === OverlayAlignment.CENTER) {
        // Check screen bounds offsets
        if (props.direction === OverlayDirection.UP || props.direction === OverlayDirection.DOWN) {
            const startPos = position.x;
            const rightEdge = props.windowRect.width + props.windowRect.x - MENU_WINDOW_BORDER;
            const leftEdge = props.windowRect.x + MENU_WINDOW_BORDER;
            // OOB right
            if (position.x + props.menuRect.width > rightEdge) {
                position.x = rightEdge - props.menuRect.width;
            }
            // OOB left
            if (position.x < leftEdge) {
                position.x = leftEdge;
            }
            position.centerOffset = Math.round(startPos - position.x);

            // Correct for over adjusting
            const maxOffset = props.menuRect.width / 2 - MENU_WINDOW_BORDER;
            if (position.centerOffset > maxOffset) {
                position.x = startPos - maxOffset;
                position.centerOffset = maxOffset;
            }
            if (position.centerOffset < -maxOffset) {
                position.x = startPos + maxOffset;
                position.centerOffset = -maxOffset;
            }
        } else {
            const startPos = position.y;
            const bottomEdge = props.windowRect.height + props.windowRect.y - MENU_WINDOW_BORDER;
            const topEdge = props.windowRect.y + MENU_WINDOW_BORDER;
            //OOB down
            if (position.y + props.menuRect.height > bottomEdge) {
                position.y = bottomEdge - props.menuRect.height;
            }
            //OOB up
            if (position.y < topEdge) {
                position.y = topEdge;
            }
            position.centerOffset = Math.round(startPos - position.y);

            // Correct for over adjusting
            const maxOffset = props.menuRect.height / 2 - MENU_WINDOW_BORDER;
            if (position.centerOffset > maxOffset) {
                position.y = startPos - maxOffset;
                position.centerOffset = maxOffset;
            }
            if (position.centerOffset < -maxOffset) {
                position.y = startPos + maxOffset;
                position.centerOffset = -maxOffset;
            }
        }
    }

    return {
        ...position,
        x: Math.round(position.x),
        y: Math.round(position.y),
        width: props.menuRect.width,
        height: props.menuRect.height,
        rects: {
            menuRect: props.menuRect,
            targetRect: props.targetRect,
            windowRect: props.windowRect,
        },
    };
}

function isPositionValid(props: PositionValidationDirectionProps) {
    const position = buildPosition(props);

    if (
        position.x > props.windowRect.x &&
        position.x + props.menuRect.width < props.windowRect.x + props.windowRect.width &&
        position.y > props.windowRect.y &&
        position.y + props.menuRect.height < props.windowRect.y + props.windowRect.height
    ) {
        return position;
    }

    return null;
}
