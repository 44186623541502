import {
    type RubyBudgetPlan,
    type RubyCampaignId,
    type RubyKeyword,
    type RubyKeywordId,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { type Action } from "./Action";

export enum UIActionName {
    TOGGLE_MODAL = "TOGGLE_MODAL",
    TOGGLE_NAVIGATION = "TOGGLE_NAVIGATION",
    SET_ACTIVE_TEAM = "SET_ACTIVE_TEAM",
    SET_ACTIVE_CAMPAIGN = "SET_ACTIVE_CAMPAIGN",
    SET_ACTIVE_KEYWORD = "SET_ACTIVE_KEYWORD",
    SET_ACTIVE_EVENT = "SET_ACTIVE_EVENT",
    SET_EDITING_KEYWORDS = "SET_EDITING_KEYWORDS",
    SET_EDITING_BUDGET = "SET_EDITING_BUDGET",
    ERROR = "ERROR",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace UIActions {
    export type ToggleModalAction = Action<
        UIActionName.TOGGLE_MODAL,
        {
            id: string;
            state: boolean;
        }
    >;
    export type ToggleNavigationAction = Action<UIActionName.TOGGLE_NAVIGATION, boolean>;
    export type SetActiveTeamAction = Action<UIActionName.SET_ACTIVE_TEAM, RubyTeamId>;
    export type SetActiveCampaignAction = Action<
        UIActionName.SET_ACTIVE_CAMPAIGN,
        {
            campaignId: RubyCampaignId;
            teamId: RubyTeamId;
        }
    >;
    export type SetActiveKeywordAction = Action<UIActionName.SET_ACTIVE_KEYWORD, RubyKeywordId>;
    export type SetActiveEventAction = Action<UIActionName.SET_ACTIVE_EVENT, string>;
    export type SetEditingKeywordsAction = Action<
        UIActionName.SET_EDITING_KEYWORDS,
        {
            keywords: RubyKeyword[];
            negative: boolean;
        }
    >;
    export type SetEditingBudgetAction = Action<UIActionName.SET_EDITING_BUDGET, RubyBudgetPlan["id"]>;
    export type ErrorAction = Action<UIActionName.ERROR, Error | string>;
}

export type UIActionType =
    | UIActions.ToggleModalAction
    | UIActions.ToggleNavigationAction
    | UIActions.SetActiveTeamAction
    | UIActions.SetActiveCampaignAction
    | UIActions.SetActiveKeywordAction
    | UIActions.SetActiveEventAction
    | UIActions.SetEditingKeywordsAction
    | UIActions.SetEditingBudgetAction
    | UIActions.ErrorAction;

export function toggleModalAction(id: string, state: boolean): UIActions.ToggleModalAction {
    return {
        type: UIActionName.TOGGLE_MODAL,
        payload: {
            id,
            state,
        },
    };
}

export function toggleNavigationAction(isOpen: boolean): UIActions.ToggleNavigationAction {
    return {
        type: UIActionName.TOGGLE_NAVIGATION,
        payload: isOpen,
    };
}

export function setActiveTeamAction(teamId: RubyTeamId): UIActions.SetActiveTeamAction {
    return {
        type: UIActionName.SET_ACTIVE_TEAM,
        payload: teamId,
    };
}

export function setActiveCampaignAction({
    teamId,
    campaignId,
}: {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
}): UIActions.SetActiveCampaignAction {
    return {
        type: UIActionName.SET_ACTIVE_CAMPAIGN,
        payload: {
            teamId,
            campaignId,
        },
    };
}

export function setActiveKeywordAction(keywordId: RubyKeywordId): UIActions.SetActiveKeywordAction {
    return {
        type: UIActionName.SET_ACTIVE_KEYWORD,
        payload: keywordId,
    };
}

export function setActiveEventAction(event: string): UIActions.SetActiveEventAction {
    return {
        type: UIActionName.SET_ACTIVE_EVENT,
        payload: event,
    };
}

export function setEditingKeywordsAction(
    keywords: RubyKeyword[],
    negative: boolean
): UIActions.SetEditingKeywordsAction {
    return {
        type: UIActionName.SET_EDITING_KEYWORDS,
        payload: {
            keywords,
            negative,
        },
    };
}

export function setEditingBudgetAction(planId: RubyBudgetPlan["id"]): UIActions.SetEditingBudgetAction {
    return {
        type: UIActionName.SET_EDITING_BUDGET,
        payload: planId,
    };
}

export function errorAction(error: Error | string): UIActions.ErrorAction {
    return {
        type: UIActionName.ERROR,
        payload: error,
    };
}
