import { formatDay, formatHour, formatWeekHour } from "@/utilities/date";
import clsx from "clsx";
import range from "lodash/range";
import { useCallback, useRef } from "react";
import { v4 as uuid } from "uuid";
import "./weekHourInput.css";

// Value keys are the numbers 0 - 167
//   0 == 00:00 Sunday
//   1 == 01:00 Sunday
//  25 == 01:00 Monday
// 167 == 23:00 Saturday

export type WeekHourValue = Record<number, boolean>;

interface WeekHourInputProps {
    className?: string;
    value: WeekHourValue;
    onChange?: (values: WeekHourValue) => void;
    isInvalid?: boolean;
    disabled?: boolean;
    id?: string;
    scaleWidth?: boolean;
}

const weekHours = range(0, 168);
const hours = range(0, 24);
const days = range(0, 7);

const defaultValues = weekHours.reduce((values, weekHour) => {
    values[weekHour] = false;
    return values;
}, {});

const isDayChecked = (value: WeekHourValue, day: number) => {
    const values = Object.entries(value);
    const checked = values.filter(([d, s]) => s && Math.floor(parseInt(d, 10) / 24) === day);
    return checked.length > 0;
};

const isHourChecked = (value: WeekHourValue, hour: number) => {
    const values = Object.entries(value);
    const checked = values.filter(([h, s]) => s && Math.floor(parseInt(h, 10) % 24) === hour);
    return checked.length > 0;
};

export default function WeekHourInput({
    className,
    isInvalid,
    onChange,
    value,
    disabled,
    id,
    scaleWidth = true,
}: WeekHourInputProps) {
    const name = useRef(uuid());

    const handleWeekDayChange = useCallback(
        (weekHour: number, state: boolean) => {
            onChange({
                ...defaultValues,
                ...value,
                [weekHour]: state,
            });
        },
        [value, onChange]
    );

    const handleDayChange = useCallback(
        (day: number, _state: boolean) => {
            const nextValue = {
                ...defaultValues,
                ...value,
            };
            const checked = isDayChecked(value, day);
            range(day * 24, (day + 1) * 24).forEach((weekHour) => {
                nextValue[weekHour] = !checked;
            });
            onChange(nextValue);
        },
        [value, onChange]
    );

    const handleHourChange = useCallback(
        (hour: number, _state: boolean) => {
            const nextValue = {
                ...defaultValues,
                ...value,
            };
            const checked = isHourChecked(value, hour);
            range(hour, hour + 167, 24).forEach((hour) => {
                nextValue[hour] = !checked;
            });
            onChange(nextValue);
        },
        [value, onChange]
    );

    return (
        <div className="weekHourInput~root">
            <fieldset
                className={clsx("weekHourInput", className, {
                    "is-invalid": isInvalid,
                    "is-disabled": disabled,
                    weekHourInput_inline: !scaleWidth,
                })}
                id={id}
            >
                {days.map((day) => {
                    const label = formatDay(day);
                    return (
                        <div className="weekHourInput-label weekHourInput-label_v weekHourInput-day" key={day}>
                            <span>{label}</span>
                            <WeekHourCheckbox
                                name={name.current + "-days"}
                                disabled={disabled}
                                value={day}
                                isChecked={isDayChecked(value, day)}
                                onChange={handleDayChange}
                                label={label}
                            />
                        </div>
                    );
                })}
                {hours.map((hour) => {
                    const label = formatHour(hour);
                    return (
                        <div className="weekHourInput-label weekHourInput-label_h weekHourInput-hour" key={hour}>
                            <span>{label}</span>
                            <WeekHourCheckbox
                                name={name.current + "-hours"}
                                disabled={disabled}
                                value={hour}
                                isChecked={isHourChecked(value, hour)}
                                onChange={handleHourChange}
                                label={label}
                            />
                        </div>
                    );
                })}
                {weekHours.map((weekHour) => {
                    const hour = weekHour % 24;
                    return (
                        <WeekHourCheckbox
                            name={name.current}
                            disabled={disabled}
                            key={weekHour}
                            value={weekHour}
                            isChecked={value[weekHour]}
                            globBefore={hour !== 0 && value[weekHour - 1]}
                            globAfter={hour !== 23 && value[weekHour + 1]}
                            onChange={handleWeekDayChange}
                            label={formatWeekHour(weekHour)}
                        />
                    );
                })}
            </fieldset>
        </div>
    );
}

interface WeekHourCheckboxProps {
    isChecked?: boolean;
    isInvalid?: boolean;
    disabled?: boolean;
    name?: string;
    value?: number;
    onChange?: (value: number, checked: boolean) => void;
    globBefore?: boolean;
    globAfter?: boolean;
    label: string;
}

function WeekHourCheckbox(props: WeekHourCheckboxProps) {
    const id = useRef(uuid());
    return (
        <label className="weekHourInput-boxWrap" htmlFor={id.current}>
            <div className="u-invisible">{props.label}</div>
            <input
                className="weekHourInput-input"
                type="checkbox"
                name={props.name}
                value={props.value}
                checked={props.isChecked}
                disabled={props.disabled}
                onChange={() => {
                    props.onChange(props.value, !props.isChecked);
                }}
                id={id.current}
            />
            <span
                className={clsx("weekHourInput-box", {
                    "is-checked": props.isChecked,
                    "is-globBefore": props.globBefore,
                    "is-globAfter": props.globAfter,
                })}
            ></span>
        </label>
    );
}
