import { Field, Formik, type FieldProps, type FormikErrors, type FormikHelpers } from "formik";
import isObject from "lodash/isObject";
import type React from "react";
import { tabbable } from "tabbable";
import { type Schema } from "yup";
import { languageString, writeList } from "../../../utilities/text";
import AutoHeight from "../autoHeight/AutoHeight";
import type ButtonLink from "../buttons/link/ButtonLink";
import type Button from "../buttons/standard/Button";
import ErrorMessage from "../errors/errorMessage/ErrorMessage";
import HintIcon from "../hintIcon/HintIcon";
import KeyPairTable from "../keyPairTable/KeyPairTable";
import Modal, { type ModalProps } from "../modal/Modal";

export interface WizardStep<T = unknown> {
    nextButton?: React.ReactElement<Button | ButtonLink>;
    prevButton?: React.ReactElement<Button | ButtonLink>;
    onSubmit?: (data: T, actions: FormikHelpers<T>) => void;
    title?: string;
    validationSchema?: Schema;
    initialValues: T;
    body: React.ReactNode;
}

interface ArrayWizardProps extends Omit<ModalProps, "children"> {
    children: WizardStep[];
    step: number;
}

interface IndexedWizardProps<T extends string = string> extends Omit<ModalProps, "children"> {
    children: { [K in T]?: WizardStep };
    step: T;
}

type WizardProps<T extends string = string> = ArrayWizardProps | IndexedWizardProps<T>;

// @ts-expect-error TS is mad that the type of `children` is wrong,
//                  fixing this would be a nasty refactor and I just
//                  want it to build right now
export default class Wizard<T extends string> extends Modal<WizardProps<T>> {
    static defaultProps = {
        fullWidth: true,
    };

    getStepData(): WizardStep<unknown> {
        if (isChildrenList(this.props)) {
            const steps = this.props.children.filter((child) => !!child?.body && !!child?.initialValues);
            if (this.props.step >= 0 && this.props.step < steps.length) {
                return steps[this.props.step];
            }
            console.error("Error: Wizard step index out of bounds");
            return {
                body: (
                    <>
                        Step: {this.props.step + 1} / {steps.length}
                    </>
                ),
                initialValues: {},
            };
        }
        if (isChildrenMap(this.props)) {
            const step = this.props.children[this.props.step];
            if (step) {
                return step;
            }
            console.error("Error: Wizard step id not found");
            return {
                body: (
                    <>
                        Step '{this.props.step}' not in {writeList(Object.keys(this.props.children), "disjunction")}
                    </>
                ),
                initialValues: {},
            };
        }
    }

    componentDidUpdate(prevProps: WizardProps<T>) {
        super.componentDidUpdate(prevProps);
        if (this.props.isOpen && this.props.step !== prevProps.step && this.modalRef.current) {
            setTimeout(() => {
                const modalTabbables = tabbable(this.modalRef.current);
                modalTabbables[0]?.focus();
            }, 100);
        }
    }

    renderContainer(children: React.ReactNode) {
        const stepData = this.getStepData();
        return super.renderContainer(
            <Formik
                enableReinitialize
                validateOnBlur={false}
                initialValues={stepData.initialValues}
                onSubmit={stepData.onSubmit}
                validationSchema={stepData.validationSchema}
            >
                {({ handleSubmit }) => <form onSubmit={handleSubmit}>{children}</form>}
            </Formik>
        );
    }

    renderHeader(): React.ReactNode {
        return (
            <header className="modal-header" id={`modalHeader-${this.uniqueId}`}>
                {this.getStepData().title || this.props.title}
            </header>
        );
    }

    renderFooter() {
        const stepData = this.getStepData();
        if (stepData.nextButton || stepData.prevButton) {
            if (stepData.prevButton && stepData.prevButton.props["type"] !== "button") {
                console.warn("Sanity check: Should your wizard previous button have type='button'?");
            }
            return (
                <footer className="modal-footer">
                    <Field name="wizardErrors">
                        {({ form }: FieldProps) => {
                            const errors = Object.values(form.errors);
                            return (
                                errors.length > 0 &&
                                form.submitCount > 0 && (
                                    <ErrorMessage className="modal-error">
                                        {languageString("ui.modalWizard.containsErrors", "Form contains {1} errors", [
                                            errors.length,
                                        ])}
                                        <HintIcon inline>{this.renderErrorObject(form.errors)}</HintIcon>
                                    </ErrorMessage>
                                )
                            );
                        }}
                    </Field>
                    {stepData.nextButton ?? <div />}
                    {stepData.prevButton ?? <div />}
                </footer>
            );
        }
        return null;
    }

    renderErrorObject<T = unknown>(errors: FormikErrors<T>) {
        return (
            <KeyPairTable>
                {Object.entries(errors).map(([field, error]) => {
                    return {
                        label: field,
                        value: JSON.stringify(error),
                    };
                })}
            </KeyPairTable>
        );
    }

    renderChildren() {
        return (
            <AutoHeight>
                <div className="modal-innerBody">{this.getStepData().body}</div>
            </AutoHeight>
        );
    }
}

function isChildrenList<T extends string>(props: WizardProps<T>): props is ArrayWizardProps {
    return typeof props.step === "number" && Array.isArray(props.children);
}

function isChildrenMap<T extends string>(props: WizardProps<T>): props is IndexedWizardProps<T> {
    return typeof props.step === "string" && isObject(props.children);
}
