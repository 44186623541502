import type { RubyTeamId } from "@/services/backend/RubyData";
import isNumber from "lodash/isNumber";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { type State, type UIState } from "./domain";

export const initialUIState: UIState = {
    modals: {},
    navOpen: false,
    activeTeam: null,
    activeCampaign: null,
    activeKeyword: null,
    activeEvent: null,
    editingKeywords: {
        keywords: [],
        negative: false,
    },
    editingBudget: null,
    error: null,
};

export default function accountReducer(state: UIState = initialUIState, action: ActionType, _globalState: State) {
    switch (action.type) {
        case ActionName.TOGGLE_MODAL: {
            state.modals[action.payload.id] = action.payload.state;
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            state.modals = Object.keys(state.modals).reduce((modalState, key) => {
                modalState[key] = false;
                return modalState;
            }, {} as Record<string, boolean>);
            break;
        }

        case ActionName.TOGGLE_NAVIGATION: {
            state.navOpen = action.payload;
            break;
        }

        case ActionName.SET_ACTIVE_TEAM: {
            state.activeTeam = isNumber(action.payload) && action.payload > 0 ? action.payload : state.activeTeam;
            break;
        }

        case ActionName.LOGIN_SUCCESS: {
            const metadata = action.payload.user?.metadata ?? {};
            const storedTeamId = "activeTeam" in metadata ? (metadata.activeTeam as RubyTeamId) : null;
            state.activeTeam = storedTeamId ?? state.activeTeam;
            break;
        }

        case ActionName.LIST_TEAMS: {
            if (action.payload.state === RequestActionState.SUCCESS && action.payload.response.length) {
                state.activeTeam ??= action.payload.response[0].id;
            }
            break;
        }

        case ActionName.SET_ACTIVE_CAMPAIGN: {
            if (isNumber(action.payload.campaignId) && action.payload.campaignId > 0) {
                state.activeCampaign = action.payload.campaignId;
            }
            if (isNumber(action.payload.teamId) && action.payload.teamId > 0) {
                state.activeTeam = action.payload.teamId;
            }
            break;
        }

        case ActionName.SET_ACTIVE_KEYWORD: {
            state.activeKeyword = action.payload;
            break;
        }

        case ActionName.SET_ACTIVE_EVENT: {
            state.activeEvent = action.payload;
            break;
        }

        case ActionName.SET_EDITING_KEYWORDS: {
            state.editingKeywords = { ...action.payload };
            break;
        }

        case ActionName.SET_EDITING_BUDGET: {
            state.editingBudget = action.payload;
            break;
        }

        case ActionName.ERROR: {
            state.error =
                action.payload instanceof Error
                    ? `${action.payload.name}: ${action.payload.message}`
                    : `${action.payload}`;
            break;
        }
    }
}
