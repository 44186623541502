import { authService, rubyService } from "@/services";
import { push } from "@lagunovsky/redux-react-router";
import { NotificationType } from "@redbox-ruby/data-lib";
import { put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    createDemoTokenAction,
    deleteAccountAction,
    listUserHistoryRecords,
    notificationPrefsAction,
    notificationsAction,
    usersTeamInviteAction,
    type AccountActions,
} from "../actions/accountActions";
import { teamsActions } from "../actions/teamActions";
import { setActiveTeamAction } from "../actions/uiActions";
import { selectUserHistoryRecords } from "../selectors/userSelectors";
import { generateToken } from "../utilities/jwt";
import {
    runDataRequestAction,
    runPaginatedDataAction,
    runRequestAction,
    selectFromState,
    takeRequests,
} from "../utilities/saga";
import { getUrl } from "../utilities/url";

function* loadUserNotificationsSaga(action: ExtractRequestActionRequestType<AccountActions.ListNotificationsAction>) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runPaginatedDataAction(
        action,
        notificationsAction.list,
        (state) => state.account.notifications,
        async function (req, opts) {
            return await rubyService.notifications.listMessages({ id: userId }, opts);
        }
    );
}

function* deleteNotificationSaga(action: ExtractRequestActionRequestType<AccountActions.DeleteNotificationAction>) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runRequestAction(action, notificationsAction.delete, async (req) => {
        await rubyService.notifications.deleteMessage({ userId: userId, notificationId: req });
    });
}

function* deleteNotificationsSaga(action: ExtractRequestActionRequestType<AccountActions.DeleteNotificationsAction>) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runRequestAction(action, notificationsAction.deleteAll, async () => {
        await rubyService.notifications.deleteMessages({ id: userId }, {});
    });
}

function* loadNotificationPrefsSaga(
    action: ExtractRequestActionRequestType<AccountActions.LoadNotificationsPrefsAction>
) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runDataRequestAction(
        action,
        notificationPrefsAction.load,
        (state, _req) => {
            return state.account.notificationPrefs;
        },
        () => rubyService.notifications.getUserNotificationSettings({ userId: userId }).then((res) => res)
    );
}

function* saveNotificationPrefsSaga(
    action: ExtractRequestActionRequestType<AccountActions.SaveNotificationsPrefsAction>
) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runRequestAction(action, notificationPrefsAction.save, (req) =>
        rubyService.notifications.setUserNotificationSettings({ userId: userId }, { email: req.email, web: req.web })
    );
}

function* deleteAccountSaga(action: ExtractRequestActionRequestType<AccountActions.DeleteAccountAction>) {
    const userId = yield* selectFromState((state) => state.user.userId);
    yield* runRequestAction(action, deleteAccountAction, async () => {
        await rubyService.accounts.deleteUser({ id: userId });
        await authService.logout();
    });
}

function* answerInviteSaga(action: ExtractRequestActionRequestType<AccountActions.AnswerInviteAction>) {
    let succeeded = false;
    const userId = yield* selectFromState((state) => state.user.userId);
    const notifications = yield* selectFromState((state) => state.account.notifications.data);
    yield* runRequestAction(action, usersTeamInviteAction.answer, async (req) => {
        await rubyService.accounts.answerInvitation({ id: req.inviteId }, { accepted: req.answer });
        const notificationId = notifications?.find(
            (n) =>
                n.type === NotificationType.ACCOUNTS_USER_INVITED_TO_TEAM && n.payload["invitationId"] === req.inviteId
        )?.id;
        // TODO - probably do this via an action and not directly, once Notifications are better implemented
        if (notificationId) {
            await rubyService.notifications.deleteMessage({ userId, notificationId });
        }
        succeeded = true;
    });
    if (succeeded) {
        yield put(teamsActions.list.request(undefined, true));
        yield put(notificationsAction.list.request(undefined, true));
        yield put(setActiveTeamAction(action.payload.request.teamId));
        yield put(push(getUrl.campaigns(action.payload.request.teamId)));
    }
}

function* listTeamInvites(action: ExtractRequestActionRequestType<AccountActions.ListUsersTeamInvitesAction>) {
    yield* runDataRequestAction(
        action,
        usersTeamInviteAction.list,
        (state) => {
            return state.account.userInvites;
        },
        () => rubyService.accounts.listUserInvitation({}).then((res) => res.results)
    );
}

function* loadUserHistoryRecordsSaga(
    action: ExtractRequestActionRequestType<AccountActions.ListUserHistoryRecordsAction>
) {
    const userId = yield* selectFromState((state) => state.user.userId);

    yield* runDataRequestAction(
        action,
        listUserHistoryRecords,
        (state, req) => selectUserHistoryRecords(state, req),
        ({ from, to, subjectId, type }) =>
            rubyService.history
                .listUserRecords(
                    { id: userId },
                    {
                        from,
                        to,
                        subjectId,
                        type,
                    },
                    { all: true }
                )
                .then((data) => data.results)
    );
}

function* createDemoTokenSaga(action: ExtractRequestActionRequestType<AccountActions.CreateDemoTokenAction>) {
    yield* runRequestAction(action, createDemoTokenAction, (req) =>
        generateToken(req.email, req.duration).then((token) => ({
            ...req,
            token,
        }))
    );
}

export default function* accountSaga() {
    yield takeEvery(ActionName.LOGIN_SUCCESS, loadUserNotificationsSaga);
    yield* takeRequests(ActionName.LIST_NOTIFICATIONS, loadUserNotificationsSaga);
    yield* takeRequests(ActionName.SAVE_NOTIFICATIONS_PREFERENCES, saveNotificationPrefsSaga);
    yield* takeRequests(ActionName.LOAD_NOTIFICATIONS_PREFERENCES, loadNotificationPrefsSaga);
    yield* takeRequests(ActionName.DELETE_ACCOUNT, deleteAccountSaga);
    yield* takeRequests(ActionName.ANSWER_TEAM_INVITE, answerInviteSaga);
    yield* takeRequests(ActionName.LIST_USERS_TEAM_INVITES, listTeamInvites);
    yield* takeRequests(ActionName.LIST_USER_HISTORY_RECORDS, loadUserHistoryRecordsSaga);
    yield* takeRequests(ActionName.DELETE_NOTIFICATION, deleteNotificationSaga);
    yield* takeRequests(ActionName.DELETE_ALL_NOTIFICATIONS, deleteNotificationsSaga);
    yield* takeRequests(ActionName.CREATE_DEMO_TOKEN, createDemoTokenSaga);
}
