import { createSelector } from "@reduxjs/toolkit";
import omit from "lodash/omit";
import { type CampaignRegionConfig } from "../components/pages/campaign/regionConfig/CampaignRegionConfigTypes";
import { type AsyncData, type RequestState, type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyBudgetPlanMethod,
    RubyBudgetPlanPolicy,
    RubyBudgetPlanStatus,
    RubyPurpose,
    RubyRegionStatus,
    type RubyASRChannel,
    type RubyASRRegion,
    type RubyASTRegion,
    type RubyBudgetPlan,
    type RubyCalendar,
    type RubyCampaignId,
    type RubyChannel,
    type RubyChannelNames,
    type RubyCountry,
    type RubyProductPage,
    type RubyRegionsReportQuery,
} from "../services/backend/RubyData";
import { type CountryMap } from "../types/utils";
import { targetingToDemographics, type DemographicsData } from "../utilities/demographics";
import { type RegionsMetricsReport } from "../utilities/metrics";
import { safeDivide, safeSum } from "../utilities/number";
import { aggregateAsyncData } from "../utilities/requests";
import { days } from "../utilities/time";
import { isRegionsWithPurpose } from "../utilities/types";
import {
    selectAppByCampaign,
    selectCampaign,
    selectCampaignActiveBudget,
    selectChannel,
    selectRegions,
} from "./campaignSelectors";
import { selectCampaignRegionsSummary } from "./reportsSelectors";

export const selectCampaignDemographics = createSelector(
    [
        selectCampaign,
        selectChannel,
        selectAppByCampaign,
        (state: State, campaignId: RubyCampaignId) => {
            const teamId = state.campaigns.campaigns[campaignId]?.data?.teamId;
            return state.team.teams.data?.[teamId]?.tier;
        },
        (state: State, campaignId: RubyCampaignId) => {
            const teamId = state.campaigns.campaigns[campaignId]?.data?.teamId;
            return state.team.ownedApps[teamId];
        },
    ],
    (campaign, channel, app, isHosted, ownedApps) => {
        const request = aggregateAsyncData(campaign, channel, app);
        if (!request.success) {
            return { ...request, data: null };
        }

        const channelBody = omit(channel.data, ["id", "updatedAt", "createdAt", "orgRef"]);
        const channelType = campaign.data?.channelType;
        const demographics = targetingToDemographics(channelBody.targetingDimensions, app.data?.trackId);

        const data: DemographicsData = {
            demographics,
            channelType,
            channelBody,
            currentApp: channelBody.applicationRef,
            otherApps: [],
        };

        if (isHosted) {
            if (!ownedApps?.success) {
                return {
                    ...aggregateAsyncData(request, ownedApps),
                    data,
                };
            }
            data.otherApps = ownedApps.data ?? [];
        }

        return { ...request, data };
    }
);

function regionSummarySelector(state: State) {
    return (query: RubyRegionsReportQuery) => selectCampaignRegionsSummary(state, query);
}

export const selectRegionsBudgetPacing = createSelector(
    [selectCampaign, selectCampaignActiveBudget, regionSummarySelector],
    (campaign, budget, regionSummarySelector) => {
        if (budget.success && !budget.data) {
            // No active budget
            return {
                ...budget,
                teamId: campaign.data?.teamId,
                campaignId: campaign.data?.id,
                data: [],
            };
        }

        return regionSummarySelector({
            teamId: campaign.data?.teamId,
            campaignId: campaign.data?.id,
            from: budget.data?.start,
            to: budget.data?.end,
        });
    }
);

export const SAMPLE_BUDGET_PLAN: RubyBudgetPlan = {
    active: false,
    status: RubyBudgetPlanStatus.DISABLED,
    createdAt: 0,
    updatedAt: 0,
    id: 0,
    campaignId: 0,
    start: Date.now() + days(1),
    end: Date.now() + days(8),
    totalBudget: 1000,
    details: null,
    policy: RubyBudgetPlanPolicy.STOP_AT_END,
    method: RubyBudgetPlanMethod.ADJUST_DAILY,
    lengthInDays: 5,
    daysRemaining: 5,
    allocations: [],
};

export const selectCampaignRegionConfig = createSelector(
    [selectCampaign, selectChannel, selectRegions, selectCampaignActiveBudget, selectRegionsBudgetPacing],
    (campaignRequest, channelRequest, regionsRequest, budgetRequest, budgetPacingRequest) => {
        const budgetPacing = budgetPacingRequest.data;
        const budget = budgetRequest.data;
        const channel = channelRequest.data;
        const regionsArray = regionsRequest.data;
        const campaign = campaignRequest.data;

        const request = aggregateAsyncData(
            campaignRequest,
            budgetRequest,
            channelRequest,
            regionsRequest,
            budgetPacingRequest
        );
        const data: Partial<CampaignRegionConfig.Data> = {};

        if (request.success) {
            // Top level data
            const defaultCpdGoal = (channel as RubyASRChannel).cpdGoal ?? 0;

            data.budget = budget ?? SAMPLE_BUDGET_PLAN;

            // data to be filled in
            const weightings: CampaignRegionConfig.Data["weightings"] = {};
            const countries: CampaignRegionConfig.Data["countries"] = {};
            let discoveryWeighting = 0.3;

            const totalBudgetWeighting = regionsArray.map((r) => r.budgetWeighting).reduce(safeSum);

            const regionsBudgetPacing: CountryMap<RegionsMetricsReport> = budgetPacing.reduce((acc, report) => {
                acc[report.region] = report;
                return acc;
            }, {});

            if (isRegionsWithPurpose(campaign.channelType, regionsArray)) {
                const groupedRegions: CountryMap<RubyASRRegion[]> = regionsArray.reduce((acc, region) => {
                    if (!acc[region.country]) {
                        acc[region.country] = [];
                    }
                    acc[region.country].push(region);
                    return acc;
                }, {} as CountryMap<RubyASRRegion[]>);

                const regions: CountryMap<RubyASRRegion[]> = {};

                Object.entries(groupedRegions).forEach(([key, value]: [RubyCountry, RubyASRRegion[]]) => {
                    const indicator = Object.values(value)[0];
                    const all = Object.values(value);

                    const budgetWeightingSum = all.reduce((sum, r) => sum + r.budgetWeighting, 0);

                    const discoverySum = all
                        .filter((r) => [RubyPurpose.BID_DISCOVERY, RubyPurpose.KEYWORD_DISCOVERY].includes(r.purpose))
                        .reduce((sum, r) => sum + r.budgetWeighting, 0);
                    const regionDiscoveryWeighting = safeDivide(discoverySum, budgetWeightingSum);

                    // status DISABLED only if all DISABLED or ACTIVE if any of them are ACTIVE
                    const status = all.every((v) => v.status === RubyRegionStatus.DISABLED)
                        ? RubyRegionStatus.DISABLED
                        : RubyRegionStatus.ACTIVE;

                    discoveryWeighting =
                        status === RubyRegionStatus.ACTIVE && regionDiscoveryWeighting > 0
                            ? regionDiscoveryWeighting
                            : discoveryWeighting;

                    weightings[key] = safeDivide(budgetWeightingSum, totalBudgetWeighting);
                    countries[key] = {
                        country: key,
                        status,
                        budgetAmount: safeDivide(budgetWeightingSum, totalBudgetWeighting) * data.budget.totalBudget,
                        amountSpent: regionsBudgetPacing[key]?.spend ?? 0,
                        budgetWeighting: safeDivide(budgetWeightingSum, totalBudgetWeighting),
                        cpdGoal: indicator.cpdGoal,
                        defaultCpdGoal,
                        targetEvent: indicator.targetEvent,
                        targetEventGoal: indicator.targetEventGoal,
                        targetRoas: indicator.targetRoas,
                        targetRoasGoal: indicator.targetRoasGoal,
                        targetRoasTimeRange: indicator.targetRoasTimeRange,
                    };
                    regions[key] = value;
                });

                data.channelType = campaign.channelType;
                data.regions = regions;
            } else {
                const regions: CountryMap<RubyASTRegion[]> = {};

                (regionsArray as RubyASTRegion[]).forEach((region) => {
                    const { country, status, budgetWeighting, cpdGoal } = region;
                    weightings[country] = safeDivide(budgetWeighting, totalBudgetWeighting);
                    countries[country] = {
                        status,
                        country,
                        budgetAmount: safeDivide(budgetWeighting, totalBudgetWeighting) * data.budget.totalBudget,
                        amountSpent: regionsBudgetPacing[country]?.spend ?? 0,
                        budgetWeighting: budgetWeighting,
                        cpdGoal,
                        defaultCpdGoal,
                    };
                    regions[country] = [region];
                });

                data.channelType = campaign.channelType;
                data.regions = regions;
            }

            data.countries = countries;
            data.weightings = weightings;
            data.discoveryWeighting = discoveryWeighting;
        }
        return { ...request, data: data as CampaignRegionConfig.Data };
    }
);

export function selectCampaignProductPages(
    state: State,
    appId: RubyChannel["applicationRef"]
): AsyncData<RubyProductPage[]> {
    return state.campaigns.productPages[appId] ?? initialAsyncDataState();
}

export function selectCalendars(state: State): AsyncData<RubyCalendar[]> {
    return state.config.calendars ?? initialAsyncDataState<RubyCalendar[]>(null);
}

export function selectAddCalendarRequest(state: State): RequestState {
    return state.config.createCalendar;
}

export function selectDeleteCalendarRequest(state: State): RequestState {
    return state.config.removeCalendar;
}

export function selectChannelNames(state: State, campaignId: RubyCampaignId): AsyncData<RubyChannelNames> {
    return state.config.channelNames[campaignId] ?? initialAsyncDataState();
}
