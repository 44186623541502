import { RuleType } from "@redbox-ruby/data-lib";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { selectRule } from "../selectors/rulesSelectors";
import { type RubyCampaignId, type RubyRule, type RubyRuleType } from "../services/backend/RubyData";
import { type AsyncData, type RulesState, type State } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const initialRulesState: RulesState = {
    campaignRules: {},
    regionRules: {},
    keywordRules: {},
    createRuleRequest: initialRequestState(),
    updateRuleRequest: initialRequestState(),
    removeRuleRequest: initialRequestState(),
    updatePrioritiesRequest: initialRequestState(),
};

export default function rulesReducer(state: RulesState = initialRulesState, action: ActionType, globalState: State) {
    switch (action.type) {
        case ActionName.LIST_CAMPAIGN_RULES: {
            const id = action.payload.request.campaignId;
            state.campaignRules[id] = requestActionReducer(action, undefined, state.campaignRules[id]);
            break;
        }

        case ActionName.LIST_REGION_RULES: {
            const id = action.payload.request.campaignId;
            state.regionRules[id] = requestActionReducer(action, undefined, state.regionRules[id]);
            break;
        }

        case ActionName.LIST_KEYWORD_RULES: {
            const id = action.payload.request.campaignId;
            state.keywordRules[id] = requestActionReducer(action, undefined, state.keywordRules[id]);
            break;
        }

        case ActionName.CREATE_RULE: {
            state.createRuleRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const rule = action.payload.response;
                const id = action.payload.request.campaignId;
                switch (rule.type) {
                    case RuleType.CAMPAIGN:
                        state.campaignRules[id].data?.push(rule);
                        break;
                    case RuleType.REGION:
                        state.regionRules[id].data?.push(rule);
                        break;
                    case RuleType.KEYWORD:
                        state.keywordRules[id].data?.push(rule);
                        break;
                }
            }
            break;
        }

        case ActionName.UPDATE_RULE: {
            state.updateRuleRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const newRuleProps = action.payload.response;
                updateRuleHelper(newRuleProps.type, state, newRuleProps, action.payload.request.campaignId);
            }
            break;
        }

        case ActionName.UPDATE_RULES_PRIORITIES: {
            state.updatePrioritiesRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                // This code will technically update the entire rule array, not just the priority,
                // but that's fine, we pass the whole rule set around in the action.
                const newRules = action.payload.response;
                for (const newRuleProps of newRules) {
                    updateRuleHelper(newRuleProps.type, state, newRuleProps, action.payload.request.campaignId);
                }
            }
            break;
        }

        case ActionName.DELETE_RULE: {
            state.removeRuleRequest = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const ruleId = action.payload.request.ruleId;
                const rule = selectRule(globalState, campaignId, ruleId);
                let ruleSet: Record<RubyCampaignId, AsyncData<RubyRule[]>>;
                switch (rule.type) {
                    case RuleType.CAMPAIGN:
                        ruleSet = state.campaignRules;
                        break;
                    case RuleType.REGION:
                        ruleSet = state.regionRules;
                        break;
                    case RuleType.KEYWORD:
                        ruleSet = state.keywordRules;
                        break;
                }
                ruleSet[campaignId].data = ruleSet[campaignId].data.filter((rule) => rule.id !== ruleId);
            }
            break;
        }
    }
}

function updateRuleHelper<R extends RubyRule>(
    type: RubyRuleType,
    state: RulesState,
    newRule: R,
    campaignId: RubyCampaignId
) {
    let ruleSet: Record<RubyCampaignId, AsyncData<RubyRule[]>>;
    switch (type) {
        case RuleType.CAMPAIGN:
            ruleSet = state.campaignRules;
            break;
        case RuleType.REGION:
            ruleSet = state.regionRules;
            break;
        case RuleType.KEYWORD:
            ruleSet = state.keywordRules;
            break;
    }
    const ruleIdx = ruleSet[campaignId].data.findIndex((rule) => rule.id === newRule.id);
    ruleSet[campaignId].data[ruleIdx] = newRule;
}
