import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { teamOrganisationActions } from "../../../actions/teamActions";
import { type State } from "../../../reducers/domain";
import AppleCallbackPageComponent, {
    type AppleCallbackPageComponentProps,
    type AppleCallbackPageProps,
} from "./AppleCallbackPageComponent";

const mapStateToProps = (state: State, props: AppleCallbackPageProps): Partial<AppleCallbackPageComponentProps> => {
    return {
        ...props,
        reportOrganisationRequest: state.team.reportOrganisation,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): Partial<AppleCallbackPageComponentProps> => ({
    reportOrganisation: () => dispatch(teamOrganisationActions.reportASA.request()),
});

const AppleCallbackPage = connect(mapStateToProps, mapDispatchToProps)(AppleCallbackPageComponent);
export default AppleCallbackPage;
