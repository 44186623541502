import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { getPostLoginUrl } from "../utilities/url";
import { type State, type UserState } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const COOKIE_OPT_OUT_KEY = "RB_CookieOptOut";
const cookieOptOut = localStorage.getItem(COOKIE_OPT_OUT_KEY);

export const initialUserState: UserState = {
    isLoggedIn: false,
    isLoggingIn: false,
    isLoggingInWithGoogle: false,
    role: null,
    userId: null,
    authId: null,
    userName: null,
    changingName: initialRequestState(),
    userEmail: null,
    emailVerified: false,
    loginErrorMessage: null,
    metadata: {},
    loginIsRestoring: false,
    passwordReset: initialRequestState(),
    acceptTermsCallbackUrl: null,
    loginRedirect: getPostLoginUrl(),
    createAccount: initialRequestState(),
    hasViewedCookies: !!cookieOptOut,
    cookieOptOut: cookieOptOut === "false" ? false : cookieOptOut === "true" ? true : null,
};

export default function userReducer(state: UserState = initialUserState, action: ActionType, globalState: State) {
    switch (action.type) {
        case ActionName.LOGIN_WITH_GOOGLE: {
            state.loginErrorMessage = null;
            state.isLoggingIn = false;
            state.isLoggingInWithGoogle = true;
            break;
        }

        case ActionName.LOGIN_REQUEST: {
            state.loginErrorMessage = null;
            state.isLoggingInWithGoogle = false;
            state.isLoggingIn = true;
            break;
        }

        case ActionName.LOGIN_SUCCESS: {
            const user = action.payload.user;

            state.isLoggedIn = true;
            state.loginRedirect = action.payload.nextPage || getPostLoginUrl();
            state.role = user.role;
            state.userId = user.id;
            state.authId = user.authId;
            state.userName = user.name;
            state.userEmail = user.email;
            state.emailVerified = user.emailVerified;
            state.loginErrorMessage = null;
            state.loginIsRestoring = false;
            state.metadata = user.metadata;
            break;
        }

        case ActionName.LOGIN_ERROR: {
            globalState.user = {
                ...initialUserState,
                loginErrorMessage: action.payload,
            };
            break;
        }

        case ActionName.LOGIN_EXPIRED: {
            globalState.user = { ...initialUserState };
            break;
        }

        case ActionName.RESTORE_LOGIN: {
            state.loginIsRestoring = true;
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            state.loginErrorMessage = null;
            state.passwordReset = initialRequestState();
            break;
        }

        case ActionName.RESET_PASSWORD: {
            state.passwordReset = requestActionReducer(action);
            break;
        }

        case ActionName.SET_TERMS_ACCEPT_URL: {
            state.acceptTermsCallbackUrl = action.payload;
            break;
        }

        case ActionName.CREATE_ACCOUNT: {
            state.createAccount = requestActionReducer(action);
            break;
        }

        case ActionName.SET_COOKIE_PREFS: {
            state.hasViewedCookies = true;
            state.cookieOptOut = action.payload;
            break;
        }

        case ActionName.SET_METADATA: {
            state.metadata = {
                ...state.metadata,
                ...action.payload,
            };
            break;
        }

        case ActionName.SET_NEW_USER_NAME: {
            state.changingName = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.userName = action.payload.response;
            }
            break;
        }
    }
}
