import isNumber from "lodash/isNumber";
import type React from "react";
import AutoHeight from "../autoHeight/AutoHeight";

export interface HiddenSectionProps {
    isHidden: boolean;
    children?: React.ReactNode;
    className?: string;
    easing?: string;
    easingClose?: string;
    easingOpen?: string;
    duration?: number;
    durationClose?: number;
    durationOpen?: number;
}

export default function HiddenSection({
    isHidden,
    children,
    className,
    easing,
    easingClose = "ease-in-out",
    easingOpen,
    duration,
    durationClose = 200,
    durationOpen,
}: HiddenSectionProps) {
    return (
        <AutoHeight
            fixedHeight={isHidden ? 0 : undefined}
            easing={easing ? easing : isHidden ? easingClose : easingOpen}
            duration={isNumber(duration) ? duration : isHidden ? durationClose : durationOpen}
            className={className}
        >
            {children}
        </AutoHeight>
    );
}
