import { apiService, rubyService } from "@/services";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import { contactFormAction, supportFormAction, type InteractionActions } from "../actions/interactionActions";
import { runRequestAction, takeRequests } from "../utilities/saga";

function* supportFormSaga(action: ExtractRequestActionRequestType<InteractionActions.SupportFormAction>) {
    yield* runRequestAction(action, supportFormAction, async (req) => {
        await rubyService.interactions.createSupportRequest(req);
    });
}

function* contactFormSaga(action: ExtractRequestActionRequestType<InteractionActions.ContactFormAction>) {
    yield* runRequestAction(action, contactFormAction, async (req) => {
        // Unable to use the ruby client here as we need to exclude the login check.
        // await rubyService.interactions.createContactRequest(req);
        await apiService.post<void>({
            sendAuth: false,
            uri: "/api/v2/interactions/contact",
            data: req,
        });
    });
}

export default function* interactionSaga() {
    yield* takeRequests(ActionName.SEND_SUPPORT_FORM, supportFormSaga);
    yield* takeRequests(ActionName.SEND_CONTACT_FORM, contactFormSaga);
}
