import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { type ConfigState, type State } from "./domain";
import { initialAsyncDataState, initialRequestState, requestActionReducer } from "./requestReducer";

export const initialConfigState: ConfigState = {
    updateChannel: initialRequestState(),
    createRegions: initialRequestState(),
    updateRegions: initialRequestState(),
    updateRegionWeightings: initialRequestState(),
    addBudgetPlan: {},
    deleteBudgetPlan: initialRequestState(),
    updateBudgetPlan: initialRequestState(),
    updateChannelNames: initialRequestState(),
    setAllocations: initialRequestState(),
    channelNames: {},
    calendars: initialAsyncDataState(),
    createCalendar: initialRequestState(),
    removeCalendar: initialRequestState(),
    convertBudgetPlan: initialRequestState(),
};

export default function reducer(state: ConfigState = initialConfigState, action: ActionType, _globalState: State) {
    switch (action.type) {
        case ActionName.DELETE_CALENDAR: {
            state.removeCalendar = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.calendars.data = state.calendars?.data?.filter((cal) => cal.id !== action.payload.request);
            }
            break;
        }

        case ActionName.LIST_CALENDARS: {
            state.calendars = requestActionReducer(action);
            break;
        }

        case ActionName.ADD_CALENDAR: {
            state.createCalendar = requestActionReducer(action);
            const calendars = state.calendars?.data ?? [];
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.calendars.data = [...calendars, requestActionReducer(action)?.data];
            }
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            state.updateChannel = initialRequestState();
            state.createRegions = initialRequestState();
            state.updateRegionWeightings = initialRequestState();
            break;
        }

        case ActionName.UPDATE_CHANNEL: {
            state.updateChannel = requestActionReducer(action);
            break;
        }

        case ActionName.CREATE_REGIONS: {
            state.createRegions = requestActionReducer(action);
            break;
        }

        case ActionName.UPDATE_REGIONS: {
            state.updateRegions = requestActionReducer(action);
            break;
        }

        case ActionName.UPDATE_REGION_WEIGHTINGS: {
            state.updateRegionWeightings = requestActionReducer(action);
            break;
        }

        case ActionName.ADD_BUDGET_PLAN: {
            state.addBudgetPlan[action.payload.request.campaignId] = requestActionReducer(action);
            break;
        }

        case ActionName.REMOVE_BUDGET_PLAN: {
            state.deleteBudgetPlan = requestActionReducer(action);
            break;
        }

        case ActionName.UPDATE_BUDGET_PLAN: {
            state.updateBudgetPlan = requestActionReducer(action);
            break;
        }

        case ActionName.GET_CHANNEL_NAMES: {
            const id = action.payload.request.campaignId;
            state.channelNames[id] = requestActionReducer(action, undefined, state.channelNames[id]);
            break;
        }

        case ActionName.UPDATE_CHANNEL_NAMES: {
            state.updateChannelNames = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const existingState = state.channelNames[action.payload.request.campaignId];
                // If we don't already have data, then discard this update
                // We could use it but be partial update (ie not have all the fields)
                if (existingState?.data) {
                    const response = action.payload.response;
                    const updatedData = existingState.data.map((campaign) => {
                        const updated = response.find((x) => campaign.campaignRef === x.campaignRef);
                        if (!updated) {
                            return campaign;
                        } else {
                            return updated;
                        }
                    });
                    state.channelNames[action.payload.request.campaignId].data = updatedData;
                }
            }
            break;
        }

        case ActionName.SET_ALLOCATIONS: {
            state.setAllocations = requestActionReducer(action);
            break;
        }

        case ActionName.CONVERT_TO_REPEATING_PLAN: {
            state.convertBudgetPlan = requestActionReducer(action);
            break;
        }
    }
}
