import React from "react";
import { v4 as uuid } from "uuid";
import { type InputProps } from "../input/Input";
import "./checkbox.css";

export interface CheckboxProps extends InputProps<HTMLInputElement> {
    type: "checkbox" | "radio";
}

export default class Checkbox extends React.PureComponent<CheckboxProps> {
    static defaultProps = {
        type: "checkbox",
    };

    id: string;

    constructor(props: CheckboxProps) {
        super(props);
        this.id = uuid();
    }

    render() {
        const { isInvalid, className, id, ...props } = this.props;
        return (
            <label
                htmlFor={id || this.id}
                className={`checkbox ${isInvalid ? `is-invalid` : ``} ${props.disabled ? `is-disabled` : ``} ${
                    props.checked ? `is-checked` : ``
                } ${className ? className : ``}`}
            >
                <input className="checkbox-input" aria-invalid={isInvalid} id={id || this.id} {...props} />
                <span className="checkbox-el"></span>
            </label>
        );
    }
}
