import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { Suspense, useEffect } from "react";
const ReactQueryDevtoolsProduction = React.lazy(() =>
    import("@tanstack/react-query-devtools/build/lib/index.prod.js").then((d) => ({
        default: d.ReactQueryDevtools,
    }))
);

export default function DevTools() {
    const [showDevtools, setShowDevtools] = React.useState(false);

    const debug = localStorage.getItem("debug") === "true";
    useEffect(() => {
        setShowDevtools(debug);
    }, [debug]);

    return (
        <>
            <ReactQueryDevtools initialIsOpen={false} />
            {showDevtools && (
                <Suspense fallback={null}>
                    <ReactQueryDevtoolsProduction initialIsOpen={false} />
                </Suspense>
            )}
        </>
    );
}
