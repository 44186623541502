import React from "react";
import { type AsyncData, type RequestState } from "../../../../reducers/domain";
import { type RubyTeamId, type RubyTeamInvite } from "../../../../services/backend/RubyData";
import { enumLanguageString, languageString } from "../../../../utilities/text";
import ModalController from "../../../assemblies/modalController/ModalController";
import PageHeader from "../../../assemblies/pageHeader/PageHeader";
import ButtonIcon from "../../../blocks/buttons/icon/ButtonIcon";
import Card from "../../../blocks/card/basic/Card";
import LayoutGrid from "../../../blocks/layoutGrid/LayoutGrid";
import List from "../../../blocks/list/List";
import CreateTeam from "../parts/createTeam/CreateTeam";
export interface TeamInvitesPageComponentProps {
    answerInviteRequest: RequestState;
    listInvites: () => void;
    answerInvite: (inviteId: RubyTeamInvite["id"], answer: boolean, teamId: RubyTeamId) => void;
    invites: AsyncData<RubyTeamInvite[]>;
    openCreateTeam: () => void;
}

export default class TeamInvitesPageComponent extends React.PureComponent<TeamInvitesPageComponentProps> {
    componentDidMount(): void {
        this.props.listInvites();
    }

    render() {
        return (
            <LayoutGrid>
                {[
                    {
                        id: "invites",
                        element: (
                            <div>
                                <PageHeader
                                    title={languageString("pages.teamInvites.title")}
                                    subTitle={languageString("pages.teamInvites.description")}
                                    additionalIcon={
                                        <ButtonIcon
                                            icon="Add"
                                            label={languageString("pages.team.createTeam")}
                                            onClick={this.props.openCreateTeam}
                                            showLabel
                                        />
                                    }
                                />
                                <Card request={this.props.invites}>
                                    <List
                                        emptyLabel={languageString("pages.teamInvites.noInvites")}
                                        headings={{
                                            name: languageString("pages.team.columns.name"),
                                            role: languageString("account.team.members.role"),
                                            answer: languageString("pages.team.columns.answer"),
                                        }}
                                        requestState={this.props.invites}
                                        data={this.props.invites.data?.map((invite) => ({
                                            key: invite.id.toString(),
                                            name: invite.teamName,
                                            role: enumLanguageString("account.team.role", invite.role),
                                            answer: (
                                                <span className="u-overflow">
                                                    <ButtonIcon
                                                        className="u-mr8"
                                                        icon="Check"
                                                        label={languageString("account.user.invites.accept")}
                                                        onClick={() => {
                                                            this.props.answerInvite(invite.id, true, invite.teamId);
                                                        }}
                                                        borderless
                                                        isPending={this.props.answerInviteRequest.isRequesting}
                                                        type="button"
                                                    />
                                                    <ButtonIcon
                                                        icon="Close"
                                                        label={languageString("account.user.invites.decline")}
                                                        onClick={() => {
                                                            this.props.answerInvite(invite.id, false, invite.teamId);
                                                        }}
                                                        borderless
                                                        isPending={this.props.answerInviteRequest.isRequesting}
                                                        type="button"
                                                    />
                                                </span>
                                            ),
                                        }))}
                                    />
                                </Card>
                                <ModalController id="createTeam">
                                    <CreateTeam displayModal />
                                </ModalController>
                            </div>
                        ),
                    },
                ]}
            </LayoutGrid>
        );
    }
}
