import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import isObject from "lodash/isObject";
import uniq from "lodash/uniq";
import { type PartialRecord } from "../types/utils";
import { valueToString } from "./format";
import { languageString, writeList } from "./text";

export function mapObject<T extends object = object, V = unknown>(
    obj: T,
    mapFunc: (key: string, value: T[keyof T]) => V
): { [key in keyof T]: V } {
    const entries = Object.entries(obj).map(([key, value]) => [key, mapFunc(key, value as T[keyof T])]);
    return Object.fromEntries(entries) as { [key in keyof T]: V };
}

export function diffObjectToString<T>(
    before: T,
    after: T,
    reportMissingKeys = true,
    labelMap: PartialRecord<keyof T, string> = {},
    formatters: {
        [K in keyof T]?: (val: T[K]) => string;
    } = {}
): string | null {
    if (!before && !after) {
        return null;
    }
    if (!before) {
        before = {} as T;
    }
    if (!after) {
        after = {} as T;
    }
    const keysBefore = Object.keys(before);
    const keysAfter = Object.keys(after);
    const changes = uniq([...(reportMissingKeys ? keysBefore : []), ...keysAfter])
        .filter((key) => {
            return (
                key !== "updatedAt" && !isEqual(before[key], after[key]) && (!isNil(before[key]) || !isNil(after[key]))
            );
        })
        .map((key) => {
            const label = labelMap[key] ?? key;
            if (!isNil(before[key]) && !isNil(after[key])) {
                return languageString("ui.diff.propertyChanged", "", [
                    label,
                    formatValue(before[key], formatters[key]),
                    formatValue(after[key], formatters[key]),
                ] as string[]);
            } else if (!isNil(before[key])) {
                return languageString("ui.diff.propertyRemoved", "", [
                    label,
                    formatValue(before[key], formatters[key]),
                ] as string[]);
            } else {
                return languageString("ui.diff.propertyAdded", "", [
                    label,
                    formatValue(after[key], formatters[key]),
                ] as string[]);
            }
        });
    if (changes.length < 1) {
        return null;
    }
    return writeList(changes);
}

type WriteObjectOpts = {
    excludeUnlabelled?: boolean;
    excludeNil?: boolean;
    excludeEmptyStr?: boolean;
};

export function writeObject<T extends object>(
    obj: T,
    labelMap: PartialRecord<keyof T, string>,
    formatters: {
        [K in keyof T]?: (val: T[K]) => string;
    },
    { excludeUnlabelled = false, excludeNil = false, excludeEmptyStr = false }: WriteObjectOpts = {}
) {
    let keys: (keyof T)[] = Object.keys(labelMap) as (keyof T)[];
    if (keys.length < 1) {
        keys = Object.keys(obj) as (keyof T)[];
    }

    const output: string[] = [];
    for (const key of keys) {
        if (excludeUnlabelled && !labelMap[key]) {
            continue;
        }
        if (excludeNil && isNil(obj?.[key])) {
            continue;
        }
        if (excludeEmptyStr && obj?.[key] === "") {
            continue;
        }
        const label = labelMap[key] ?? (key as string);
        const formattedValue: string = formatValue(obj?.[key], formatters[key]);
        output.push(`${label}: ${formattedValue}`);
    }
    return writeList(Object.values(output));
}

export function writeRecord<A extends string | number | symbol, B, T extends Record<A, B>>(
    record: T,
    keyFormatter: (val: string) => string = formatValue,
    valueFormatter: (val: B) => string = formatValue
) {
    const entries = Object.entries(record).map(([key, val]) => {
        return `${keyFormatter(key)}: ${valueFormatter(val as B)}`;
    });

    return writeList(entries);
}

export function safeParseObj<T>(serialised: string): T | null {
    if (!serialised) {
        return null;
    }
    try {
        return JSON.parse(serialised) as T;
    } catch (err) {
        return null;
    }
}

/**
 * Returns all the leaf values of a nested object.
 * Don not pass in objects with circular refs!
 */
export function getAllObjectValues<T>(obj: T): unknown[] {
    if (Array.isArray(obj)) {
        return obj.flatMap(getAllObjectValues);
    }

    if (isObject(obj)) {
        Object.values(obj).flatMap(getAllObjectValues);
    }

    return [obj];
}

function formatValue<T>(value: T, formatter?: (val: T) => string): string {
    if (formatter) {
        return formatter(value);
    }
    return valueToString(value);
}
