import clamp from "lodash/clamp";
import React from "react";
import { v4 as uuid } from "uuid";
import { formatPercent, safeDivide } from "../../../../utilities/number";
import HintIcon from "../../hintIcon/HintIcon";
import "./statRadial.css";

export interface StatRadialProps {
    label: React.ReactNode;
    hint?: React.ReactNode;
    value: number;
    formatter: (val: number) => string;
    subLabel?: React.ReactNode;
    className?: string;
    disabled?: boolean;
    min?: number;
    max?: number;
    size?: number;
}

const TAU = Math.PI * 2;

interface BarValues {
    start: number;
    end: number;
    startX: number;
    startY: number;
    endX: number;
    endY: number;
}

export default class StatRadial<P extends StatRadialProps = StatRadialProps> extends React.PureComponent<P> {
    static defaultProps = {
        min: 0,
        max: 1,
        size: 180,
    };

    gradId: string;
    radius = 50;
    get diameter() {
        return this.radius * 2;
    }
    padding = 10;

    constructor(props: P) {
        super(props);
        this.gradId = uuid();
    }

    getPercent(value: number = this.props.value, min: number = this.props.min, max: number = this.props.max): number {
        const range = max - min;
        return safeDivide(value - min, range);
    }

    getClampedPercent(...args: Parameters<typeof this.getPercent>) {
        return clamp(this.getPercent(...args), 0, 1);
    }

    getBarValues(): BarValues {
        const start = TAU * 0.45;
        const end = TAU * 1.05;
        const startX = Math.cos(start) * this.radius + this.radius;
        const startY = Math.sin(start) * this.radius + this.radius;
        const endX = Math.cos(end) * this.radius + this.radius;
        const endY = Math.sin(end) * this.radius + this.radius;

        return {
            start,
            end,
            startX,
            startY,
            endX,
            endY,
        };
    }

    getSVGProps(): React.SVGAttributes<SVGElement> {
        return {
            viewBox: `
                ${-this.padding}
                ${-this.padding}
                ${this.diameter + this.padding * 2}
                ${this.diameter * 0.65 + this.padding * 2}
            `,
            width: this.props.size,
            height: this.props.size * 0.7,
            className: "statRadial-bar",
            xmlns: "http://www.w3.org/2000/svg",
        };
    }

    drawBgBar(bar: BarValues) {
        return (
            <path
                d={`
                M ${bar.startX} ${bar.startY}
                A
                    ${this.radius} ${this.radius}
                    0 ${bar.end - bar.start < Math.PI ? "0" : "1"} 1
                    ${bar.endX} ${bar.endY}
            `}
                fill="none"
                stroke="white"
                opacity={0.2}
                strokeWidth={5}
                strokeLinecap="round"
            />
        );
    }

    renderBar() {
        const bar = this.getBarValues();

        const fillEnd = (bar.end - bar.start) * this.getClampedPercent() + bar.start;
        const fillEndX = Math.cos(fillEnd) * this.radius + this.radius;
        const fillEndY = Math.sin(fillEnd) * this.radius + this.radius;

        return (
            <svg {...this.getSVGProps()}>
                <defs>
                    <mask id={`mask${this.gradId}`}>
                        <path
                            d={`
                                M ${bar.startX} ${bar.startY}
                                A 
                                    ${this.radius} ${this.radius}
                                    0 ${fillEnd - bar.start < Math.PI ? "0" : "1"} 1
                                    ${fillEndX} ${fillEndY}
                            `}
                            fill="none"
                            stroke="white"
                            strokeWidth={10}
                            strokeLinecap="round"
                            id={`path${this.gradId}`}
                        />
                    </mask>
                    <linearGradient id={`grad${this.gradId}`} x1={0} x2={1} y1={0} y2={0}>
                        <stop offset="0%" stopColor="var(--color-grad1)" />
                        <stop offset="50%" stopColor="var(--color-grad2)" />
                        <stop offset="100%" stopColor="var(--color-grad3)" />
                    </linearGradient>
                </defs>
                {this.drawBgBar(bar)}
                {this.getPercent() > 0 && (
                    <rect
                        width={this.diameter + this.padding * 2}
                        height={this.diameter + this.padding * 2}
                        x={-this.padding}
                        y={-this.padding}
                        fill={`url('#grad${this.gradId}')`}
                        mask={`url('#mask${this.gradId}')`}
                    />
                )}
            </svg>
        );
    }

    formatPercent(val: number) {
        return formatPercent(val);
    }

    formatValue(val: number) {
        return this.props.formatter(val);
    }

    getParentProps(): React.HTMLAttributes<HTMLDivElement> {
        // Abstract
        return {};
    }
    render() {
        const props = this.getParentProps();
        return (
            <div
                className={`statRadial`}
                {...props}
                style={{
                    ...props.style,
                    "--statSize": this.props.size,
                }}
            >
                <div className="statRadial-barOuter">
                    {this.renderBar()}
                    <div className="statRadial-value">
                        <div className="statRadial-valuePer">{this.formatPercent(this.getPercent())}</div>
                        <div className="statRadial-valueAbs">{this.formatValue(this.props.value)}</div>
                    </div>
                </div>
                <div className="statRadial-label">
                    {this.props.label}
                    {this.props.hint && <HintIcon>{this.props.hint}</HintIcon>}
                </div>
                <div className="statRadial-subLabel">{this.props.subLabel}</div>
            </div>
        );
    }
}
