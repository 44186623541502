import type { TimeRange } from "@/reducers/domain";
import type { RubyCampaign, RubyCampaignId, RubyTargetingDimensions } from "@/services/backend/RubyData";

export enum CampaignObjectiveType {
    AD_CAMPAIGN = "AD_CAMPAIGN",
    EXPERIMENT = "EXPERIMENT",
    IMPORTED_CAMPAIGN = "IMPORTED_CAMPAIGN",
}

interface CampaignObjectiveBase {
    objectiveType: CampaignObjectiveType;
    notes?: string;
}

export interface AdCampaignObjective extends CampaignObjectiveBase {
    objectiveType: CampaignObjectiveType.AD_CAMPAIGN;
    tags: string[];
}

export enum ExperimentType {
    DEMOGRAPHICS = "DEMOGRAPHICS",
    PRODUCT_PAGE = "PRODUCT_PAGE",
    KEYWORD_SET = "KEYWORD_SET",
    BID_PRICE = "BID_PRICE",
}

export enum ExperimentAlignment {
    SEQUENTIAL = "SEQUENTIAL",
    INTERLACED = "INTERLACED",
    REGIONAL = "REGIONAL",
}

export type ExperimentCampaignState = {
    productPageRef?: string;
    targetingDimensions?: RubyTargetingDimensions;
    keywords?: string[];
    keywordsBroad?: string[];
    bidAmount?: number;
};

export interface ExperimentCampaignObjective extends CampaignObjectiveBase {
    objectiveType: CampaignObjectiveType.EXPERIMENT;

    experimentType: ExperimentType;
    /**
     * Warning: Historical experiments this value will be null!
     */
    alignment: ExperimentAlignment;

    totalBudget: number;

    /**
     * Warning: Historical experiments this value will be null!
     */
    duration: TimeRange;
    durationA?: TimeRange;
    durationB?: TimeRange;

    campaignIdA?: RubyCampaignId;
    campaignIdB?: RubyCampaignId;

    stateA: ExperimentCampaignState;
    stateB: ExperimentCampaignState;
}

export interface ImportCampaignObjective extends CampaignObjectiveBase {
    objectiveType: CampaignObjectiveType.IMPORTED_CAMPAIGN;
}

export type CampaignObjective = AdCampaignObjective | ExperimentCampaignObjective | ImportCampaignObjective;

export interface AdCampaign extends RubyCampaign {
    objective?: AdCampaignObjective;
}

export interface ExperimentCampaign extends RubyCampaign {
    objective: ExperimentCampaignObjective;
}

export interface ImportedCampaign extends RubyCampaign {
    objective: ImportCampaignObjective;
}
