/* eslint-disable @typescript-eslint/unbound-method */
import useMeasure from "@/utilities/useMeasure";
import clsx from "clsx";
import debounce from "lodash/debounce";
import omit from "lodash/omit";
import React, { useEffect, type CSSProperties, type UIEvent } from "react";
import { type RequestState } from "../../../reducers/domain";
import { languageString } from "../../../utilities/text";
import BlockMessage from "../blockMessage/BlockMessage";
import ButtonIcon from "../buttons/icon/ButtonIcon";
import ErrorMessage from "../errors/errorMessage/ErrorMessage";
import Checkbox from "../inputs/checkbox/Checkbox";
import Scroller from "../scroller/Scroller";
import SpinnerOverlay from "../spinnerOverlay/SpinnerOverlay";
import "./list.css";

interface ListProps<T> {
    headings?: { [K in keyof Omit<T, "key">]?: React.ReactNode };
    footers?: { [K in keyof Omit<T, "key">]?: React.ReactNode };
    formatters?: { [K in keyof Omit<T, "key">]?: (v: T[K], o?: T) => React.ReactNode };
    columnOrder?: { [K in keyof Omit<T, "key">]?: number };
    data: (T & { key: string })[];
    withSelect?: boolean;
    withSelectAll?: boolean;
    onSelectChange?: (selectedRows: string[]) => void;
    selectedRows?: string[];
    withSort?: boolean;
    height?: CSSProperties["height"];
    autoHeight?: boolean;
    requestState?: RequestState;
    columnsWidths?: { [K in keyof Omit<T, "key">]?: CSSProperties["width"] };
    emptyLabel?: React.ReactNode;
    defaultSort?: keyof Omit<T, "key">;
    defaultAsc?: boolean;
    sorters?: { [K in keyof Omit<T, "key">]?: (a: T[K], b: T[K], aObj: T, bObj: T) => number };
    children?: React.ReactNode;
}

interface ListState<T> {
    sortKey?: keyof Omit<T, "key">;
    sortAsc: boolean;
    sizeCache: Record<number, number>;
    sizeCacheInit: boolean;
}

const selectColWidth = 42;

export default class List<T> extends React.PureComponent<ListProps<T>, ListState<T>> {
    static defaultProps = {
        height: 300,
    };

    headerRef: React.RefObject<HTMLDivElement>;
    footerRef: React.RefObject<HTMLDivElement>;
    sizeCache: Record<number, number> = {};

    constructor(props: ListProps<T>) {
        super(props);
        this.state = {
            sortKey: this.props.defaultSort ?? null,
            sortAsc: this.props.defaultAsc ?? false,
            sizeCache: {},
            sizeCacheInit: false,
        };
        this.headerRef = React.createRef();
        this.footerRef = React.createRef();
        this.handleScroll = this.handleScroll.bind(this);
        this.updateCache = this.updateCache.bind(this);
        this.colWidthStyle = this.colWidthStyle.bind(this);
        this.updateCacheState = debounce(this.updateCacheState.bind(this), 200, {
            trailing: true,
            leading: false,
        });
    }

    updateCache(index: number, size: number) {
        if ((this.sizeCache?.[index] ?? 0) < size) {
            this.sizeCache[index] = size;
            this.updateCacheState();
        }
    }

    updateCacheState() {
        this.setState({
            sizeCache: {
                ...this.sizeCache,
            },
            sizeCacheInit: true,
        });
    }

    handleScroll(e: UIEvent) {
        if (e?.target) {
            const scroller = e.target as HTMLElement;
            const left = scroller.scrollLeft;
            if (this.headerRef.current instanceof HTMLElement) {
                this.headerRef.current.style.transform = `translateX(-${left}px)`;
            }
            if (this.footerRef.current instanceof HTMLElement) {
                this.footerRef.current.style.transform = `translateX(-${left}px)`;
            }
        }
    }

    onSelectAll() {
        if (this.props.selectedRows.length < this.props.data?.length) {
            this.props.onSelectChange?.(this.props.data?.map((row) => row.key));
        } else {
            this.props.onSelectChange?.([]);
        }
    }

    clearSelection() {
        this.props.onSelectChange?.([]);
    }

    onSelectRow(key: string) {
        if (this.props.selectedRows.includes(key)) {
            this.props.onSelectChange?.(this.props.selectedRows.filter((val) => val !== key));
        } else {
            this.props.onSelectChange?.([...this.props.selectedRows, key]);
        }
    }

    setSort(key: keyof Omit<T, "key">) {
        if (key === this.state.sortKey) {
            if (!this.state.sortAsc) {
                this.setState({
                    sortAsc: true,
                });
            } else {
                this.setState({
                    sortKey: null,
                    sortAsc: false,
                });
            }
        } else {
            this.setState({
                sortKey: key,
                sortAsc: false,
            });
        }
    }

    get sortedData() {
        let rows = [...(this.props.data ?? [])].sort((a, b) => {
            if (!this.state.sortKey) {
                return 0;
            }
            if (this.props.sorters?.[this.state.sortKey]) {
                return this.props.sorters[this.state.sortKey](a[this.state.sortKey], b[this.state.sortKey], a, b);
            }
            const aVal = a[this.state.sortKey];
            const bVal = b[this.state.sortKey];
            if (typeof aVal === "string" && typeof bVal === "string") {
                return aVal.localeCompare(bVal);
            }
            if (typeof aVal === "number" && typeof bVal === "number") {
                return bVal - aVal;
            }
            if (React.isValidElement(aVal) && React.isValidElement(bVal)) {
                // This is kinda shoddy and hopes that the child elements are simple
                try {
                    return JSON.stringify((aVal.props as Record<string, unknown>).children).localeCompare(
                        JSON.stringify((bVal.props as Record<string, unknown>).children)
                    );
                } catch (err) {
                    return 0;
                }
            }
            return 0;
        });

        if (this.state.sortAsc && this.state.sortKey) {
            rows = rows.reverse();
        }

        return rows;
    }

    get dataKeys() {
        let keys = Object.keys(
            // Get the keys from the headings or if not the first data object
            this.props.headings ?? omit(this.props.children?.[0] ?? {}, "key")
        ) as (keyof Omit<T, "key">)[];

        // colOrder overrides any assumed ordering, set a negative number to remove the column
        if (this.props.columnOrder) {
            keys = keys
                .filter((key) => {
                    return !(key in this.props.columnOrder) || this.props.columnOrder[key] >= 0;
                })
                .sort((a, b) => {
                    const aIndex = this.props.columnOrder[a] ?? keys.indexOf(a) + 999_999;
                    const bIndex = this.props.columnOrder[b] ?? keys.indexOf(b) + 999_999;
                    return aIndex - bIndex;
                });
        }

        return keys;
    }

    colWidthStyle(key?: keyof Omit<T, "key">, size?: CSSProperties["width"]): CSSProperties {
        if (!size && key) {
            size = this.props.columnsWidths?.[key] ?? null;
        }
        if (size) {
            return {
                width: size,
                flexGrow: 0,
                minWidth: size,
                flexBasis: size,
            };
        }
        return null;
    }

    render() {
        let rows = this.sortedData;
        const keys = this.dataKeys;

        if (this.props.requestState?.errorMessage || this.props.requestState?.isRequesting) {
            rows = [];
        }

        return (
            <div
                className={clsx("list", {
                    list_withSelect: this.props.withSelect,
                    "is-cacheInit": !this.state.sizeCacheInit,
                })}
            >
                {this.props.headings && (
                    <div className="list-header" ref={this.headerRef}>
                        <div className="list-virtItem">
                            {this.props.withSelect && (
                                <div className="list-cell" style={this.colWidthStyle(null, selectColWidth)}>
                                    {this.props.withSelectAll ? (
                                        <Checkbox
                                            onChange={() => this.onSelectAll()}
                                            checked={this.props.selectedRows?.length === rows.length && rows.length > 0}
                                        />
                                    ) : (
                                        <ButtonIcon
                                            icon="Close"
                                            label={languageString("ui.alt.clearSelection")}
                                            onClick={() => this.clearSelection()}
                                            borderless
                                        />
                                    )}
                                </div>
                            )}
                            {keys.map((key, i) => (
                                <CellMeasurer
                                    key={i}
                                    cache={this.state.sizeCache}
                                    columnIndex={i}
                                    updateCache={this.updateCache}
                                >
                                    {this.props.withSort && this.props.headings[key] ? (
                                        <label
                                            key={key as string}
                                            data-test-id={`listHeading-${key as string}`}
                                            data-key-index={i}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setSort(key);
                                            }}
                                            className={clsx("list-cell", "list-sortBtn", {
                                                "is-sorted": this.state.sortKey === key,
                                                "is-asc": this.state.sortAsc,
                                            })}
                                            style={this.colWidthStyle(key)}
                                        >
                                            {this.props.headings[key] ?? null}
                                            <button type="button" className="u-invisible"></button>
                                        </label>
                                    ) : (
                                        <div
                                            className="list-cell"
                                            key={key as string}
                                            data-test-id={`listHeading-${key as string}`}
                                            data-key-index={i}
                                            style={this.colWidthStyle(key)}
                                        >
                                            {this.props.headings[key] ?? null}
                                        </div>
                                    )}
                                </CellMeasurer>
                            ))}
                        </div>
                    </div>
                )}
                <div className="list-scroll">
                    <div className="list-fullWidth">
                        {this.props.requestState?.isRequesting && <SpinnerOverlay isStatic />}
                        {this.props.requestState?.errorMessage && (
                            <ErrorMessage className="u-mt8">{this.props.requestState?.errorMessage}</ErrorMessage>
                        )}
                        {this.props.emptyLabel &&
                            rows.length < 1 &&
                            !this.props.requestState?.isRequesting &&
                            !this.props.requestState?.errorMessage && (
                                <BlockMessage>{this.props.emptyLabel}</BlockMessage>
                            )}
                    </div>
                    <Scroller
                        height={this.props.autoHeight ? undefined : this.props.height}
                        maxHeight={this.props.height}
                        minHeight={60}
                        autoHeight={this.props.autoHeight}
                        onScroll={this.handleScroll}
                        padding={0}
                        className="list-list"
                        virtualise
                        virtualisedProps={{
                            itemClassName: "list-virtItem",
                            listClassName: "list-virtList",
                        }}
                    >
                        {rows.map((listItem, i) => {
                            return (
                                <React.Fragment key={listItem.key}>
                                    {this.props.withSelect && (
                                        <div
                                            className={clsx("list-cell", {
                                                "list-cell_odd": i % 2 === 0,
                                            })}
                                            style={this.colWidthStyle(null, selectColWidth)}
                                        >
                                            <Checkbox
                                                value={listItem.key}
                                                onChange={() => this.onSelectRow(listItem.key)}
                                                checked={!!this.props.selectedRows?.find((key) => listItem.key === key)}
                                            />
                                        </div>
                                    )}
                                    {keys.map((key, ii) => {
                                        const value = listItem[key];
                                        return (
                                            <CellMeasurer
                                                key={ii}
                                                cache={this.state.sizeCache}
                                                columnIndex={ii}
                                                updateCache={this.updateCache}
                                            >
                                                <div
                                                    className={clsx("list-cell", {
                                                        "list-cell_odd": i % 2 === 0,
                                                    })}
                                                    key={key as string}
                                                    data-test-id={`listCell-${i}-${key as string}`}
                                                    data-list-index={i}
                                                    data-key-index={ii}
                                                    style={this.colWidthStyle(key)}
                                                >
                                                    {this.props.formatters?.[key]?.(value, listItem) ??
                                                        (value as string)}
                                                </div>
                                            </CellMeasurer>
                                        );
                                    })}
                                </React.Fragment>
                            );
                        })}
                    </Scroller>
                </div>
                {this.props.footers && (
                    <div className="list-footer" ref={this.footerRef}>
                        <div className="list-virtItem">
                            {this.props.withSelect && (
                                <span style={this.colWidthStyle(null, selectColWidth)}>&nbsp;</span>
                            )}
                            {keys.map((key, i) => (
                                <CellMeasurer
                                    key={i}
                                    cache={this.state.sizeCache}
                                    columnIndex={i}
                                    updateCache={this.updateCache}
                                >
                                    <div
                                        className="list-cell"
                                        key={key as string}
                                        data-key-index={i}
                                        style={this.colWidthStyle(key)}
                                    >
                                        {this.props.footers[key] ?? "\u00a0"}
                                    </div>
                                </CellMeasurer>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

interface CellMeasurerProps {
    columnIndex: number;
    cache: Record<number, number>;
    children: React.ReactElement;
    updateCache: (index: number, size: number) => void;
}

function CellMeasurer({ columnIndex, cache, children, updateCache }: CellMeasurerProps) {
    const { ref, bounds } = useMeasure<HTMLDivElement>();

    useEffect(() => {
        if (ref.current) {
            const flexGrow = ref.current.style.flexGrow;
            ref.current.style.flexGrow = "0";
            const width = ref.current.clientWidth;
            ref.current.style.flexGrow = flexGrow;
            const cacheWidth = cache?.[columnIndex] ?? 0;
            if (width > cacheWidth) {
                updateCache(columnIndex, width);
            }
        }
    }, [ref.current, bounds, updateCache]);

    return React.cloneElement(children, {
        ref,
        style: {
            width: cache?.[columnIndex] ?? 0,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            ...children.props.style,
        } as CSSProperties,
    });
}
