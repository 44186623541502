import type { CampaignObjective, ExperimentAlignment, ExperimentType } from "@/types/CampaignObjective";
import { type ReduxRouterState } from "@lagunovsky/redux-react-router";
import { type ActionType } from "../actions/ActionType";
import {
    type RubyAppleCampaignsWithOrg,
    type RubyBudgetAllocation,
    type RubyBudgetPlan,
    type RubyBudgetPlanMethod,
    type RubyBudgetPlanPolicy,
    type RubyCalendar,
    type RubyCampaign,
    type RubyCampaignAppleStatus,
    type RubyCampaignId,
    type RubyCampaignListingData,
    type RubyCampaignRecord,
    type RubyCampaignRule,
    type RubyCampaignSynchronisation,
    type RubyChannel,
    type RubyChannelNames,
    type RubyChannelType,
    type RubyCountry,
    type RubyEventQuery,
    type RubyEventSeriesQuery,
    type RubyEventTimestamp,
    type RubyEventsCount,
    type RubyHistoryRecordQuery,
    type RubyIOSApp,
    type RubyKeyword,
    type RubyKeywordId,
    type RubyKeywordRankQuery,
    type RubyKeywordRankReport,
    type RubyKeywordReportQuery,
    type RubyKeywordRule,
    type RubyMetricsReportGrouping,
    type RubyMetricsReportQuery,
    type RubyNotification,
    type RubyNotificationSettings,
    type RubyOrder,
    type RubyPaymentCard,
    type RubyProductPage,
    type RubyRegion,
    type RubyRegionRule,
    type RubySearchTermReportQuery,
    type RubyStripeCustomer,
    type RubyTargetingDimensions,
    type RubyTargetingKeywordBidsInfo,
    type RubyTeam,
    type RubyTeamId,
    type RubyTeamInvite,
    type RubyTeamMember,
    type RubyTeamOrganisation,
    type RubyTeamRecord,
    type RubyTier,
    type RubyUserRecord,
    type RubyUserRole,
} from "../services/backend/RubyData";
import { type CountryMap } from "../types/utils";
import {
    type EventKey,
    type EventKeywordReport,
    type EventKeywordTemporalReport,
    type EventReport,
    type EventSummaryReport,
    type TemporalEventReport,
} from "../utilities/events";
import {
    type CampaignMetricsReport,
    type MetricKey,
    type MetricsReport,
    type PurposeMetricsReport,
    type RegionsReport,
    type TemporalKeywordMetricsReport,
    type TemporalMetricsReport,
} from "../utilities/metrics";

// Root State

export interface State {
    router: ReduxRouterState;
    meta: MetaState;
    ui: UIState;
    user: UserState;
    account: AccountState;
    team: TeamState;
    campaigns: CampaignsState;
    config: ConfigState;
    reports: ReportsState;
    keywords: KeywordsState;
    creation: CreationState;
    interaction: InteractionState;
    bulk: BulkState;
    rules: RulesState;
}

// Root States

export interface CreationState {
    step: CreationStep;
    dirty: Record<CreationStep, boolean>;
    restore: RequestState;
    creation: RequestState;
    creationProgress: number;
    templateCampaign?: RubyCampaignId;
    team?: RubyTeamId;
    app?: RubyIOSApp;
    channelType?: RubyChannelType;
    regions: RubyCountry[];
    targeting: RubyTargetingDimensions;
    seedKeywords: string[];
    targetingKeywords: string[];
    targetingKeywordsBroad: string[];
    negativeKeywords: string[];
    budget?: BudgetPlanTemplate;
    costPerDownload?: number;
    regionalCostPerDownload: CountryMap<number>;
    regionalStartingBids: CountryMap<number>;
    organisationId?: RubyTeamOrganisation["orgRef"];
    startingBid?: number;
    productPage?: RubyProductPage["id"];
    createSeparateRegions: boolean;
    experimentType: ExperimentType;
    experimentAlignment: ExperimentAlignment;
    experimentTimeRange?: TimeRange;
    experimentTimeCadence: number;
    experimentTimeCadenceAlignment: ExperimentTimeCadenceAlignment;
    experimentTargeting?: RubyTargetingDimensions;
    experimentProductPage?: RubyProductPage["id"];
    experimentRegion?: RubyCountry;
    experimentKeywords?: string[];
    experimentKeywordsBroad?: string[];
    experimentBidAmount?: number;
    objective?: CampaignObjective;
    lockedBidPrice?: number;
    discoveryWeighting?: number;
}

export interface MetaState {
    windowWidth: number;
    windowHeight: number;
    scrollHistory: Record<string, number>;
    flipperKey: number;
    lastActions: ActionType[];
}

export interface UIState {
    modals: Record<string, boolean>;
    navOpen: boolean;
    activeTeam: RubyTeamId;
    activeCampaign: RubyCampaignId;
    activeKeyword: RubyKeywordId;
    activeEvent: string;
    editingKeywords: {
        negative: boolean;
        keywords: RubyKeyword[];
    };
    editingBudget?: RubyBudgetPlan["id"];
    error?: string;
}

export interface UserState {
    isLoggedIn: boolean;
    isLoggingIn: boolean;
    isLoggingInWithGoogle: boolean;
    loginIsRestoring: boolean;
    loginErrorMessage: string;
    loginRedirect: string;
    role: RubyUserRole;
    userId: number;
    authId: string;
    userName: string;
    userEmail: string;
    emailVerified: boolean;
    metadata: Record<string, unknown>;
    passwordReset: RequestState;
    createAccount: RequestState;
    acceptTermsCallbackUrl: string;
    hasViewedCookies: boolean;
    cookieOptOut?: boolean;
    changingName: RequestState;
}

export interface CampaignsState {
    search: string;
    searchDates: TimeRange;
    searchSortOrder: CampaignSortOrder;
    searchVisibility: Record<CampaignVisibilityFilter, boolean>;
    campaignView: CampaignView;
    searchAppFilter: RubyIOSApp["trackId"][];
    teamCampaigns: Record<RubyTeamId, AsyncData<RubyCampaignId[]>>;
    campaigns: Record<RubyCampaignId, AsyncData<RubyCampaign>>;
    channels: Record<RubyCampaignId, AsyncData<RubyChannel>>;
    apps: Record<RubyChannel["applicationRef"], AsyncData<RubyIOSApp>>;
    productPages: Record<RubyChannel["applicationRef"], AsyncData<RubyProductPage[]>>;
    regions: Record<RubyCampaignId, AsyncData<RubyRegion[]>>;
    budgets: Record<RubyCampaignId, AsyncData<RubyBudgetPlan[]>>;
    activeBudgets: Record<RubyCampaignId, AsyncData<RubyBudgetPlan>>;
    allocations: Record<RubyCampaignId, AsyncData<RubyBudgetAllocation[]>>;
    events: Record<RubyCampaignId, AsyncData<RubyEventsCount[]>>;
    teamEvents: Record<RubyTeamId, AsyncData<RubyEventsCount[]>>;
    funds: Record<RubyCampaignId, AsyncData<number>>;
    addFunds: RequestState;
    deleteCampaign: RequestState;
    appleStatus: Record<RubyCampaignId, AsyncData<RubyCampaignAppleStatus>>;
    synchronisation: Record<RubyCampaignId, AsyncData<RubyCampaignSynchronisation>>;
    syncRequest: RequestState;
    historyRecords: Record<RubyCampaignId, HistoryAsyncData<RubyCampaignRecord[]>[]>;
    editCampaign: RequestState;
    experimentSelected?: RubyCampaignId;
    listData: Record<RubyTeamId, AsyncData<RubyCampaignListingData>>;
    eligibleImports: Record<RubyTeamId, AsyncData<RubyAppleCampaignsWithOrg[]>>;
    importStandaloneCampaign: RequestState;
    updateImportedCampaign: RequestState;
}

export interface ConfigState {
    updateChannel: RequestState;
    createRegions: RequestState;
    updateRegions: RequestState;
    updateRegionWeightings: RequestState;
    addBudgetPlan: Record<RubyCampaignId, RequestState>;
    deleteBudgetPlan: RequestState;
    updateBudgetPlan: RequestState;
    updateChannelNames: RequestState;
    channelNames: Record<RubyCampaignId, AsyncData<RubyChannelNames>>;
    setAllocations: RequestState;
    calendars: AsyncData<RubyCalendar[]>;
    createCalendar: RequestState;
    removeCalendar: RequestState;
    convertBudgetPlan: RequestState;
}

export interface ReportsState {
    graphedMetrics: MetricKey[];
    graphedEventStats: EventKey[];
    regionFilter: RubyCountry[];
    eventTimeFilter: RubyEventTimestamp;
    summary: Record<RubyTeamId, ReportAsyncData<MetricsReport>[]>;
    series: Record<RubyTeamId, ReportAsyncData<TemporalMetricsReport[]>[]>;
    keywordsSummary: Record<RubyTeamId, ReportAsyncData<RubyMetricsReportGrouping[]>[]>;
    keywordSummary: Record<RubyTeamId, KeywordReportAsyncData<KeywordReport>[]>;
    keywordSeries: Record<RubyTeamId, KeywordReportAsyncData<TemporalKeywordMetricsReport[]>[]>;
    keywordRankSeries: Record<RubyTeamId, KeywordRankingAsyncData<RubyKeywordRankReport[]>[]>;
    regions: Record<RubyTeamId, ReportAsyncData<RegionsReport>[]>;
    eventListSummary: Record<RubyTeamId, EventAsyncData<EventSummaryReport[]>[]>;
    eventsSummary: Record<RubyTeamId, EventAsyncData<EventReport>[]>;
    eventsKeywords: Record<RubyTeamId, EventAsyncData<EventKeywordReport[]>[]>;
    eventsKeywordsSeries: Record<RubyTeamId, EventAsyncGranularData<EventKeywordTemporalReport[]>[]>;
    eventsSeries: Record<RubyTeamId, EventAsyncData<TemporalEventReport[]>[]>;
    teamSummaries: Record<RubyTeamId, ReportAsyncData<CampaignMetricsReport[]>[]>;
    purposeSummaries: Record<RubyTeamId, ReportAsyncData<PurposeMetricsReport[]>[]>;
    searchTermsSummaries: Record<RubyTeamId, SearchTermAsyncData<RubyMetricsReportGrouping[]>[]>;
}

export interface KeywordsState {
    targeting: Record<RubyCampaignId, AsyncData<RubyKeyword[]>>;
    negative: Record<RubyCampaignId, AsyncData<RubyKeyword[]>>;
    seed: Record<RubyCampaignId, AsyncData<RubyKeyword[]>>;
    bidsInfo: Record<RubyCampaignId, AsyncData<RubyTargetingKeywordBidsInfo[]>>;
    addTargetingKeywordsRequest: RequestState;
    addNegativeKeywordsRequest: RequestState;
    removeTargetingKeywordsRequest: RequestState;
    removeNegativeKeywordsRequest: RequestState;
    makeNegativeTargetingKeywordsRequest: RequestState;
    updateSeedKeywordsRequest: RequestState;
    tagTargetingKeywordsRequest: RequestState;
    tagNegativeKeywordsRequest: RequestState;
    setKeywordsRegionsRequest: RequestState;
    setKeywordsBidsRequest: RequestState;
    setKeywordsMatchRequest: RequestState;
    importTargetingKeywordsRequest: RequestState;
    importNegativeKeywordsRequest: RequestState;
}

export interface RulesState {
    campaignRules: Record<RubyCampaignId, AsyncData<RubyCampaignRule[]>>;
    regionRules: Record<RubyCampaignId, AsyncData<RubyRegionRule[]>>;
    keywordRules: Record<RubyCampaignId, AsyncData<RubyKeywordRule[]>>;
    createRuleRequest: RequestState;
    removeRuleRequest: RequestState;
    updateRuleRequest: RequestState;
    updatePrioritiesRequest: RequestState;
}

export interface AccountState {
    notifications: PaginatedAsyncData<RubyNotification[]>;
    saveNotificationPrefs: RequestState;
    notificationPrefs: AsyncData<RubyNotificationSettings>;
    deleteAccount: RequestState;
    answerInvite: RequestState;
    userInvites: AsyncData<RubyTeamInvite[]>;
    historyRecords: HistoryAsyncData<RubyUserRecord[]>[];
    deleteAllNotifications: RequestState;
    deleteNotification: Record<RubyNotification["id"], RequestState>;
    demoToken: AsyncData<DemoToken>;
}

export interface TeamState {
    teams: AsyncData<RubyTeam[]>;

    createTeam: AsyncData<RubyTeam>;
    updateTeam: RequestState;
    deleteTeam: RequestState;

    paymentDetails: Record<RubyTeamId, AsyncData<RubyStripeCustomer>>;
    updatePaymentDetails: RequestState;

    tiers: AsyncData<RubyTier[]>;
    createTier: RequestState;
    editTier: RequestState;
    deleteTier: RequestState;

    invites: Record<RubyTeamId, AsyncData<RubyTeamInvite[]>>;
    createInvite: RequestState;
    deleteInvite: RequestState;

    members: Record<RubyTeamId, AsyncData<RubyTeamMember[]>>;
    deleteMember: RequestState;
    updateMember: RequestState;

    ownedApps: Record<RubyTeamId, AsyncData<RubyChannel["applicationRef"][]>>;

    organisations: Record<RubyTeamId, AsyncData<RubyTeamOrganisation[]>>;
    reportOrganisation: RequestState;

    paymentMethods: Record<RubyTeamId, AsyncData<RubyPaymentCard[]>>;
    addPaymentMethod: RequestState;
    setPaymentMethod: RequestState;
    deletePaymentMethod: RequestState;

    historyRecords: Record<RubyTeamId, HistoryAsyncData<RubyTeamRecord[]>[]>;

    orders: Record<RubyTeamId, AsyncData<RubyOrder[]>>;
}

export interface InteractionState {
    supportForm: RequestState;
    contactForm: RequestState;
}

export interface BulkState {
    bulkEditRequest: RequestState;
    wizardStep: number;
    selectedCampaigns: number[];
}

//Utils

export interface RequestState {
    isRequesting: boolean;
    success: boolean;
    errorMessage: string;
}

export interface AsyncData<T> extends RequestState {
    lastUpdated: number;
    data?: T;
}

export interface PaginatedAsyncData<T extends unknown[]> extends AsyncData<T> {
    recordsLoaded: number;
    totalRecords: number;
    hasAll: boolean;
}

export type ReportAsyncData<T> = AsyncData<T> & RubyMetricsReportQuery;
export type EventAsyncData<T> = AsyncData<T> & RubyEventQuery;
export type EventAsyncGranularData<T> = AsyncData<T> & RubyEventSeriesQuery;
export type HistoryAsyncData<T> = AsyncData<T> & RubyHistoryRecordQuery;
export type SearchTermAsyncData<T> = AsyncData<T> & RubySearchTermReportQuery;

export type KeywordReport = MetricsReport & { regions: RegionsReport };
export type KeywordReportAsyncData<T> = AsyncData<T> & RubyKeywordReportQuery;
export type KeywordRankingAsyncData<T> = AsyncData<T> & RubyKeywordRankQuery;

export type TimeRange = [number, number];

export interface BudgetPlanTemplate {
    timeRange: TimeRange;
    totalAmount: number;
    policy: RubyBudgetPlanPolicy;
    method: RubyBudgetPlanMethod;
    weeklyOverspend?: RubyBudgetPlan["details"]["weekdayOverspend"];
}

export interface DemoToken {
    token: string;
    email: string;
    duration: number;
}

//Enums

export enum CampaignSortOrder {
    CREATED_AT = "CREATED_AT",
    NAME = "NAME",
    BUDGET_REMAINING = "BUDGET_REMAINING",
}

export enum CampaignView {
    METRICS = "METRICS",
    SCHEDULED_CHANGES = "SCHEDULED_CHANGES",
    BUDGET = "BUDGET",
    EVENTS = "EVENTS",
}

export enum CampaignVisibilityFilter {
    STATUS = "STATUS",
    BUDGET_REMAINING = "BUDGET_REMAINING",
}

export enum CreationStep {
    LOGIN = "LOGIN",
    TEAM = "TEAM",
    PAYMENT = "PAYMENT",
    APP = "APP",
    REGIONS = "REGIONS",
    COST_PER_DOWNLOAD = "COST_PER_DOWNLOAD",
    TARGETING = "TARGETING",
    PRODUCT_PAGE = "PRODUCT_PAGE",
    KEYWORDS = "KEYWORDS",
    BUDGET = "BUDGET",
    FINALISE = "FINALISE",
    EXPERIMENT_INFO = "EXPERIMENT_INFO",
    EXPERIMENT = "EXPERIMENT",
    EXPERIMENT_TARGETING = "EXPERIMENT_TARGETING",
    EXPERIMENT_PRODUCT_PAGE = "EXPERIMENT_PRODUCT_PAGE",
    EXPERIMENT_REGION = "EXPERIMENT_REGION",
    EXPERIMENT_KEYWORDS = "EXPERIMENT_KEYWORDS",
    EXPERIMENT_BID_AMOUNT = "EXPERIMENT_BID_AMOUNT",
}

export enum ExperimentTimeCadenceAlignment {
    REGULAR = "REGULAR",
    ALTERNATING = "ALTERNATING",
    RANDOM = "RANDOM",
}
