import { createAppIconDataUrl } from "@/utilities/appIcons";
import React from "react";
import { type RequestState } from "../../../reducers/domain";
import { languageString } from "../../../utilities/text";
import { FALLBACK_APP_ICONS } from "../../../utilities/vars";
import RequestLoader from "../requestLoader/RequestLoader";
import "./appIcon.css";
import missingIcon from "./missingAppIcon.svg";

const sizes = {
    tiny: 24,
    small: 32,
    medium: 48,
    large: 64,
};

interface AppIconProps {
    iconUrl: string;
    appName: string;
    className?: string;
    isAndroid?: boolean;
    size: keyof typeof sizes;
    id?: string;
    request?: RequestState;
}

interface AppIconError {
    hasError: boolean;
    missingIcon: string;
}

const TRANS =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQIW2NgAAIAAAUAAR4f7BQAAAAASUVORK5CYII=";

export default class AppIcon extends React.PureComponent<AppIconProps, AppIconError> {
    static defaultProps = {
        size: "small",
    };

    constructor(props: AppIconProps) {
        super(props);
        this.state = {
            hasError: !this.props.iconUrl,
            missingIcon: missingIcon,
        };
    }

    handleError = () => {
        this.setState({
            hasError: true,
            missingIcon: this.missingIcon,
        });
    };

    componentDidUpdate(prevProps: AppIconProps) {
        if (prevProps.iconUrl !== this.props.iconUrl && this.state.hasError) {
            this.setState({
                hasError: false,
            });
        }
    }

    get missingIcon() {
        if (FALLBACK_APP_ICONS) {
            const size = sizes[this.props.size];
            return createAppIconDataUrl(size, size);
        }
        return missingIcon;
    }

    render() {
        const { iconUrl, appName, className, isAndroid, size, request, ...props } = this.props;

        const imgProps = {
            ...props,
            className: `appIcon ${isAndroid ? "" : "appIcon_curved"} appIcon_${size} ${request ? "" : className || ""}`,
            style: {
                "--appIcon-size": sizes[size],
            },
            src: this.state.hasError ? this.state.missingIcon : iconUrl,
            alt: this.state.hasError
                ? languageString("ui.alt.unknownApp")
                : languageString("ui.alt.appIcon", "App icon for {1}", [appName]),
            width: sizes[size],
            height: sizes[size],
            onError: this.handleError,
            loading: "lazy",
        } as React.ImgHTMLAttributes<HTMLImageElement>;

        if (request) {
            return (
                <div className={`u-relative u-inlineBlock ${className || ""}`}>
                    <RequestLoader
                        request={request}
                        placeholder={<img {...imgProps} src={TRANS} alt={languageString("ui.alt.loading")} />}
                        fallback={<img {...imgProps} alt={imgProps.alt} />}
                    >
                        <img {...imgProps} alt={imgProps.alt} />
                    </RequestLoader>
                </div>
            );
        }

        return <img {...imgProps} alt={imgProps.alt} />;
    }
}
