import React from "react";
import { renderToString as render } from "react-dom/server";
import { languageString } from "../../../utilities/text";
import AspectRatioIframe from "../aspectRatioIframe/AspectRatioIframe";

interface EmbedYouTubeProps extends React.IframeHTMLAttributes<HTMLIFrameElement> {
    videoId: string;
    title: string;
    aspectRatio?: number;
    loading?: "eager" | "lazy";
}

export default class EmbedYouTube extends React.PureComponent<EmbedYouTubeProps> {
    render() {
        const { aspectRatio, title, videoId, loading, ...props } = this.props;

        const src = `https://www.youtube.com/embed/${videoId}?color=white&amp;modestbranding=1&amp;playsinline=1&amp;rel=0`;

        const thumbUrl1 = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
        const thumbUrl2 = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

        const srcDoc = (
            <>
                <style>{`
                * {
                    padding: 0;
                    margin: 0;
                    overflow: hidden;
                }
                html,
                body {
                    height: 100%;
                    background: black;
                }
                img, span {
                    position: absolute;
                    width: 100%;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    height: 100%;
                    object-fit: cover;
                }
                img {
                    opacity: 0.7;
                }
                span {
                    height: 1.5em;
                    text-align: center;
                    font: 48px/1.5 sans-serif;
                    color: white;
                    text-shadow: 0 0 0.5em black;
                }
            `}</style>
                <a href={`${src}&amp;autoplay=1`}>
                    <img
                        src={thumbUrl1}
                        alt={title ?? languageString("ui.alt.videoThumb", "Video thumbnail")}
                        onError={(e) => {
                            (e.target as HTMLImageElement).src = thumbUrl2;
                        }}
                    />
                    <span>&#x25BA;</span>
                </a>
            </>
        );

        return (
            <AspectRatioIframe
                aspectRatio={aspectRatio ?? 16 / 9}
                src={src}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; autoplay"
                allowFullScreen
                title={title}
                srcDoc={render(srcDoc)}
                loading={loading ?? "lazy"}
                {...props}
            />
        );
    }
}
