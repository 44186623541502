import throttle from "lodash/throttle";
import React from "react";

type ResponsiveEl = React.ReactElement<ResponsiveElementProps>;

interface ResponsiveProps {
    children?: ResponsiveEl[] | ResponsiveEl;
    vertical?: boolean;
}

interface ResponsiveState {
    size: number;
}

export default class Responsive extends React.PureComponent<ResponsiveProps, ResponsiveState> {
    constructor(props: ResponsiveProps) {
        super(props);
        this.state = {
            size: this.size,
        };

        this.onResize = throttle(this.onResize.bind(this), 500);
    }

    get size() {
        return this.props.vertical ? window.innerHeight : window.innerWidth;
    }

    componentDidMount(): void {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(): void {
        // eslint-disable-next-line @typescript-eslint/unbound-method
        window.removeEventListener("resize", this.onResize);
    }

    onResize() {
        this.setState({
            size: this.size,
        });
    }

    render(): React.ReactNode {
        let activeChild: React.ReactNode = null;

        if (!Array.isArray(this.props.children) && this.props.children.props.minWidth < this.state.size) {
            activeChild = this.props.children;
        }

        if (Array.isArray(this.props.children)) {
            activeChild = [...this.props.children]
                .sort((a, b) => b.props.minWidth - a.props.minWidth)
                .find((c) => {
                    return c.props.minWidth < this.state.size;
                });
        }

        return activeChild;
    }
}

interface ResponsiveElementProps {
    minWidth?: number;
    children?: React.ReactNode;
}

export class ResponsiveElement extends React.PureComponent<ResponsiveElementProps> {
    static defaultProps = {
        minWidth: 0,
    };

    render(): React.ReactNode {
        return this.props.children;
    }
}

// Note; this doesn't cause a re-render on resize, but there is a window resize redux event that does, so this happens to work as expected
export function responsiveValue<T>(defaultValue: T, valueMap: Record<number, T>, vertical?: boolean) {
    const winSize = vertical ? window.innerHeight : window.innerWidth;

    valueMap[0] = defaultValue;
    const values = Object.entries(valueMap);

    const activeValue = values
        .sort(([a, _aVal], [b, _bVal]) => parseFloat(b) - parseFloat(a))
        .find(([minWidth, _value]) => {
            return parseFloat(minWidth) < winSize;
        })[1];

    return activeValue;
}
