import React from "react";
import "./keyPairTable.css";

interface KeyPairValue {
    label: React.ReactNode;
    value: React.ReactNode;
    isLarge?: boolean;
    withDivide?: boolean;
    isBold?: boolean;
}

interface KeyPairTableProps {
    children: KeyPairValue[];
    className?: string;
    striped?: boolean;
}

export default class KeyPairTable extends React.PureComponent<KeyPairTableProps> {
    render() {
        const children = this.props.children.filter((child) => !!child?.label && !!child?.value);
        return (
            <dl
                className={`keyPairTable ${this.props.striped ? "keyPairTable_striped" : ""} ${
                    this.props.className || ""
                }`}
            >
                {children.map((pair, i) => (
                    <div
                        key={i}
                        className={`keyPairTable-line ${pair.isLarge ? "keyPairTable-line_large" : ""} ${
                            pair.withDivide ? "keyPairTable-line_divide" : ""
                        } ${pair.isBold ? "keyPairTable-line_bold" : ""}`}
                    >
                        <dt>{pair.label}</dt>
                        <dd>{pair.value}</dd>
                    </div>
                ))}
            </dl>
        );
    }
}
