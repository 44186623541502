import omit from "lodash/omit";
import React from "react";
import { type InputProps } from "../input/Input";
import "../input/input.css";
import "./textarea.css";

export default class TextArea extends React.PureComponent<InputProps> {
    render() {
        const { isInvalid, className, ...props } = omit(this.props, ["inputRef"]);
        return (
            <textarea
                className={`input textarea ${className || ""} ${isInvalid ? `is-invalid` : ``}`}
                aria-invalid={isInvalid}
                {...props}
            />
        );
    }
}
