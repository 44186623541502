import { ROUTER_ON_LOCATION_CHANGED, type LocationChangeAction } from "@lagunovsky/redux-react-router";
import { AccountActionName, type AccountActionType } from "./accountActions";
import { BulkActionName, type BulkActionType } from "./bulkActions";
import { CampaignActionName, type CampaignActionType } from "./campaignActions";
import { ConfigActionName, type ConfigActionType } from "./configActions";
import { CreationActionName, type CreationActionType } from "./creationActions";
import { InteractionActionName, type InteractionActionType } from "./interactionActions";
import { KeywordsActionName, type KeywordsActionType } from "./keywordsActions";
import { MetaActionName, type MetaActionType } from "./metaActions";
import { ReportingActionName, type ReportingActionType } from "./reportingActions";
import { RulesActionName, type RulesActionType } from "./rulesActions";
import { TeamActionName, type TeamActionType } from "./teamActions";
import { UIActionName, type UIActionType } from "./uiActions";
import { UserActionName, type UserActionType } from "./userActions";

export type ActionType =
    | LocationChangeAction
    | AccountActionType
    | MetaActionType
    | UserActionType
    | TeamActionType
    | UIActionType
    | CampaignActionType
    | ReportingActionType
    | KeywordsActionType
    | ConfigActionType
    | CreationActionType
    | InteractionActionType
    | BulkActionType
    | RulesActionType;

export const ActionName = {
    LOCATION_CHANGE: ROUTER_ON_LOCATION_CHANGED,
    ...AccountActionName,
    ...MetaActionName,
    ...UserActionName,
    ...TeamActionName,
    ...UIActionName,
    ...CampaignActionName,
    ...ReportingActionName,
    ...KeywordsActionName,
    ...ConfigActionName,
    ...CreationActionName,
    ...InteractionActionName,
    ...BulkActionName,
    ...RulesActionName,
} as const;
