export function getCookies(): Map<string, string> {
    const cookies = document.cookie.split(";");
    return cookies.reduce((cSet, cookie) => {
        const [name, value] = cookie.trim().split("=");
        cSet[name] = value;
        return cSet;
    }, new Map<string, string>());
}

export function getCookie(name: string) {
    return getCookies()[name] as string | null;
}

export function removeCookie(name: string) {
    document.cookie = name + "=; Max-Age=-99999999;";
}
