import { Field, type FieldProps } from "formik";
import isArray from "lodash/isArray";
import HiddenSection, { type HiddenSectionProps } from "../../hiddenSection/HiddenSection";

interface HiddenInputSectionProps extends Omit<HiddenSectionProps, "isHidden"> {
    hiddenBy: string;
    inverted?: boolean;
    validator?: (value: unknown) => boolean;
}

// Handle arrays with a length check, useful when dealing with checkbox inputs
function isTrue(value: unknown): boolean {
    if (isArray(value)) {
        return value.length > 0;
    }
    return !!value;
}

export default function HiddenInputSection({
    hiddenBy,
    children,
    inverted,
    validator = isTrue,
    ...props
}: HiddenInputSectionProps) {
    return (
        <Field name={hiddenBy}>
            {({ field }: FieldProps) => (
                <HiddenSection {...props} isHidden={inverted ? !validator(field.value) : validator(field.value)}>
                    {children}
                </HiddenSection>
            )}
        </Field>
    );
}
