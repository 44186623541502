import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { usersTeamInviteAction } from "../../../../actions/accountActions";
import { toggleModalAction } from "../../../../actions/uiActions";
import { type State } from "../../../../reducers/domain";
import TeamInvitesPageComponent, { type TeamInvitesPageComponentProps } from "./TeamInvitesPageComponent";

const mapStateToProps = (state: State): Partial<TeamInvitesPageComponentProps> => {
    return {
        invites: state.account.userInvites,
        answerInviteRequest: state.account.answerInvite,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): Partial<TeamInvitesPageComponentProps> => ({
    listInvites: () => dispatch(usersTeamInviteAction.list.request()),
    answerInvite: (inviteId, answer, teamId) =>
        dispatch(usersTeamInviteAction.answer.request({ inviteId, answer, teamId })),
    openCreateTeam: () => dispatch(toggleModalAction("createTeam", true)),
});

const TeamInvitesPage = connect(mapStateToProps, mapDispatchToProps)(TeamInvitesPageComponent);
export default TeamInvitesPage;
