import React from "react";
import { getCountry } from "../../../utilities/country";
import { languageString } from "../../../utilities/text";
import "./flag.css";

interface FlagProps {
    countryCode: string;
    size: "small" | "medium" | "large" | "dynamic";
    className?: string;
    isFaded?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

export default class Flag extends React.PureComponent<FlagProps> {
    static defaultProps = {
        size: "small",
    };

    render() {
        const data = getCountry(this.props.countryCode);
        const title = languageString("ui.alt.flag", "Flag of {1}", [data.name]);
        return (
            <div
                className={`flag flag_${this.props.size} ${this.props.isFaded ? `flag_faded` : ``} ${
                    this.props.className || ""
                }`}
                role="img"
                title={title}
                aria-label={title}
                onClick={this.props.onClick}
                style={{
                    backgroundImage: `url('/static/flags/${this.props.countryCode.toLowerCase()}.svg')`,
                }}
            />
        );
    }
}
