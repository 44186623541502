import { rubyService } from "@/services";
import { generateEmptyTemporalReport, tsToEndOfGranularity, tsToStartOfGranularity } from "@/utilities/time";
import { type ReplicaCustomReportResponse } from "@redbox-ruby/data-lib";
import flatten from "lodash/flatten";
import omit from "lodash/omit";
import { put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    getCampaignSummariesAction,
    getEventListSummaryAction,
    getEventsKeywordsAction,
    getEventsSeriesAction,
    getEventsSeriesKeywordsAction,
    getEventsSummaryAction,
    getKeywordRanksSeriesAction,
    getKeywordSeriesAction,
    getKeywordSummaryAction,
    getKeywordsSummaryAction,
    getMetricsSeriesAction,
    getMetricsSummaryAction,
    getPurposeSummariesAction,
    getRegionsReportAction,
    getSearchTermsSummaryAction,
    type ReportingActions,
} from "../actions/reportingActions";
import { setMetadataAction } from "../actions/userActions";
import { selectActiveCountries } from "../selectors/campaignSelectors";
import {
    selectCampaignRegionsSummary,
    selectCampaignSeries,
    selectCampaignSummaries,
    selectCampaignSummary,
    selectEventKeywords,
    selectEventListSummary,
    selectEventSeries,
    selectEventSeriesKeywords,
    selectEventSummary,
    selectKeywordRankSeries,
    selectKeywordReport,
    selectKeywordSeries,
    selectKeywordsSummary,
    selectPurposeSummaries,
    selectSearchTermsSummary,
} from "../selectors/reportsSelectors";
import {
    calculateAdditionalMetrics,
    extractRegionsReport,
    type MetricsReport,
    type RegionsReport,
} from "../utilities/metrics";
import { runDataRequestAction, runDataRequestSaga, selectAsyncDataFromState, takeRequests } from "../utilities/saga";

function* loadMetricsSummarySaga(action: ExtractRequestActionRequestType<ReportingActions.GetMetricsSummaryAction>) {
    yield* runDataRequestAction(action, getMetricsSummaryAction, selectCampaignSummary, (req) =>
        rubyService.appleReplica
            .getCampaignReportSummary({ teamId: req.teamId }, omit(req, ["teamId"]))
            .then((res) => res.totals)
    );
}

function* loadMetricsSeriesSaga(action: ExtractRequestActionRequestType<ReportingActions.GetMetricsSeriesAction>) {
    yield* runDataRequestAction(action, getMetricsSeriesAction, selectCampaignSeries, (req) =>
        rubyService.appleReplica
            .getCampaignReportGrouping({ teamId: req.teamId }, { ...omit(req, ["teamId"]), by: "period" })
            .then((res) => res.groups)
    );
}

function* loadRegionsReportSaga(action: ExtractRequestActionRequestType<ReportingActions.GetRegionsReportAction>) {
    yield* runDataRequestAction(
        action,
        getRegionsReportAction,
        (state, req) => selectCampaignRegionsSummary(state, req),
        ({ teamId, ...req }) =>
            rubyService.appleReplica
                .getCampaignReportGrouping({ teamId: teamId }, { ...req, by: "country" })
                .then((res) => res.groups)
    );
}

function* saveGraphedMetricsSaga(action: ReportingActions.SetGraphedMetricsAction) {
    yield put(setMetadataAction({ graphedMetrics: action.payload }));
}

export function* loadKeywordsSummarySaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetKeywordsSummaryAction>
) {
    yield* runDataRequestAction(action, getKeywordsSummaryAction, selectKeywordsSummary, (req) =>
        rubyService.appleReplica
            .getAdGroupTargetingKeywordReportGrouping(
                { teamId: req.teamId },
                {
                    ...omit(req, ["teamId"]),
                    by: "keywordId",
                }
            )
            .then((res) => res.groups)
    );
}

function* loadKeywordSummarySaga(action: ExtractRequestActionRequestType<ReportingActions.GetKeywordSummaryAction>) {
    yield* runDataRequestSaga(action, getKeywordSummaryAction, selectKeywordReport, function* (req) {
        const countries = yield* selectAsyncDataFromState((state) =>
            selectActiveCountries(state, action.payload.request.campaignId)
        );
        const allCountries =
            action.payload.request.country?.length > 0 ? action.payload.request.country : countries.activeCountries;

        const [data, regions] = (yield Promise.all([
            rubyService.appleReplica
                .getAdGroupTargetingKeywordReportSummary(
                    { teamId: req.teamId },
                    {
                        ...omit(req, ["teamId", "keywordId"]),
                        keywordId: [req.keywordId],
                    }
                )
                .then((res) => calculateAdditionalMetrics(res.totals)),
            rubyService.appleReplica
                .getAdGroupTargetingKeywordReportGrouping(
                    { teamId: req.teamId },
                    {
                        by: "country",
                        ...omit(req, ["teamId", "keywordId"]),
                        keywordId: [req.keywordId],
                    }
                )
                .then((res) => extractRegionsReport(res.groups, allCountries)),
        ])) as [MetricsReport, RegionsReport];

        return {
            ...data,
            regions,
        };
    });
}

export function* loadKeywordSeriesSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetKeywordSeriesAction>
) {
    yield* runDataRequestAction(action, getKeywordSeriesAction, selectKeywordSeries, (req) =>
        rubyService.appleReplica
            .getAdGroupTargetingKeywordReportGrouping(
                { teamId: req.teamId },
                { ...omit(req, ["teamId", "keywordId"]), keywordId: [req.keywordId], by: "period" }
            )
            .then((res) => res.groups)
    );
}

function* loadEventListSummarySaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetEventListSummaryAction>
) {
    yield* runDataRequestAction(
        action,
        getEventListSummaryAction,
        selectEventListSummary,
        ({ teamId, campaignId, name: _name, ...req }) =>
            rubyService.attributions
                .groupEvents(
                    { teamId },
                    {
                        ...req,
                        campaignId: [campaignId],
                        by: "name",
                    }
                )
                .then((res) => res.groups)
    );
}

function* loadEventsSummarySaga(action: ExtractRequestActionRequestType<ReportingActions.GetEventsSummaryAction>) {
    yield* runDataRequestAction(
        action,
        getEventsSummaryAction,
        selectEventSummary,
        ({ teamId, campaignId, name, ...req }) =>
            rubyService.attributions.summariseEvents(
                { teamId },
                {
                    ...req,
                    campaignId: [campaignId],
                    name: [name],
                }
            )
    );
}

export function* loadEventsKeywordsSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetEventsKeywordsAction>
) {
    yield* runDataRequestAction(
        action,
        getEventsKeywordsAction,
        selectEventKeywords,
        ({ teamId, campaignId, name, ...req }) =>
            rubyService.attributions
                .groupEvents(
                    { teamId },
                    {
                        ...req,
                        campaignId: [campaignId],
                        name: [name],
                        by: "keyword",
                    }
                )
                .then((res) => res.groups)
    );
}

export function* loadEventsSeriesKeywordsSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetEventsSeriesKeywordsAction>
) {
    yield* runDataRequestAction(
        action,
        getEventsSeriesKeywordsAction,
        selectEventSeriesKeywords,
        async ({ teamId, campaignId, name, granularity, ...req }) => {
            const reportsReq = generateEmptyTemporalReport(req.from, req.to, granularity, {}).map((report) => {
                return rubyService.attributions
                    .groupEvents(
                        { teamId },
                        {
                            ...req,
                            campaignId: [campaignId],
                            name: [name],
                            by: "keyword",
                            from: tsToStartOfGranularity(report.date, granularity),
                            to: tsToEndOfGranularity(report.date, granularity),
                        }
                    )
                    .then((res) => res.groups.map((rep) => ({ ...rep, date: report.date })));
            });
            const reports = await Promise.all(reportsReq);

            return flatten(reports);
        }
    );
}

function* loadEventsSeriesSaga(action: ExtractRequestActionRequestType<ReportingActions.GetEventsSeriesAction>) {
    yield* runDataRequestAction(
        action,
        getEventsSeriesAction,
        selectEventSeries,
        ({ teamId, campaignId, name, ...req }) =>
            rubyService.attributions
                .groupEvents(
                    { teamId },
                    {
                        ...req,
                        campaignId: [campaignId],
                        name: [name],
                        by: "period",
                    }
                )
                .then((res) => res.groups)
    );
}

function* saveGraphedEventStatsSaga(action: ReportingActions.SetGraphedEventStatsAction) {
    yield put(setMetadataAction({ graphedEventStats: action.payload }));
}

function* loadCampaignSummariesSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetCampaignSummariesAction>
) {
    yield* runDataRequestAction(action, getCampaignSummariesAction, selectCampaignSummaries, ({ teamId, ...req }) =>
        rubyService.appleReplica
            .getCampaignReportGrouping({ teamId }, { ...req, by: "campaignId" })
            .then((res) => res.groups)
    );
}

function* loadPurposeSummariesSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetPurposeSummariesAction>
) {
    yield* runDataRequestAction(action, getPurposeSummariesAction, selectPurposeSummaries, ({ teamId, ...req }) =>
        rubyService.appleReplica
            .getAdGroupReportGrouping(
                { teamId },
                {
                    ...req,
                    by: "purpose",
                }
            )
            .then((res) => res.groups)
    );
}

function* loadKeywordRankSeriesSaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetKeywordRanksSeriesAction>
) {
    yield* runDataRequestSaga(
        action,
        getKeywordRanksSeriesAction,
        selectKeywordRankSeries,
        function* ({ teamId, country, campaignId, ...req }) {
            if (country.length === 0) {
                const countries = yield* selectAsyncDataFromState((state) => selectActiveCountries(state, campaignId));
                country = countries.activeCountries;
            }
            const data = yield Promise.all(
                country.map((country) =>
                    rubyService.appleReplica.listCustomReports(
                        { teamId },
                        {
                            ...req,
                            country,
                        },
                        { all: true }
                    )
                )
            );
            return (data as ReplicaCustomReportResponse[]).flatMap((response) => response.results);
        }
    );
}

function* loadSearchTermsSummarySaga(
    action: ExtractRequestActionRequestType<ReportingActions.GetSearchTermsSummaryAction>
) {
    yield* runDataRequestAction(action, getSearchTermsSummaryAction, selectSearchTermsSummary, (req) =>
        rubyService.appleReplica
            .getSearchTermReportGrouping(
                { teamId: req.teamId },
                {
                    ...omit(req, ["teamId"]),
                    by: "searchTerm",
                }
            )
            .then((res) => res.groups)
    );
}

export default function* reportsSaga() {
    yield* takeRequests(ActionName.GET_METRICS_SUMMARY, loadMetricsSummarySaga);
    yield* takeRequests(ActionName.GET_METRICS_SERIES, loadMetricsSeriesSaga);
    yield* takeRequests(ActionName.GET_REGIONS_REPORT, loadRegionsReportSaga);
    yield takeEvery(ActionName.SET_GRAPHED_METRICS, saveGraphedMetricsSaga);
    yield* takeRequests(ActionName.GET_KEYWORDS_SUMMARY, loadKeywordsSummarySaga);
    yield* takeRequests(ActionName.GET_KEYWORD_SUMMARY, loadKeywordSummarySaga);
    yield* takeRequests(ActionName.GET_KEYWORD_SERIES, loadKeywordSeriesSaga);
    yield* takeRequests(ActionName.GET_KEYWORD_RANKS_SERIES, loadKeywordRankSeriesSaga);
    yield* takeRequests(ActionName.GET_EVENT_LIST_SUMMERY, loadEventListSummarySaga);
    yield* takeRequests(ActionName.GET_EVENTS_SUMMARY, loadEventsSummarySaga);
    yield* takeRequests(ActionName.GET_EVENTS_KEYWORDS, loadEventsKeywordsSaga);
    yield* takeRequests(ActionName.GET_EVENTS_SERIES, loadEventsSeriesSaga);
    yield takeEvery(ActionName.SET_GRAPHED_EVENT_STATS, saveGraphedEventStatsSaga);
    yield* takeRequests(ActionName.GET_CAMPAIGN_SUMMARIES, loadCampaignSummariesSaga);
    yield* takeRequests(ActionName.GET_PURPOSE_SUMMARIES, loadPurposeSummariesSaga);
    yield* takeRequests(ActionName.GET_SEARCH_TERMS_SUMMARY, loadSearchTermsSummarySaga);
}
