import { useEffect } from "react";
import { type RequestState } from "../../../reducers/domain";
import { languageString } from "../../../utilities/text";
import ActionPage from "../../assemblies/actionPage/ActionPage";
import ErrorMessage from "../../blocks/errors/errorMessage/ErrorMessage";
import SpinnerOverlay from "../../blocks/spinnerOverlay/SpinnerOverlay";
import SuccessMessage from "../../blocks/successMessage/SuccessMessage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppleCallbackPageProps {}

export interface AppleCallbackPageComponentProps extends AppleCallbackPageProps {
    reportOrganisationRequest: RequestState;
    reportOrganisation: () => void;
}

export default function AppleCallbackPageComponent({
    reportOrganisationRequest,
    reportOrganisation,
}: AppleCallbackPageComponentProps) {
    useEffect(() => {
        reportOrganisation();
    }, [reportOrganisation]);

    return (
        <ActionPage title={languageString("account.team.asa.title")}>
            {reportOrganisationRequest.success && (
                <SuccessMessage>{languageString("account.team.asa.callback")}</SuccessMessage>
            )}
            {reportOrganisationRequest.errorMessage && (
                <ErrorMessage>{reportOrganisationRequest.errorMessage}</ErrorMessage>
            )}
            {reportOrganisationRequest.isRequesting && <SpinnerOverlay isStatic />}
        </ActionPage>
    );
}
