import capitalize from "lodash/capitalize";
import lowerFirst from "lodash/lowerFirst";
import upperFirst from "lodash/upperFirst";
import { getDict } from "../dictionaries";

export function writeList(arr: string[] = [], type?: Intl.ListFormatType): string {
    return new Intl.ListFormat(undefined, {
        style: "long",
        type: type ?? "conjunction",
    }).format(arr.filter((a) => !!a));
}

// Not sure this belongs here, but there isn't anywhere it particularly does
export function formatTargeting(percent: number, target: string) {
    if (percent > 0.01) {
        return languageString("campaign.targeting.above", "", [target]);
    }
    if (percent < -0.01) {
        return languageString("campaign.targeting.below", "", [target]);
    }
    return languageString("campaign.targeting.on", "", [target]);
}

export function formatOptimising(percent: number) {
    if (percent > 0.8) {
        return languageString("campaign.keywords.optimise.end");
    }
    if (percent > 0.2) {
        return languageString("campaign.keywords.optimise.mid");
    }
    return languageString("campaign.keywords.optimise.start");
}

/**
 * String substitution:
 *     NOT 0 indexed, start counting at 1 like a regex
 *     "Some values include {1} variables"
 *
 * Plurals:
 *     Check a number value and add a string based on it
 *     "I have selected {1} region{1!1?s} and got {2} error{2!foo?s} which is {2>5?loads}{2=5?normal}{2<5?low}"
 *     Syntax: { variableIndex (= ! > <) comparisonValue ? stringToPrint }
 *
 * Functions:
 *     Manipulate variables with functions
 *     String variables:
 *         {1|lower} Lowercase
 *         {1|upper} Uppercase
 *         {1|capital} Capitalise first character
 *     Number variables:
 *         {1|round} Round to nearest number
 *         {1|floor} Round down
 *         {1|ceil} Round up
 *
 * @param english This value is intentionally unused, it remains
 * in the arguments as it is helpful when coding to have an idea what the return value could look like.
 */
export function languageString(key: string, english?: string, values?: (string | number)[]) {
    // "en-GB" is hard coded for now as there are no translations
    const dict = getDict("en-GB");

    let result = dict.get(key);

    if (values) {
        values.forEach((val, i) => {
            if (val?.toString) {
                result = result.replaceAll(`{${i + 1}}`, val.toString());
            }
        });
        values.forEach((val, i) => {
            if (val?.toString) {
                result = applyFuncs(result, i + 1, val);
            }
        });
        values.forEach((val, i) => {
            if (val?.toString) {
                result = applyChecks(result, i + 1, val);
            }
        });
    }

    return result;
}

export function applyChecks(langString: string, index: number, value: string | number): string {
    const test = new RegExp(`{${index}(=|<|>|!)(.*?)\\?(.+?)}`, "gi");
    const matches = langString.match(test);

    for (const match of matches ?? []) {
        const values = match.replaceAll(/{|}/gi, "").split("?");
        const replacement = values[1] ?? "";

        if (values[0].includes("=")) {
            const equals = values[0].split("=")[1];
            langString = langString.replace(match, value.toString() === equals ? replacement : "");
        } else if (values[0].includes("!")) {
            const equals = values[0].split("!")[1];
            langString = langString.replace(match, value.toString() !== equals ? replacement : "");
        } else if (values[0].includes("<") && typeof value === "number") {
            const equals = parseInt(values[0].split("<")[1], 10);
            langString = langString.replace(match, value < equals ? replacement : "");
        } else if (values[0].includes(">") && typeof value === "number") {
            const equals = parseInt(values[0].split(">")[1], 10);
            langString = langString.replace(match, value > equals ? replacement : "");
        } else if (values[0].includes("<") && typeof value === "string") {
            const equals = parseInt(values[0].split("<")[1], 10);
            langString = langString.replace(match, value.length < equals ? replacement : "");
        } else if (values[0].includes(">") && typeof value === "string") {
            const equals = parseInt(values[0].split(">")[1], 10);
            langString = langString.replace(match, value.length > equals ? replacement : "");
        } else {
            langString = langString.replace(match, "");
        }
    }

    return langString;
}

export function applyFuncs(langString: string, index: number, value: string | number): string {
    const test = new RegExp(`{${index}\\|([a-z]+?)}`, "gi");
    const matches = langString.match(test);

    for (const match of matches ?? []) {
        const func = match.replaceAll(/{|}/gi, "").split("|")[1];
        if (func === "lower") {
            langString = langString.replace(match, value.toString().toLocaleLowerCase());
        } else if (func === "upper") {
            langString = langString.replace(match, value.toString().toLocaleUpperCase());
        } else if (func === "capital") {
            langString = langString.replace(match, capitalize(value.toString()));
        } else if (func === "round") {
            langString = langString.replace(match, Math.round(parseFloat(value.toString())).toString());
        } else if (func === "floor") {
            langString = langString.replace(match, Math.floor(parseFloat(value.toString())).toString());
        } else if (func === "ceil") {
            langString = langString.replace(match, Math.ceil(parseFloat(value.toString())).toString());
        }
    }

    return langString;
}

export function getEnumKey(type: Record<string, string>, value: string): string {
    return Object.entries(type).find(([_key, val]) => val === value)?.[0];
}

export function enumLanguageString(keyPath: string, value: string, values?: (string | number)[]) {
    const key = `${keyPath}.${enumKey(value)}`;
    return languageString(key, undefined, values);
}

export function enumKey(value: string): string {
    return lowerFirst((value ?? "").toLocaleLowerCase().split("_").map(upperFirst).join(""));
}
