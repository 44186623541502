import clsx from "clsx";
import isNumber from "lodash/isNumber";
import type React from "react";
import "./inputGrid.css";

interface InputGridProps {
    label?: React.ReactNode;
    children?: React.ReactNode;
    colWidth?: number;
    minInputWidth?: number;
    className?: string;
    gap?: number;
}

export default function InputGrid({ label, children, colWidth = 1, minInputWidth, className, gap }: InputGridProps) {
    return (
        <fieldset className={clsx("inputGrid", className)}>
            {label && <legend className="inputGrid-label">{label}</legend>}
            <div
                className="inputGrid-grid"
                style={{
                    "--inputGrid-colWidth": colWidth,
                    "--input-minWidth": minInputWidth ? `${minInputWidth}px` : null,
                    "--gap": isNumber(gap) ? `${gap}px` : null,
                }}
            >
                {children}
            </div>
        </fieldset>
    );
}
