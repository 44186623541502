import { matchHistoryRecord } from "@/selectors/matchers";
import { type RubyTeam, type RubyTier } from "@/services/backend/RubyData";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { selectTeam } from "../selectors/teamSelectors";
import { languageString } from "../utilities/text";
import { type State, type TeamState } from "./domain";
import {
    initialAsyncDataState,
    initialRequestState,
    requestActionReducer,
    requestReportActionReducer,
} from "./requestReducer";

export const initialTeamState: TeamState = {
    teams: initialAsyncDataState<RubyTeam[]>([]),
    createTeam: initialAsyncDataState(),
    updateTeam: initialRequestState(),
    deleteTeam: initialRequestState(),

    paymentDetails: {},
    updatePaymentDetails: initialRequestState(),

    tiers: initialAsyncDataState<RubyTier[]>([]),
    createTier: initialRequestState(),
    editTier: initialRequestState(),
    deleteTier: initialRequestState(),

    invites: {},
    createInvite: initialRequestState(),
    deleteInvite: initialRequestState(),

    members: {},
    deleteMember: initialRequestState(),
    updateMember: initialRequestState(),

    ownedApps: {},

    paymentMethods: {},
    addPaymentMethod: initialRequestState(),
    setPaymentMethod: initialRequestState(),
    deletePaymentMethod: initialRequestState(),

    organisations: {},
    reportOrganisation: initialRequestState(),

    historyRecords: {},

    orders: {},
};

export default function accountReducer(state: TeamState = initialTeamState, action: ActionType, globalState: State) {
    switch (action.type) {
        case ActionName.LOCATION_CHANGE: {
            state.createTeam = initialAsyncDataState();
            state.updateTeam = initialRequestState();
            state.updatePaymentDetails = initialRequestState();
            state.createTier = initialRequestState();
            state.createInvite = initialRequestState();
            state.deleteInvite = initialRequestState();
            state.deleteMember = initialRequestState();
            state.updateMember = initialRequestState();
            state.reportOrganisation = initialRequestState();
            break;
        }

        case ActionName.LIST_TEAMS: {
            state.teams = requestActionReducer(action, undefined, state.teams);
            break;
        }

        case ActionName.CREATE_TEAM: {
            state.createTeam = requestActionReducer(action, (payload) => payload.team);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.teams.data.push(action.payload.response.team);
                state.paymentDetails[action.payload.response.team.id] = {
                    data: action.payload.response.paymentDetails,
                    isRequesting: false,
                    lastUpdated: Date.now(),
                    errorMessage: null,
                    success: true,
                };
            }
            break;
        }

        case ActionName.UPDATE_TEAM: {
            state.updateTeam = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const teamIndex = state.teams.data?.findIndex((team) => team.id === action.payload.request.teamId);
                state.teams.data[teamIndex] = action.payload.response;
            }
            break;
        }

        case ActionName.GET_TEAM_PAYMENT_DETAILS: {
            state.paymentDetails[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.paymentDetails[action.payload.request]
            );
            break;
        }

        case ActionName.UPDATE_TEAM_PAYMENT_DETAILS: {
            state.updatePaymentDetails = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.paymentDetails[action.payload.request.teamId] = {
                    data: action.payload.response,
                    isRequesting: false,
                    lastUpdated: Date.now(),
                    errorMessage: null,
                    success: true,
                };
            }
            break;
        }

        case ActionName.DELETE_TEAM: {
            state.deleteTeam = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.teams.data = state.teams.data.filter((team) => team.id !== action.payload.request);
            }
            break;
        }

        case ActionName.LIST_TIERS: {
            state.tiers = requestActionReducer(action, undefined, state.tiers);
            break;
        }

        case ActionName.CREATE_TIER: {
            state.createTier = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.tiers.data.push(action.payload.response);
            }
            break;
        }

        case ActionName.EDIT_TIER: {
            state.editTier = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const tierIndex = state.tiers.data.findIndex((tier) => tier.id === action.payload.request.id);
                state.tiers.data[tierIndex] = action.payload.response;
            }
            break;
        }

        case ActionName.DELETE_TIER: {
            state.deleteTier = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.tiers.data = state.tiers.data.filter((tier) => tier.id !== action.payload.request);
            }
            break;
        }

        case ActionName.LIST_TEAM_INVITES: {
            state.invites[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.invites[action.payload.request]
            );
            break;
        }

        case ActionName.CREATE_TEAM_INVITE: {
            state.createInvite = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                // This is create or update on the server
                // If you invite a user who is already invited, it'll update the existing invite
                const response = action.payload.response;
                const invites = state.invites[action.payload.request.teamId].data;
                const existingIndex = invites.findIndex((x) => x.id === response.id);
                if (existingIndex === -1) {
                    invites.push(response);
                } else {
                    invites[existingIndex] = response;
                }
            }
            break;
        }

        case ActionName.DELETE_TEAM_INVITE: {
            state.deleteInvite = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.invites[action.payload.request.teamId].data = state.invites[
                    action.payload.request.teamId
                ].data.filter((invite) => invite.id !== action.payload.request.inviteId);
            }
            break;
        }

        case ActionName.LIST_TEAM_MEMBERS: {
            state.members[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.members[action.payload.request]
            );
            break;
        }

        case ActionName.DELETE_TEAM_MEMBER: {
            state.deleteMember = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.members[action.payload.request.teamId].data = state.members[
                    action.payload.request.teamId
                ].data.filter((member) => member.user.id !== action.payload.request.userId);
            }
            break;
        }

        case ActionName.UPDATE_TEAM_MEMBER: {
            state.updateMember = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const memberIndex = state.members[action.payload.request.teamId].data.findIndex(
                    (member) => member.user.id !== action.payload.request.userId
                );
                state.members[action.payload.request.teamId].data[memberIndex] = action.payload.response;
            }
            break;
        }

        case ActionName.LIST_TEAM_ORGANISATIONS: {
            state.organisations[action.payload.request.teamId] = requestActionReducer(
                action,
                undefined,
                state.organisations[action.payload.request.teamId]
            );
            break;
        }

        case ActionName.REPORT_ASA_AUTH_CREDS: {
            state.reportOrganisation = requestActionReducer(action);

            if (action.payload.state === RequestActionState.SUCCESS) {
                const teamCurrency = selectTeam(globalState, action.payload.response.teamId).data?.currency;
                const orgCurrencies = action.payload.response.organisations.map((org) => org.currency);
                if (teamCurrency && orgCurrencies.length && !orgCurrencies.includes(teamCurrency)) {
                    state.organisations[action.payload.response.teamId] = {
                        errorMessage: languageString("account.team.asa.missingCurrency"),
                        isRequesting: false,
                        lastUpdated: Date.now(),
                        success: false,
                        data: state.organisations[action.payload.response.teamId].data,
                    };
                } else {
                    // This should be overwritten with a fresh call to the listing endpoint
                    state.organisations[action.payload.response.teamId] = {
                        success: false,
                        errorMessage: null,
                        isRequesting: false,
                        lastUpdated: Date.now(),
                        data: action.payload.response.organisations,
                    };
                }
            }
            break;
        }

        case ActionName.LIST_TEAM_APPS: {
            state.ownedApps[action.payload.request] = requestActionReducer(
                action,
                (apps) => apps.map((app) => app.trackId),
                state.ownedApps[action.payload.request]
            );
            break;
        }

        case ActionName.LIST_TEAM_PAYMENT_METHODS: {
            state.paymentMethods[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.paymentMethods[action.payload.request]
            );
            break;
        }

        case ActionName.ADD_PAYMENT_METHOD: {
            state.addPaymentMethod = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const paymentMethods = state.paymentMethods[action.payload.request];
                if (paymentMethods?.data) {
                    state.paymentMethods[action.payload.request].data.push(action.payload.response);
                } else {
                    state.paymentMethods[action.payload.request] = {
                        ...initialAsyncDataState(),
                        data: [action.payload.response],
                    };
                }
            }
            break;
        }

        case ActionName.SET_DEFAULT_PAYMENT_METHOD: {
            state.setPaymentMethod = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.paymentMethods[action.payload.request.teamId].data.forEach((card) => {
                    card.defaultMethod = card.id === action.payload.request.paymentMethodId;
                });
            }
            break;
        }

        case ActionName.DELETE_PAYMENT_METHOD: {
            state.deletePaymentMethod = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.paymentMethods[action.payload.request.teamId].data = state.paymentMethods[
                    action.payload.request.teamId
                ].data.filter((card) => card.id !== action.payload.request.paymentMethodId);
            }
            break;
        }

        case ActionName.LIST_TEAM_HISTORY_RECORDS: {
            state.historyRecords[action.payload.request.teamId] = requestReportActionReducer(
                action,
                state.historyRecords[action.payload.request.teamId],
                matchHistoryRecord
            );
            break;
        }

        case ActionName.LIST_ORDERS: {
            state.orders[action.payload.request.teamId] = requestActionReducer(
                action,
                undefined,
                state.orders[action.payload.request.teamId]
            );
        }
    }
}
