import type { StripeServiceImpl } from "@/types/StripeService";
import type { RubyApiClient } from "@redbox-ruby/client-lib";
import { languageString } from "../utilities/text";
import {
    RubyOrderPaymentStatus,
    RubyOrderStatus,
    RubyPaymentProcessor,
    type RubyCampaignId,
    type RubyOrder,
    type RubyTeamId,
} from "./backend/RubyData";

interface OrderRequest {
    teamId: RubyTeamId;
    campaignId: RubyCampaignId;
    amount: number;
}

export class OrderService {
    private order: RubyOrder;
    private lastPaidOrder: RubyOrder;
    private stripeService: StripeServiceImpl;
    private rubyService: RubyApiClient;

    constructor(stripeService: StripeServiceImpl, rubyService: RubyApiClient) {
        this.stripeService = stripeService;
        this.rubyService = rubyService;
    }

    private async createOrder(props: OrderRequest): Promise<RubyOrder> {
        const existingOrders = await this.rubyService.budgets.listOrders({ id: props.teamId }, {}, { all: true });
        let order = existingOrders.results.find((order) => order.status === RubyOrderStatus.DRAFT);
        if (order) {
            this.order = order;
            return await this.updateOrder(props);
        }

        order = await this.rubyService.budgets.createPrepayOrder(
            { teamId: props.teamId },
            {
                campaignId: props.campaignId > 0 ? props.campaignId : null,
                budgetPaid: props.amount,
                processor: RubyPaymentProcessor.STRIPE,
            }
        );
        this.order = order;
        return order;
    }

    private async updateOrder(props: OrderRequest): Promise<RubyOrder> {
        let order = this.order;
        if (!order) {
            order = await this.createOrder(props);
        }
        order = await this.rubyService.budgets.updatePrepayOrder(
            {
                orderId: order.id,
                teamId: props.teamId,
            },
            {
                budgetPaid: props.amount,
                campaignId: props.campaignId > 0 ? props.campaignId : null,
            }
        );
        this.order = order;
        return order;
    }

    async getOrder(props: OrderRequest): Promise<RubyOrder> {
        let order = this.order;
        if (!order) {
            order = await this.createOrder(props);
        }
        if (
            order.budgetSpend !== props.amount ||
            order.campaignId !== props.campaignId ||
            order.teamId !== props.teamId
        ) {
            order = await this.updateOrder(props);
        }
        return order;
    }

    async payOrder(props: OrderRequest) {
        const order = await this.getOrder(props);
        if (order.campaignId < 1) {
            throw new Error(languageString("ui.error.orderCampaignId"));
        }

        const payment = await this.rubyService.budgets.payPrepayOrderWithStripe({
            orderId: order.id,
            teamId: props.teamId,
        });

        if (payment.paymentStatus === RubyOrderPaymentStatus.REQUIRES_ACTION && payment.paymentClientSecret) {
            await this.stripeService.handlePaymentAction(payment.paymentClientSecret);
        }

        this.lastPaidOrder = { ...this.order };
        this.order = null;
    }

    getLastPaidOrder(): RubyOrder {
        return this.lastPaidOrder;
    }
}
