import React from "react";
import { Link } from "react-router-dom";
import { languageString } from "../../../utilities/text";
import { getUrl } from "../../../utilities/url";
import Button from "../../blocks/buttons/standard/Button";
import "./cookiePopover.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CookiePopoverProps {}

export interface CookiePopoverComponentProps extends CookiePopoverProps {
    hasViewedCookies: boolean;
    setCookieOptOut: (optOut: boolean) => void;
}

interface CookiePopoverState {
    isOpen: boolean;
}

export default class CookiePopoverComponent extends React.PureComponent<
    CookiePopoverComponentProps,
    CookiePopoverState
> {
    constructor(props: CookiePopoverComponentProps) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                isOpen: !this.props.hasViewedCookies,
            });
        }, 1000);
    }

    decline() {
        this.props.setCookieOptOut(true);
    }

    accept() {
        this.props.setCookieOptOut(false);
    }

    render() {
        return (
            <div className={`cookiePopover ${!this.props.hasViewedCookies && this.state.isOpen ? "is-open" : ""}`}>
                <h2>{languageString("ui.cookie.title", "Would you like a cookie?")}</h2>
                <p>
                    {languageString("ui.cookie.message.prefix", "We set cookies...")}{" "}
                    <Link to={getUrl.privacy()}>
                        {languageString("ui.cookie.message.privacyLink", "privacy policy")}
                    </Link>{" "}
                    {languageString("ui.cookie.message.suffix", "... on your device.")}
                </p>
                <div className="cookiePopover-btns">
                    <Button type="button" onClick={this.decline.bind(this)} buttonType="clear" id="test-cookie-decline">
                        {languageString("ui.cookie.decline", "Only Required Cookies")}
                    </Button>
                    <Button type="button" buttonType="cta" onClick={this.accept.bind(this)} id="test-cookie-accept">
                        {languageString("ui.cookie.accept", "All Cookies")}
                    </Button>
                </div>
            </div>
        );
    }
}
