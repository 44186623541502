import React, { type CSSProperties } from "react";
import type { ButtonLinkProps } from "../link/ButtonLink";
import { type ButtonProps, type ButtonType } from "../standard/Button";
import "./buttonMenu.css";

type ButtonsProps = ButtonProps | ButtonLinkProps;
type ButtonsEl = React.ReactElement<ButtonsProps>;

interface ButtonMenuProps {
    children: ButtonsEl | ButtonsEl[];
    className?: string;
    withGap?: boolean;
    textAlign?: CSSProperties["textAlign"];
    buttonType?: ButtonType;
}

export default class ButtonMenu extends React.PureComponent<ButtonMenuProps> {
    static defaultProps = {
        textAlign: "left",
    };

    render(): React.ReactNode {
        // eslint-disable-next-line prefer-const
        let { children, className, withGap, textAlign, buttonType, ...props } = this.props;
        if (!Array.isArray(children)) {
            children = [children];
        }
        return (
            <ul
                className={`buttonMenu ${withGap ? "buttonMenu_withGap" : ""} ${className || ""}`}
                style={{
                    "--button-textAlign": textAlign,
                }}
                {...props}
            >
                {children.map((btn, i) => {
                    return (
                        <li key={i}>
                            {React.cloneElement(btn, {
                                isBlock: true,
                                buttonType: buttonType ?? btn.props.buttonType,
                            } satisfies ButtonsProps)}
                        </li>
                    );
                })}
            </ul>
        );
    }
}
