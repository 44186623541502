import React from "react";
import { v4 as uuid } from "uuid";
import { type InputProps } from "../input/Input";
import "./radio.css";

interface RadioProps extends InputProps {
    type: "checkbox" | "radio";
}

export default class Radio extends React.PureComponent<RadioProps> {
    static defaultProps = {
        type: "radio",
    };

    id: string;

    constructor(props: RadioProps) {
        super(props);
        this.id = uuid();
    }

    render() {
        const { isInvalid, className, id, ...props } = this.props;
        return (
            <label
                htmlFor={id || this.id}
                className={`radio ${isInvalid ? `is-invalid` : ``} ${props.disabled ? `is-disabled` : ``} ${
                    props.checked ? `is-checked` : ``
                } ${className ? className : ``}`}
            >
                <input className="radio-input" aria-invalid={isInvalid} id={id || this.id} {...props} />
                <span className="radio-el"></span>
            </label>
        );
    }
}
