import { rubyService } from "@/services";
import { RuleType } from "@redbox-ruby/data-lib";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    campaignRulesActions,
    createRuleAction,
    deleteRuleAction,
    keywordRulesActions,
    regionRulesActions,
    updateRuleAction,
    updateRulesPrioritiesAction,
    type RulesActions,
} from "../actions/rulesActions";
import { type AsyncData } from "../reducers/domain";
import { selectCampaignRules, selectKeywordRules, selectRegionRules, selectRule } from "../selectors/rulesSelectors";
import {
    RubyRuleScope,
    type RubyCampaignId,
    type RubyRule,
    type RubyRuleCreateBody,
    type RubyTeamId,
    type RubyUpdateRulePriorityBody,
} from "../services/backend/RubyData";
import {
    runDataRequestAction,
    runRequestAction,
    runRequestSaga,
    selectFromState,
    takeRequests,
} from "../utilities/saga";

function* loadCampaignRulesSaga(action: ExtractRequestActionRequestType<RulesActions.ListCampaignRulesAction>) {
    yield* runDataRequestAction(
        action,
        campaignRulesActions.list,
        (state, req) => selectCampaignRules(state, req.campaignId),
        ({ teamId, campaignId }) =>
            rubyService.rules
                .listCampaignRules({ teamId, campaignId }, undefined, { all: true })
                .then((data) => data.results)
    );
}

function* loadRegionRulesSaga(action: ExtractRequestActionRequestType<RulesActions.ListRegionRulesAction>) {
    yield* runDataRequestAction(
        action,
        regionRulesActions.list,
        (state, req) => selectRegionRules(state, req.campaignId),
        ({ teamId, campaignId }) =>
            rubyService.rules
                .listRegionRules({ teamId, campaignId }, undefined, { all: true })
                .then((data) => data.results)
    );
}

function* loadKeywordRulesSaga(action: ExtractRequestActionRequestType<RulesActions.ListKeywordRulesAction>) {
    yield* runDataRequestAction(
        action,
        keywordRulesActions.list,
        (state, req) => selectKeywordRules(state, req.campaignId),
        ({ teamId, campaignId }) =>
            rubyService.rules
                .listKeywordRules({ teamId, campaignId }, undefined, { all: true })
                .then((data) => data.results)
    );
}

function* createRulesSaga(action: ExtractRequestActionRequestType<RulesActions.CreateRuleAction>) {
    yield* runRequestAction(action, createRuleAction, async (req) => {
        let rule: RubyRule;
        switch (req.type) {
            case RuleType.CAMPAIGN:
                rule = (await rubyService.rules.createCampaignRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
            case RuleType.REGION:
                rule = (await rubyService.rules.createRegionRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
            case RuleType.KEYWORD:
                rule = (await rubyService.rules.createKeywordRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
        }

        rule = await setRuleMappings(req, rule.id, req.teamId, req.campaignId);

        return rule;
    });
}

type SetterParams = Parameters<
    | typeof rubyService.rules.setCampaignRuleMapping
    | typeof rubyService.rules.setRegionRuleMapping
    | typeof rubyService.rules.setKeywordRuleMapping
>;

async function setRuleMappings(
    body: RubyRuleCreateBody,
    ruleId: RubyRule["id"],
    teamId: RubyTeamId,
    campaignId: RubyCampaignId
): Promise<RubyRule> {
    let setter: (...params: SetterParams) => Promise<RubyRule>;
    let rule: RubyRule;
    switch (body.type) {
        case RuleType.CAMPAIGN:
            setter = (body, params) => rubyService.rules.setCampaignRuleMapping(body, params) as Promise<RubyRule>;
            break;
        case RuleType.REGION:
            setter = (body, params) => rubyService.rules.setRegionRuleMapping(body, params) as Promise<RubyRule>;
            break;
        case RuleType.KEYWORD:
            setter = (body, params) => rubyService.rules.setKeywordRuleMapping(body, params) as Promise<RubyRule>;
            break;
    }

    // Assign region rule to it's regions
    if (body.regionIds && body.type === RuleType.REGION) {
        rule = await setter(
            { teamId, campaignId, ruleId },
            {
                exclude: false,
                regionIds: body.regionIds,
            }
        );
    }

    // Assign keyword rule to it's keywords
    if (body.keywordIds && body.type === RuleType.KEYWORD) {
        rule = await setter(
            { teamId, campaignId, ruleId },
            {
                exclude: false,
                keywordIds: body.keywordIds,
            }
        );
    }

    // Apply region exclusions to campaign rules
    if (body.excludedRegionIds && body.type === RuleType.CAMPAIGN) {
        rule = await setter(
            { teamId, campaignId, ruleId },
            {
                exclude: true,
                regionIds: body.excludedRegionIds,
            }
        );
    }

    // Assign keyword exclusions to campaign and region
    if (body.excludedKeywordIds && body.type !== RuleType.KEYWORD) {
        rule = await setter(
            { teamId, campaignId, ruleId },
            {
                exclude: true,
                keywordIds: body.excludedKeywordIds,
            }
        );
    }

    return rule;
}

function* updateRulesSaga(action: ExtractRequestActionRequestType<RulesActions.UpdateRuleAction>) {
    yield* runRequestAction(action, updateRuleAction, async (req) => {
        let rule: RubyRule;
        switch (req.type) {
            case RuleType.CAMPAIGN:
                rule = (await rubyService.rules.updateCampaignRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                        ruleId: req.id,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
            case RuleType.REGION:
                rule = (await rubyService.rules.updateRegionRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                        ruleId: req.id,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
            case RuleType.KEYWORD:
                rule = (await rubyService.rules.updateKeywordRule(
                    {
                        teamId: req.teamId,
                        campaignId: req.campaignId,
                        ruleId: req.id,
                    },
                    {
                        lookBackDays: req.lookBackDays,
                        priority: req.priority,
                        ruleAction: req.ruleAction,
                        ruleCondition: req.ruleCondition,
                        ruleProperty: req.ruleProperty,
                        ruleValue: req.ruleValue,
                        ruleScope: RubyRuleScope.KEYWORD,
                    }
                )) as RubyRule;
                break;
        }

        rule = await setRuleMappings(req, req.id, req.teamId, req.campaignId);

        return rule;
    });
}

function* deleteRuleSaga(action: ExtractRequestActionRequestType<RulesActions.DeleteRuleAction>) {
    yield* runRequestSaga(action, deleteRuleAction, function* (req) {
        const rule = yield* selectFromState((state) => selectRule(state, req.campaignId, req.ruleId));
        const rules = yield* selectFromState<AsyncData<RubyRule[]>>((state) => {
            switch (rule.type) {
                case RuleType.CAMPAIGN:
                    return selectCampaignRules(state, req.campaignId);
                case RuleType.REGION:
                    return selectRegionRules(state, req.campaignId);
                case RuleType.KEYWORD:
                    return selectKeywordRules(state, req.campaignId);
            }
        });
        const prioritisedRules = rules.data
            .filter((r) => r.id !== req.ruleId)
            .sort((a, b) => a.priority - b.priority)
            .map((r, i) => {
                return {
                    ...r,
                    priority: i,
                };
            });
        switch (rule.type) {
            case RuleType.CAMPAIGN:
                yield rubyService.rules.deleteCampaignRule(req);
                break;
            case RuleType.REGION:
                yield rubyService.rules.deleteRegionRule(req);
                break;
            case RuleType.KEYWORD:
                yield rubyService.rules.deleteKeywordRule(req);
                break;
        }
        yield* updateRulesPrioritiesSaga(
            updateRulesPrioritiesAction.request({
                teamId: req.teamId,
                campaignId: req.campaignId,
                rules: prioritisedRules,
            })
        );
    });
}

function* updateRulesPrioritiesSaga(action: ExtractRequestActionRequestType<RulesActions.UpdateRulesPrioritiesAction>) {
    yield* runRequestAction(action, updateRulesPrioritiesAction, async (req) => {
        const type = req.rules?.[0]?.type;
        const priorities: RubyUpdateRulePriorityBody = req.rules.map((rule) => ({
            ruleId: rule.id,
            priority: rule.priority,
        }));

        const ids = {
            teamId: req.teamId,
            campaignId: req.campaignId,
        };

        switch (type) {
            case RuleType.CAMPAIGN: {
                const newRules = await rubyService.rules.updateCampaignRulePriorities(ids, priorities);
                return newRules.results as RubyRule[];
            }
            case RuleType.REGION: {
                const newRules = await rubyService.rules.updateRegionRulePriorities(ids, priorities);
                return newRules.results as RubyRule[];
            }
            case RuleType.KEYWORD: {
                const newRules = await rubyService.rules.updateKeywordRulePriorities(ids, priorities);
                return newRules.results as RubyRule[];
            }
        }
    });
}

export default function* rulesSaga() {
    yield* takeRequests(ActionName.LIST_CAMPAIGN_RULES, loadCampaignRulesSaga);
    yield* takeRequests(ActionName.LIST_REGION_RULES, loadRegionRulesSaga);
    yield* takeRequests(ActionName.LIST_KEYWORD_RULES, loadKeywordRulesSaga);
    yield* takeRequests(ActionName.CREATE_RULE, createRulesSaga);
    yield* takeRequests(ActionName.UPDATE_RULE, updateRulesSaga);
    yield* takeRequests(ActionName.DELETE_RULE, deleteRuleSaga);
    yield* takeRequests(ActionName.UPDATE_RULES_PRIORITIES, updateRulesPrioritiesSaga);
}
