import { type AsyncData, type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyUserRole,
    type RubyTeamInvite,
    type RubyUserHistoryRecordQuery,
    type RubyUserRecord,
} from "../services/backend/RubyData";
import { matchHistoryRecord } from "./matchers";

export function selectUserIsAdmin(state: State): boolean {
    return RubyUserRole.ADMIN === state.user.role;
}

export function selectUserIsDeveloper(state: State): boolean {
    return RubyUserRole.DEVELOPER === state.user.role || RubyUserRole.CSE === state.user.role;
}

export function selectUserIsManager(state: State): boolean {
    return RubyUserRole.MANAGER === state.user.role;
}

export function selectUsersTeamInvite(state: State, inviteId: RubyTeamInvite["id"]): AsyncData<RubyTeamInvite> {
    const request = state.account.userInvites;
    if (!request.success) {
        return {
            ...request,
            data: null,
        };
    }
    const invite = request.data?.find((invite) => invite.id === inviteId);
    return {
        ...request,
        data: invite,
    };
}

export function selectUserHistoryRecords(state: State, query: RubyUserHistoryRecordQuery): AsyncData<RubyUserRecord[]> {
    const requests = state.account.historyRecords;
    const request = requests?.find(matchHistoryRecord(query));
    return (
        request ?? {
            ...initialAsyncDataState(),
            ...query,
        }
    );
}
