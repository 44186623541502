import { connect } from "react-redux";
import type { Dispatch } from "redux";
import { type State } from "../../../../reducers/domain";
import AuthenticateComponent, {
    type AuthenticateComponentProps,
    type AuthenticateProps,
} from "./AuthenticateComponent";

const mapStateToProps = (state: State, props: AuthenticateProps): Partial<AuthenticateComponentProps> => ({
    ...props,
    loginError: state.user.loginErrorMessage,
    isLoggedIn: state.user.isLoggedIn,
    nextPageRedirect: state.user.loginRedirect,
});

const mapDispatchToProps = (_dispatch: Dispatch): Partial<AuthenticateComponentProps> => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateComponent);
