import type { RubyTeamId } from "@/services/backend/RubyData";
import { useStateSelector } from "@/store/stateHooks";
import { createSelector } from "@reduxjs/toolkit";
import { type State } from "../reducers/domain";
import { selectUserIsOwner, selectUserIsReader, selectUserIsWriter } from "../selectors/teamSelectors";
import { selectUserIsAdmin, selectUserIsDeveloper, selectUserIsManager } from "../selectors/userSelectors";

export interface UserPermission {
    isAdmin: boolean;
    isDeveloper: boolean;
    isManager: boolean;
    isOwner: boolean;
    canWrite: boolean;
    canView: boolean;
}

export function useUserPermission(teamId?: RubyTeamId): UserPermission {
    return useStateSelector((state) => selectUserPermission(state, teamId));
}

export function useUserPermissionsMap(teamIds: RubyTeamId[]): Record<RubyTeamId, UserPermission> {
    const state = useStateSelector((state) => state);
    return teamIds.reduce((map, teamId) => {
        map[teamId] = selectUserPermission(state, teamId);
        return map;
    }, {} as Record<RubyTeamId, UserPermission>);
}

export const selectUserPermission = createSelector(
    [
        selectUserIsAdmin,
        selectUserIsDeveloper,
        selectUserIsManager,
        (state: State, teamId?: RubyTeamId) => selectUserIsOwner(state, teamId ?? state.ui.activeTeam),
        (state: State, teamId?: RubyTeamId) => selectUserIsWriter(state, teamId ?? state.ui.activeTeam),
        (state: State, teamId?: RubyTeamId) => selectUserIsReader(state, teamId ?? state.ui.activeTeam),
    ],
    (isAdmin, isDeveloper, isManager, isOwner, canWrite, canView): UserPermission => {
        isDeveloper = isDeveloper || isAdmin;
        isManager = isManager || isDeveloper;
        isOwner = isOwner || isManager;
        canWrite = canWrite || isOwner;
        canView = canView || canWrite;

        return {
            isAdmin,
            isDeveloper,
            isManager,
            isOwner,
            canWrite,
            canView,
        };
    }
);
