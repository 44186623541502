import { type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    type RubyCampaignId,
    type RubyCampaignRule,
    type RubyKeywordRule,
    type RubyRegionRule,
    type RubyRule,
} from "../services/backend/RubyData";

export function selectCampaignRules(state: State, campaignId: RubyCampaignId) {
    return state.rules.campaignRules[campaignId] ?? initialAsyncDataState<RubyCampaignRule[]>([]);
}
export function selectRegionRules(state: State, campaignId: RubyCampaignId) {
    return state.rules.regionRules[campaignId] ?? initialAsyncDataState<RubyRegionRule[]>([]);
}
export function selectKeywordRules(state: State, campaignId: RubyCampaignId) {
    return state.rules.keywordRules[campaignId] ?? initialAsyncDataState<RubyKeywordRule[]>([]);
}

export function selectRule(state: State, campaignId: RubyCampaignId, ruleId: RubyRule["id"]): RubyRule {
    let rule: RubyRule = state.rules.campaignRules[campaignId].data?.find((rule) => rule.id === ruleId);

    if (!rule) {
        rule = state.rules.regionRules[campaignId].data?.find((rule) => rule.id === ruleId);
    }
    if (!rule) {
        rule = state.rules.keywordRules[campaignId].data?.find((rule) => rule.id === ruleId);
    }

    return rule;
}
