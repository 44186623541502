import { formatResponseError } from "@/utilities/response";
import { languageString } from "../../utilities/text";
import { seconds } from "../../utilities/time";

const CONCURRENCY = 20;
const TIMEOUT = seconds(100);

interface Job {
    input: RequestInfo;
    init?: RequestInit;
    resolve: (val: Response) => void;
    reject: (reason: unknown) => void;
}

interface WorkQueue {
    queue: Job[];
    runningJobs: number;
}

export class NetworkFetchClient {
    constructor() {
        this.tick();
    }

    private workQueues: Record<string, WorkQueue> = {};

    async call(input: RequestInfo, init?: RequestInit): Promise<Response> {
        const job = new Promise<Response>((resolve, reject) => {
            this.addJob({
                input,
                init,
                resolve,
                reject,
            });
        });

        return job;
    }

    private addJob(job: Job) {
        const queueId = this.getQueueId(job.input);
        if (!this.workQueues[queueId]) {
            this.workQueues[queueId] = {
                queue: [],
                runningJobs: 0,
            };
        }
        this.workQueues[queueId].queue.push(job);
    }

    private tick() {
        Object.values(this.workQueues).forEach((workQueue) => {
            while (workQueue.runningJobs < CONCURRENCY && workQueue.queue.length > 0) {
                workQueue.runningJobs++;
                this.run(workQueue, workQueue.queue.shift());
            }
        });
        requestIdleCallback(this.tick.bind(this), { timeout: 500 });
    }

    private getQueueId(input: RequestInfo) {
        const url = typeof input === "string" ? input : input?.url;
        let genericUrl = url.replaceAll(/\d+/gi, "{ID}");
        if (genericUrl.includes("?")) {
            genericUrl = genericUrl.substring(0, genericUrl.indexOf("?"));
        }
        const method = typeof input === "string" ? "GET" : input?.method?.toUpperCase() ?? "GET";
        return `${method} ${genericUrl}`;
    }

    private run(workQueue: WorkQueue, job: Job) {
        const controller = new AbortController();
        const id = setTimeout(() => {
            controller.abort(languageString("ui.error.request.timeout", "", [TIMEOUT]));
            console.error(
                "Aborted long running network request",
                JSON.stringify({
                    input: job.input,
                    init: job.init,
                    TIMEOUT,
                })
            );
        }, TIMEOUT);

        fetch(job.input, {
            ...job.init,
            signal: controller.signal,
        })
            .then((res) => {
                if (!res.ok) {
                    return this.handleError(res, job);
                }
                return job.resolve(res);
            })
            .catch((err) => this.handleError(err, job))
            .finally(() => {
                workQueue.runningJobs--;
                clearTimeout(id);
            });
    }

    private async handleError(err: unknown, job: Job) {
        const error = {
            ok: false,
            status: null,
            statusText: null,
            url: null,
            message: languageString("ui.error.request.unknown"),
            request: job.input,
            context: job.init,
        };
        if (error.context && "authorization" in error.context.headers) {
            error.context.headers.authorization = "REDACTED";
        }
        if (err instanceof Error) {
            error.message = err.message;
        }
        if (err instanceof Response) {
            error.message = await formatResponseError(err);
            error.url = typeof job.input === "string" ? job.input : job.input?.url;
            error.status = err.status;
            error.statusText = err.statusText;
        }
        return job.reject(error);
    }
}

const networkFetchClient = new NetworkFetchClient();

export default function networkFetch(input: RequestInfo, init?: RequestInit) {
    return networkFetchClient.call(input, init);
}
