import type { CampaignObjective } from "@/types/CampaignObjective";
import type { TeamCampaignIdParams } from "@redbox-ruby/data-lib";
import {
    type CampaignSortOrder,
    type CampaignView,
    type CampaignVisibilityFilter,
    type TimeRange,
} from "../reducers/domain";
import {
    type RubyAppleCampaignsWithOrg,
    type RubyBudgetAllocation,
    type RubyBudgetPlan,
    type RubyCampaign,
    type RubyCampaignAppleStatus,
    type RubyCampaignHistoryRecordQuery,
    type RubyCampaignId,
    type RubyCampaignListingData,
    type RubyCampaignRecord,
    type RubyCampaignSynchronisation,
    type RubyChannel,
    type RubyChannelType,
    type RubyCountry,
    type RubyEventsCount,
    type RubyIOSApp,
    type RubyImportStandaloneCampaignBody,
    type RubyImportStandaloneCampaignResponse,
    type RubyPaymentCard,
    type RubyProductPage,
    type RubyRegion,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum CampaignActionName {
    LIST_CAMPAIGNS = "LIST_CAMPAIGNS",
    GET_CAMPAIGN = "GET_CAMPAIGN",
    GET_CAMPAIGNS_LIST_DATA = "GET_CAMPAIGNS_LIST_DATA",
    SEARCH_CAMPAIGNS = "SEARCH_CAMPAIGNS",
    GET_CHANNEL = "GET_CHANNEL",
    LIST_CHANNELS = "LIST_CHANNELS",
    GET_IOS_APP = "GET_IOS_APP",
    LIST_IOS_APPS = "LIST_IOS_APPS",
    LIST_REGIONS = "LIST_REGIONS",
    LIST_BUDGET_PLANS = "LIST_BUDGET_PLANS",
    GET_ACTIVE_BUDGET_PLAN = "GET_ACTIVE_BUDGET_PLAN",
    LIST_ALL_ALLOCATIONS = "LIST_ALL_ALLOCATIONS",
    SET_SEARCH_DATES = "SET_SEARCH_DATES",
    SET_SEARCH_ORDER = "SET_SEARCH_ORDER",
    SET_SEARCH_VISIBILITY = "SET_SEARCH_VISIBILITY",
    SET_CAMPAIGN_VIEW = "SET_CAMPAIGN_VIEW",
    LIST_EVENTS = "LIST_EVENTS",
    LIST_TEAM_EVENTS = "LIST_TEAM_EVENTS",
    GET_CAMPAIGN_FUNDS = "GET_CAMPAIGN_FUNDS",
    DELETE_CAMPAIGN = "DELETE_CAMPAIGN",
    GET_CAMPAIGN_APPLE_STATUS = "GET_CAMPAIGN_APPLE_STATUS",
    GET_CAMPAIGN_SYNCHRONISATION = "GET_CAMPAIGN_SYNCHRONISATION",
    GET_IOS_APP_PRODUCT_PAGES = "GET_IOS_APP_PRODUCT_PAGES",
    SYNCHRONISE_CAMPAIGN = "SYNCHRONISE_CAMPAIGN",
    ADD_CAMPAIGN_FUNDS = "ADD_CAMPAIGN_FUNDS",
    LIST_CAMPAIGN_HISTORY_RECORDS = "LIST_CAMPAIGN_HISTORY_RECORDS",
    EDIT_CAMPAIGN = "EDIT_CAMPAIGN",
    SET_APP_FILTER = "SET_APP_FILTER",
    SET_SELECTED_EXPERIMENT = "SET_SELECTED_EXPERIMENT",
    DELETE_EXPERIMENT = "DELETE_EXPERIMENT",
    GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT = "GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT",
    IMPORT_STANDALONE_CAMPAIGN = "IMPORT_STANDALONE_CAMPAIGN",
    UPDATE_IMPORTED_CAMPAIGN = "UPDATE_IMPORTED_CAMPAIGN",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace CampaignActions {
    export type ListCampaignsAction = RequestAction<CampaignActionName.LIST_CAMPAIGNS, RubyTeamId, RubyCampaign[]>;
    export type GetCampaignAction = RequestAction<
        CampaignActionName.GET_CAMPAIGN,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyCampaign
    >;
    export type GetCampaignsListDataAction = RequestAction<
        CampaignActionName.GET_CAMPAIGNS_LIST_DATA,
        RubyTeamId,
        RubyCampaignListingData
    >;
    export type SearchCampaignsAction = Action<CampaignActionName.SEARCH_CAMPAIGNS, string>;
    export type GetChannelAction = RequestAction<
        CampaignActionName.GET_CHANNEL,
        {
            channelType: RubyChannelType;
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyChannel
    >;
    export type ListChannelsAction = Action<CampaignActionName.LIST_CHANNELS, RubyTeamId>;
    export type GetIOSAppAction = RequestAction<
        CampaignActionName.GET_IOS_APP,
        {
            appId: RubyChannel["applicationRef"];
            teamId: RubyTeamId;
            countryCode?: RubyCountry;
        },
        RubyIOSApp
    >;
    export type ListIOSAppsAction = Action<CampaignActionName.LIST_IOS_APPS, RubyTeamId>;

    export type GetIOSAppProductPagesAction = RequestAction<
        CampaignActionName.GET_IOS_APP_PRODUCT_PAGES,
        {
            appId: RubyChannel["applicationRef"];
            teamId: RubyTeamId;
            campaignId?: RubyCampaignId;
        },
        RubyProductPage[]
    >;
    export type ListRegionsAction = RequestAction<
        CampaignActionName.LIST_REGIONS,
        {
            channelType: RubyChannelType;
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyRegion[]
    >;
    export type ListBudgetPlansAction = RequestAction<
        CampaignActionName.LIST_BUDGET_PLANS,
        RubyCampaignId,
        RubyBudgetPlan[]
    >;
    export type ListAllAllocationsAction = RequestAction<
        CampaignActionName.LIST_ALL_ALLOCATIONS,
        {
            campaignId: RubyCampaignId;
        },
        RubyBudgetAllocation[]
    >;
    export type GetActiveBudgetPlanAction = RequestAction<
        CampaignActionName.GET_ACTIVE_BUDGET_PLAN,
        RubyCampaignId,
        RubyBudgetPlan
    >;

    export type SetSearchDatesAction = Action<CampaignActionName.SET_SEARCH_DATES, TimeRange>;
    export type SetSearchOrderAction = Action<CampaignActionName.SET_SEARCH_ORDER, CampaignSortOrder>;
    export type SetSearchVisibilityAction = Action<
        CampaignActionName.SET_SEARCH_VISIBILITY,
        {
            filter: CampaignVisibilityFilter;
            state: boolean;
        }
    >;
    export type SetCampaignViewAction = Action<CampaignActionName.SET_CAMPAIGN_VIEW, CampaignView>;

    export type ListEventsAction = RequestAction<
        CampaignActionName.LIST_EVENTS,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyEventsCount
    >;
    export type ListTeamEventsAction = RequestAction<CampaignActionName.LIST_TEAM_EVENTS, RubyTeamId, RubyEventsCount>;
    export type GetCampaignFundsAction = RequestAction<
        CampaignActionName.GET_CAMPAIGN_FUNDS,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        number
    >;

    export type DeleteCampaignAction = RequestAction<
        CampaignActionName.DELETE_CAMPAIGN,
        { campaignId: RubyCampaignId; teamId: RubyTeamId },
        void
    >;
    export type GetCampaignAppleStatusAction = RequestAction<
        CampaignActionName.GET_CAMPAIGN_APPLE_STATUS,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyCampaignAppleStatus
    >;

    export type GetCampaignSynchronisationAction = RequestAction<
        CampaignActionName.GET_CAMPAIGN_SYNCHRONISATION,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
            channelType: RubyChannelType;
        },
        RubyCampaignSynchronisation
    >;
    export type SynchroniseCampaignAction = RequestAction<
        CampaignActionName.SYNCHRONISE_CAMPAIGN,
        RubyCampaignId,
        void
    >;
    export type AddCampaignFundsAction = RequestAction<
        CampaignActionName.ADD_CAMPAIGN_FUNDS,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
            totalAmount: number;
            paymentId?: RubyPaymentCard["id"];
        },
        void
    >;

    export type ListCampaignHistoryRecordsAction = RequestAction<
        CampaignActionName.LIST_CAMPAIGN_HISTORY_RECORDS,
        RubyCampaignHistoryRecordQuery,
        RubyCampaignRecord[]
    >;

    export type EditCampaignAction = RequestAction<
        CampaignActionName.EDIT_CAMPAIGN,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
            name: string;
            description: string;
            objective: CampaignObjective;
        },
        void
    >;
    export type SetAppFilterAction = Action<CampaignActionName.SET_APP_FILTER, RubyIOSApp["trackId"][]>;
    export type SetSelectedExperimentAction = Action<CampaignActionName.SET_SELECTED_EXPERIMENT, RubyCampaignId>;
    export type DeleteExperimentAction = RequestAction<
        CampaignActionName.DELETE_EXPERIMENT,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        }
    >;
    export type GetCampaignsEligibleForImport = RequestAction<
        CampaignActionName.GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT,
        RubyTeamId,
        RubyAppleCampaignsWithOrg[]
    >;
    export type ImportStandaloneCampaign = RequestAction<
        CampaignActionName.IMPORT_STANDALONE_CAMPAIGN,
        Omit<RubyImportStandaloneCampaignBody, "objective"> & { teamId: RubyTeamId },
        RubyImportStandaloneCampaignResponse
    >;
    export type UpdateImportedCampaign = RequestAction<
        CampaignActionName.UPDATE_IMPORTED_CAMPAIGN,
        TeamCampaignIdParams
    >;
}

export type CampaignActionType =
    | CampaignActions.ListCampaignsAction
    | CampaignActions.GetCampaignAction
    | CampaignActions.GetCampaignsListDataAction
    | CampaignActions.SearchCampaignsAction
    | CampaignActions.GetChannelAction
    | CampaignActions.ListChannelsAction
    | CampaignActions.GetIOSAppAction
    | CampaignActions.ListIOSAppsAction
    | CampaignActions.ListRegionsAction
    | CampaignActions.ListBudgetPlansAction
    | CampaignActions.GetActiveBudgetPlanAction
    | CampaignActions.ListAllAllocationsAction
    | CampaignActions.SetSearchDatesAction
    | CampaignActions.SetSearchOrderAction
    | CampaignActions.SetSearchVisibilityAction
    | CampaignActions.SetCampaignViewAction
    | CampaignActions.ListEventsAction
    | CampaignActions.ListTeamEventsAction
    | CampaignActions.GetCampaignFundsAction
    | CampaignActions.DeleteCampaignAction
    | CampaignActions.GetCampaignAppleStatusAction
    | CampaignActions.GetCampaignSynchronisationAction
    | CampaignActions.GetIOSAppProductPagesAction
    | CampaignActions.SynchroniseCampaignAction
    | CampaignActions.AddCampaignFundsAction
    | CampaignActions.ListCampaignHistoryRecordsAction
    | CampaignActions.EditCampaignAction
    | CampaignActions.SetAppFilterAction
    | CampaignActions.SetSelectedExperimentAction
    | CampaignActions.DeleteExperimentAction
    | CampaignActions.GetCampaignsEligibleForImport
    | CampaignActions.ImportStandaloneCampaign
    | CampaignActions.UpdateImportedCampaign;

export function searchCampaignsAction(search: string): CampaignActions.SearchCampaignsAction {
    return {
        type: CampaignActionName.SEARCH_CAMPAIGNS,
        payload: search,
    };
}

export const getChannelAction = requestActions<CampaignActions.GetChannelAction>(CampaignActionName.GET_CHANNEL);

export function listChannelsAction(teamId: RubyTeamId): CampaignActions.ListChannelsAction {
    return {
        type: CampaignActionName.LIST_CHANNELS,
        payload: teamId,
    };
}

export const getIOSAppAction = requestActions<CampaignActions.GetIOSAppAction>(CampaignActionName.GET_IOS_APP);

export function listIOSAppsAction(teamId: RubyTeamId): CampaignActions.ListIOSAppsAction {
    return {
        type: CampaignActionName.LIST_IOS_APPS,
        payload: teamId,
    };
}

export const getIOSAppProductPagesAction = requestActions<CampaignActions.GetIOSAppProductPagesAction>(
    CampaignActionName.GET_IOS_APP_PRODUCT_PAGES
);

export const listRegionsAction = requestActions<CampaignActions.ListRegionsAction>(CampaignActionName.LIST_REGIONS);

export const listBudgetPlansAction = requestActions<CampaignActions.ListBudgetPlansAction>(
    CampaignActionName.LIST_BUDGET_PLANS
);

export const getActiveBudgetPlanAction = requestActions<CampaignActions.GetActiveBudgetPlanAction>(
    CampaignActionName.GET_ACTIVE_BUDGET_PLAN
);

export function setSearchDatesAction(dates: TimeRange): CampaignActions.SetSearchDatesAction {
    return {
        type: CampaignActionName.SET_SEARCH_DATES,
        payload: dates,
    };
}

export function setSearchOrderAction(order: CampaignSortOrder): CampaignActions.SetSearchOrderAction {
    return {
        type: CampaignActionName.SET_SEARCH_ORDER,
        payload: order,
    };
}

export function setSearchVisibilityAction(
    filter: CampaignVisibilityFilter,
    state: boolean
): CampaignActions.SetSearchVisibilityAction {
    return {
        type: CampaignActionName.SET_SEARCH_VISIBILITY,
        payload: {
            filter,
            state,
        },
    };
}

export function setCampaignViewAction(view: CampaignView): CampaignActions.SetCampaignViewAction {
    return {
        type: CampaignActionName.SET_CAMPAIGN_VIEW,
        payload: view,
    };
}

export const campaignActions = {
    get: requestActions<CampaignActions.GetCampaignAction>(CampaignActionName.GET_CAMPAIGN),
    list: requestActions<CampaignActions.ListCampaignsAction>(CampaignActionName.LIST_CAMPAIGNS),
    edit: requestActions<CampaignActions.EditCampaignAction>(CampaignActionName.EDIT_CAMPAIGN),
    delete: requestActions<CampaignActions.DeleteCampaignAction>(CampaignActionName.DELETE_CAMPAIGN),
    listData: requestActions<CampaignActions.GetCampaignsListDataAction>(CampaignActionName.GET_CAMPAIGNS_LIST_DATA),
};

export const listCampaignHistoryRecords = requestActions<CampaignActions.ListCampaignHistoryRecordsAction>(
    CampaignActionName.LIST_CAMPAIGN_HISTORY_RECORDS
);

export const listEventsAction = requestActions<CampaignActions.ListEventsAction>(CampaignActionName.LIST_EVENTS);

export const listTeamEventsAction = requestActions<CampaignActions.ListTeamEventsAction>(
    CampaignActionName.LIST_TEAM_EVENTS
);

export const campaignFundsActions = {
    get: requestActions<CampaignActions.GetCampaignFundsAction>(CampaignActionName.GET_CAMPAIGN_FUNDS),
    add: requestActions<CampaignActions.AddCampaignFundsAction>(CampaignActionName.ADD_CAMPAIGN_FUNDS),
};

export const getCampaignAppleStatusAction = requestActions<CampaignActions.GetCampaignAppleStatusAction>(
    CampaignActionName.GET_CAMPAIGN_APPLE_STATUS
);

export const campaignSynchronisationActions = {
    forceSync: requestActions<CampaignActions.SynchroniseCampaignAction>(CampaignActionName.SYNCHRONISE_CAMPAIGN),
    get: requestActions<CampaignActions.GetCampaignSynchronisationAction>(
        CampaignActionName.GET_CAMPAIGN_SYNCHRONISATION
    ),
};
export const getCampaignSynchronisationAction = requestActions<CampaignActions.GetCampaignSynchronisationAction>(
    CampaignActionName.GET_CAMPAIGN_SYNCHRONISATION
);

export function setAppFilterAction(appFilter: RubyIOSApp["trackId"][]): CampaignActions.SetAppFilterAction {
    return {
        type: CampaignActionName.SET_APP_FILTER,
        payload: appFilter,
    };
}

export const listAllAllocationsAction = requestActions<CampaignActions.ListAllAllocationsAction>(
    CampaignActionName.LIST_ALL_ALLOCATIONS
);

export function setSelectedExperimentAction(experimentId: RubyCampaignId): CampaignActions.SetSelectedExperimentAction {
    return {
        type: CampaignActionName.SET_SELECTED_EXPERIMENT,
        payload: experimentId,
    };
}

export const deleteExperimentAction = requestActions<CampaignActions.DeleteExperimentAction>(
    CampaignActionName.DELETE_EXPERIMENT
);

export const listCampaignsEligibleForImportAction = requestActions<CampaignActions.GetCampaignsEligibleForImport>(
    CampaignActionName.GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT
);

export const importStandaloneCampaignAction = requestActions<CampaignActions.ImportStandaloneCampaign>(
    CampaignActionName.IMPORT_STANDALONE_CAMPAIGN
);

export const updateImportedCampaignAction = requestActions<CampaignActions.UpdateImportedCampaign>(
    CampaignActionName.UPDATE_IMPORTED_CAMPAIGN
);
