import type { AsyncData, RequestState } from "../reducers/domain";
import { initialAsyncDataState, initialRequestState } from "../reducers/requestReducer";

export function aggregateRequests(...requests: RequestState[]): RequestState {
    if (!requests || requests.length < 1) {
        return initialRequestState();
    }
    requests = requests.map((req) => req ?? initialRequestState());
    return {
        success: !requests.some((r) => !r || !r.success),
        isRequesting:
            requests.some((r) => r && r.isRequesting) && !requests.some((r) => !r || (!r.success && !r.isRequesting)),
        errorMessage: requests.find((r) => r && r.errorMessage)?.errorMessage,
    };
}

type ExtractAsync<T> = T extends AsyncData<infer U> ? U : never;

type UnwrapAsync<T extends [...unknown[]]> = T extends [infer Head, ...infer Tail]
    ? [ExtractAsync<Head>, ...UnwrapAsync<Tail>]
    : [];

export function aggregateAsyncData<T extends AsyncData<D>[], D = unknown>(
    ...requests: [...T]
): AsyncData<UnwrapAsync<T>> {
    if (!requests || requests.length < 1) {
        return initialAsyncDataState();
    }
    requests = requests.map((req) => req ?? initialAsyncDataState()) as T;
    return {
        ...aggregateRequests(...requests),
        lastUpdated: requests.reduce((a, b) => (!b || a < b.lastUpdated ? a : b.lastUpdated), Date.now()),
        data: requests.map((r) => r.data) as UnwrapAsync<T>,
    };
}

export function hasRequested(request: RequestState): boolean {
    return request.isRequesting || !!request.errorMessage || request.success;
}

export function promisifyRequest(request: RequestState): Promise<void> {
    return new Promise((resolve, reject) => {
        const poll = setInterval(() => {
            if (!request.isRequesting) {
                if (request.success) {
                    clearInterval(poll);
                    return resolve();
                }
                if (request.errorMessage) {
                    clearInterval(poll);
                    return reject(request.errorMessage);
                }
                return reject("Request has not been made, unable to promisify");
            }
        }, 250);
    });
}

export async function promisifyAsyncData<T>(request: AsyncData<T>): Promise<T> {
    await promisifyRequest(request);
    return request.data;
}
