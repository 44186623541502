import { Formik } from "formik";
import { Link, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { type LoginRequestPayload } from "../../../../actions/userActions";
import { languageString } from "../../../../utilities/text";
import { getUrl } from "../../../../utilities/url";
import { DEMO_MODE } from "../../../../utilities/vars";
import ButtonLink from "../../../blocks/buttons/link/ButtonLink";
import Button from "../../../blocks/buttons/standard/Button";
import ErrorMessage from "../../../blocks/errors/errorMessage/ErrorMessage";
import ControlledInput from "../../../blocks/inputs/controlledInput/ControlledInput";
import SpinnerOverlay from "../../../blocks/spinnerOverlay/SpinnerOverlay";
import "../login.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginProps {}

export interface LoginComponentProps extends LoginProps {
    onLogin: (username: string, password: string) => void;
    onLoginWithGoogle: () => void;
    loginError?: string;
    isLoggingIn: boolean;
    isLoggingInWithGoogle: boolean;
    isRestoring: boolean;
    isLoggedIn: boolean;
    nextPageRedirect: string;
}

export default function LoginComponent({
    nextPageRedirect,
    isLoggedIn,
    onLogin,
    onLoginWithGoogle,
    isRestoring,
    loginError,
    isLoggingIn,
    isLoggingInWithGoogle,
}: LoginComponentProps) {
    if (isLoggedIn) {
        return <Navigate to={nextPageRedirect} />;
    }
    return (
        <Formik
            initialValues={
                {
                    username: "",
                    password: "",
                } as LoginRequestPayload
            }
            validationSchema={Yup.object().shape({
                username: Yup.string()
                    .email(languageString("login.validation.emailIsEmail", "Your username should be an email address"))
                    .required(languageString("login.validation.emailRequired", "Required")),
                password: Yup.string().required(languageString("login.validation.passwordRequired", "Required")),
            })}
            onSubmit={(values) => {
                onLogin(values.username, values.password);
            }}
        >
            {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    {isRestoring && (
                        <SpinnerOverlay>{languageString("login.restoring", "Checking login…")}</SpinnerOverlay>
                    )}

                    <ControlledInput
                        name="username"
                        label={languageString("login.email", "Email address")}
                        type="email"
                        className="u-pb32"
                        autoComplete="email"
                    />

                    <ControlledInput
                        name="password"
                        label={
                            DEMO_MODE
                                ? languageString("login.demoKey", "Demo key")
                                : languageString("login.password", "Password")
                        }
                        type={DEMO_MODE ? "textarea" : "password"}
                        className="u-pb16"
                        autoComplete={DEMO_MODE ? "false" : "password"}
                    />

                    {!DEMO_MODE && (
                        <>
                            <div>
                                <Link className="u-inlineBlock u-mb16" to={getUrl.passwordReset()}>
                                    {languageString("login.forgottenPasswordLink", "I have forgotten my password")}
                                </Link>
                            </div>
                            <div>
                                <ButtonLink type="button" buttonType="link" to={getUrl.createAccount()}>
                                    {languageString("login.button.newAccount", "Create new account")}
                                </ButtonLink>
                            </div>
                        </>
                    )}

                    {loginError && <ErrorMessage className="u-mt16">{loginError}</ErrorMessage>}

                    <div className="login-btns login-btns_reverse u-mt16">
                        <Button
                            type="submit"
                            isPending={isLoggingIn}
                            disabled={isLoggingIn || isLoggingInWithGoogle}
                            id="test-login-with-password"
                        >
                            {languageString("login.button.login", "Login")}
                        </Button>
                        {!DEMO_MODE && (
                            <Button
                                type="button"
                                onClick={onLoginWithGoogle}
                                isPending={isLoggingInWithGoogle}
                                disabled={isLoggingIn || isLoggingInWithGoogle}
                                id="test-login-with-google"
                            >
                                {languageString("login.button.withGoogle", "Login with Google")}
                            </Button>
                        )}
                    </div>
                </form>
            )}
        </Formik>
    );
}
