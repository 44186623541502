import { BACKEND_URL } from "@/utilities/vars";
import { loginExpiredAction } from "../actions/userActions";
import { AuthServiceImpl } from "../types/AuthService";
import { generateToken } from "../utilities/jwt";
import { minutes } from "../utilities/time";
import { type RubyUserMe } from "./backend/RubyData";

const SESSION_KEY = "RB_Demo_Session_Auth";
const MAX_TOKEN_AGE = minutes(30);

export class DemoAuthService extends AuthServiceImpl {
    private redirect: string;
    private username: string;
    private password: string;
    private age: number;

    async checkSession(): Promise<RubyUserMe> {
        const response = await fetch(`${BACKEND_URL}/api/v2/accounts/me`, {
            method: "POST",
            body: JSON.stringify({
                username: this.username,
                password: this.password,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        });
        const user = (await response.json()) as RubyUserMe;

        user.emailVerified = true;
        user.name = "Demo user";
        user.email = this.username;
        user.authId = "id-000000";
        return user;
    }

    createAccount(): Promise<void> {
        return Promise.resolve();
    }

    async login(username: string, password: string): Promise<void> {
        this.username = username;
        this.password = password;
        localStorage.setItem(SESSION_KEY, JSON.stringify({ username, password }));
        await this.checkSession();
        window.location.assign(window.location.origin + "/authenticate");
        return Promise.resolve();
    }

    loginWithGoogle() {
        window.location.assign(window.location.origin + "/authenticate");
        return Promise.resolve();
    }

    logout() {
        this.username = null;
        this.password = null;
        localStorage.removeItem(SESSION_KEY);
        window.location.assign(window.location.origin);
        return Promise.resolve();
    }

    restoreSession(): Promise<RubyUserMe> {
        const storedCreds = JSON.parse(localStorage.getItem(SESSION_KEY) ?? "{}") as object;
        if ("username" in storedCreds && storedCreds.username && "password" in storedCreds && storedCreds.password) {
            this.username = storedCreds.username as string;
            this.password = storedCreds.password as string;
        }
        return this.checkSession();
    }

    parseHash(): Promise<RubyUserMe> {
        return this.restoreSession();
    }

    patchMetadata(_data: unknown): Promise<void> {
        return Promise.resolve();
    }

    resetPassword(_email: string): Promise<void> {
        return Promise.resolve();
    }

    setRedirect(redirect: string) {
        this.redirect = redirect;
    }

    getRedirect() {
        return this.redirect;
    }

    async getToken(): Promise<string> {
        if (!this.username || !this.password) {
            return Promise.reject("Unable to get token, user is not logged in");
        }
        const token = "abcdefghijklmnopqrstuvwxyz1234567890";
        if (this.age > Date.now() - MAX_TOKEN_AGE) {
            return Promise.resolve(token);
        }
        try {
            await this.restoreSession();
        } catch (err) {
            window.dispatchToStore(loginExpiredAction());
            return Promise.reject(err);
        }
        this.age = Date.now();
        return Promise.resolve(token);
    }

    crossOriginVerification() {
        //void
    }
}

window["demoToken"] = async (userName: string) => {
    console.log(await generateToken(userName ?? "admin@redboxmobile.com", 1));
};
