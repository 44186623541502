import type { RubyApiClient } from "@redbox-ruby/client-lib";
import { RubyChatRole, type RubyChatMessage } from "./backend/RubyData";

export class GPTService {
    private rubyService: RubyApiClient;

    constructor(rubyService: RubyApiClient) {
        this.rubyService = rubyService;
    }

    private responseCache = new Map<string, string>();

    private async chat(context: string, chat: RubyChatMessage[], model = "gpt-3.5-turbo") {
        const response = await this.rubyService.services.getOpenAIChatCompletions({
            model,
            context,
            chat,
        });
        return response;
    }

    // Typing this input object was just causing needless issues
    async describeMetrics(metrics: unknown[], prefix = "Can you analyze this ad data") {
        // GPT has a max body size so limiting to 30 lines of data, this sucks but what can you do
        const metricData = JSON.stringify(metrics.slice(0, 30));

        if (this.responseCache.has(metricData)) {
            return this.responseCache.get(metricData);
        }

        const response = await this.chat(
            "You are a helpful assistant that describes advertising spend data from Apple Search Ads campaigns. Provide insights and analysis for any provided data. Try to keep responses about 2 paragraphs. Try to be positive. If you receive exactly 30 records be aware you may have a limited data subset. The data may also be in a random sort order.",
            [
                {
                    role: RubyChatRole.User,
                    content: `${prefix}: ${metricData}`,
                },
            ]
        );
        this.responseCache.set(metricData, response.message);

        return response.message;
    }
}
