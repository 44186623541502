import { put, takeEvery } from "redux-saga/effects";
import { ActionName } from "../actions/ActionType";
import { type UIActions } from "../actions/uiActions";
import { setMetadataAction } from "../actions/userActions";

function* saveActiveTeamSaga(action: UIActions.SetActiveTeamAction) {
    yield put(setMetadataAction({ activeTeam: action.payload }));
}
function* saveActiveTeamCampaignSaga(action: UIActions.SetActiveCampaignAction) {
    yield put(setMetadataAction({ activeTeam: action.payload.teamId }));
}

export default function* uiSaga() {
    yield takeEvery(ActionName.SET_ACTIVE_TEAM, saveActiveTeamSaga);
    yield takeEvery(ActionName.SET_ACTIVE_CAMPAIGN, saveActiveTeamCampaignSaga);
}
