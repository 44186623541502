import clsx from "clsx";
import React from "react";
import Icon from "../../icon/Icon";
import Button, { type ButtonProps } from "../standard/Button";
import "./buttonIcon.css";

export interface ButtonIconProps extends Omit<ButtonProps, "children"> {
    icon: keyof typeof Icon;
    label: string;
    borderless?: boolean;
    size?: number;
    showLabel?: boolean;
}

export default class ButtonIcon extends React.PureComponent<ButtonIconProps> {
    static defaultProps = {
        ...Button.defaultProps,
        type: "button",
    };

    render() {
        const { icon, label, className, borderless, showLabel, size, ...props } = this.props;
        const IconType = Icon[icon];
        return (
            <Button
                {...props}
                className={clsx(`buttonIcon`, className)}
                title={showLabel ? null : label}
                buttonType={borderless ? "clear" : "primary"}
            >
                <IconType width={size ?? 18} height={size ?? 18} />
                <span className={showLabel ? "" : "u-invisible"}>{label}</span>
            </Button>
        );
    }
}
