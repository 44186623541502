/* eslint-disable @typescript-eslint/unbound-method */
import clsx from "clsx";
import isNil from "lodash/isNil";
import type React from "react";
import { useCallback, useRef } from "react";
import {
    formatCurrencyValue,
    getCurrencySymbol,
    isCurrencyFractional,
    parseCurrency,
} from "../../../../utilities/currency";
import Input, { type InputProps } from "../input/Input";
import "./currencyInput.css";

interface CurrencyInputProps extends InputProps {
    currencyCode: string;
    value?: number | string;
    includeFloat?: boolean;
}

export default function CurrencyInput({
    includeFloat = true,
    currencyCode,
    value,
    onBlur,
    className,
    disabled,
    min,
    max,
    ...props
}: CurrencyInputProps) {
    includeFloat = isCurrencyFractional(currencyCode) && includeFloat;
    value = typeof value === "number" && isNaN(value) ? null : value;

    const inputRef = useRef<HTMLInputElement>();

    const numberValue = parseCurrency(value, includeFloat);
    const stringValue =
        isNil(value) || value === "" ? "" : formatCurrencyValue(numberValue, currencyCode, includeFloat);

    const forceChange = (value: string | number) => {
        const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
        nativeInputValueSetter.call(inputRef.current, value);
        const changeEvent = new Event("input", { bubbles: true });
        inputRef.current.dispatchEvent(changeEvent);
    };

    const handleBlur = useCallback(
        (e: React.FocusEvent<HTMLInputElement>) => {
            forceChange(parseCurrency(e.target.value, includeFloat));
            onBlur?.(e);
        },
        [onBlur]
    );

    const handlePaste = useCallback(
        (e: React.ClipboardEvent<HTMLInputElement>) => {
            if (document.activeElement !== inputRef.current) {
                return;
            }
            const pasteText = e.clipboardData.getData("text/plain");
            const nextValue = parseCurrency(pasteText, includeFloat);
            if (nextValue > 0) {
                forceChange(nextValue);
            }

            e.preventDefault();
            e.stopPropagation();
        },
        [value]
    );

    const symbol = getCurrencySymbol(currencyCode);

    min = typeof min === "number" && !includeFloat ? Math.ceil(min) : min;
    max = typeof max === "number" && !includeFloat ? Math.floor(max) : max;

    return (
        <div
            className={clsx(
                "currencyInput",
                {
                    "is-disabled": disabled,
                },
                className
            )}
        >
            <span className="currencyInput-symbol">{symbol}</span>
            <Input
                {...props}
                min={min}
                max={max}
                type="number"
                className="currencyInput-input"
                value={numberValue}
                step={includeFloat ? 0.01 : 1}
                disabled={disabled}
                inputMode="decimal"
                onBlur={handleBlur}
                inputRef={inputRef}
                onPasteCapture={handlePaste}
            />
            <span className="currencyInput-overlay">{stringValue}</span>
        </div>
    );
}
