import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { type State } from "../../../../../reducers/domain";
import CampaignAsidePanelComponent, {
    type CampaignAsidePanelComponentProps,
    type CampaignAsidePanelProps,
} from "./CampaignAsidePanelComponent";

const mapStateToProps = (state: State, props: CampaignAsidePanelProps): Partial<CampaignAsidePanelComponentProps> => {
    return {
        ...props,
    };
};

const mapDispatchToProps = (_dispatch: Dispatch): Partial<CampaignAsidePanelComponentProps> => ({});

const CampaignAsidePanel = connect(mapStateToProps, mapDispatchToProps)(CampaignAsidePanelComponent);
export default CampaignAsidePanel;
