import {
    APIMethod,
    type APIRequest,
    type APIRequestFull,
    type APIServiceImpl,
    type WrappedResponse,
} from "@/types/APIService";
import type { AuthServiceImpl } from "@/types/AuthService";
import { getTimestampParameters } from "@/utilities/analytics";
import { formatResponseError } from "@/utilities/response";
import fetchJsonp from "fetch-jsonp";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import { minutes } from "../utilities/time";
import { BACKEND_URL } from "../utilities/vars";
import { type RubyUserMe } from "./backend/RubyData";

const baseUrl = BACKEND_URL;
const errorTimeout = parseInt(process.env.REACT_APP_API_CALL_TIMEOUT_WARNING_MINUTES, 10) || 2;

export class APIService implements APIServiceImpl {
    private authService: AuthServiceImpl;

    constructor(authService: AuthServiceImpl) {
        this.authService = authService;
    }

    async get<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.GET,
            useQueryString: true,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async put<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.PUT,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async post<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.POST,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async patch<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.PATCH,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async delete<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.DELETE,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async head<T>(request: APIRequest): Promise<T> {
        return this.call<T>({
            method: APIMethod.HEAD,
            handleErrors: true,
            sendAuth: true,
            ...request,
        });
    }

    async jsonp<T>(request: APIRequest & { callbackParam?: string }): Promise<T> {
        const [url] = await this.buildRequestOpts({ ...request, useQueryString: true, method: APIMethod.GET });
        const response = await fetchJsonp(url, {
            jsonpCallback: request.callbackParam,
        });
        const object = await response.json<T>();
        return object;
    }

    private async call<T>(request: APIRequestFull): Promise<T> {
        const [url, opts] = await this.buildRequestOpts(request);

        const startTime = Date.now();
        const response = await this.makeRequest(url, opts, request.handleErrors);
        const respTime = Date.now() - startTime;

        if (!response.ok) {
            void this.reportError(url, opts, response);
            if (request.handleErrors !== false) {
                return Promise.reject(await formatResponseError(response));
            }
            return Promise.reject(response);
        }

        // Report overly long requests
        if (respTime > minutes(errorTimeout)) {
            void this.reportError(
                url,
                opts,
                `Timing warning: API call took more than ${errorTimeout} minutes to respond. ${respTime / 1000} seconds`
            );
        }

        if (request.wrappedResponse) {
            const body = await this.parseBody<WrappedResponse<T>>(response);
            if (body.error) {
                const error = body.errors?.[0] ?? "An error has occurred with no message provided.";
                return Promise.reject(error);
            }
            return body.response;
        }

        return this.parseBody<T>(response);
    }

    private async buildRequestOpts(request: APIRequestFull): Promise<[string, RequestInit]> {
        let url: string;
        if (request.domain) {
            url = request.domain + request.uri;
        } else {
            url = baseUrl + request.uri;
        }

        const opts = {
            method: request.method,
            headers: {
                Accept: "application/json",
            },
            mode: "cors",
        } as RequestInit;

        if (request.sendAuth) {
            try {
                opts.headers["Authorization"] = "Bearer " + (await this.authService.getToken());
            } catch (err) {
                return Promise.reject(err);
            }
        }

        if (request.data) {
            if (request.submitAsFormData) {
                opts.headers["Content-Type"] = "application/x-www-form-urlencoded";
                opts.body = isObject(request.data)
                    ? objToQuery(request.data)
                    : encodeURIComponent(JSON.stringify(request.data));
            } else if (request.useQueryString) {
                url +=
                    "?" +
                    (isObject(request.data)
                        ? objToQuery(request.data)
                        : encodeURIComponent(JSON.stringify(request.data)));
            } else {
                if (isString(request.data)) {
                    opts.headers["Content-Type"] = "text/plain";
                    opts.body = request.data;
                } else {
                    opts.headers["Content-Type"] = "application/json";
                    opts.body = JSON.stringify(request.data);
                }
            }
        }

        if (request.headers) {
            opts.headers = {
                ...opts.headers,
                ...request.headers,
            };
        }

        return [url, opts];
    }

    private async makeRequest(url: string, opts: RequestInit, handleErrors = true): Promise<Response> {
        const startTime = Date.now();
        try {
            const resp = await fetch(url, opts);

            // Check GA is running
            if (window["dataLayer"]) {
                // Report API speed to GA
                const urlObj = new URL(url);
                const pathname = urlObj.pathname.replace(
                    /\/[\w\d]{8}-[\w\d]{4}-[\w\d]{4}-[\w\d]{4}-[\w\d]{12}\//,
                    "/{id}/"
                );
                const now = Date.now();
                window["dataLayer"].push({
                    event: "GA4Timing",
                    location: urlObj.origin + pathname,
                    time: now - startTime,
                    ...getTimestampParameters(),
                });
                window["dataLayer"].push({
                    event: "timing",
                    eventCategory: "timing",
                    eventAction: "apiCall",
                    eventLabel: urlObj.origin + pathname,
                    eventValue: now - startTime,
                });
            }

            const body = await resp.text();
            resp.text = () => Promise.resolve(body);
            return resp;
        } catch (error) {
            const respTime = Date.now() - startTime;
            console.error("API Service failed to call endpoint: ", url, opts, error);
            if (handleErrors) {
                if (error instanceof Response && error.status > 0) {
                    const errorMessage = await formatResponseError(error);
                    console.error("API Service returning formatted message: ", errorMessage);
                    return Promise.reject(errorMessage);
                }
                if (respTime > minutes(errorTimeout)) {
                    void this.reportError(
                        url,
                        opts,
                        `API Service failed to call endpoint, suspected timeout issue. Failed after ${
                            respTime / 1000
                        } seconds`
                    );
                }
                return Promise.reject(`An error has occurred: Could not contact the server`);
            }
            return Promise.reject(error);
        }
    }

    private async parseBody<T>(response: Response): Promise<T> {
        try {
            const body = await response.text();
            if (body && body.trim()) {
                return JSON.parse(body) as T;
            }
            return null as T;
        } catch (err) {
            return Promise.reject("An error has occurred: Failed to parse response body data");
        }
    }

    private previousErrors = [];
    private errorExpire: number = null;

    async reportError(...errorMessages: unknown[]) {
        if (process.env.REACT_APP_AWS_REPORT_ENDPOINT && process.env.REACT_APP_ENABLE_AWS_NOTIFICATIONS === "true") {
            console.log("Reporting error to SNS");
            let user: RubyUserMe = null;
            try {
                user = await this.authService.checkSession();
            } catch (err) {
                // Don't care
            }
            try {
                const errorStr = (await Promise.all(errorMessages.map((err) => writeAWSParameter(err)))).join(",\n");
                if (this.previousErrors.includes(errorStr)) {
                    console.error("Aborted duplicate error report to SNS", errorStr);
                    return;
                }
                this.previousErrors.push(errorStr);
                this.previousErrors = this.previousErrors.slice(-3);
                clearTimeout(this.errorExpire);
                this.errorExpire = window.setTimeout(() => {
                    this.previousErrors = [];
                }, minutes(10));

                const resp = await fetch(process.env.REACT_APP_AWS_REPORT_ENDPOINT, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body:
                        "[\n" +
                        errorStr +
                        ",\n" +
                        JSON.stringify(
                            {
                                userId: user ? user.id : "",
                                roles: user ? user.role : [],
                                pageResolution: `${window.innerWidth} x ${window.innerHeight}`,
                                screenResolution: `${window.screen.width} x ${window.screen.height}`,
                                devicePixelRatio: window.devicePixelRatio,
                                pageURL: window.location.href,
                            },
                            null,
                            2
                        ) +
                        "\n]",
                });
                if (!resp.ok) {
                    console.error("Failed to report error to SNS", await resp.text());
                }
            } catch (err) {
                console.error("Failed to report error to SNS", err);
            }
        }
    }
}

export function objToQuery(data: object): string {
    return Object.entries(data)
        .filter((param) => param[1] !== null && param[1] !== undefined)
        .map((param) => {
            if (isArray(param[1])) {
                return param[1]
                    .map((p) => encodeURIComponent(param[0]) + "=" + encodeURIComponent(p as string))
                    .join("&");
            }
            if (isObject(param[1])) {
                return encodeURIComponent(param[0]) + "=" + encodeURIComponent(JSON.stringify(param[1]));
            }
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            return encodeURIComponent(param[0]) + "=" + encodeURIComponent(param[1]);
        })
        .join("&");
}

async function writeAWSParameter(message: unknown): Promise<string> {
    if (message instanceof Error) {
        return message.toString();
    }
    if (message instanceof Response) {
        return JSON.stringify(
            {
                status: message.status,
                statusText: message.statusText,
                body: await message.text(),
            },
            null,
            2
        );
    }
    if (isObject(message) && "headers" in message && isObject(message["headers"])) {
        message["headers"] = {
            ...message["headers"],
            Authorization: "",
        };
    }
    return JSON.stringify(message, null, 2);
}
