import { Field, type FieldProps, type FormikConfig } from "formik";
import React from "react";
import * as Yup from "yup";
import { type AsyncData } from "../../../../../reducers/domain";
import {
    RubyTeamStatus,
    type RubyCreateTeamBody,
    type RubyCurrency,
    type RubyTeam,
    type RubyTier,
} from "../../../../../services/backend/RubyData";
import { history } from "../../../../../store";
import {
    getCurrencyName,
    getSupportedCurrencies,
    getSupportedCurrenciesAsOptions,
} from "../../../../../utilities/currency";
import { languageString, writeList } from "../../../../../utilities/text";
import { getUrl } from "../../../../../utilities/url";
import { nameValidation } from "../../../../../utilities/validation";
import CustomerInputs, {
    customerInitialValues,
    customerValidationSchema,
} from "../../../../assemblies/payment/customerInputs/CustomerInputs";
import ButtonLink from "../../../../blocks/buttons/link/ButtonLink";
import ErrorMessage from "../../../../blocks/errors/errorMessage/ErrorMessage";
import Hr from "../../../../blocks/hr/Hr";
import InfoBox from "../../../../blocks/infoBox/InfoBox";
import ControlledInput from "../../../../blocks/inputs/controlledInput/ControlledInput";
import Form from "../../../../blocks/inputs/form/Form";
import InputGrid from "../../../../blocks/inputs/grid/InputGrid";
import SpinnerOverlay from "../../../../blocks/spinnerOverlay/SpinnerOverlay";

export interface CreateTeamProps {
    displayModal?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
    formProvider?: (props: CreateTeamComponent) => React.ReactNode;
    redirectToTeam?: boolean;
}

export interface CreateTeamComponentProps extends CreateTeamProps {
    createTeam: (details: RubyCreateTeamBody) => void;
    createTeamRequest: AsyncData<RubyTeam>;
    getTiers: () => void;
    tiers: AsyncData<RubyTier[]>;
}

export interface CreateTeamFormValues {
    name: string;
    description: string;
    currency: RubyCurrency;
    tierId: number;
    email: string;
    phone: string;
    contact: string;
    streetAddress: string;
    city: string;
    country: string;
    postcode: string;
    taxApplies: boolean;
    taxId: string;
    taxCode: string;
}

export default class CreateTeamComponent extends React.PureComponent<CreateTeamComponentProps> {
    componentDidMount(): void {
        this.props.getTiers();
    }

    componentDidUpdate(prevProps: CreateTeamComponentProps) {
        if (this.props.redirectToTeam && this.props.createTeamRequest.success && !prevProps.createTeamRequest.success) {
            history.push(getUrl.teamDetails(this.props.createTeamRequest.data?.id));
        }
    }

    getFormProps(): FormikConfig<CreateTeamFormValues> {
        return {
            initialValues: {
                name: "",
                description: "",
                tierId: this.props.tiers.data?.[0]?.id,
                currency: "GBP" as RubyCurrency,
                ...customerInitialValues,
            },
            onSubmit: (values) => {
                this.props.createTeam({
                    team: {
                        status: RubyTeamStatus.ACTIVE,
                        name: values.name,
                        description: values.description,
                        currency: values.currency,
                        tierId: parseInt(`${values.tierId}`),
                    },
                    paymentDetails: {
                        email: values.email,
                        phone: values.phone,
                        name: values.contact,
                        address: {
                            line1: values.streetAddress,
                            city: values.city,
                            country: values.country,
                            postalCode: values.postcode,
                            line2: null,
                            state: null,
                        },
                        tax: values.taxApplies
                            ? [
                                  {
                                      type: values.taxId,
                                      value: values.taxCode,
                                  },
                              ]
                            : undefined,
                    },
                });
            },
            validationSchema: Yup.object({
                name: nameValidation().required(languageString("account.team.create.validation.nameRequired")),
                tierId: Yup.number().required(languageString("account.team.create.validation.tierRequired")),
                currency: Yup.string()
                    .required(languageString("account.team.create.validation.currencyRequired"))
                    .when("tierId", {
                        is: (tierId: string) =>
                            this.props.tiers.data?.find((tier) => tier.id === parseInt(tierId))?.hosted,
                        then: (s) =>
                            s.oneOf(
                                getSupportedCurrencies(true),
                                languageString("account.team.create.validation.oneOfCurrencyRequired", undefined, [
                                    writeList(getSupportedCurrencies(true).map(getCurrencyName)),
                                ])
                            ),
                        otherwise: (s) =>
                            s.oneOf(
                                getSupportedCurrencies(false),
                                languageString("account.team.create.validation.oneOfCurrencyRequired", undefined, [
                                    writeList(getSupportedCurrencies(false).map(getCurrencyName)),
                                ])
                            ),
                    }),
                ...customerValidationSchema,
            }),
        };
    }

    renderInputs() {
        if (this.props.tiers.isRequesting) {
            return <SpinnerOverlay isStatic />;
        }

        if (this.props.tiers.errorMessage) {
            return <ErrorMessage>{this.props.tiers.errorMessage}</ErrorMessage>;
        }

        if (this.props.tiers.success && this.props.tiers.data?.length < 1) {
            return <ErrorMessage>{languageString("account.team.create.error.noTiers")}</ErrorMessage>;
        }

        return (
            <>
                <InfoBox dismissible="onboardingTeam" storeDismissal>
                    <p>{languageString("account.team.create.onboarding")}</p>
                    <div className="u-textRight u-pt8">
                        <ButtonLink to={getUrl.contact()}>
                            {languageString("account.team.create.onboardingButton")}
                        </ButtonLink>
                    </div>
                </InfoBox>
                <InputGrid colWidth={2}>
                    <ControlledInput label={languageString("account.team.create.teamName")} name="name" />
                    <ControlledInput
                        label={languageString("account.team.create.description")}
                        name="description"
                        required={false}
                    />
                </InputGrid>
                {this.props.tiers.data?.length > 1 && (
                    <ControlledInput
                        label={languageString("account.team.create.tier")}
                        name="tierId"
                        type="select"
                        options={this.props.tiers.data?.reduce((opts, tier) => {
                            const name = languageString("account.team.create.tierName", "", [
                                tier.name,
                                tier.private ? 1 : 0,
                            ]);
                            opts[tier.id] = name;
                            return opts;
                        }, {})}
                    />
                )}
                <Hr />
                <InputGrid colWidth={1} label={languageString("account.team.create.billingInfo")}>
                    <Field name="tierId">
                        {({ field }: FieldProps<string>) => {
                            const tier = this.props.tiers.data.find((t) => t.id === parseInt(field.value));
                            const hosted = tier?.hosted ?? false;
                            return (
                                <ControlledInput
                                    label={languageString("account.team.create.currency")}
                                    name="currency"
                                    type="select"
                                    options={getSupportedCurrenciesAsOptions(hosted)}
                                />
                            );
                        }}
                    </Field>
                    <CustomerInputs />
                </InputGrid>
            </>
        );
    }

    render(): React.ReactNode {
        if (this.props.formProvider) {
            return this.props.formProvider(this);
        }
        return (
            <Form
                display={this.props.displayModal ? "Modal" : null}
                isOpen={this.props.isOpen}
                onClose={this.props.onClose}
                autoClose
                title={languageString("account.team.create.title")}
                buttonLabel={languageString("account.team.create.button", "Create")}
                successLabel={languageString("account.team.create.created", "Team Created!")}
                request={this.props.createTeamRequest}
                {...this.getFormProps()}
            >
                {this.renderInputs()}
            </Form>
        );
    }
}
