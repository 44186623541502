import { useEffect, useRef, useState } from "react";
import ResizeObserver from "resize-observer-polyfill";

// This hook code was found online, attaches a resize observer to a ref element.
// Works well, via whatever black magic it employs.

export default function useMeasure<El extends HTMLElement>() {
    const ref = useRef<El>(null);
    const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
    const [ro] = useState(
        () =>
            new ResizeObserver(([entry]: ResizeObserverEntry[]) => {
                set(entry.contentRect);
            })
    );
    useEffect(() => {
        if (ref.current) {
            ro.observe(ref.current);
        }
        return () => ro.disconnect();
    }, [ro]);
    return { ref, bounds };
}
