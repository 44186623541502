import { Navigate } from "react-router-dom";
import { isSnapshot } from "../../../../utilities/snapshot";
import { languageString } from "../../../../utilities/text";
import ErrorScreen from "../../../blocks/errors/errorScreen/ErrorScreen";
import SpinnerOverlay from "../../../blocks/spinnerOverlay/SpinnerOverlay";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AuthenticateProps {}

export interface AuthenticateComponentProps extends AuthenticateProps {
    loginError?: string;
    isLoggedIn?: boolean;
    nextPageRedirect?: string;
}

export default function AuthenticateComponent({
    isLoggedIn,
    nextPageRedirect,
    loginError,
}: AuthenticateComponentProps) {
    if (!isSnapshot) {
        if (isLoggedIn) {
            return <Navigate to={nextPageRedirect} />;
        }
        if (loginError) {
            return <ErrorScreen message={loginError} />;
        }
    }
    return <SpinnerOverlay isStatic>{languageString("login.authenticating", "Authenticating…")}</SpinnerOverlay>;
}
