import get from "lodash/get";
import defaults from "./en-GB.yml";

type DictType = { [key: string]: string | DictType };

class Dictionary {
    private strings: DictType;
    private locale: string;
    constructor(strings: DictType, locale: string) {
        this.strings = strings;
        this.locale = locale;
    }

    get(key: string): string {
        const val = get(this.strings, key);
        if (!val && val !== "") {
            console.error("Missing language string", this.locale, key);
            return `MISSING_${this.locale}_${key}`;
        }
        if (typeof val !== "string") {
            console.error("Incorrect language string type", this.locale, key, val);
            return `MISSING_${this.locale}_${key}`;
        }
        return val;
    }
}

const dictCache: Record<string, Dictionary> = {
    "en-GB": new Dictionary(defaults as DictType, "en-GB"),
};

export function getDict(locale: string = navigator.language): Dictionary {
    if (!dictCache[locale]) {
        void loadDict(locale);
    }
    return dictCache[locale] ?? dictCache["en-GB"];
}

async function loadDict(locale: string) {
    const dict = (await import(`./${locale}.yml`)) as DictType;
    dictCache[locale] = new Dictionary(dict, locale);
}
