export function cssVar(varName: string, element: Element = document.documentElement): string {
    return getComputedStyle(element).getPropertyValue(varName);
}

export function prefersDarkMode(): MediaQueryList {
    return window.matchMedia("(prefers-color-scheme: dark)");
}

/**
 * Removes the ambiguity from numeric values when passed into CSS, ensuring they are notated as pixels
 */
export function cssSizeValue(value: number | string): string {
    if (typeof value === "number") {
        return `${value}px`;
    }
    return value;
}
