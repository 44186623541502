import clsx from "clsx";
import { useEffect, useRef } from "react";
import { languageString } from "../../../../utilities/text";
import Icon from "../../icon/Icon";
import { type InputProps } from "../input/Input";
import "../input/input.css";
import "./fileInput.css";

interface FileInputProps extends Omit<InputProps<HTMLInputElement>, "onChange" | "value"> {
    onChange?: (file: File | FileList) => void;
    value?: File;
}

export default function FileInput({ onChange, isInvalid, className, value, ...props }: FileInputProps) {
    const handleClear = () => {
        if (onChange) {
            onChange(null);
        }
        inputRef.current.value = null;
    };

    const inputRef = useRef<HTMLInputElement>();

    const clickInput = () => {
        inputRef.current?.click();
    };

    useEffect(() => {
        if (!value) {
            inputRef.current.value = null;
        }
    }, [value]);

    return (
        <div
            className={clsx("input", "fileInput", className, {
                "is-invalid": isInvalid,
                "is-disabled": props.disabled,
                "is-filled": !!value,
            })}
        >
            <span>{value ? value.name : "\u00a0"}</span>
            <input
                className="input-facade input-facade_pointer"
                aria-invalid={isInvalid}
                {...props}
                ref={inputRef}
                type="file"
                onChange={(e) => {
                    if (onChange) {
                        if (props.multiple) {
                            onChange(e.target.files);
                        } else {
                            onChange(e.target.files[0]);
                        }
                    }
                }}
            />
            <button
                className="input-btn input-btn_file"
                type="button"
                aria-label={languageString("ui.alt.selectFile", "Select file")}
                disabled={props.disabled}
                onClick={clickInput}
            >
                <Icon.Folder />
            </button>
            <button
                className="input-btn input-btn_clear"
                type="button"
                aria-label={languageString("ui.alt.clearFile", "Clear selected file")}
                disabled={props.disabled}
                onClick={handleClear}
            >
                <Icon.Close />
            </button>
        </div>
    );
}
