import { ActionName, type ActionType } from "../actions/ActionType";
import { type MetaState, type State } from "./domain";

export const initialMetaState: MetaState = {
    windowWidth: 0,
    windowHeight: 0,
    scrollHistory: {},
    flipperKey: 0,
    lastActions: [],
};

export default function metaReducer(state: MetaState = initialMetaState, action: ActionType, globalState: State) {
    //state.lastActions = [...state.lastActions, cloneDeep(action)].slice(-5);

    switch (action.type) {
        case ActionName.WINDOW_RESIZE: {
            state.windowWidth = action.payload.width;
            state.windowHeight = action.payload.height;
            break;
        }

        case ActionName.WINDOW_SCROLL: {
            const path = globalState.router.location.pathname;
            state.scrollHistory[path] = action.payload;
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            state.flipperKey += 1;
            break;
        }
    }
}
