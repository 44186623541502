import { createSelector } from "@reduxjs/toolkit";
import uniq from "lodash/uniq";
import { type AsyncData, type State } from "../reducers/domain";
import { initialAsyncDataState } from "../reducers/requestReducer";
import {
    RubyKeywordStatus,
    RubyPurpose,
    RubyRegionStatus,
    type RubyASRRegion,
    type RubyCampaignId,
    type RubyChannel,
    type RubyChannelType,
    type RubyCountry,
    type RubyKeyword,
    type RubyRegion,
} from "../services/backend/RubyData";
import { isChannelWithPurposes } from "../utilities/types";
import { selectRegions } from "./campaignSelectors";

export type KeywordAssignment = RubyKeyword["assignments"][number] & { purpose?: RubyPurpose };

export interface TargetingKeyword extends RubyKeyword {
    countries: RubyCountry[];
    purpose?: RubyPurpose;
    assignmentMap?: Record<RubyCountry, KeywordAssignment>;
    bids?: number[];
    lockedBids?: number[];
    activeAssignments?: KeywordAssignment[];
}

export const toTargetingKeyword = (regions: RubyRegion[]) => {
    return (keyword: RubyKeyword): TargetingKeyword => {
        if (!keyword) {
            return null;
        }

        const countries = new Set<RubyCountry>();
        const assignmentMap = new Map<RubyCountry, KeywordAssignment>();
        let purpose: RubyPurpose = null;
        const activeRegionIds = regions
            ?.filter((region) => region.status == RubyRegionStatus.ACTIVE)
            ?.map((region) => region.id);

        keyword?.assignments?.forEach((assignment) => {
            if (assignment.status !== RubyKeywordStatus.ACTIVE) {
                return;
            }
            const region = regions?.find((region) => region.id === assignment.regionId);
            if (region) {
                countries.add(region.country);
                const regionPurpose = (region as RubyASRRegion).purpose;

                // If this is a KD region, we don't care, don't want to mess with it.
                if (regionPurpose === RubyPurpose.KEYWORD_DISCOVERY) {
                    return;
                }

                if (purpose === null) {
                    // If we don't have a purpose, just set it
                    purpose = regionPurpose;
                } else if (regionPurpose === RubyPurpose.BID_OPTIMISATION) {
                    // If any assignment is in optimisation then we say the keyword is in optimisation
                    // This is a bit naff, as a keyword can be in BO in one country and not in another.
                    purpose = regionPurpose;
                }

                if (!assignmentMap.has(region.country)) {
                    assignmentMap.set(region.country, {
                        ...assignment,
                        purpose: regionPurpose,
                    });
                } else {
                    console.warn("Multiple region assignments found!", { keyword, region, assignmentMap, assignment });
                }
            }
        });

        const activeAssignments =
            keyword?.assignments?.filter(
                (ass) => activeRegionIds?.includes(ass.regionId) && ass.status === RubyKeywordStatus.ACTIVE
            ) ?? [];
        const lockedBids = activeAssignments?.map((ass) => ass.forceBidAmount)?.filter((a) => !!a);
        const bids = activeAssignments?.map((ass) => ass.bidAmount)?.filter((a) => !!a);

        return {
            ...keyword,
            countries: [...countries],
            purpose,
            assignmentMap: Object.fromEntries(assignmentMap) as Record<RubyCountry, KeywordAssignment>,
            lockedBids,
            bids,
            activeAssignments,
        };
    };
};

export const selectTargetingKeywords = createSelector(
    [
        (state: State, campaignId: RubyCampaignId) => state.keywords.targeting[campaignId],
        selectRegions,
        (_s: State, _id: RubyCampaignId, countryFilter?: RubyCountry[], allStatuses?: boolean) =>
            [countryFilter, allStatuses] as const,
    ],
    (targeting, regions, [countryFilter, allStatuses]) => {
        if (!targeting?.success) {
            return {
                ...initialAsyncDataState(),
                ...targeting,
                data: null,
            };
        }
        let data: TargetingKeyword[] = targeting.data
            .map(toTargetingKeyword(regions.data))
            .filter((r) => r.purpose !== RubyPurpose.KEYWORD_DISCOVERY);

        if (!allStatuses) {
            data = data.filter((kw) => kw.status === RubyKeywordStatus.ACTIVE);
        }
        if (countryFilter?.length > 0) {
            data = data.filter((keyword) => keyword.countries.some((country) => countryFilter.includes(country)));
        }
        return {
            ...initialAsyncDataState(),
            ...targeting,
            data,
        };
    }
);

export function selectNegativeKeywords(state: State, campaignId: RubyCampaignId): AsyncData<RubyKeyword[]> {
    const negative = state.keywords.negative[campaignId];
    return negative ?? initialAsyncDataState<RubyKeyword[]>([]);
}

export function selectSeedKeywords(state: State, campaignId: RubyCampaignId): AsyncData<RubyKeyword[]> {
    const seed = state.keywords.seed[campaignId];
    return seed ?? initialAsyncDataState<RubyKeyword[]>([]);
}

export const selectKeywordTags = createSelector([selectTargetingKeywords], (targetingKeywords) => {
    return {
        ...targetingKeywords,
        data: uniq(targetingKeywords?.data?.map((kw) => kw.tag).filter((t) => !!t) ?? []),
    };
});

export function hasKeywordDiscovery(channelType: RubyChannelType, channel: RubyChannel): boolean {
    return (
        (isChannelWithPurposes(channelType, channel) && channel?.purposes?.includes(RubyPurpose.KEYWORD_DISCOVERY)) ??
        false
    );
}
