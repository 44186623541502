import type { AuthServiceImpl } from "@/types/AuthService";
import { type NetworkClientHttpAuthenticationProvider } from "@redbox-ruby/client-lib";

export default class NetworkAuthProvider implements NetworkClientHttpAuthenticationProvider {
    private authService: AuthServiceImpl;
    constructor(authService: AuthServiceImpl) {
        this.authService = authService;
    }

    async getRequestHeaders() {
        const token = await this.authService.getToken();
        return {
            authorization: `Bearer ${token}`,
        };
    }
}
