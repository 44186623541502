import isString from "lodash/isString";
import React, { type CSSProperties } from "react";
import { languageString } from "../../../../utilities/text";
import Icon from "../../icon/Icon";
import "../input/input.css";
import "./select.css";

interface SelectProps extends Omit<React.SelectHTMLAttributes<HTMLSelectElement>, "children"> {
    isInvalid?: boolean;
    children: {
        [value: string]: string | SelectOption;
    };
    value: string;
}

export interface SelectOption {
    label: string;
    disabled?: boolean;
    style?: CSSProperties;
}

export default class Select extends React.PureComponent<SelectProps> {
    static defaultProps = {
        children: { null: languageString("ui.input.noValue", "No Value") },
    };

    getValue() {
        const value = this.props.value ?? "null";
        const label = this.props.children[value];
        return (isString(label) ? label : label?.label) || languageString("ui.input.noValue", "No Value");
    }

    render() {
        const { children, isInvalid, className, disabled, value, ...props } = this.props;
        const valueProps = isString(children[value ?? "null"]) ? null : (children[value ?? "null"] as SelectOption);
        return (
            <div
                className={`input select ${className || ""} ${isInvalid ? `is-invalid` : ``} ${
                    disabled ? `is-disabled` : ``
                }`}
            >
                <span style={valueProps?.style}>{this.getValue()}</span>
                <Icon.ChevronDown className="select-icon" />
                <select
                    className="input-facade"
                    aria-invalid={isInvalid}
                    value={value ?? "null"}
                    disabled={disabled}
                    {...props}
                >
                    {Object.entries(children).map(([key, label]: [string, string | SelectOption]) => {
                        return (
                            <option key={key} value={key} disabled={!isString(label) && label?.disabled}>
                                {isString(label) ? label : label?.label}
                            </option>
                        );
                    })}
                </select>
            </div>
        );
    }
}
