import { type RequestAction, requestActions } from "./Action";

export enum InteractionActionName {
    SEND_CONTACT_FORM = "SEND_CONTACT_FORM",
    SEND_SUPPORT_FORM = "SEND_SUPPORT_FORM",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace InteractionActions {
    export type ContactFormAction = RequestAction<
        InteractionActionName.SEND_CONTACT_FORM,
        { name: string; message: string; email: string },
        void
    >;
    export type SupportFormAction = RequestAction<
        InteractionActionName.SEND_SUPPORT_FORM,
        { name: string; message: string },
        void
    >;
}

export type InteractionActionType = InteractionActions.ContactFormAction | InteractionActions.SupportFormAction;

export const contactFormAction = requestActions<InteractionActions.ContactFormAction>(
    InteractionActionName.SEND_CONTACT_FORM
);

export const supportFormAction = requestActions<InteractionActions.SupportFormAction>(
    InteractionActionName.SEND_SUPPORT_FORM
);
