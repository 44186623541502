import type React from "react";
import Scroller from "../../blocks/scroller/Scroller";
import AsidePanel from "../asidePanel/AsidePanel";
import Navigation from "./Navigation";
import "./navigation.css";

interface NavigationPageProps {
    asidePanel?: React.ReactElement;
    children: React.ReactNode;
}

export default function NavigationPage({ asidePanel, children }: NavigationPageProps) {
    return (
        <div className="navigation~root">
            <Navigation />
            <div className="navigation-page">
                {asidePanel ? (
                    <AsidePanel content={asidePanel}>
                        <Scroller className="navigation-pageInner" autoHeight={false}>
                            <div className="navigation-spacer">{children}</div>
                        </Scroller>
                    </AsidePanel>
                ) : (
                    <Scroller className="navigation-pageInner" autoHeight={false}>
                        <div className="navigation-spacer">{children}</div>
                    </Scroller>
                )}
            </div>
        </div>
    );
}
