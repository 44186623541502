import clsx from "clsx";
import isFunction from "lodash/isFunction";
import React from "react";
import { type RequestState } from "../../../../reducers/domain";
import AutoHeight from "../../autoHeight/AutoHeight";
import RequestLoader from "../../requestLoader/RequestLoader";
import "./card.css";

export type CardElements = {
    head?: React.ReactNode | (() => React.ReactNode);
    body?: React.ReactNode | (() => React.ReactNode);
    placeholder?: React.ReactNode | (() => React.ReactNode);
};

export interface CardProps extends Omit<React.HTMLAttributes<Element>, "children"> {
    children: CardElements | React.ReactNode;
    className?: string;
    loadingMessage?: string;
    request?: RequestState;
    autoHeight?: boolean;
    isDisabled?: boolean;
    isError?: boolean;
    isSuccess?: boolean;
}

export default class Card extends React.PureComponent<CardProps> {
    static defaultProps = {
        children: {},
    };

    render() {
        const { children, className, loadingMessage, autoHeight, request, isDisabled, isError, isSuccess, ...props } =
            this.props;

        const elements: CardElements =
            React.isValidElement(children) || (!children["body"] && !children["head"] && !children["placeholder"])
                ? {
                      body: children as React.ReactNode,
                  }
                : (children as CardElements);

        const renderBody = () =>
            elements.body ? isFunction(elements.body) ? elements.body() : elements.body : <>&nbsp;</>;

        const cardEl = (
            <div
                {...props}
                className={clsx("card", className, {
                    card_headless: !elements.head,
                    card_inactive: isDisabled,
                    card_error: isError && !isDisabled,
                    card_success: isSuccess,
                })}
            >
                {elements.head && (
                    <div className={`card-header`}>{isFunction(elements.head) ? elements.head() : elements.head}</div>
                )}
                <div className="card-main">
                    {request ? (
                        <RequestLoader
                            request={request}
                            placeholder={
                                isFunction(elements?.placeholder) ? elements?.placeholder() : elements?.placeholder
                            }
                            label={loadingMessage}
                        >
                            {renderBody()}
                        </RequestLoader>
                    ) : (
                        renderBody()
                    )}
                </div>
            </div>
        );

        return autoHeight ? <AutoHeight>{cardEl}</AutoHeight> : cardEl;
    }
}
