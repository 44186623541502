import {
    type RubyCampaignId,
    type RubyCampaignRule,
    type RubyKeywordRule,
    type RubyRegionRule,
    type RubyRule,
    type RubyRuleCreateBody,
    type RubyRuleUpdateBody,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { requestActions, type RequestAction } from "./Action";

export enum RulesActionName {
    LIST_CAMPAIGN_RULES = "LIST_CAMPAIGN_RULES",
    LIST_REGION_RULES = "LIST_REGION_RULES",
    LIST_KEYWORD_RULES = "LIST_KEYWORD_RULES",
    CREATE_RULE = "CREATE_RULE",
    UPDATE_RULE = "UPDATE_RULE",
    DELETE_RULE = "DELETE_RULE",
    UPDATE_RULES_PRIORITIES = "UPDATE_RULES_PRIORITIES",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace RulesActions {
    export type ListCampaignRulesAction = RequestAction<
        RulesActionName.LIST_CAMPAIGN_RULES,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyCampaignRule[]
    >;
    export type ListRegionRulesAction = RequestAction<
        RulesActionName.LIST_REGION_RULES,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyRegionRule[]
    >;
    export type ListKeywordRulesAction = RequestAction<
        RulesActionName.LIST_KEYWORD_RULES,
        {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyKeywordRule[]
    >;
    export type CreateRuleAction = RequestAction<
        RulesActionName.CREATE_RULE,
        RubyRuleCreateBody & {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyRule
    >;
    export type UpdateRuleAction = RequestAction<
        RulesActionName.UPDATE_RULE,
        RubyRuleUpdateBody & {
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyRule
    >;
    export type DeleteRuleAction = RequestAction<
        RulesActionName.DELETE_RULE,
        {
            ruleId: RubyRule["id"];
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        void
    >;
    export type UpdateRulesPrioritiesAction = RequestAction<
        RulesActionName.UPDATE_RULES_PRIORITIES,
        {
            rules: RubyRule[];
            teamId: RubyTeamId;
            campaignId: RubyCampaignId;
        },
        RubyRule[]
    >;
}

export type RulesActionType =
    | RulesActions.ListCampaignRulesAction
    | RulesActions.ListRegionRulesAction
    | RulesActions.ListKeywordRulesAction
    | RulesActions.CreateRuleAction
    | RulesActions.UpdateRuleAction
    | RulesActions.DeleteRuleAction
    | RulesActions.UpdateRulesPrioritiesAction;

export const campaignRulesActions = {
    list: requestActions<RulesActions.ListCampaignRulesAction>(RulesActionName.LIST_CAMPAIGN_RULES),
};

export const regionRulesActions = {
    list: requestActions<RulesActions.ListRegionRulesAction>(RulesActionName.LIST_REGION_RULES),
};

export const keywordRulesActions = {
    list: requestActions<RulesActions.ListKeywordRulesAction>(RulesActionName.LIST_KEYWORD_RULES),
};

export const createRuleAction = requestActions<RulesActions.CreateRuleAction>(RulesActionName.CREATE_RULE);
export const updateRuleAction = requestActions<RulesActions.UpdateRuleAction>(RulesActionName.UPDATE_RULE);
export const deleteRuleAction = requestActions<RulesActions.DeleteRuleAction>(RulesActionName.DELETE_RULE);
export const updateRulesPrioritiesAction = requestActions<RulesActions.UpdateRulesPrioritiesAction>(
    RulesActionName.UPDATE_RULES_PRIORITIES
);
