import clsx from "clsx";
import isArray from "lodash/isArray";
import moment from "moment";
import React from "react";
import { type TileDisabledFunc } from "react-calendar/dist/cjs/shared/types";
import { formatDate, localToUTC } from "../../../../utilities/date";
import { MIN_POSSIBLE_DATE, getAllTimeFilters, type TimeFilter } from "../../../../utilities/timeFilter";
import ButtonMenu from "../../buttons/menu/ButtonMenu";
import Button from "../../buttons/standard/Button";
import Calendar, { type CalendarProps } from "../../calendar/Calendar";
import Icon from "../../icon/Icon";
import { OverlayAlignment, OverlayDirection } from "../../overlays/domain";
import ToolMenu from "../../overlays/toolMenu/ToolMenu";
import "../input/input.css";
import "./dateInput.css";

interface DateInputProps extends CalendarProps {
    disableWeekends?: boolean;
    showPresetRanges?: boolean;
    presetTimeRanges?: TimeFilter[];
    preferredDirection?: OverlayDirection;
    alignment?: OverlayAlignment;
    disabledDates?: number[];
    disabled?: boolean;
    isInvalid?: boolean;
}

export default function DateInput({
    minDate = new Date(MIN_POSSIBLE_DATE),
    disableWeekends,
    preferredDirection,
    alignment,
    disabledDates,
    disabled,
    isInvalid,
    value = new Date(),
    ...props
}: DateInputProps) {
    const isDisabledDate: TileDisabledFunc = (props) => {
        const date = localToUTC(props.date);

        // Check if disabled
        if (disabledDates?.length > 0) {
            if (disabledDates.includes(date.getTime())) {
                return true;
            }
        }

        // Check weekends
        if (disableWeekends) {
            const day = moment.utc(date).day();
            return [6, 7].includes(day);
        }

        return false;
    };

    if (disabled) {
        return <DateValueView viewProps={{ value, ...props }} isInvalid={isInvalid} disabled />;
    }
    return (
        <ToolMenu
            preferredDirection={preferredDirection ?? OverlayDirection.DOWN}
            alignment={alignment ?? OverlayAlignment.START}
        >
            {{
                target: (
                    <div>
                        <DateValueView viewProps={{ value, ...props }} isInvalid={isInvalid} />
                    </div>
                ),
                content: (
                    <DateValueSelector
                        {...props}
                        value={value}
                        minDate={minDate}
                        tileDisabled={(p) => props.tileDisabled?.(p) || isDisabledDate(p)}
                    />
                ),
            }}
        </ToolMenu>
    );
}

interface DateValueViewProps {
    viewProps: DateInputProps;
    disabled?: boolean;
    isInvalid?: boolean;
}

function DateValueView({ viewProps, isInvalid, ...props }: DateValueViewProps) {
    const renderValue = (inputProps: DateInputProps) => {
        if (inputProps.selectRange && isArray(inputProps.value)) {
            return inputProps.value?.map((date) => formatDate(new Date(date))).join(" - ");
        }
        return formatDate(inputProps.value as Date);
    };

    return (
        <button
            {...props}
            className={clsx("input", "dateInput-button", {
                "is-invalid": isInvalid,
            })}
            type="button"
        >
            {renderValue(viewProps)}
            <Icon.DateRange className="dateInput-icon" />
        </button>
    );
}

class DateValueSelector extends React.PureComponent<DateInputProps> {
    render() {
        return (
            <div className="dateInput-menu">
                <Calendar {...this.props} />
                {(this.props.showPresetRanges ||
                    (this.props.presetTimeRanges?.length > 0 && this.props.showPresetRanges !== false)) && (
                    <ButtonMenu buttonType="clear" className="dateInput-presets">
                        {this.presetRanges.map((timeFilter, i) => (
                            <Button
                                key={i}
                                onClick={() =>
                                    this.props.onChange?.(
                                        [timeFilter.from.toDate(), timeFilter.to.toDate()] as [Date, Date],
                                        null
                                    )
                                }
                            >
                                {timeFilter.description}
                            </Button>
                        ))}
                    </ButtonMenu>
                )}
            </div>
        );
    }

    get presetRanges() {
        if (this.props.presetTimeRanges?.length > 0) {
            return this.props.presetTimeRanges;
        }

        let ranges = getAllTimeFilters();

        if (this.props.minDate) {
            ranges = getAllTimeFilters(moment.utc(this.props.minDate));
            ranges = ranges.filter((range) => range.from.isSameOrAfter(this.props.minDate));
        }
        if (this.props.maxDate) {
            ranges = ranges.filter((range) => range.to.isSameOrBefore(this.props.maxDate));
        }

        return ranges;
    }
}
