import type React from "react";

export class OverlayDirectionHelper extends String {
    get isUp() {
        return this.toString() === "up";
    }
    get isDown() {
        return this.toString() === "down";
    }
    get isLeft() {
        return this.toString() === "left";
    }
    get isRight() {
        return this.toString() === "right";
    }
    get isVertical() {
        return this.isDown || this.isUp;
    }
    get isHorizontal() {
        return !this.isVertical;
    }
}

export class OverlayAlignmentHelper extends String {
    get isCenter() {
        return this.toString() === "center";
    }
    get isStart() {
        return this.toString() === "start";
    }
    get isEnd() {
        return this.toString() === "end";
    }
}

export const OverlayAlignment = {
    CENTER: new OverlayAlignmentHelper("center"),
    START: new OverlayAlignmentHelper("start"),
    END: new OverlayAlignmentHelper("end"),
};
// This is a fudge to make an object of classes into an enum
export type OverlayAlignment = (typeof OverlayAlignment)[keyof typeof OverlayAlignment]; // eslint-disable-line

export const OverlayDirection = {
    UP: new OverlayDirectionHelper("up"),
    DOWN: new OverlayDirectionHelper("down"),
    RIGHT: new OverlayDirectionHelper("right"),
    LEFT: new OverlayDirectionHelper("left"),
};
// This is a fudge to make an object of classes into an enum
export type OverlayDirection = (typeof OverlayDirection)[keyof typeof OverlayDirection]; // eslint-disable-line

export interface ValidPositionProps {
    x: number;
    y: number;
    width: number;
    height: number;
    direction: OverlayDirection;
    centerOffset: number;
    rects: PositionValidationProps;
}

export interface PositionRect {
    x: number;
    y: number;
    width: number;
    height: number;
}

export interface PositionValidationProps {
    windowRect: PositionRect;
    menuRect: PositionRect;
    targetRect: PositionRect;
}

export interface OverlayProps {
    children: {
        target: React.ReactElement;
        content: React.ReactElement;
    };
    baseClass?: string;
    preferredDirection?: OverlayDirection;
    alignment?: OverlayAlignment;
    className?: string;
    autoClose?: boolean;
}

export interface ControlledOverlayProps extends OverlayProps {
    isOpen: boolean;
    onToggle: (isOpen: boolean) => void;
}

export interface OverlayState extends ValidPositionProps {
    lastPositionTime: number;
    positionCalcCount: number;
    zIndex: number;
    children: string[];
}
