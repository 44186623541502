import { connect } from "react-redux";
import { type Dispatch } from "redux";
import { notificationsAction } from "../../../actions/accountActions";
import { type State } from "../../../reducers/domain";
import NotificationsComponent, {
    type NotificationsComponentProps,
    type NotificationsProps,
} from "./NotificationsComponent";

const mapStateToProps = (state: State, props: NotificationsProps): Partial<NotificationsComponentProps> => ({
    ...props,
    user: state.user,
    notifications: state.account.notifications,
    deleteAllNotificationsRequest: state.account.deleteAllNotifications,
    deleteNotificationRequest: state.account.deleteNotification,
});

const mapDispatchToProps = (dispatch: Dispatch): Partial<NotificationsComponentProps> => ({
    checkNotifications: () => dispatch(notificationsAction.list.request(undefined, true)),
    loadMoreNotifications: () => dispatch(notificationsAction.list.request()),
    deleteNotification: (noteId) => dispatch(notificationsAction.delete.request(noteId)),
    deleteNotifications: () => dispatch(notificationsAction.deleteAll.request()),
});

const Notifications = connect(mapStateToProps, mapDispatchToProps)(NotificationsComponent);

export default Notifications;
