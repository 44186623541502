import { ActionName, type ActionType } from "../actions/ActionType";
import { type InteractionState, type State } from "./domain";
import { initialRequestState, requestActionReducer } from "./requestReducer";

export const initialInteractionState: InteractionState = {
    contactForm: initialRequestState(),
    supportForm: initialRequestState(),
};

export default function interactionReducer(
    state: InteractionState = initialInteractionState,
    action: ActionType,
    _globalState: State
) {
    switch (action.type) {
        case ActionName.SEND_CONTACT_FORM: {
            state.contactForm = requestActionReducer(action);
            break;
        }

        case ActionName.SEND_SUPPORT_FORM: {
            state.supportForm = requestActionReducer(action);
            break;
        }
    }
}
