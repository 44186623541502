import React from "react";
import { ReactComponent as LogoSVG } from "./Astra.svg";
import "./logo.css";

interface LogoProps {
    portrait?: boolean;
    withoutText?: boolean;
}

export default class Logo extends React.PureComponent<LogoProps> {
    render() {
        return (
            <div className={`logo ${this.props.portrait ? "logo_portrait" : ""}`} role="img">
                <LogoSVG width={39} height={43} />
                {!this.props.withoutText && <span>Astra</span>}
            </div>
        );
    }
}
