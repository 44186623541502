import { BACKEND_URL, DEMO_MODE, MOCK_ITUNES_CLIENT } from "@/utilities/vars";
import {
    NetworkClient,
    NetworkClientConsoleLogger,
    NetworkClientMapCacheProvider,
    NetworkClientStaticHttpUrlProvider,
    NoopNetworkClientMessageSender,
    createRubyApiClient,
    createRubySpiClient,
} from "@redbox-ruby/client-lib";
import { APIService } from "./APIService";
import { ASAService } from "./ASAService";
import { AppSearchProvider, MockedAppSearchProvider } from "./AppSearchProvider";
import { AppSearchService } from "./AppSearchService";
import { AuthService } from "./AuthService";
import { BroadcastService } from "./BroadcastService";
import { CreationService } from "./CreationService";
import { DemoAuthService } from "./DemoAuthService";
import { GPTService } from "./GPTService";
import { NewsletterService } from "./NewsletterService";
import { OrderService } from "./OrderService";
import { PortalService } from "./PortalService";
import { StripeService } from "./StripeService";
import NetworkAuthProvider from "./backend/NetworkAuthProvider";
import networkFetchClient from "./backend/NetworkFetchClient";

export const asaService = new ASAService();

export const authService = DEMO_MODE ? new DemoAuthService() : new AuthService();
window["logout"] = authService.logout.bind(authService);

export const apiService = new APIService(authService);

export const broadcastService = new BroadcastService();

export const creationService = new CreationService();

export const newsletterService = new NewsletterService();

export const portalService = new PortalService();

const rubyNetworkClient = new NetworkClient(
    networkFetchClient,
    new NoopNetworkClientMessageSender(),
    new NetworkAuthProvider(authService),
    new NetworkClientStaticHttpUrlProvider(BACKEND_URL),
    new NetworkClientMapCacheProvider(),
    new NetworkClientConsoleLogger()
);
export const rubyService = createRubyApiClient(rubyNetworkClient);
export const rubySecretService = createRubySpiClient(rubyNetworkClient);

export const stripeService = new StripeService(rubyService);

export const orderService = new OrderService(stripeService, rubyService);

export const gptService = new GPTService(rubyService);

const searchProvider = !MOCK_ITUNES_CLIENT
    ? new AppSearchProvider(apiService, rubyService)
    : new MockedAppSearchProvider();
export const appSearchService = new AppSearchService(searchProvider);
