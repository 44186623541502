import { cssSizeValue } from "@/utilities/style";
import useMeasure from "@/utilities/useMeasure";
import clsx from "clsx";
import type React from "react";
import { useCallback, useEffect, useRef, type CSSProperties } from "react";
import { Virtuoso, VirtuosoGrid, type VirtuosoGridProps, type VirtuosoProps } from "react-virtuoso";
import "./scroller.css";

interface ScrollerVirtualiseGridProps {
    virtualise: boolean;
    virtualisedProps?: Partial<VirtuosoGridProps<unknown, unknown>>;
    virtualiseType?: "grid";
}
interface ScrollerVirtualiseDefaultProps {
    virtualise: boolean;
    virtualisedProps?: Partial<VirtuosoProps<unknown, unknown>>;
    virtualiseType: "default";
}
interface ScrollerVirtualiseOffProps {
    virtualise?: boolean;
    virtualisedProps?: never;
    virtualiseType?: never;
}
type ScrollerVirtualiseProps =
    | ScrollerVirtualiseGridProps
    | ScrollerVirtualiseDefaultProps
    | ScrollerVirtualiseOffProps;

type ScrollerProps = ScrollerVirtualiseProps & {
    padding?: number;
    onScroll?: React.UIEventHandler<HTMLDivElement>;
    minHeight?: CSSProperties["minHeight"];
    maxHeight?: CSSProperties["maxHeight"];
    height?: CSSProperties["height"];
    children?: React.ReactNode;
    autoHeight?: boolean;
    className?: string;
    scrollShadow?: boolean;
};

export default function Scroller({
    virtualise = false,
    padding = 12,
    onScroll,
    minHeight,
    maxHeight,
    height,
    children,
    autoHeight,
    className,
    scrollShadow,
    ...props
}: ScrollerProps) {
    const { ref: viewRef, bounds: viewRect } = useMeasure<HTMLDivElement>();
    const { ref: childRef, bounds: childRect } = useMeasure<HTMLDivElement>();

    const trackHRef = useRef<HTMLDivElement>();
    const trackVRef = useRef<HTMLDivElement>();
    const thumbHRef = useRef<HTMLDivElement>();
    const thumbVRef = useRef<HTMLDivElement>();

    const updateView = useCallback(() => {
        const view = viewRef.current;
        if (!view) return;

        const scrollMaxHeight = view.scrollHeight;
        const scrollMaxWidth = view.scrollWidth;
        const viewHeight = view.clientHeight;
        const viewWidth = view.clientWidth;
        const scrollPosHeight = view.scrollTop;
        const scrollPosWidth = view.scrollLeft;

        // Set heights
        const minHeight = 16 / viewHeight;
        const thumbHeight = Math.max(viewHeight / scrollMaxHeight, minHeight);
        thumbVRef.current.style.height = `${thumbHeight * 100}%`;
        if (thumbHeight < 1) {
            view.parentElement.classList.add("is-vertical");
        } else {
            view.parentElement.classList.remove("is-vertical");
        }

        const minWidth = 16 / viewWidth;
        const thumbWidth = Math.max(viewWidth / scrollMaxWidth, minWidth);
        thumbHRef.current.style.width = `${thumbWidth * 100}%`;
        if (thumbWidth < 1) {
            view.parentElement.classList.add("is-horizontal");
        } else {
            view.parentElement.classList.remove("is-horizontal");
        }

        // Set positions
        const scrollHeight = scrollMaxHeight - viewHeight;
        const scrollVPercent = scrollPosHeight / scrollHeight;
        thumbVRef.current.style.top = `${(scrollVPercent - scrollVPercent * thumbHeight) * 100}%`;

        const scrollWidth = scrollMaxWidth - viewWidth;
        const scrollHPercent = scrollPosWidth / scrollWidth;
        thumbHRef.current.style.left = `${(scrollHPercent - scrollHPercent * thumbWidth) * 100}%`;

        // Deal with the shadow classes
        if (scrollShadow) {
            if (thumbHeight >= 1) {
                view.parentElement.classList.remove("is-scrollUp");
                view.parentElement.classList.remove("is-scrollDown");
            } else {
                if (scrollVPercent > 0) {
                    view.parentElement.classList.add("is-scrollUp");
                } else {
                    view.parentElement.classList.remove("is-scrollUp");
                }
                if (scrollVPercent < 1) {
                    view.parentElement.classList.add("is-scrollDown");
                } else {
                    view.parentElement.classList.remove("is-scrollDown");
                }
            }
        }
    }, [viewRect, childRect, thumbVRef, trackVRef, thumbHRef, trackHRef]);

    const handleScroll = useCallback<React.UIEventHandler<HTMLDivElement>>(
        (e) => {
            onScroll?.(e);
            updateView();
        },
        [onScroll, updateView]
    );

    const handleDrag = useCallback<React.PointerEventHandler<HTMLDivElement>>((e) => {
        if (e.target !== thumbVRef.current && e.target !== thumbHRef.current) {
            return;
        }

        e.preventDefault();

        const view = viewRef.current;
        const target = e.target as HTMLDivElement;
        target.setPointerCapture(e.pointerId);

        const isVertical = target === thumbVRef.current;

        const startPos = isVertical ? e.pageY : e.pageX;
        const startScroll = isVertical ? view.scrollTop : view.scrollLeft;

        const onMove = (moveE: PointerEvent) => {
            moveE.preventDefault();
            const newPos = isVertical ? moveE.pageY : moveE.pageX;
            const diff = newPos - startPos;
            const trackSize = isVertical ? trackVRef.current.clientHeight : trackHRef.current.clientWidth;
            const scrollSize = isVertical ? view.scrollHeight : view.scrollWidth;
            const multiplier = scrollSize / trackSize;
            const scrollBy = diff * multiplier;

            view.scrollTo(
                isVertical
                    ? {
                          top: startScroll + scrollBy,
                      }
                    : {
                          left: startScroll + scrollBy,
                      }
            );
        };

        target.addEventListener("pointermove", onMove);
        target.addEventListener(
            "pointerup",
            (upE) => {
                target.removeEventListener("pointermove", onMove);
                target.releasePointerCapture(upE.pointerId);
            },
            { once: true }
        );
    }, []);

    const handleZoop = useCallback<React.PointerEventHandler<HTMLDivElement>>((e) => {
        e.preventDefault();
        const view = viewRef.current;

        if (e.target === trackVRef.current) {
            const trackRect = trackVRef.current.getBoundingClientRect();
            const mousePos = e.pageY - trackRect.top;
            const posPercent = mousePos / trackRect.height;
            const range = view.scrollHeight - view.clientHeight;
            view.scrollTo({
                top: range * posPercent,
                behavior: "smooth",
            });
        } else if (e.target === trackHRef.current) {
            const trackRect = trackHRef.current.getBoundingClientRect();
            const mousePos = e.pageX - trackRect.left;
            const posPercent = mousePos / trackRect.width;
            const range = view.scrollWidth - view.clientWidth;
            view.scrollTo({
                left: range * posPercent,
                behavior: "smooth",
            });
        }
    }, []);

    let childComponent = children;
    if (virtualise && Array.isArray(children)) {
        if (props.virtualiseType === "default") {
            childComponent = (
                <Virtuoso
                    {...props.virtualisedProps}
                    totalCount={children.length}
                    itemContent={(i) => children[i] as React.ReactNode}
                    customScrollParent={viewRef.current}
                />
            );
        } else {
            childComponent = (
                <VirtuosoGrid
                    {...props.virtualisedProps}
                    totalCount={children.length}
                    itemContent={(i) => children[i] as React.ReactNode}
                    customScrollParent={viewRef.current}
                />
            );
        }
    } else {
        // Toggle this off so the class isn't applied
        virtualise = false;
    }

    useEffect(() => {
        updateView();
        const timer = setInterval(() => {
            updateView();
            if (viewRef.current) {
                viewRef.current.parentElement.scrollTop = 0;
                viewRef.current.parentElement.scrollLeft = 0;
            }
        }, 1000);
        return () => clearInterval(timer);
    }, [updateView, viewRef.current]);

    return (
        <div
            className={clsx("scroller", className, {
                scroller_autoHeight: autoHeight,
                scroller_virtualised: virtualise,
            })}
            style={{
                maxHeight,
                minHeight,
                height,
            }}
        >
            <div
                className="scroller-view"
                ref={viewRef}
                onScroll={handleScroll}
                style={{
                    "--scroll-padding": `${cssSizeValue(padding)}`,
                    "--scroll-min-height": minHeight ? `${cssSizeValue(minHeight)}` : "0px",
                    "--scroll-max-height": maxHeight ? `${cssSizeValue(maxHeight)}` : "none",
                    "--scroll-height": height ? `${cssSizeValue(height)}` : "auto",
                }}
            >
                <div className="scroller-child" ref={childRef}>
                    {childComponent}
                </div>
            </div>
            <div className="scroller-track scroller-track_h" ref={trackHRef} onPointerDown={handleZoop}>
                <div className="scroller-thumb" ref={thumbHRef} onPointerDown={handleDrag}></div>
            </div>
            <div className="scroller-track scroller-track_v" ref={trackVRef} onPointerDown={handleZoop}>
                <div className="scroller-thumb" ref={thumbVRef} onPointerDown={handleDrag}></div>
            </div>
        </div>
    );
}
