import React from "react";
import { type PaginatedAsyncData, type RequestState } from "../../../reducers/domain";
import { type RubyNotification } from "../../../services/backend/RubyData";
import { languageString } from "../../../utilities/text";
import { minutes } from "../../../utilities/time";
import BlockMessage from "../../blocks/blockMessage/BlockMessage";
import ButtonIcon from "../../blocks/buttons/icon/ButtonIcon";
import Button from "../../blocks/buttons/standard/Button";
import ErrorMessage from "../../blocks/errors/errorMessage/ErrorMessage";
import { OverlayAlignment, OverlayDirection } from "../../blocks/overlays/domain";
import ToolMenu from "../../blocks/overlays/toolMenu/ToolMenu";
import Scroller from "../../blocks/scroller/Scroller";
import SpinnerOverlay from "../../blocks/spinnerOverlay/SpinnerOverlay";
import { NotificationFactory, type MinimalUserState } from "./NotificationFactory";
import "./notifications.css";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NotificationsProps {}

export interface NotificationsComponentProps extends NotificationsProps {
    user: MinimalUserState;
    notifications: PaginatedAsyncData<RubyNotification[]>;
    checkNotifications: () => void;
    loadMoreNotifications: () => void;
    deleteAllNotificationsRequest: RequestState;
    deleteNotificationRequest: Record<RubyNotification["id"], RequestState>;
    deleteNotification: (noteId: RubyNotification["id"]) => void;
    deleteNotifications: () => void;
}

export default class NotificationsComponent extends React.PureComponent<NotificationsComponentProps> {
    private interval: NodeJS.Timer = null;

    componentDidMount() {
        this.interval = setInterval(this.props.checkNotifications, minutes(5));
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const hasNotifications = this.props.notifications.data?.length > 0;
        return (
            <ToolMenu alignment={OverlayAlignment.END} preferredDirection={OverlayDirection.DOWN}>
                {{
                    target: (
                        <ButtonIcon
                            icon={hasNotifications ? "Alert" : "NoAlert"}
                            label={languageString("ui.alt.notifications", "Notifications")}
                            className={hasNotifications ? "notifications-alert" : ""}
                        />
                    ),
                    content: (
                        <div className="notifications-menu">
                            <div className="notifications-header">
                                <h3 className="notifications-title">{languageString("notifications.title")}</h3>
                                {this.props.notifications.data?.length > 0 ? (
                                    <Button
                                        onClick={() => {
                                            this.props.deleteNotifications();
                                        }}
                                        buttonType="link"
                                        isPending={this.props.deleteAllNotificationsRequest.isRequesting}
                                    >
                                        {languageString("notifications.clearAll")}
                                    </Button>
                                ) : null}
                            </div>
                            {this.props.notifications.isRequesting ? (
                                <SpinnerOverlay>{languageString("notifications.loading")}</SpinnerOverlay>
                            ) : null}
                            {this.props.notifications.errorMessage && (
                                <ErrorMessage>{this.props.notifications.errorMessage}</ErrorMessage>
                            )}
                            <Scroller minHeight={100} maxHeight={"60vh"} padding={0} autoHeight>
                                {this.props.notifications.data?.length === 0 ? (
                                    <BlockMessage>{languageString("notifications.empty")}</BlockMessage>
                                ) : null}
                                <ol className="notifications-list">
                                    {this.props.notifications.data?.map((note) => {
                                        return (
                                            <li className="notifications-note" key={note.id.toString()}>
                                                <NotificationFactory user={this.props.user} note={note} />
                                                <ButtonIcon
                                                    className="notifications-noteClose"
                                                    icon="Close"
                                                    label={languageString("ui.alt.notificationClear")}
                                                    onClick={() => {
                                                        this.props.deleteNotification(note.id);
                                                    }}
                                                    borderless
                                                    isPending={
                                                        this.props.deleteNotificationRequest[note.id]?.isRequesting
                                                    }
                                                />
                                            </li>
                                        );
                                    })}
                                </ol>
                                {!this.props.notifications.hasAll ? (
                                    <div className="u-pr16">
                                        <Button
                                            isBlock
                                            onClick={() => this.props.loadMoreNotifications()}
                                            disabled={
                                                this.props.notifications.isRequesting ||
                                                !!this.props.notifications.errorMessage
                                            }
                                        >
                                            {languageString("notifications.loadMore")}
                                        </Button>
                                    </div>
                                ) : null}
                            </Scroller>
                        </div>
                    ),
                }}
            </ToolMenu>
        );
    }
}
