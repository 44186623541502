import dedent from "dedent";
import random from "lodash/random";

export function createAppIconDataUrl(w: number, h: number): string {
    const svg = createAppIcon(w, h);
    const svg2 = svg.replaceAll("\n", "");
    const svg3 = svg2.replaceAll(/\s{2,}/gi, " ");
    return "data:image/svg+xml;base64," + btoa(svg3);
}

export function createAppIcon(w: number, h: number): string {
    const hsl = [random(0, 360), random(40, 100), random(30, 45)];
    const path = randomShape(w * 0.2, h * 0.2, w * 0.6, h * 0.6);

    return dedent`
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 ${w} ${h}'>
        ${bgGradient(hsl, "bgGrad")}
        <rect fill='url(#bgGrad)' width='${w}' height='${h}' />
        <path fill='black' fill-opacity="0.4" d='${path}' transform='translate(${w * 0.02}, ${h * 0.04})' />
        <path fill='white' d='${path}' />
    </svg>`;
}

function bgGradient(hsl: number[], id: string) {
    const [h, s, l] = hsl;
    return dedent`
    <linearGradient id="${id}" x1=".2" y1="0" x2=".8" y2="1">
        <stop offset="0%" stop-color="${formatHSL([h, s, l + 10])}"/>
        <stop offset="100%" stop-color="${formatHSL([h, s, l - 10])}"/>
    </linearGradient>`;
}

function formatHSL(hsl: number[]) {
    const [h, s, l] = hsl;
    return `hsl(${h}, ${s}%, ${l}%)`;
}

function randomShape(x: number, y: number, width: number, height: number): string {
    const shape = random(1, 3, false);
    switch (shape) {
        case 1: {
            // Polygon
            const count = random(4, 8);
            const deg = (Math.PI * 2) / count;
            const rotate = Math.random() > 0.5 ? deg / 2 : 0;
            const points = new Array(count)
                .fill([0, 0])
                .map((_, i) => [Math.sin(deg * i + rotate), Math.cos(deg * i + rotate)]);
            const radius = Math.min(width, height) / 2;
            const path = points.map((cords) => {
                return [cords[0] * radius + width / 2 + x, cords[1] * radius + height / 2 + y].join(" ");
            });
            return `M ${path.join(" L ")} Z`;
        }
        case 2: {
            // Star
            const count = Math.round(random(8, 14, false) / 2) * 2;
            const deg = (Math.PI * 2) / count;
            const rotate = Math.random() > 0.5 ? deg : 0;
            const points = new Array(count)
                .fill([0, 0])
                .map((_, i) => [Math.sin(deg * i + rotate), Math.cos(deg * i + rotate)]);
            const outerRadius = Math.min(width, height) / 2;
            const innerRadius = outerRadius / 2;
            const path = points.map((cords, i) => {
                const radius = i % 2 > 0 ? outerRadius : innerRadius;
                return [cords[0] * radius + width / 2 + x, cords[1] * radius + height / 2 + y].join(" ");
            });
            return `M ${path.join(" L ")} Z`;
        }
        case 3: {
            //Triangle
            const size = Math.min(width, height);
            const alignedX = x + width * 0.5 - size * 0.5;
            const alignedY = y + height * 0.5 - size * 0.5;
            const padX = width * 0.1;
            const padY = height * 0.1;
            const xp = alignedX + padX;
            const yp = alignedY + padY;
            const wp = size - padX * 2;
            const hp = size - padY * 2;
            switch (random(1, 4, false)) {
                case 1:
                    return `M ${xp + wp / 2} ${yp} L ${xp + wp} ${yp + hp} L ${xp} ${yp + hp} Z`;
                case 2:
                    return `M ${xp} ${yp} L ${xp + wp} ${yp} L ${xp + wp / 2} ${yp + hp} Z`;
                case 3:
                    return `M ${xp} ${yp} L ${xp + wp} ${yp + hp / 2} L ${xp} ${yp + hp} Z`;
                case 4:
                    return `M ${xp} ${yp + hp / 2} L ${xp + wp} ${yp} L ${xp + wp} ${yp + hp} Z`;
                default:
                    return ``;
            }
        }
        default:
            return ``;
    }
}
