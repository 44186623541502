import clsx from "clsx";
import isString from "lodash/isString";
import React from "react";
import { languageString, writeList } from "../../../../utilities/text";
import HiddenSection from "../../hiddenSection/HiddenSection";
import "./labeledInput.css";

interface LabeledInputProps {
    children: React.ReactNode;
    inputId: string;
    label: React.ReactNode;
    hint?: React.ReactNode;
    errorMessage?: string | string[];
    className?: string;
    isCheckbox?: boolean;
    placeHintAbove?: boolean;
    required?: boolean;
}

export default class LabeledInput extends React.PureComponent<LabeledInputProps> {
    static defaultProps = {
        required: true,
    };

    render() {
        const hint = <LabeledInput.Hint>{this.props.hint}</LabeledInput.Hint>;
        const error = (
            <HiddenSection isHidden={!this.props.errorMessage} duration={100} easing="ease">
                <div className="labeledInput-error" role="alert">
                    {Array.isArray(this.props.errorMessage)
                        ? writeList(this.props.errorMessage.filter(isString))
                        : isString(this.props.errorMessage)
                        ? this.props.errorMessage
                        : JSON.stringify(this.props.errorMessage)}
                </div>
            </HiddenSection>
        );

        return (
            <div
                className={clsx("labeledInput", this.props.className, {
                    labeledInput_checkbox: this.props.isCheckbox,
                })}
            >
                {LabeledInput.Label(this.props)}
                <div className="labeledInput-input">
                    {this.props.placeHintAbove && error}
                    {this.props.hint && this.props.placeHintAbove && hint}
                    {!this.props.isCheckbox && this.props.children}
                    {!this.props.placeHintAbove && error}
                    {this.props.hint && !this.props.placeHintAbove && hint}
                </div>
            </div>
        );
    }

    static Label({ required = true, ...props }: Omit<LabeledInputProps, "children"> & { children?: React.ReactNode }) {
        return (
            <label className="labeledInput-label" htmlFor={props.inputId}>
                {props.isCheckbox && props.children}
                <span>
                    {props.label}{" "}
                    {!required && <span className="labeledInput-optional">{languageString("ui.input.optional")}</span>}
                </span>
            </label>
        );
    }

    static Hint({ children, className }: { children?: React.ReactNode; className?: string }) {
        return <div className={clsx("labeledInput-hint", className)}>{children}</div>;
    }
}
