import { matchHistoryRecord } from "@/selectors/matchers";
import { type RubyNotification, type RubyTeamInvite } from "@/services/backend/RubyData";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { type AccountState, type State } from "./domain";
import {
    initialAsyncDataState,
    initialPaginatedAsyncData,
    initialRequestState,
    paginatedRequestActionReducer,
    requestActionReducer,
    requestReportActionReducer,
} from "./requestReducer";

export const initialAccountState: AccountState = {
    notifications: initialPaginatedAsyncData<RubyNotification[]>([]),
    saveNotificationPrefs: initialRequestState(),
    notificationPrefs: initialAsyncDataState(),
    deleteAccount: initialRequestState(),
    answerInvite: initialRequestState(),
    userInvites: initialAsyncDataState<RubyTeamInvite[]>([]),
    historyRecords: [],
    deleteAllNotifications: initialRequestState(),
    deleteNotification: {},
    demoToken: initialAsyncDataState(),
};

export default function accountReducer(
    state: AccountState = initialAccountState,
    action: ActionType,
    _globalState: State
): void {
    switch (action.type) {
        case ActionName.LIST_NOTIFICATIONS: {
            state.notifications = paginatedRequestActionReducer(action, undefined, state.notifications);
            break;
        }

        case ActionName.LOAD_NOTIFICATIONS_PREFERENCES: {
            state.notificationPrefs = requestActionReducer(action, undefined, state.notificationPrefs);
            break;
        }

        case ActionName.DELETE_NOTIFICATION: {
            state.deleteNotification[action.payload.request] = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.notifications.data = state.notifications.data.filter(
                    (note) => note.id !== action.payload.request
                );
                state.notifications.recordsLoaded -= 1;
                state.notifications.totalRecords -= 1;
            }
            break;
        }

        case ActionName.DELETE_ALL_NOTIFICATIONS: {
            state.deleteAllNotifications = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.notifications.hasAll = true;
                state.notifications.recordsLoaded = 0;
                state.notifications.totalRecords = 0;
                state.notifications.data = [];
            }
            break;
        }

        case ActionName.SAVE_NOTIFICATIONS_PREFERENCES: {
            state.saveNotificationPrefs = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.notificationPrefs.data = action.payload.response;
            }
            break;
        }

        case ActionName.DELETE_ACCOUNT: {
            state.deleteAccount = requestActionReducer(action);
            break;
        }

        case ActionName.ANSWER_TEAM_INVITE: {
            state.answerInvite = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.userInvites.data = state.userInvites.data.filter(
                    (invite) => invite.id !== action.payload.request.inviteId
                );
            }
            break;
        }

        case ActionName.LIST_USERS_TEAM_INVITES: {
            state.userInvites = requestActionReducer(action, undefined, state.userInvites);
            break;
        }

        case ActionName.LIST_USER_HISTORY_RECORDS: {
            state.historyRecords = requestReportActionReducer(action, state.historyRecords, matchHistoryRecord);
            break;
        }

        case ActionName.CREATE_DEMO_TOKEN: {
            state.demoToken = requestActionReducer(action, undefined, state.demoToken);
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            state.demoToken = initialAsyncDataState();
            break;
        }
    }
}
