//General
export const VERSION = process.env.PACKAGE_VERSION;
export const NODE_ENV = process.env.NODE_ENV;
export const DEVELOPMENT = NODE_ENV === "development";
export const PRODUCTION = NODE_ENV === "production";
export const CI = process.env.CI === "true";
export const IS_DOWNTIME = parseBool(process.env.REACT_APP_IS_DOWNTIME);
export const SUPPORTED_COUNTRY_CODES = parseArr(process.env.REACT_APP_SUPPORTED_COUNTRY_CODES);
export const SUPPORTED_CURRENCIES = parseArr(process.env.REACT_APP_SUPPORTED_CURRENCIES);
export const SUPPORTED_HOSTED_CURRENCIES = parseArr(process.env.REACT_APP_SUPPORTED_HOSTED_CURRENCIES);

export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const MAX_DATA_AGE_MINUTES = parseFloat(process.env.REACT_APP_MAX_CACHE_DATA_AGE_MINUTES || "60");

export const TERMS_CONDITIONS_VERSION = "2.0.0";

export const LOW_VOLUME_TERMS = "Low volume terms";

//Forms / validation
export const MINIMUM_BID_VALUE = parseCurrencyMap("REACT_APP_MINIMUM_BID_VALUE", "0.04");
export const MIN_DRIP_FEED = parseCurrencyMap("REACT_APP_MINIMUM_DRIP_FEED", "5");

export const MIN_TARGETING_AGE = parseInt(process.env.REACT_APP_MIN_TARGETING_AGE || "18", 10);
export const MAX_TARGETING_AGE = parseInt(process.env.REACT_APP_MAX_TARGETING_AGE || "65", 10);

export const MIN_SEED_KEYWORDS = parseInt(process.env.REACT_APP_MIN_SEED_KEYWORDS || "1", 10);
export const MAX_SEED_KEYWORDS = parseInt(process.env.REACT_APP_MAX_SEED_KEYWORDS || "15", 10);

export const DEFAULT_DISCOVERY_WEIGHTING = parseFloat(process.env.REACT_APP_DEFAULT_DISCOVERY_WEIGHTING || "0.7");
export const SUPPORTED_LOCALE_COUNTRY_CODES = parseArr(process.env.REACT_APP_SUPPORTED_LOCALE_COUNTRY_CODES);

//Contact
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL;
export const PRIVACY_EMAIL = process.env.REACT_APP_PRIVACY_EMAIL;
export const CONTACT_NUMBER = {
    HREF: process.env.REACT_APP_CONTACT_NUMBER_HREF,
    VIEW: process.env.REACT_APP_CONTACT_NUMBER_VIEW,
};

//Demo mode
export const DEMO_MODE = parseBool(process.env.REACT_APP_DEMO_MODE);
export const DEMO_AS_ADMIN = parseBool(process.env.REACT_APP_DEMO_AS_ADMIN);
export const FALLBACK_APP_ICONS = parseBool(process.env.REACT_APP_FALLBACK_APP_ICONS);
export const MOCK_ITUNES_CLIENT = parseBool(process.env.REACT_APP_MOCK_ITUNES_CLIENT);

// Caches
export const WORKER_CACHE_NAMES = {
    PROD: `redbox-platform-cache-v${VERSION}`,
    DEMO: `redbox-platform-demo-cache-v${VERSION}`,
    REPORT: `redbox-platform-report-cache-v${VERSION}`,
};

//Services
export const AUTH0 = {
    DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
    CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
};

export const ASA_AUTH_CLIENT_ID = process.env.REACT_APP_ASA_AUTH_CLIENT_ID;

export const MAILCHIMP = {
    URL: process.env.REACT_APP_MAILCHIMP_SIGNUP_URL,
    HONEYPOT: process.env.REACT_APP_MAILCHIMP_SIGNUP_HONEYPOT,
};

export const STRIPE = {
    PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
};

export const ANALYTICS = {
    ENABLED: parseBool(process.env.REACT_APP_ENABLE_TRACKING),
    EVENT_BLACKLIST: parseArr(process.env.REACT_APP_REDUX_ANALYTICS_EVENT_BLACKLIST),
    GTM_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
};

// Parsers

function parseArr(arr: string) {
    return (arr ?? "")
        .split(",")
        .map((e) => e.trim())
        .filter((e) => !!e);
}

function parseBool(bool: string) {
    return bool === "true";
}

function parseCurrencyMap(prefix: string, fallback: string): Record<string, number> {
    return {
        ...SUPPORTED_CURRENCIES.reduce((set, currency) => {
            set[currency] = parseFloat(process.env[`${prefix}_${currency}`] || fallback);
            return set;
        }, {} as Record<string, number>),
    };
}
