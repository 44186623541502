import type { ExperimentAlignment, ExperimentType } from "@/types/CampaignObjective";
import {
    type BudgetPlanTemplate,
    type CreationState,
    type CreationStep,
    type ExperimentTimeCadenceAlignment,
    type TimeRange,
} from "../reducers/domain";
import {
    type RubyCampaignId,
    type RubyChannelStructure,
    type RubyChannelType,
    type RubyCountry,
    type RubyIOSApp,
    type RubyProductPage,
    type RubyTargetingDimensions,
    type RubyTeamId,
    type RubyTeamOrganisation,
} from "../services/backend/RubyData";
import { type CountryMap } from "../types/utils";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum CreationActionName {
    SET_CREATION_STEP = "SET_CREATION_STEP",
    SET_CREATION_APP = "SET_CREATION_APP",
    SET_CREATION_CHANNEL = "SET_CREATION_CHANNEL",
    SET_CREATION_ORGANISATION = "SET_CREATION_ORGANISATION",
    SET_CREATION_TEAM = "SET_CREATION_TEAM",
    SET_CREATION_REGIONS = "SET_CREATION_REGIONS",
    SET_CREATION_TARGETING = "SET_CREATION_TARGETING",
    SET_CREATION_KEYWORDS = "SET_CREATION_KEYWORDS",
    SET_CREATION_BIDS = "SET_CREATION_BIDS",
    SET_CREATION_BUDGET = "SET_CREATION_BUDGET",
    SET_CREATION_PRODUCT_PAGE = "SET_CREATION_PRODUCT_PAGE",
    CREATE_CAMPAIGN = "CREATE_CAMPAIGN",
    UPDATE_CREATION_PROGRESS = "UPDATE_CREATION_PROGRESS",
    RESET_CREATION = "RESET_CREATION",
    RESTORE_CREATION_STATE = "RESTORE_CREATION_STATE",
    SET_CREATION_EXPERIMENT = "SET_CREATION_EXPERIMENT",
    SET_CREATION_EXPERIMENT_TARGETING = "SET_CREATION_EXPERIMENT_TARGETING",
    SET_CREATION_EXPERIMENT_PRODUCT_PAGE = "SET_CREATION_EXPERIMENT_PRODUCT_PAGE",
    SET_CREATION_EXPERIMENT_REGION = "SET_CREATION_EXPERIMENT_REGION",
    SET_CREATION_EXPERIMENT_KEYWORDS = "SET_CREATION_EXPERIMENT_KEYWORDS",
    SET_CREATION_EXPERIMENT_BID_PRICE = "SET_CREATION_EXPERIMENT_BID_PRICE",
    CREATE_EXPERIMENT = "CREATE_EXPERIMENT",
    READ_EXPERIMENT_INFO = "READ_EXPERIMENT_INFO",
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace CreationActions {
    export type SetCreationStepAction = Action<CreationActionName.SET_CREATION_STEP, CreationStep>;
    export type SetCreationAppAction = Action<CreationActionName.SET_CREATION_APP, RubyIOSApp>;
    export type SetCreationChannelAction = Action<CreationActionName.SET_CREATION_CHANNEL, RubyChannelType>;
    export type SetCreationOrganisationAction = Action<
        CreationActionName.SET_CREATION_ORGANISATION,
        RubyTeamOrganisation["orgRef"]
    >;
    export type SetCreationTeamAction = Action<
        CreationActionName.SET_CREATION_TEAM,
        {
            teamId: RubyTeamId;
            campaignId?: RubyCampaignId;
        }
    >;
    export type SetCreationRegionsAction = Action<
        CreationActionName.SET_CREATION_REGIONS,
        {
            regions: RubyCountry[];
            createSeparateRegions: boolean;
        }
    >;
    export type SetCreationTargetingAction = Action<CreationActionName.SET_CREATION_TARGETING, RubyTargetingDimensions>;
    export type SetCreationKeywordsAction = Action<
        CreationActionName.SET_CREATION_KEYWORDS,
        {
            seedKeywords: string[] | null;
            targetingKeywords: string[];
            targetingKeywordsBroad: string[];
            negativeKeywords: string[];
            isAutomated: boolean;
            lockedBidPrice?: number;
        }
    >;
    export type SetCreationBidsAction = Action<
        CreationActionName.SET_CREATION_BIDS,
        {
            costPerDownloadTarget: number;
            startingBid: number;
            regionalCostPerDownload?: CountryMap<number>;
            regionalStartingBid?: CountryMap<number>;
        }
    >;
    export type SetCreationBudgetAction = Action<CreationActionName.SET_CREATION_BUDGET, BudgetPlanTemplate>;
    export type SetCreationProductPageAction = Action<
        CreationActionName.SET_CREATION_PRODUCT_PAGE,
        RubyProductPage["id"]
    >;
    export type CreateCampaignAction = RequestAction<
        CreationActionName.CREATE_CAMPAIGN,
        {
            name: string;
            description: string;
            orderNumber?: string;
            structure?: RubyChannelStructure;
            notes?: string;
        },
        void
    >;
    export type UpdateCreationProgressAction = Action<CreationActionName.UPDATE_CREATION_PROGRESS, number>;
    export type ResetCreationAction = Action<CreationActionName.RESET_CREATION, void>;
    export type RestoreCreationStateAction = RequestAction<
        CreationActionName.RESTORE_CREATION_STATE,
        void,
        CreationState
    >;
    export type SetCreationExperimentAction = Action<
        CreationActionName.SET_CREATION_EXPERIMENT,
        {
            experimentType: ExperimentType;
            experimentTimeRange: TimeRange;
            experimentAlignment: ExperimentAlignment;
            experimentTimeCadence: number;
            experimentTimeCadenceAlignment: ExperimentTimeCadenceAlignment;
        }
    >;
    export type SetCreationExperimentTargetingAction = Action<
        CreationActionName.SET_CREATION_EXPERIMENT_TARGETING,
        RubyTargetingDimensions
    >;
    export type SetCreationExperimentProductPageAction = Action<
        CreationActionName.SET_CREATION_EXPERIMENT_PRODUCT_PAGE,
        RubyProductPage["id"]
    >;
    export type SetCreationExperimentKeywordsAction = Action<
        CreationActionName.SET_CREATION_EXPERIMENT_KEYWORDS,
        {
            keywords: string[];
            keywordsBroad: string[];
        }
    >;
    export type SetCreationExperimentBidPriceAction = Action<
        CreationActionName.SET_CREATION_EXPERIMENT_BID_PRICE,
        number
    >;
    export type SetCreationExperimentRegion = Action<
        CreationActionName.SET_CREATION_EXPERIMENT_REGION,
        {
            experimentRegion?: RubyCountry;
            adminArea?: RubyTargetingDimensions["adminArea"];
            locality?: RubyTargetingDimensions["locality"];
            experimentAdminArea?: RubyTargetingDimensions["adminArea"];
            experimentLocality?: RubyTargetingDimensions["locality"];
        }
    >;
    export type CreateExperimentAction = RequestAction<
        CreationActionName.CREATE_EXPERIMENT,
        {
            name: string;
            description: string;
            orderNumber?: string;
            structure?: RubyChannelStructure;
            notes?: string;
        },
        void
    >;
    export type ReadExperimentInfoAction = Action<CreationActionName.READ_EXPERIMENT_INFO, void>;
}

export type CreationActionType =
    | CreationActions.SetCreationStepAction
    | CreationActions.SetCreationAppAction
    | CreationActions.SetCreationTeamAction
    | CreationActions.SetCreationRegionsAction
    | CreationActions.SetCreationChannelAction
    | CreationActions.SetCreationTargetingAction
    | CreationActions.SetCreationKeywordsAction
    | CreationActions.SetCreationBidsAction
    | CreationActions.SetCreationBudgetAction
    | CreationActions.CreateCampaignAction
    | CreationActions.UpdateCreationProgressAction
    | CreationActions.SetCreationOrganisationAction
    | CreationActions.ResetCreationAction
    | CreationActions.RestoreCreationStateAction
    | CreationActions.SetCreationProductPageAction
    | CreationActions.SetCreationExperimentAction
    | CreationActions.SetCreationExperimentRegion
    | CreationActions.SetCreationExperimentTargetingAction
    | CreationActions.SetCreationExperimentProductPageAction
    | CreationActions.SetCreationExperimentKeywordsAction
    | CreationActions.SetCreationExperimentBidPriceAction
    | CreationActions.CreateExperimentAction
    | CreationActions.ReadExperimentInfoAction;

export function setCreationStepAction(step: CreationStep): CreationActions.SetCreationStepAction {
    return {
        type: CreationActionName.SET_CREATION_STEP,
        payload: step,
    };
}

export function setCreationAppAction(app: RubyIOSApp): CreationActions.SetCreationAppAction {
    return {
        type: CreationActionName.SET_CREATION_APP,
        payload: app,
    };
}

export function setCreationChannelAction(channelType: RubyChannelType): CreationActions.SetCreationChannelAction {
    return {
        type: CreationActionName.SET_CREATION_CHANNEL,
        payload: channelType,
    };
}

export function setCreationTeamAction(
    teamId: RubyTeamId,
    campaignId?: RubyCampaignId
): CreationActions.SetCreationTeamAction {
    return {
        type: CreationActionName.SET_CREATION_TEAM,
        payload: {
            teamId,
            campaignId,
        },
    };
}

export function setCreationRegionsAction(
    regions: RubyCountry[],
    createSeparateRegions: boolean
): CreationActions.SetCreationRegionsAction {
    return {
        type: CreationActionName.SET_CREATION_REGIONS,
        payload: {
            regions,
            createSeparateRegions,
        },
    };
}

export function setCreationTargetingAction(
    targeting: RubyTargetingDimensions
): CreationActions.SetCreationTargetingAction {
    return {
        type: CreationActionName.SET_CREATION_TARGETING,
        payload: targeting,
    };
}

export function setCreationKeywordsAction(
    seedKeywords: string[] | null,
    targetingKeywords: string[],
    targetingKeywordsBroad: string[],
    negativeKeywords: string[],
    isAutomated: boolean,
    lockedBidPrice?: number
): CreationActions.SetCreationKeywordsAction {
    return {
        type: CreationActionName.SET_CREATION_KEYWORDS,
        payload: {
            seedKeywords,
            targetingKeywords,
            targetingKeywordsBroad,
            negativeKeywords,
            isAutomated,
            lockedBidPrice,
        },
    };
}

export function setCreationBidsAction(payload: {
    costPerDownloadTarget: number;
    startingBid: number;
    regionalCostPerDownload?: CountryMap<number>;
    regionalStartingBid?: CountryMap<number>;
}): CreationActions.SetCreationBidsAction {
    return {
        type: CreationActionName.SET_CREATION_BIDS,
        payload,
    };
}

export function setCreationBudgetAction(plan: BudgetPlanTemplate): CreationActions.SetCreationBudgetAction {
    return {
        type: CreationActionName.SET_CREATION_BUDGET,
        payload: plan,
    };
}

export const createCampaignAction = requestActions<CreationActions.CreateCampaignAction>(
    CreationActionName.CREATE_CAMPAIGN
);

export function updateCreationProgressAction(progress: number): CreationActions.UpdateCreationProgressAction {
    return {
        type: CreationActionName.UPDATE_CREATION_PROGRESS,
        payload: progress,
    };
}

export function setCreationOrganisationAction(
    orgRef: RubyTeamOrganisation["orgRef"]
): CreationActions.SetCreationOrganisationAction {
    return {
        type: CreationActionName.SET_CREATION_ORGANISATION,
        payload: orgRef,
    };
}

export function resetCreationAction(): CreationActions.ResetCreationAction {
    return {
        type: CreationActionName.RESET_CREATION,
        payload: null,
    };
}
export const restoreCreationStateAction = requestActions<CreationActions.RestoreCreationStateAction>(
    CreationActionName.RESTORE_CREATION_STATE
);

export function setCreationProductPageAction(
    productPageId: RubyProductPage["id"]
): CreationActions.SetCreationProductPageAction {
    return {
        type: CreationActionName.SET_CREATION_PRODUCT_PAGE,
        payload: productPageId,
    };
}

export function setCreationExperimentAction(payload: {
    experimentType: ExperimentType;
    experimentTimeRange: TimeRange;
    experimentAlignment: ExperimentAlignment;
    experimentTimeCadence: number;
    experimentTimeCadenceAlignment: ExperimentTimeCadenceAlignment;
}): CreationActions.SetCreationExperimentAction {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT,
        payload,
    };
}

export function setCreationExperimentRegionAction(payload: {
    experimentRegion?: RubyCountry;
    adminArea?: RubyTargetingDimensions["adminArea"];
    locality?: RubyTargetingDimensions["locality"];
    experimentAdminArea?: RubyTargetingDimensions["adminArea"];
    experimentLocality?: RubyTargetingDimensions["locality"];
}): CreationActions.SetCreationExperimentRegion {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT_REGION,
        payload,
    };
}

export function setCreationExperimentTargetingAction(
    targeting: RubyTargetingDimensions
): CreationActions.SetCreationExperimentTargetingAction {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT_TARGETING,
        payload: targeting,
    };
}

export function setCreationExperimentProductPageAction(
    productPageId: RubyProductPage["id"]
): CreationActions.SetCreationExperimentProductPageAction {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT_PRODUCT_PAGE,
        payload: productPageId,
    };
}

export const createExperimentAction = requestActions<CreationActions.CreateExperimentAction>(
    CreationActionName.CREATE_EXPERIMENT
);

export function readExperimentInfoAction(): CreationActions.ReadExperimentInfoAction {
    return {
        type: CreationActionName.READ_EXPERIMENT_INFO,
        payload: null,
    };
}

export function setCreationExperimentKeywordsAction(
    keywords: string[],
    keywordsBroad: string[]
): CreationActions.SetCreationExperimentKeywordsAction {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT_KEYWORDS,
        payload: {
            keywords,
            keywordsBroad,
        },
    };
}

export function setCreationExperimentBidPriceAction(
    bidPrice: number
): CreationActions.SetCreationExperimentBidPriceAction {
    return {
        type: CreationActionName.SET_CREATION_EXPERIMENT_BID_PRICE,
        payload: bidPrice,
    };
}
