import React from "react";
import { default as RCalendar, type CalendarProps as RCalendarProps } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { UTCToLocal, localToUTC } from "../../../utilities/date";
import "./calendar.css";

// This type is dumb and doesn't behave correctly. However it is compatible with react-calendar.
type DateRange = Date | [Date, Date];

// This is a wrapper around react-calendar to handle styling and UTC offsets
export default class CalendarM extends React.PureComponent<CalendarProps> {
    render() {
        return (
            <RCalendar
                maxDetail="month"
                minDetail="month"
                {...this.props}
                onChange={(val: DateRange, evt) => {
                    if (Array.isArray(val)) {
                        val = val.map(localToUTC) as DateRange;
                    } else {
                        val = localToUTC(val) as DateRange;
                    }
                    this.props?.onChange(val, evt);
                }}
                value={(() => {
                    let val = this.props.value;
                    if (Array.isArray(val)) {
                        val = val.map((v) => UTCToLocal(new Date(v))) as DateRange;
                    } else {
                        val = UTCToLocal(new Date(val)) as DateRange;
                    }
                    return val;
                })()}
                maxDate={this.props.maxDate ? UTCToLocal(this.props.maxDate) : null}
                minDate={this.props.minDate ? UTCToLocal(this.props.minDate) : null}
            />
        );
    }
}

export type CalendarProps = RCalendarProps;
