import type { PredicateSupplier } from "@/reducers/requestReducer";
import type {
    RubyEventQuery,
    RubyHistoryRecordQuery,
    RubyKeywordRankQuery,
    RubyKeywordReportQuery,
    RubyMetricsReportQuery,
    RubySearchTermReportQuery,
} from "@/services/backend/RubyData";
import isEqual from "lodash/isEqual";

export const matchHistoryRecord: PredicateSupplier<RubyHistoryRecordQuery> = (query) => (req) =>
    req.from === query.from &&
    req.to === query.to &&
    req.subjectId === query.subjectId &&
    req.type === query.type &&
    req.level === query.level;

export const matchSearchTermsSummary: PredicateSupplier<RubySearchTermReportQuery> = (query) => (req) =>
    req.campaignId === query.campaignId &&
    req.teamId === query.teamId &&
    isEqual(req.campaignRef, query.campaignRef) &&
    isEqual(req.country, query.country) &&
    req.from === query.from &&
    req.to === query.to &&
    isEqual(req.purpose, query.purpose) &&
    isEqual(req.keywordId, query.keywordId);

export const matchEventQuery: PredicateSupplier<RubyEventQuery> = (query) => (req) =>
    req.campaignId === query.campaignId &&
    req.name === query.name &&
    req.token === query.token &&
    req.from === query.from &&
    req.to === query.to &&
    req.teamId === query.teamId &&
    req.timestampKey === query.timestampKey &&
    isEqual(req.country, query.country);

export const matchKeywordSummary: PredicateSupplier<RubyKeywordReportQuery> = (query) => (req) =>
    req.campaignId === query.campaignId &&
    req.teamId === query.teamId &&
    isEqual(req.campaignRef, query.campaignRef) &&
    isEqual(req.country, query.country) &&
    req.keywordId === query.keywordId &&
    req.from === query.from &&
    req.to === query.to;

export const matchKeywordRanking: PredicateSupplier<RubyKeywordRankQuery> = (query) => (req) =>
    req.campaignId === query.campaignId &&
    req.teamId === query.teamId &&
    isEqual(req.country, query.country) &&
    req.searchTerm?.toLocaleLowerCase() === query.searchTerm?.toLocaleLowerCase() &&
    req.adamRef === query.adamRef &&
    req.from === query.from &&
    req.to === query.to;

export const matchKeywordsSummary: PredicateSupplier<RubyMetricsReportQuery> = (query) => (req) =>
    req.campaignId === query.campaignId &&
    req.teamId === query.teamId &&
    isEqual(req.campaignRef, query.campaignRef) &&
    isEqual(req.country, query.country) &&
    req.from === query.from &&
    req.to === query.to;
