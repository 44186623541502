import { createRouterMiddleware } from "@lagunovsky/redux-react-router";
import { configureStore } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { type Middleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { type ActionType } from "../actions/ActionType";
import { errorAction } from "../actions/uiActions";
import createReducer from "../reducers";
import { type State } from "../reducers/domain";
import sagas from "../sagas";
import { CI } from "../utilities/vars";
import { analyticsMiddleware } from "./analyticsMiddleware";
import { consoleAccessMiddleware, cypressMiddleware, exceptionMiddleware, loggerMiddleware } from "./debugMiddleware";

export const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware({
    onError: (err) => {
        console.error(err);
        store.dispatch(errorAction(err));
    },
});

const store = configureStore<State, ActionType, Middleware[]>({
    reducer: createReducer(history),
    middleware: (getDefaults) =>
        getDefaults({
            immutableCheck: CI,
            serializableCheck: CI,
        }).concat([
            createRouterMiddleware(history),
            sagaMiddleware,
            analyticsMiddleware,
            cypressMiddleware,
            loggerMiddleware,
            consoleAccessMiddleware,
            exceptionMiddleware,
        ]),
});

sagaMiddleware.run(sagas);

declare global {
    interface Window {
        dispatchToStore: (action: ActionType) => void;
    }
}
window.dispatchToStore = (action) => {
    store.dispatch(action);
};

export default store;
