import { ExperimentTimeCadenceAlignment } from "@/reducers/domain";
import isEqual from "lodash/isEqual";
import range from "lodash/range";
import {
    type RubyChannel,
    type RubyChannelType,
    type RubyChannelUpdateBody,
    type RubyDeviceClass,
    type RubyGender,
    type RubyIOSApp,
    type RubyTargetingDimensions,
} from "../services/backend/RubyData";
import { isChannelWithScheduleTarget } from "./types";
import { MAX_TARGETING_AGE, MIN_TARGETING_AGE } from "./vars";

export enum Audience {
    EVERYONE = "EVERYONE",
    NEW_USERS = "NEW_USERS",
    MY_OTHER_APPS = "MY_OTHER_APPS",
    RETURNING_USERS = "RETURNING_USERS",
}

export type Demographics = {
    targetAllAges?: boolean;
    minAge?: number;
    maxAge?: number;
    audience?: Audience;
    deviceClass: RubyDeviceClass[];
    gender: RubyGender[];
    locality?: string[];
    adminArea?: string[];
    schedule?: Record<number, boolean>;
};

export interface DemographicsData {
    demographics: Demographics;
    channelType: RubyChannelType;
    channelBody: RubyChannelUpdateBody;
    currentApp: RubyChannel["applicationRef"];
    otherApps: RubyChannel["applicationRef"][];
}

export function demographicsToTargeting(demographicsData: DemographicsData): RubyTargetingDimensions {
    const { demographics, channelBody, currentApp, otherApps, channelType } = demographicsData;
    const { maxAge, minAge, audience, gender, deviceClass, schedule, locality, adminArea } = demographics;

    const genderClean = gender.filter((v) => !!v);
    const deviceClassClean = deviceClass.filter((v) => !!v);
    const localityClean = locality.filter((v) => !!v);
    const adminAreaClean = adminArea.filter((v) => !!v);

    let appDownloaders: RubyTargetingDimensions["appDownloaders"] = undefined;
    if (audience === Audience.NEW_USERS) {
        appDownloaders = {
            excluded: [currentApp.toString()],
        };
    } else if (audience === Audience.MY_OTHER_APPS) {
        appDownloaders = {
            included: otherApps.map((app) => app.toString()),
        };
    } else if (audience === Audience.RETURNING_USERS) {
        appDownloaders = {
            included: [currentApp.toString()],
        };
    }

    let daypart: RubyTargetingDimensions["daypart"] = undefined;
    const activeHours = getActiveScheduleHours(schedule);
    if (activeHours && isChannelWithScheduleTarget(channelType)) {
        daypart = {
            userTime: {
                included: activeHours,
            },
        };
    }

    const targetingDimensions: RubyTargetingDimensions = {
        ...channelBody.targetingDimensions,
        age:
            minAge && maxAge
                ? {
                      included: [
                          {
                              maxAge,
                              minAge,
                          },
                      ],
                  }
                : undefined,
        deviceClass: deviceClassClean.length === 1 ? { included: deviceClassClean } : undefined,
        gender: genderClean.length === 1 ? { included: genderClean } : undefined,
        appDownloaders,
        locality: localityClean.length > 0 ? { included: localityClean } : undefined,
        adminArea: adminAreaClean.length > 0 ? { included: adminAreaClean } : undefined,
        daypart,
    };

    return targetingDimensions;
}

export function targetingToDemographics(
    targeting: RubyTargetingDimensions,
    appId: RubyIOSApp["trackId"]
): Demographics {
    const { age, deviceClass, gender, appDownloaders, locality, daypart, adminArea } = targeting;

    let audience = Audience.EVERYONE;
    if (appDownloaders?.included?.length) {
        if (isEqual(appDownloaders.included, [appId?.toString()])) {
            audience = Audience.RETURNING_USERS;
        } else {
            audience = Audience.MY_OTHER_APPS;
        }
    } else if (appDownloaders?.excluded?.length && isEqual(appDownloaders?.excluded, [appId?.toString()])) {
        audience = Audience.NEW_USERS;
    }

    const schedule = {};
    range(0, 168).forEach((hour) => {
        if (!Array.isArray(daypart?.userTime?.included)) {
            schedule[hour] = true;
        } else {
            schedule[hour] = daypart?.userTime?.included?.includes(hour) ?? false;
        }
    });

    const demographics: Demographics = {
        targetAllAges: !age?.included?.[0]?.minAge,
        minAge: age?.included?.[0]?.minAge ?? MIN_TARGETING_AGE,
        maxAge: age?.included?.[0]?.maxAge ?? MAX_TARGETING_AGE,
        deviceClass: deviceClass?.included ?? ["IPHONE", "IPAD"],
        gender: gender?.included ?? ["M", "F"],
        audience,
        locality: locality?.included ?? [],
        adminArea: adminArea?.included ?? [],
        schedule,
    };
    return demographics;
}

// Note: 0 == 00:00 Sunday morning
//       1 == 01:00 Sunday
//      25 == 01:00 Monday
//     167 == 23:00 Saturday

function getActiveScheduleHours(schedule?: Record<number, boolean>): number[] | undefined {
    if (!schedule) {
        return undefined;
    }
    const active: number[] = [];
    range(0, 168).forEach((hour) => {
        if (schedule?.[hour]) {
            active.push(hour);
        }
    });
    if (active.length === 168 || active.length === 0) {
        return undefined;
    }
    return active;
}

export function getInterlacedScheduleHours(
    cadenceHours: number,
    alignment: ExperimentTimeCadenceAlignment
): {
    activeHoursA: number[];
    activeHoursB: number[];
} {
    const activeHoursA: number[] = [];
    const activeHoursB: number[] = [];

    let latch = false;

    for (let day = 0; day < 7; day++) {
        const buckets: number[][] = [];
        for (let hour = 0; hour < 24; hour += cadenceHours) {
            const weekHour = day * 24 + hour;
            const weekHours = range(weekHour, weekHour + cadenceHours);

            if (alignment === ExperimentTimeCadenceAlignment.REGULAR) {
                if (latch) {
                    activeHoursA.push(...weekHours);
                } else {
                    activeHoursB.push(...weekHours);
                }
                latch = !latch;
            }

            if (alignment === ExperimentTimeCadenceAlignment.ALTERNATING) {
                if (latch) {
                    activeHoursA.push(...weekHours);
                } else {
                    activeHoursB.push(...weekHours);
                }
                if (hour != 24 - cadenceHours || day === 6) {
                    latch = !latch;
                }
            }

            if (alignment === ExperimentTimeCadenceAlignment.RANDOM) {
                buckets.push(weekHours);
            }
        }

        if (alignment === ExperimentTimeCadenceAlignment.RANDOM) {
            while (buckets.length > 0) {
                const weekHourIndex = Math.floor(Math.random() * buckets.length);
                const weekHours = buckets[weekHourIndex];
                if (latch) {
                    activeHoursA.push(...weekHours);
                } else {
                    activeHoursB.push(...weekHours);
                }
                buckets.splice(weekHourIndex, 1);
                latch = !latch;
            }
        }
    }
    return {
        activeHoursA,
        activeHoursB,
    };
}
