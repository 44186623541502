import { type Action as ReduxAction } from "redux";

export interface Action<N, P> extends ReduxAction<N> {
    type: N;
    payload: P;
}

// Typing for async requests

export enum RequestActionState {
    REQUEST = "REQUEST",
    CALL = "CALL",
    SUCCESS = "SUCCESS",
    ERROR = "ERROR",
}

export interface RequestActionPayloadRequest<Req> {
    state: RequestActionState.REQUEST;
    request: Req;
    refresh?: boolean;
}
export interface RequestActionPayloadCall<Req> {
    state: RequestActionState.CALL;
    request: Req;
}
export interface RequestActionPayloadSuccess<Req, Res> {
    state: RequestActionState.SUCCESS;
    request: Req;
    response: Res;
}
export interface RequestActionPayloadError<Req> {
    state: RequestActionState.ERROR;
    request: Req;
    errorMessage: string;
}

export type RequestAction<Name, Req = void, Res = void> = Action<
    Name,
    | RequestActionPayloadRequest<Req>
    | RequestActionPayloadCall<Req>
    | RequestActionPayloadSuccess<Req, Res>
    | RequestActionPayloadError<Req>
>;

export interface RequestActions<Name, Req, Res> {
    request: (r: Req, refresh?: boolean) => Action<Name, RequestActionPayloadRequest<Req>>;
    call: (r: Req) => Action<Name, RequestActionPayloadCall<Req>>;
    success: (r: Req, p: Res) => Action<Name, RequestActionPayloadSuccess<Req, Res>>;
    error: (r: Req, e: string) => Action<Name, RequestActionPayloadError<Req>>;
}

export type ExtractActionTypeName<C extends RequestAction<unknown, unknown, unknown>> = C extends RequestAction<
    infer Name,
    unknown,
    unknown
>
    ? Name
    : unknown;
export type ExtractActionTypeReq<C extends RequestAction<unknown, unknown, unknown>> = C extends RequestAction<
    unknown,
    infer Req,
    unknown
>
    ? Req
    : unknown;
export type ExtractActionTypeRes<C extends RequestAction<unknown, unknown, unknown>> = C extends RequestAction<
    unknown,
    unknown,
    infer Res
>
    ? Res
    : unknown;

export type ExtractRequestActionRequestType<C extends RequestAction<unknown, unknown, unknown>> =
    C extends RequestAction<infer Name, infer Req, infer Res>
        ? ReturnType<RequestActions<Name, Req, Res>["request"]>
        : unknown;
export type ExtractRequestActionResponseType<C extends RequestAction<unknown, unknown, unknown>> =
    C extends RequestAction<infer Name, infer Req, infer Res>
        ? ReturnType<RequestActions<Name, Req, Res>["success"]>
        : unknown;

export function requestActions<A extends RequestAction<unknown, unknown, unknown>>(
    actionName: ExtractActionTypeName<A>
): RequestActions<ExtractActionTypeName<A>, ExtractActionTypeReq<A>, ExtractActionTypeRes<A>> {
    return {
        request: (request: ExtractActionTypeReq<A>, refresh?: boolean) => ({
            type: actionName,
            payload: {
                state: RequestActionState.REQUEST,
                request,
                refresh,
            },
        }),
        call: (request: ExtractActionTypeReq<A>) => ({
            type: actionName,
            payload: {
                state: RequestActionState.CALL,
                request,
            },
        }),
        success: (request: ExtractActionTypeReq<A>, response: ExtractActionTypeRes<A>) => ({
            type: actionName,
            payload: {
                state: RequestActionState.SUCCESS,
                request,
                response,
            },
        }),
        error: (request: ExtractActionTypeReq<A>, errorMessage: string) => ({
            type: actionName,
            payload: {
                state: RequestActionState.ERROR,
                request,
                errorMessage,
            },
        }),
    };
}
