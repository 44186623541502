import { MAILCHIMP } from "../utilities/vars";

export class NewsletterService {
    async signup(emailAddress: string, honey: string) {
        const data = new FormData();
        data.append("EMAIL", emailAddress);
        data.append(MAILCHIMP.HONEYPOT, honey);

        try {
            return fetch(MAILCHIMP.URL, {
                method: "post",
                mode: "no-cors",
                body: data,
            });
        } catch (err) {
            console.error("Mailchimp Error: ", err);
            return Promise.reject("Failed to contact the MailChimp newsletter service"); //TODO languageString
        }
    }
}
