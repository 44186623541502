/* eslint-disable @typescript-eslint/unbound-method */
import { portalService } from "@/services";
import clsx from "clsx";
import isEqual from "lodash/isEqual";
import React, { type CSSProperties } from "react";
import ReactDOM from "react-dom";
import ResizeObserver from "resize-observer-polyfill";
import { tabbable } from "tabbable";
import { v4 as uuid } from "uuid";
import { getModalRoot } from "../../../utilities/dom";
import { OverlayDirection, type ControlledOverlayProps, type OverlayState, type ValidPositionProps } from "./domain";
import { getValidPosition } from "./utils";

export class Overlay<P extends ControlledOverlayProps = ControlledOverlayProps> extends React.PureComponent<
    P,
    OverlayState
> {
    static defaultProps = {
        preferredDirection: OverlayDirection.UP,
        baseClass: "overlay",
    };

    targetRef: React.RefObject<HTMLElement>;
    menuRef: React.RefObject<HTMLDivElement>;
    targetId: string;
    menuId: string;
    lastClickEvent: number;
    backgroundId: string;
    observer: ResizeObserver;

    constructor(props: P) {
        super(props);
        this.targetRef = React.createRef();
        this.menuRef = React.createRef();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
        this.targetId = this.props.children.target.props?.id ?? uuid();
        this.menuId = uuid();
        this.backgroundId = uuid();
        this.observer = new ResizeObserver(() => {
            this.updatePosition();
        });
        this.state = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            centerOffset: 0,
            direction: this.props.preferredDirection,
            lastPositionTime: Date.now(),
            positionCalcCount: 0,
            rects: {
                menuRect: null,
                targetRect: null,
                windowRect: null,
            },
            zIndex: portalService.getZIndex(),
            children: [],
        };
        portalService.registerPortal(this.menuId, this);
        this.handleClick = this.handleClick.bind(this);
        this.handleClickOut = this.handleClickOut.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.isEventWithin = this.isEventWithin.bind(this);
    }

    calculatePosition(): ValidPositionProps {
        const menu = this.menuRef.current;
        // TODO Refactor this out
        // eslint-disable-next-line react/no-find-dom-node
        const target = ReactDOM.findDOMNode(this.targetRef.current) as HTMLElement;

        const targetRect = target.getBoundingClientRect();
        const menuRect = menu.getBoundingClientRect();

        const windowRect = {
            y: window.scrollY,
            x: window.scrollX,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight,
        };

        return getValidPosition(
            {
                targetRect,
                menuRect,
                windowRect,
            },
            this.props.preferredDirection,
            this.props.alignment
        );
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleResize);
        window.addEventListener("scroll", this.handleScroll, { capture: true });
        this.updatePosition();
    }

    componentWillUnmount() {
        this.observer.disconnect();
        portalService.unregisterPortal(this.menuId);
        document.removeEventListener("click", this.handleClickOut);
        window.removeEventListener("resize", this.handleResize);
        document.removeEventListener("keydown", this.handleKeyDown);
        window.removeEventListener("scroll", this.handleScroll, { capture: true });
    }

    captureChildren(): string[] {
        const menu = this.menuRef.current;
        const childMenus = [...menu.querySelectorAll("[data-rb-overlay]")];
        return childMenus.map((el) => el.getAttribute("data-rb-overlay")).filter((i) => !!i);
    }

    componentDidUpdate(prevProps: ControlledOverlayProps) {
        this.observer.observe(this.menuRef.current);

        if (!prevProps.isOpen && this.props.isOpen) {
            //Opening
            this.setState({
                zIndex: portalService.getZIndex(),
                children: this.captureChildren(),
            });
            document.addEventListener("click", this.handleClickOut);
            document.addEventListener("keydown", this.handleKeyDown);
            setTimeout(() => {
                if (this.menuRef.current) {
                    const tabbables = tabbable(this.menuRef.current, {
                        includeContainer: true,
                    });
                    if (tabbables[0]) {
                        tabbables[0].focus();
                    } else {
                        this.menuRef.current.focus();
                    }
                }
            }, 100);
        }
        if (prevProps.isOpen && !this.props.isOpen) {
            //Closing
            document.removeEventListener("click", this.handleClickOut);
            document.removeEventListener("keydown", this.handleKeyDown);
            if (this.targetRef.current && document.activeElement?.closest(`[id="${this.menuId}"]`)) {
                // TODO Refactor this out
                // eslint-disable-next-line react/no-find-dom-node
                (ReactDOM.findDOMNode(this.targetRef.current) as HTMLElement).focus();
            }
        }
        if (this.props.isOpen) {
            this.updatePosition();
        }
    }

    updatePosition() {
        const position = this.calculatePosition();
        const currentPosition: ValidPositionProps = {
            x: this.state.x,
            y: this.state.y,
            width: this.state.width,
            height: this.state.height,
            direction: this.state.direction,
            centerOffset: this.state.centerOffset,
            rects: this.state.rects,
        };

        const isLoop = Date.now() - this.state.lastPositionTime < 500;
        const shouldBreak = isLoop && this.state.positionCalcCount > 5;

        if (!this.getStateEquality(currentPosition, position) && !shouldBreak) {
            this.setState({
                ...position,
                lastPositionTime: Date.now(),
                positionCalcCount: isLoop ? this.state.positionCalcCount + 1 : 1,
            });
        }
    }

    getStateEquality(a: ValidPositionProps, b: ValidPositionProps) {
        if (isEqual(a, b)) {
            return true;
        }
        return (
            a.x === b.x &&
            a.y === b.y &&
            a.width === b.width &&
            a.height === b.height &&
            a.centerOffset === b.centerOffset &&
            a.direction === b.direction
        );
    }

    getChildren(): Overlay[] {
        return this.state.children.map((childId) => portalService.getPortal(childId)).filter((p) => !!p) as Overlay[];
    }

    isEventWithin(e: Event): boolean {
        const children = this.getChildren();
        return (
            !!(e.target as HTMLElement).closest(`[id="${this.menuId}"]`) ||
            children.some((child) => child.isEventWithin(e))
        );
    }

    handleScroll(e: WheelEvent) {
        if (this.props.isOpen && !this.isEventWithin(e)) {
            this.handleClick();
        }
    }

    handleKeyDown(e: KeyboardEvent) {
        if (this.props.isOpen && e.key === "Escape") {
            this.handleClick();
        }
        if (this.props.isOpen && e.key === "Tab" && this.menuRef.current) {
            // TODO Refactor this out
            // eslint-disable-next-line react/no-find-dom-node
            const target = ReactDOM.findDOMNode(this.targetRef.current) as HTMLElement;
            const tabbables = tabbable(this.menuRef.current);
            if (tabbables.length < 1) {
                this.menuRef.current.focus();
                return;
            }
            // Tabbing off the end of the menu returns to flow order
            if (!e.shiftKey && tabbables[tabbables.length - 1] === document.activeElement && this.targetRef.current) {
                target.focus();
            }
            // Tabbing into the menu
            else if (!e.shiftKey && target === document.activeElement && tabbables[0]) {
                e.preventDefault();
                tabbables[0].focus();
            }
            // Shift tabbing out of menu
            else if (e.shiftKey && document.activeElement === tabbables[0] && this.targetRef.current) {
                e.preventDefault();
                target.focus();
            }
            // Shift tabbing into the bottom of the nav
            else if (e.shiftKey && !document.activeElement?.closest(`.${this.props.baseClass}`)) {
                setTimeout(() => {
                    if (target === document.activeElement) {
                        tabbables[tabbables.length - 1].focus();
                    }
                }, 50);
            }
        }
    }

    handleClick(e?: MouseEvent) {
        this.lastClickEvent = e?.timeStamp;
        this.props.onToggle(!this.props.isOpen);
        this.getChildren().forEach((child) => child.props.isOpen && child.handleClick(e));
    }

    handleClickOut(e: MouseEvent) {
        // Don't do anything if this is the same click event as just opened the menu
        if (this.lastClickEvent > 0 && e?.timeStamp === this.lastClickEvent) {
            return;
        }

        if (this.props.isOpen && (this.props.autoClose || !this.isEventWithin(e))) {
            this.handleClick();
        }
    }

    handleResize() {
        if (this.props.isOpen) {
            this.handleClick();
        }
    }

    drawBackground() {
        // Abstract
        return null;
    }

    getPositionStyles(): CSSProperties {
        return {
            top: `${this.state.y || 0}px`,
            left: `${this.state.x || 0}px`,
        };
    }

    render() {
        const style = {
            ...this.getPositionStyles(),
            zIndex: this.state.zIndex,
        };

        const directionClass = `${this.props.baseClass}_${this.state.direction as unknown as string}`;
        const alignmentClass = `${this.props.baseClass}_${this.props.alignment as unknown as string}`;

        return (
            <>
                {React.cloneElement(this.props.children.target, {
                    ref: this.targetRef,
                    onClick: this.handleClick,
                    "aria-haspopup": "menu",
                    "aria-expanded": this.props.isOpen,
                    "aria-controls": this.menuId,
                    id: this.targetId,
                    "data-rb-overlay": this.menuId,
                })}
                {ReactDOM.createPortal(
                    <div
                        ref={this.menuRef}
                        className={clsx(this.props.baseClass, this.props.className, directionClass, alignmentClass, {
                            "is-open": this.props.isOpen,
                        })}
                        style={style}
                        aria-hidden={!this.props.isOpen}
                        role="menu"
                        id={this.menuId}
                        aria-labelledby={this.targetId}
                    >
                        {this.drawBackground()}
                        <div className={`${this.props.baseClass}-content`}>{this.props.children.content}</div>
                    </div>,
                    getModalRoot()
                )}
            </>
        );
    }
}
