import clsx from "clsx";
import React from "react";
import { roundCorners } from "../../../../utilities/paths";
import { cssVar } from "../../../../utilities/style";
import { Overlay } from "../Overlay";
import OverlayController from "../OverlayController";
import { OverlayAlignment, OverlayDirection, type OverlayProps } from "../domain";
import "./toolMenu.css";

export default function ToolMenu(props: OverlayProps) {
    return (
        <OverlayController>
            {(isOpen, onToggle) => <ControlledToolMenu {...props} isOpen={isOpen} onToggle={onToggle} />}
        </OverlayController>
    );
}

export class ControlledToolMenu extends Overlay {
    static defaultProps = {
        preferredDirection: OverlayDirection.RIGHT,
        alignment: OverlayAlignment.START,
        baseClass: "toolMenu",
    };

    drawBackground() {
        const state = this.state;
        let width = state.width;
        let height = state.height;
        const t = state.rects.targetRect;
        const tWidth = t?.width ?? 0;
        const tHeight = t?.height ?? 0;
        let tTop = 0;
        let tLeft = 0;

        let d = `
            M 0 0
            H ${state.width}
            V ${state.height}
            H 0
            Z
        `;
        let preD = `
            M 0 0
            H ${state.width}
            V ${state.height}
            H 0
            Z
        `;

        if (state.direction.isRight) {
            const c = state.height / 2 + state.centerOffset;
            width = state.width + tWidth;
            tLeft = 0 - tWidth;
            tTop = c - tHeight / 2;
            d = `
                M ${t?.width ?? 0} 0
                H ${state.width + tWidth}
                V ${state.height}
                H ${t?.width ?? 0}
                V ${c + tHeight / 2}
                H 0
                V ${c - tHeight / 2}
                H ${t?.width ?? 0}
                Z
            `;
            preD = `
                M ${tWidth + 1} ${c - tHeight / 2}
                H ${tWidth + 2}
                V ${c + tHeight / 2}
                H ${tWidth + 1}
                H ${tWidth}
                H 0
                V ${c - tHeight / 2}
                H ${tWidth}
                Z
            `;
        }
        if (state.direction.isLeft) {
            const c = state.height / 2 + state.centerOffset;
            width = state.width + tWidth;
            tLeft = state.width;
            tTop = c - tHeight / 2;
            d = `
                M 0 0
                H ${state.width}
                V ${c - tHeight / 2}
                H ${state.width + tWidth}
                V ${c + tHeight / 2}
                H ${state.width}
                V ${state.height}
                H 0
                Z
            `;
            preD = `
                M ${state.width - 2} ${c - tHeight / 2}
                H ${state.width - 1}
                H ${state.width}
                H ${state.width + tWidth}
                V ${c + tHeight / 2}
                H ${state.width}
                H ${state.width - 1}
                H${state.width - 2}
                Z
            `;
        }
        if (state.direction.isDown) {
            const c = state.width / 2 + state.centerOffset;
            height = state.height + tHeight;
            tTop = 0 - tHeight;
            tLeft = c - tWidth / 2;
            d = `
                M 0 ${t?.height ?? 0}
                H ${c - tWidth / 2}
                V 0
                H ${c + tWidth / 2}
                V ${t?.height ?? 0}
                H ${state.width}
                V ${state.height + tHeight}
                H 0
                Z
            `;
            preD = `
                M ${c - tWidth / 2} ${tHeight + 1}
                V ${tHeight}
                V 0
                H ${c + tWidth / 2}
                V ${tHeight}
                V ${tHeight + 1}
                V ${tHeight + 2}
                H ${c - tWidth / 2}
                Z
            `;
        }
        if (state.direction.isUp) {
            const c = state.width / 2 + state.centerOffset;
            height = state.height + tHeight;
            tTop = state.height;
            tLeft = c - tWidth / 2;
            d = `
                M 0 0
                H ${state.width}
                V ${state.height}
                H ${c + tWidth / 2}
                V ${state.height + tHeight}
                H ${c - tWidth / 2}
                V ${state.height}
                H 0
                Z
            `;
            preD = `
                M ${c - tWidth / 2} ${state.height - 2}
                H ${c + tWidth / 2}
                V ${state.height - 1}
                V ${state.height}
                V ${state.height + tHeight}
                H ${c - tWidth / 2}
                V ${state.height}
                V ${state.height - 1}
                Z
            `;
        }

        // Smooth paths
        const radius = parseInt(cssVar("--interactableRadius"));
        d = roundCorners(d, radius);
        preD = roundCorners(preD, radius);

        // Calculate paddings
        const padding = 20;
        let padLeft = padding;
        let padTop = padding;

        if (state.direction.isVertical && tWidth > width) {
            width = tWidth;
            if (this.props.alignment.isCenter) {
                padLeft += (tWidth - state.width) / 2;
            }
            if (this.props.alignment.isEnd) {
                padLeft += tWidth - state.width;
            }
        }
        if (state.direction.isHorizontal && tHeight > height) {
            height = tHeight;
            if (this.props.alignment.isCenter) {
                padTop += (tHeight - state.height) / 2;
            }
            if (this.props.alignment.isEnd) {
                padTop += tHeight - state.height;
            }
        }

        // Extend the class
        // Making an assumption the child is able to have a className
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
        const className = clsx(this.props.children.target?.props?.className, "toolMenu-target");

        return (
            <>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={`${-padLeft} ${-padTop} ${width + padding * 2} ${height + padding * 2}`}
                    style={{
                        width: width + padding * 2,
                        height: height + padding * 2,
                        "--insetX": `${0 - padLeft}px`,
                        "--insetY": `${0 - padTop}px`,
                    }}
                    aria-hidden
                >
                    <defs>
                        <linearGradient id={`${this.backgroundId}`} x1={0} x2={1} y1={0} y2={0}>
                            <stop offset="0%" stopColor="white" stopOpacity={0.1} />
                            <stop offset="100%" stopColor="white" stopOpacity={0} />
                        </linearGradient>
                    </defs>
                    <path
                        d={d}
                        fill="var(--backgroundColor)"
                        style={{
                            "--closedPath": `path("${preD}")`,
                        }}
                    />
                    <path
                        d={d}
                        stroke="var(--borderColor)"
                        fill={`url(#${this.backgroundId})`}
                        style={{
                            "--closedPath": `path("${preD}")`,
                        }}
                    />
                </svg>

                {/* Render a second copy of the target button within the background to resolve stacking issues */}
                {React.cloneElement(this.props.children.target, {
                    onClick: (e: MouseEvent) => this.handleClick(e),
                    style: {
                        position: "absolute",
                        left: `${tLeft}px`,
                        top: `${tTop}px`,
                        "--input-border-color": "transparent",
                        "--input-border-color-active": "transparent",
                        "--input-background-color": "none",
                        // Overriding these values here is a corner cut that should be handled better, but it works in practice
                        borderColor: "transparent",
                        background: "none",
                        width: t?.width,
                        height: t?.height,
                    },
                    className,
                })}
            </>
        );
    }
}
