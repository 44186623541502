import { portalService } from "@/services";
import clsx from "clsx";
import noScroll from "no-scroll";
import React from "react";
import ReactDOM from "react-dom";
import { tabbable, type FocusableElement } from "tabbable";
import { v4 as uuid } from "uuid";
import { getModalRoot } from "../../../utilities/dom";
import { isSnapshot } from "../../../utilities/snapshot";
import { languageString } from "../../../utilities/text";
import ButtonIcon from "../buttons/icon/ButtonIcon";
import type ButtonLink from "../buttons/link/ButtonLink";
import type Button from "../buttons/standard/Button";
import Scroller from "../scroller/Scroller";
import "./modal.css";

export interface ModalProps {
    isOpen?: boolean;
    preventClose?: boolean;
    fullWidth?: boolean;

    onClose?: () => void;

    title: React.ReactNode;
    children?: React.ReactNode;
    nextButton?: React.ReactElement<Button | ButtonLink>;
    prevButton?: React.ReactElement<Button | ButtonLink>;
    ltrButtonOrder?: boolean;
    id?: string;
}

interface ModalState {
    previousFocus: HTMLElement;
    zIndex: number;
}

export default class Modal<T extends ModalProps> extends React.PureComponent<T, ModalState> {
    modalRef: React.RefObject<HTMLDivElement & HTMLFormElement>;
    uniqueId: string;

    constructor(props: T) {
        super(props);
        this.state = {
            previousFocus: null,
            zIndex: portalService.getZIndex(),
        };
        this.uniqueId = uuid();
        this.modalRef = React.createRef();
    }

    componentDidMount() {
        window.addEventListener("keydown", this.handleKeydown.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.handleKeydown.bind(this));
        noScroll.off();
        document.querySelector("#pageUI")?.setAttribute("aria-hidden", "false");
    }

    componentDidUpdate(prevProps: T) {
        if (!prevProps.isOpen && this.props.isOpen) {
            // Opened
            const previousFocus = document.activeElement as HTMLElement;
            if (previousFocus) {
                this.setState({
                    previousFocus,
                });
            }
            this.setState({
                zIndex: portalService.getZIndex(),
            });
            if (this.modalRef.current) {
                setTimeout(() => {
                    const modalTabbables = tabbable(this.modalRef.current);
                    modalTabbables[0]?.focus();
                }, 100);
            }
            setTimeout(() => {
                if (window.innerHeight < document.body.clientHeight) {
                    noScroll.on();
                }
                document.querySelector("#pageUI")?.setAttribute("aria-hidden", "true");
            }, 150);
        } else if (prevProps.isOpen && !this.props.isOpen) {
            // Closed
            noScroll.off();
            document.querySelector("#pageUI")?.setAttribute("aria-hidden", "false");
            if (this.state.previousFocus) {
                this.state.previousFocus.focus();
            }
        }
    }

    handleKeydown(e: KeyboardEvent) {
        if (this.props.isOpen && e.key === "Escape" && !this.props.preventClose) {
            this.handleClose();
        }
        if (this.props.isOpen && e.key === "Tab") {
            // Handle trapped focus
            e.preventDefault();
            const focus = document.activeElement as FocusableElement;
            const modalTabbables = tabbable(this.modalRef.current);
            const focusIndex = modalTabbables.findIndex((t) => t === focus);
            let nextFocusIndex = focusIndex + (e.shiftKey ? -1 : 1);
            if (focusIndex < 0) {
                nextFocusIndex = 0;
            }
            nextFocusIndex =
                nextFocusIndex < 0 ? modalTabbables.length + nextFocusIndex : nextFocusIndex % modalTabbables.length;
            modalTabbables[nextFocusIndex]?.focus();
        }
    }

    handleBackdropClick() {
        if (!this.props.preventClose) {
            this.handleClose();
        }
    }

    handleClose() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        if (isSnapshot) {
            return null;
        }
        return ReactDOM.createPortal(
            <div
                className={clsx("modal", {
                    "is-open": this.props.isOpen,
                })}
                aria-hidden={!this.props.isOpen}
                style={{ zIndex: this.state.zIndex }}
                id={this.props.id}
            >
                <div onClick={this.handleBackdropClick.bind(this)} className="modal-backdrop" role="presentation" />
                {this.renderContainer(
                    <>
                        {this.renderHeader()}
                        <div className={`modal-body`}>
                            <Scroller className="modal-scroll" autoHeight maxHeight={"70vh"} scrollShadow>
                                {this.renderChildren()}
                            </Scroller>
                        </div>
                        {this.renderFooter()}
                        {!this.props.preventClose && (
                            <ButtonIcon
                                icon="Close"
                                label={languageString("ui.alt.closeModal", "Close")}
                                onClick={this.handleClose.bind(this)}
                                className="modal-close u-absolute"
                                borderless
                                type="button"
                            />
                        )}
                    </>
                )}
            </div>,
            getModalRoot()
        );
    }

    getContainerProps() {
        return {
            className: `modal-frame ${this.props.fullWidth ? "modal-frame_large" : ""}`,
            "aria-modal": true,
            role: "dialog",
            "aria-labelledby": `modalHeader-${this.uniqueId}`,
            ref: this.modalRef,
        };
    }

    renderContainer(children: React.ReactNode): React.ReactNode {
        return <div {...this.getContainerProps()}>{children}</div>;
    }

    renderHeader(): React.ReactNode {
        return (
            <header className="modal-header" id={`modalHeader-${this.uniqueId}`}>
                {this.props.title}
            </header>
        );
    }

    renderFooter(): React.ReactNode {
        if (this.props.nextButton || this.props.prevButton) {
            const buttons = [this.props.nextButton ?? <div />, this.props.prevButton ?? <div />];
            if (this.props.ltrButtonOrder) {
                buttons.reverse();
            }
            return (
                <footer
                    className={clsx("modal-footer", {
                        "modal-footer_ltr": this.props.ltrButtonOrder,
                    })}
                >
                    {buttons.map((btn, i) =>
                        React.cloneElement(btn, {
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                            key: btn.props?.key ?? `modalBtn-${i}`,
                        })
                    )}
                </footer>
            );
        }
        return null;
    }

    renderChildren(): React.ReactNode {
        return <div className="modal-innerBody">{this.props.children}</div>;
    }
}
