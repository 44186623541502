import clsx from "clsx";
import React from "react";
import { languageString } from "../../../utilities/text";
import Button from "../buttons/standard/Button";
import Icon from "../icon/Icon";
import { OverlayDirection } from "../overlays/domain";
import Tooltip from "../overlays/tooltip/Tooltip";
import "./hintIcon.css";

interface HintIconProps {
    title?: string;
    children: React.ReactNode;
    className?: string;
    inline?: boolean;
}

export default class HintIcon extends React.PureComponent<HintIconProps> {
    render() {
        return (
            <Tooltip autoClose preferredDirection={OverlayDirection.UP}>
                {{
                    target: (
                        <Button
                            type="button"
                            buttonType="link"
                            className={clsx("hintIcon", this.props.className, {
                                hintIcon_inline: this.props.inline,
                            })}
                            area-label={
                                this.props.title
                                    ? languageString("ui.alt.hint", "Explain what this means")
                                    : languageString("ui.alt.hintTitle", "Explain {1}", [this.props.title])
                            }
                        >
                            <Icon.Help width={12} height={12} />
                        </Button>
                    ),
                    content: (
                        <div className="hintIcon-menu">
                            {this.props.title && <h3 className="hintIcon-title">{this.props.title}</h3>}
                            <div className="hintIcon-text">{this.props.children}</div>
                        </div>
                    ),
                }}
            </Tooltip>
        );
    }
}
