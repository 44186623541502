import { all } from "redux-saga/effects";
import accountSaga from "./accountSaga";
import bulkSaga from "./bulkSaga";
import campaignSaga from "./campaignSaga";
import configSaga from "./configSaga";
import creationSaga from "./creationSaga";
import interactionSaga from "./interactionSaga";
import keywordsSaga from "./keywordsSaga";
import metaSaga from "./metaSaga";
import reportsSaga from "./reportsSaga";
import rulesSaga from "./rulesSaga";
import teamSaga from "./teamSaga";
import uiSaga from "./uiSaga";
import userSaga from "./userSaga";

export default function* rootSaga() {
    yield all([
        accountSaga(),
        userSaga(),
        metaSaga(),
        teamSaga(),
        campaignSaga(),
        reportsSaga(),
        uiSaga(),
        keywordsSaga(),
        configSaga(),
        creationSaga(),
        interactionSaga(),
        bulkSaga(),
        rulesSaga(),
    ]);
}
