/* eslint-disable @typescript-eslint/unbound-method */
import clsx from "clsx";
import clone from "lodash/clone";
import uniqBy from "lodash/uniqBy";
import React from "react";
import { formatNumber } from "../../../../utilities/number";
import { languageString } from "../../../../utilities/text";
import BlockMessage from "../../blockMessage/BlockMessage";
import ButtonIcon from "../../buttons/icon/ButtonIcon";
import Button from "../../buttons/standard/Button";
import Icon from "../../icon/Icon";
import Scroller from "../../scroller/Scroller";
import Input from "../input/Input";
import "./arrayInput.css";

interface ArrayInputProps {
    className?: string;
    value: string[];
    onChange?: (values: string[]) => void;
    isInvalid?: boolean;
    disabled?: boolean;
    emptyText?: string;
    id?: string;
    displayFilter?: string;
    removeOnly?: boolean;
    caseSensitive?: boolean;
    formatter?: (value: string) => React.ReactNode;
}

interface ArrayInputState {
    inputValue: string;
}

export default class ArrayInput extends React.PureComponent<ArrayInputProps, ArrayInputState> {
    static defaultProps = {
        formatter: (a: string) => a,
    };

    constructor(props: ArrayInputProps) {
        super(props);
        this.state = {
            inputValue: "",
        };
        this.handleInput = this.handleInput.bind(this);
        this.handleEnter = this.handleEnter.bind(this);
        this.addValue = this.addValue.bind(this);
        this.removeValues = this.removeValues.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handlePaste = this.handlePaste.bind(this);
    }

    handleInput(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        this.setState({
            inputValue: value,
        });
    }

    handleEnter(e: React.KeyboardEvent) {
        if (e.key === "Enter") {
            e.preventDefault();
            this.addValue();
        }
    }

    handleBlur() {
        if (this.state.inputValue) {
            this.addValue();
        }
    }

    handlePaste(e: React.ClipboardEvent<HTMLInputElement>) {
        e.preventDefault();
        const pastedText = e.clipboardData.getData("text/plain");
        const parsed = pastedText
            .trim()
            .split("\n")
            .map((t) => t.trim())
            .join(", ");

        const start = e.currentTarget.selectionStart;
        const end = e.currentTarget.selectionEnd;
        const length = end - start;

        const newValueChars = this.state.inputValue.split("");
        newValueChars.splice(start, length, ...parsed.split(""));
        const newValue = newValueChars.join("");

        this.setState({
            inputValue: newValue,
        });
    }

    addValue() {
        if (this.props.onChange) {
            const newValue = this.state.inputValue;
            let newValues: string[];
            if (newValue.includes(",")) {
                newValues = newValue.split(",").map((val) => val.trim());
            } else {
                newValues = [newValue.trim()];
            }
            newValues = newValues.filter((val) => !!val.trim());

            const changedValue = uniqBy(
                [...this.props.value, ...newValues],
                !this.props.caseSensitive ? (a) => a.toLocaleLowerCase() : (a) => a
            );
            this.props.onChange(changedValue);
        }
        this.setState({
            inputValue: "",
        });
    }

    removeValues(removedValues: string[]) {
        if (this.props.onChange) {
            const newValues = this.props.value.filter((val) => !removedValues.includes(val));
            this.props.onChange(newValues);
        }
    }

    render() {
        let displayValue = this.props.value;
        if (this.props.displayFilter) {
            displayValue = clone(this.props.value).filter((val) => val.includes(this.props.displayFilter));
        }
        const isEmpty = this.props.value.length < 1 || displayValue.length < 1;
        return (
            <div
                className={clsx(`arrayInput`, this.props.className, {
                    "is-invalid": this.props.isInvalid,
                    arrayInput_removeOnly: this.props.removeOnly,
                })}
            >
                <div className="arrayInput-wrap">
                    {!this.props.removeOnly && (
                        <div className="arrayInput-inputWrap">
                            <Input
                                id={this.props.id}
                                className="arrayInput-input"
                                onKeyDown={this.handleEnter}
                                onBlur={this.handleBlur}
                                value={this.state.inputValue}
                                onChange={this.handleInput}
                                type="text"
                                disabled={this.props.disabled}
                                onPaste={this.handlePaste}
                            />
                            <button
                                className="input-btn"
                                type="button"
                                onClick={this.addValue}
                                aria-label="Add"
                                disabled={this.props.disabled}
                            >
                                <Icon.Add />
                            </button>
                        </div>
                    )}

                    <Scroller
                        height={200}
                        className={`arrayInput-scrollOuter`}
                        virtualise
                        virtualisedProps={{
                            listClassName: `arrayInput-scrollInner ${isEmpty ? "is-empty" : ""}`,
                        }}
                    >
                        {[
                            ...displayValue.map((value) => (
                                <div key={value} className="arrayInput-word">
                                    <span>{this.props.formatter(value)}</span>
                                    <ButtonIcon
                                        icon="Close"
                                        label={languageString("ui.alt.removeFromArray", `Remove '{1}'`, [value])}
                                        borderless
                                        onClick={() => this.removeValues([value])}
                                        disabled={this.props.disabled}
                                    />
                                </div>
                            )),

                            this.props.value.length > 0 && displayValue.length < 1 && (
                                <BlockMessage className="arrayInput-msg" key="filterHiddenMessage">
                                    {languageString("ui.input.array.hidden", "{1} hidden by filter", [
                                        formatNumber(this.props.value.length),
                                    ])}
                                </BlockMessage>
                            ),

                            this.props.value.length < 1 && (
                                <BlockMessage className="arrayInput-msg" key="emptyMessage">
                                    {this.props.emptyText || languageString("ui.input.array.empty", "Empty")}
                                </BlockMessage>
                            ),
                        ].filter((r) => !!r)}
                    </Scroller>
                </div>

                <div className="u-textRight u-pt16">
                    <Button
                        buttonType="link"
                        className="arrayInput-clearBtn"
                        onClick={() => this.removeValues(displayValue)}
                        type="button"
                        disabled={this.props.disabled || displayValue.length < 1}
                    >
                        {languageString("ui.input.array.clear", "Clear All")}
                    </Button>
                </div>
            </div>
        );
    }
}
