import { type LocationChangeAction } from "@lagunovsky/redux-react-router";
import { Action as HistoryAction } from "history";
import { takeEvery } from "redux-saga/effects";
import { ActionName } from "../actions/ActionType";
import { selectFromState } from "../utilities/saga";

//TODO fix this
function* restorePageScrollSaga(action: LocationChangeAction) {
    //Reset keyboard users to the top of the page
    (document.activeElement as HTMLElement).blur();

    if (action.payload.action === HistoryAction.Pop) {
        const lastPosition = yield* selectFromState(
            (state) => state.meta.scrollHistory[action.payload.location.pathname]
        );
        if (lastPosition > 0) {
            window.scrollTo(0, lastPosition);
        } else {
            window.scrollTo(0, 0);
        }
    } else if (
        action.payload.action === HistoryAction.Push &&
        action.payload.location.pathname.startsWith("/support/")
    ) {
        // Do nothing - The support page handles its own scroll
    } else {
        window.scrollTo(0, 0);
    }
}

export default function* metaSaga() {
    yield takeEvery(ActionName.LOCATION_CHANGE, restorePageScrollSaga);
}
