import React, { type CSSProperties } from "react";
import "./hr.css";

interface HrProps {
    vertical?: boolean;
    className?: string;
    color?: CSSProperties["color"];
}

export default class Hr extends React.PureComponent<HrProps> {
    render() {
        return (
            <hr
                style={{ "--hr-color": this.props.color ?? null }}
                className={`hr ${this.props.vertical ? "hr_vertical" : ""} ${this.props.className || ""}`}
            />
        );
    }
}
