import * as Yup from "yup";
import { formatCurrency } from "./currency";
import { languageString } from "./text";
import { MINIMUM_BID_VALUE } from "./vars";

export const nameValidation = (schema?: Yup.StringSchema) =>
    (schema ? schema : Yup.string())
        .trim()
        .min(3, languageString("ui.validation.minLength", "", ["3"]))
        .matches(/[\p{L}\d]/u, languageString("ui.validation.requiresText"));

export const emailValidation = (schema?: Yup.StringSchema) =>
    (schema ? schema : Yup.string()).email(languageString("ui.validation.email"));

export const getBidValidator = (currency: string) =>
    Yup.number()
        .transform((val: number) => (isNaN(val) ? undefined : val))
        .min(
            MINIMUM_BID_VALUE[currency],
            languageString("campaign.budget.validation.bidMinimum", "", [
                formatCurrency(MINIMUM_BID_VALUE[currency], currency),
            ])
        );
