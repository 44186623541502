import { toggleNavigationAction } from "@/actions/uiActions";
import { useActionDispatcher } from "@/store/stateHooks";
import { languageString } from "../../../utilities/text";
import ButtonIcon from "../../blocks/buttons/icon/ButtonIcon";
import "./navigation.css";

export default function NavigationButton() {
    const onToggle = useActionDispatcher(toggleNavigationAction);

    return (
        <ButtonIcon
            className="navigation-button"
            onClick={() => onToggle(true)}
            icon="Menu"
            label={languageString("ui.alt.viewNavigation", "Open the navigation menu")}
            borderless
            size={24}
        />
    );
}
