import moment, { type Moment } from "moment";
import { formatDate } from "./date";
import { languageString } from "./text";

export enum ReportGranularity {
    HOURLY = "HOURLY",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}
export interface Temporal {
    date: number;
    granularity: ReportGranularity;
}

export const second = 1000;
export const minute = second * 60;
export const hour = minute * 60;
export const day = hour * 24;
export const week = day * 7;
export const year = day * 365;

export const seconds = (amount: number) => amount * second;
export const minutes = (amount: number) => amount * minute;
export const hours = (amount: number) => amount * hour;
export const days = (amount: number) => amount * day;
export const weeks = (amount: number) => amount * week;
export const years = (amount: number) => amount * year;

export async function delay(delay: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

export function addGranularity(now: number, granularity: ReportGranularity) {
    switch (granularity) {
        case ReportGranularity.HOURLY: {
            return now + hours(1);
        }
        case ReportGranularity.DAILY: {
            return now + days(1);
        }
        case ReportGranularity.WEEKLY: {
            return now + weeks(1);
        }
        case ReportGranularity.MONTHLY: {
            const date = moment.utc(now).add(1, "month");
            return date.valueOf();
        }
        case ReportGranularity.YEARLY: {
            return now + years(1);
        }
        default:
            return now + years(999);
    }
}

export function formatDateAtGranularity(date: Moment | Date | number, granularity: ReportGranularity) {
    switch (granularity) {
        case ReportGranularity.HOURLY: {
            return formatDate(date, {
                day: "numeric",
                month: "short",
                hour: "numeric",
                minute: "numeric",
            });
        }
        case ReportGranularity.DAILY: {
            return formatDate(date);
        }
        case ReportGranularity.WEEKLY: {
            const weekStart = moment.utc(date).startOf("week");
            const weekEnd = moment.utc(date).endOf("week");

            const start = formatDate(weekStart, { day: "numeric", month: "short" });
            const end = formatDate(weekEnd, { day: "numeric", month: "short" });
            const year = formatDate(weekEnd, { year: "numeric" });

            return languageString("ui.date.week", "{1} - {2}, {3}", [start, end, year]);
        }
        case ReportGranularity.MONTHLY: {
            return formatDate(date, { month: "long", year: "numeric" });
        }
        case ReportGranularity.YEARLY: {
            return formatDate(date, { year: "numeric" });
        }
        default:
            return formatDate(date);
    }
}

export function getDateRange(dates: number[]): [number, number] {
    const { from, to } = dates.reduce(
        (bounds, date) => {
            if (date < bounds.from) {
                bounds.from = date;
            }
            if (date > bounds.to) {
                bounds.to = date;
            }
            return bounds;
        },
        { from: Date.now(), to: 0 }
    );
    return [from, to];
}

export function getTimelineGranularity(startDate: number, endDate: number) {
    const lengthInDays = Math.abs(moment.utc(endDate).diff(moment.utc(startDate), "days"));
    if (lengthInDays <= 1) {
        return ReportGranularity.HOURLY;
    } else if (lengthInDays <= 31) {
        return ReportGranularity.DAILY;
    } else if (lengthInDays <= 152) {
        return ReportGranularity.WEEKLY;
    } else {
        return ReportGranularity.MONTHLY;
    }
}

export function getDefaultGranularity(reports: Temporal[], from?: number, to?: number): ReportGranularity {
    if (!from || !to) {
        [from, to] = getDateRange(reports.map((t) => t.date));
    }
    return getTimelineGranularity(from, to);
}

export function getTemporalRange(reports: Temporal[]): [number, number] {
    return getDateRange(reports.map((report) => report.date));
}

export function aggregateAtGranularity<T extends Temporal>(
    reports: T[],
    granularity: ReportGranularity,
    emptyReport: Omit<T, "date" | "granularity">,
    aggregator: (data: T[]) => Omit<T, "date" | "granularity">,
    from?: number,
    to?: number
): T[] {
    //TODO this currently assumes we start with HOURLY input data

    if (!from || !to) {
        [from, to] = getTemporalRange(reports);
    }

    from = tsToStartOfGranularity(from, granularity);
    to = tsToStartOfGranularity(to, granularity);

    const reportGroups: T[] = generateEmptyTemporalReport(from, to, granularity, emptyReport);

    const reportMap = reportGroups.reduce((map, empty) => {
        map.set(empty.date, []);
        return map;
    }, new Map<number, T[]>());

    reports.forEach((report) => {
        const group = reportGroups.find(
            (group) => group.date <= report.date && addGranularity(group.date, granularity) > report.date
        );
        if (group) {
            const mapKey = group.date;
            reportMap.get(mapKey).push(report);
        }
    });

    const aggregated: T[] = [];
    reportMap.forEach((reports, date) => {
        aggregated.push({
            ...emptyReport,
            ...aggregator(reports.length ? reports : [{ ...emptyReport, date, granularity } as T]),
            date,
            granularity,
        } as T);
    });

    return aggregated;
}

export function generateEmptyTemporalReport<T extends Temporal>(
    from: number,
    to: number,
    granularity: ReportGranularity,
    emptyReport: Omit<T, "date" | "granularity">
): T[] {
    from = tsToStartOfGranularity(from, granularity);
    to = tsToStartOfGranularity(to, granularity);

    const reportGroups: T[] = [];
    let i = from;
    while (i <= to && i <= Date.now()) {
        reportGroups.push({
            date: i,
            granularity,
            ...emptyReport,
        } as T);
        i = addGranularity(i, granularity);
    }
    return reportGroups;
}

export function tsToStartOfGranularity(date: number, granularity: ReportGranularity) {
    return moment.utc(date).startOf(reportGranularityToMomentUnit(granularity)).valueOf();
}

export function tsToEndOfGranularity(date: number, granularity: ReportGranularity) {
    return moment.utc(date).endOf(reportGranularityToMomentUnit(granularity)).valueOf();
}

export function tsToNearestGranularity(date: number, granularity: ReportGranularity) {
    const half = addGranularity(0, granularity) / 2;
    return tsToStartOfGranularity(date + half, granularity);
}

export function reportGranularityToMomentUnit(granularity: ReportGranularity): moment.unitOfTime.Base {
    switch (granularity) {
        case ReportGranularity.HOURLY:
            return "hour";
        case ReportGranularity.DAILY:
            return "day";
        case ReportGranularity.WEEKLY:
            return "week";
        case ReportGranularity.MONTHLY:
            return "month";
        case ReportGranularity.YEARLY:
            return "year";
    }
}
