export function getApplicationRoot(): HTMLElement {
    return document.getElementById("root") ?? document.body;
}

function selectOrCreateById(id: string): HTMLElement {
    let element = document.getElementById(id);
    if (!element) {
        element = document.createElement("div");
        element.setAttribute("id", id);
        getApplicationRoot().append(element);
    }
    return element;
}

export function getModalRoot(): HTMLElement {
    return selectOrCreateById("root-modal");
}
